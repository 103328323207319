import React from 'react';
import { connect } from 'react-redux';
import CopyText from './CopyText';
import useTranslation from 'components/customHooks/useTranslation';

import {
  IAddress,
  InitialOrderInterface,
  ICustomerCartInfo,
  IEcommerceProductData,
  ResponseModalDataInterface,
} from 'pages/inbox/inboxInterface';

import SingleProduct from './SingleProduct';
import Button from 'library/button';
import { CircleSpinner } from 'pages/inbox/assets/iconComponent/Spinner';

interface PaymentMethodInterface {
  is_enabled: boolean;
  method_id: string;
  method_title: string;
}

interface ShippingMethodInterface {
  method_id: string;
  method_title: string;
  shipping_cost: number;
}

interface Props {
  teamId: number;
  currentTicket: any;
  currencySymbol: string;
  modalHeader: React.ReactNode;
  billingInformation: IAddress;
  shippingInformation: IAddress;
  sameAsShippingAddress: boolean;
  customerCart: ICustomerCartInfo;
  orderInformation: InitialOrderInterface;
  paymentMethodList: PaymentMethodInterface[];
  shippingMethodList: ShippingMethodInterface[];
  responseModalData: ResponseModalDataInterface;
  setStep: (step: number) => void;
  sendCheckoutLink: (data: string) => void;
  setShowRightbar: (value: boolean) => void;
  renderWooCommerceOrderAmountsView: (step: number) => void;
  setOpenResponseModal: (data: boolean) => void;
  fetchCustomerInfo: (customerId: number) => Promise<any>;
  setShowResponseModalLoader: (data: boolean) => void;
  setResponseModalData: (data: ResponseModalDataInterface) => void;
  updateEcommerceStateData: (key: string, value: boolean) => void;
  createWooCommerceOrder: (requestBody: {
    teamId: number;
    customerId: number;
    ticketId: number;
    billing_address: IAddress;
    shipping_address: IAddress;
    paymentMethod: PaymentMethodInterface;
    shippingMethod: ShippingMethodInterface;
    couponCode: string;
  }) => Promise<any>;
}

const OrderSummary: React.FC<Props> = ({
  teamId,
  modalHeader,
  customerCart,
  currentTicket,
  currencySymbol,
  orderInformation,
  paymentMethodList,
  responseModalData,
  shippingMethodList,
  billingInformation,
  shippingInformation,
  sameAsShippingAddress,
  setStep,
  setShowRightbar,
  sendCheckoutLink,
  fetchCustomerInfo,
  setOpenResponseModal,
  setResponseModalData,
  createWooCommerceOrder,
  updateEcommerceStateData,
  setShowResponseModalLoader,
  renderWooCommerceOrderAmountsView,
}) => {
  let aliceCart = customerCart?.alice_cart;
  const { t } = useTranslation();
  const [apiRequestOnGoing, setApiRequestOnGoing] = React.useState(false);

  const getStringFromObject = (
    obj: IAddress,
    fields: Array<string> = [],
    fullObject: boolean = false
  ) => {
    let objectToString = '';

    if (fullObject) {
      objectToString =
        `${obj?.first_name} ${obj?.last_name}` +
        `${obj?.email && ', ' + obj?.email}` +
        `${obj?.phone && ', ' + obj?.phone}` +
        `${obj?.address_one && ', ' + obj?.address_one}` +
        `${obj?.address_two && ', ' + obj?.address_two}` +
        `${obj?.city && ', ' + obj?.city}` +
        `${obj?.state && ', ' + obj?.state}` +
        `${obj?.postcode && ', ' + obj?.postcode}`;
    } else {
      for (let key of fields) {
        if (obj[key]) {
          objectToString += !!objectToString ? ', ' + obj[key] : obj[key];
        }
      }
    }

    return !!objectToString ? objectToString : 'N/A';
  };

  const handleSuccessResponse = async (response: any) => {
    await fetchCustomerInfo(currentTicket?.customer_id);
    setResponseModalData({
      ...responseModalData,
      title: t('Congratulations!'),
      description: t(
        `You have successfully placed an order #${response?.dataSource?.ecommerce_order_id}`
      ),
      success: true,
      firstButtonName: t('Send Checkout Link'),
      secondButtonName: t('Back to Chat'),
      firstBtnCss: 'bg-primary text-white',
      secondBtnCss: 'text-gray-600 bg-white',
      firstBtnAction: () => {
        sendCheckoutLink(response?.dataSource?.checkout_link);
      },
      secondBtnAction: () => {
        setOpenResponseModal(false);
      },
    });

    setOpenResponseModal(true);
    setApiRequestOnGoing(false);
    setShowResponseModalLoader(false);
    setShowRightbar(false);
    updateEcommerceStateData('openWooCommerceModal', false);
  };

  const handleErrorResponse = (errorMessage: string) => {
    setApiRequestOnGoing(false);
    setShowResponseModalLoader(false);
    setResponseModalData({
      ...responseModalData,
      title: t('Something went wrong'),
      description: (
        <p
          dangerouslySetInnerHTML={{
            __html: `<div class='woocommerce-response-description'>${t(
              t(errorMessage)
            )} </div>`,
          }}
        ></p>
      ),
      success: false,
      firstButtonName: t('Try Again'),
      secondButtonName: t('Cancel'),
      firstBtnCss: 'text-gray-600',
      secondBtnCss: 'text-gray-600 bg-white',
      firstBtnAction: handleCreateWooCommerceOrder,
      secondBtnAction: () => {
        setOpenResponseModal(false);
      },
    });
    setOpenResponseModal(true);
  };

  const handleCreateWooCommerceOrder = async () => {
    setApiRequestOnGoing(true);

    // process billing and shipping address
    let billing_address = billingInformation;
    let shipping_address = shippingInformation;

    let paymentMethod = paymentMethodList.filter(
      (item: PaymentMethodInterface) => {
        return item.method_title === orderInformation?.paymentMethod;
      }
    );

    let shippingMethod = shippingMethodList.filter(
      (item: ShippingMethodInterface) => {
        return item.method_title === orderInformation?.deliveryType;
      }
    );
    shippingMethod[0].shipping_cost = orderInformation?.wooCommerceShippingCost;

    let response = await createWooCommerceOrder({
      teamId: teamId,
      customerId: customerCart?.customer_id,
      ticketId: currentTicket?.id,
      billing_address: billing_address,
      shipping_address: shipping_address,
      paymentMethod: paymentMethod[0],
      shippingMethod: shippingMethod[0],
      couponCode:
        orderInformation?.couponOrDiscount === 'No coupon/discount'
          ? ''
          : orderInformation?.couponOrDiscount,
    });

    if (response.success) {
      await handleSuccessResponse(response);
    } else {
      handleErrorResponse(response.error);
    }
  };

  const handleTextToCopy = () => {
    return (
      `Name: ${shippingInformation?.first_name} ${shippingInformation?.last_name}` +
      `\nEmail: ${shippingInformation?.email}` +
      `\nPhone: ${shippingInformation?.phone}` +
      `\nShipping Address: ${getStringFromObject(
        shippingInformation,
        ['address_one', 'address_two', 'city', 'state', 'postcode'],
        false
      )}` +
      `\nBilling Information: ${
        sameAsShippingAddress
          ? 'Same as shipping address'
          : getStringFromObject(billingInformation, [], true)
      }` +
      `\nPayment Method: ${orderInformation?.paymentMethod}` +
      `\nShipping Method: ${orderInformation?.deliveryType}` +
      `\nCoupons: ${
        orderInformation?.couponOrDiscount
          ? orderInformation?.couponOrDiscount
          : 'N/A'
      }`
    );
  };

  const renderAliceCartProductsView = () => {
    return (
      aliceCart &&
      aliceCart.map((product: IEcommerceProductData, key: number) => {
        return (
          <SingleProduct
            key={key}
            product={product}
            orderSummaryPage={true}
            currencySymble={currencySymbol}
          />
        );
      })
    );
  };

  const renderBillingAndShippingInformationView = () => {
    return (
      <div key={'billing-and-shipping-information'}>
        <div className='flex pt-4 pb-1 text-xs border border-transparent border-t-gray-200 space-between'>
          <span>{t('Billing & Shipping')}</span>
          <span>
            <div className='float-right'>
              <CopyText height={20} textToCopy={handleTextToCopy()} />
            </div>
          </span>
        </div>

        <div className='flex flex-col gap-2 py-4 text-xs border border-transparent border-t-gray-200'>
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-900'>Name</span>
            <span className='w-[62%] text-gray-500'>
              {shippingInformation?.first_name} {shippingInformation?.last_name}
            </span>
          </p>
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-900'>Email</span>
            <span className='w-[62%] text-gray-500'>
              {shippingInformation?.email}
            </span>
          </p>
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-900'>Phone</span>
            <span className='w-[62%] text-gray-500'>
              {shippingInformation?.phone}
            </span>
          </p>
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-900'>
              {t('Shipping Address')}
            </span>
            <span className='w-[62%] text-gray-500'>
              {getStringFromObject(
                shippingInformation,
                ['address_one', 'address_two', 'city', 'state', 'postcode'],
                false
              )}
            </span>
          </p>
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-900'>
              {sameAsShippingAddress && t('Billing Address')}
              {!sameAsShippingAddress && t('Billing Information')}
            </span>
            {sameAsShippingAddress && (
              <span className='w-[62%] text-gray-500'>
                {t('Same as shipping address')}
              </span>
            )}
            {!sameAsShippingAddress && (
              <span className='w-[62%] text-gray-500'>
                {getStringFromObject(billingInformation, [], true)}
              </span>
            )}
          </p>
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-900'>{t('Payment method')}</span>
            <span className='w-[62%] text-gray-500'>
              {orderInformation?.paymentMethod}
            </span>
          </p>
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-900'>
              {t('Shipping method')}
            </span>
            <span className='w-[62%] text-gray-500'>
              {orderInformation?.deliveryType}
            </span>
          </p>
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-900'>Coupons</span>
            <span className='w-[62%] text-gray-500'>
              {orderInformation?.couponOrDiscount &&
                orderInformation?.couponOrDiscount !== 'No coupon/discount' &&
                orderInformation?.couponOrDiscount}
              {(!orderInformation?.couponOrDiscount ||
                orderInformation?.couponOrDiscount === 'No coupon/discount') &&
                'N/A'}
            </span>
          </p>
        </div>
      </div>
    );
  };

  const renderFooterButtonsView = () => {
    return (
      <div className='flex space-between text-sm border border-transparent border-t-gray-200 p-2 h-[7.5vh]'>
        <Button
          className={`w-[49%] h-[100%] rounded-md justify-center`}
          onClick={() => setStep(2)}
        >
          Back
        </Button>

        <Button
          isDisabled={apiRequestOnGoing}
          intent='primary'
          onClick={() => {
            handleCreateWooCommerceOrder();
          }}
          className={` justify-center w-[49%] h-[100%] rounded-md`}
        >
          {t('Place Order ')}&nbsp;
          {apiRequestOnGoing && <CircleSpinner />}
        </Button>
      </div>
    );
  };

  return (
    <div key={'order-summary'} className='flex flex-col h-full'>
      {modalHeader}

      <div className='h-[86vh] create-wooCoomerce-order px-3 overflow-auto'>
        <div className='mb-4'>{renderAliceCartProductsView()}</div>

        {renderWooCommerceOrderAmountsView(3)}

        {renderBillingAndShippingInformationView()}
      </div>

      {renderFooterButtonsView()}
    </div>
  );
};

const mapState = (state: any) => ({
  customerCart: state.ecommerce.cart,
  currentTicket: state.inbox.selectedTicket,
  teamId: state.dashboard.selectedProject.id,
  paymentMethodList: state.ecommerce.paymentMethodList,
  shippingMethodList: state.ecommerce.shippingMethodList,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
});

const mapDispatch = (dispatch: any) => ({
  updateEcommerceStateData: (key: string, value: any) =>
    dispatch.ecommerce.updateEcommerceStateData({ key, value }),
  createWooCommerceOrder: (requestBody: {
    teamId: number;
    customerId: number;
    ticketId: number;
    billing_address: IAddress;
    shipping_address: IAddress;
    paymentMethod: PaymentMethodInterface;
    shippingMethod: ShippingMethodInterface;
    couponCode: string;
  }) => dispatch.ecommerce.createWooCommerceOrder(requestBody),
  fetchCustomerInfo: (customerId: number) =>
    dispatch.ecommerce.fetchCustomerInfo(customerId),
});

export default connect(mapState, mapDispatch)(OrderSummary);
