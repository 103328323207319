import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import {
  classNames,
  getPlatformInfoTitle,
  getUpdatedPlatformIconOutlined,
} from '../../utilities/utils';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import IomnihubIcon from 'assets/icons/partnerIconSet/iomnihub.svg';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface IChannelTypeProps {
  id: number | null;
  type: string;
  title: string | undefined;
  [id: string]: any;
  // this interface can contain more keys but these are required channel
}

interface Prop {
  value: IChannelTypeProps;
  onChange: (channel: any) => void;
  options: IChannelTypeProps[];
  hasAllChannel?: boolean;
}

const ChannelListDropdown: React.FC<Prop> = ({
  value,
  onChange,
  options,
  hasAllChannel = true,
}) => {
  const { t } = useTranslation();
  const allChannelData: IChannelTypeProps = {
    id: null,
    type: 'all',
    title: 'All Channels',
  };
  const { isPartnerRestricted, currentDomain } = usePartner();
  const shouldReplaceMyaliceLogo = isPartnerRestricted(
    'ChannelListDropdown/node/channel-logo'
  );

  const renderDefaultLogo = () => {
    if (shouldReplaceMyaliceLogo) {
      switch (currentDomain) {
        case 'app.iomnihub.ai':
          return IomnihubIcon;
        default:
          return getUpdatedPlatformIconOutlined('all');
      }
    }
    return getUpdatedPlatformIconOutlined('all');
  };

  const renderChannelLogo = (type: string) => {
    if (type === 'all') {
      return renderDefaultLogo();
    }
    return getUpdatedPlatformIconOutlined(type);
  };

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div id='channel-logo' className='relative w-full mt-1 md:w-auto'>
            <Listbox.Button className='relative w-full py-2 ltr:pl-3 ltr:pr-16 rtl:pl-16 rtl:pr-3 ltr:text-left rtl:text-right bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer md:w-60 focus:outline-none sm:text-xs'>
              <span className='flex items-center'>
                <img
                  src={renderChannelLogo(
                    !!value.platform_type
                      ? value.platform_type !== 'all'
                        ? value.platform_type
                        : 'all'
                      : 'all'
                  )}
                  alt=''
                  className='flex-shrink-0 w-4 h-4'
                />
                <span className='block ltr:ml-3 rtl:mr-3 truncate'>
                  {getPlatformInfoTitle(value)}
                </span>
              </span>
              <span className='absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center pr-2 ml-3 pointer-events-none'>
                <ChevronDownIcon
                  className='w-4 h-4 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              >
                {!!hasAllChannel && (
                  <Listbox.Option
                    key={0}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-primary' : 'text-gray-900',
                        'cursor-default select-none relative py-1.5 ltr:pl-2.5 ltr:pr-2.5 rtl:pl-2.5 rtl:pr-2.5'
                      )
                    }
                    value={allChannelData}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className='flex items-center'>
                          <img
                            src={renderChannelLogo('all')}
                            alt='channel logo'
                            className='flex-shrink-0 w-4 h-4'
                          />
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ltr:ml-2 rtl:mr-2 block truncate'
                            )}
                          >
                            {t(getPlatformInfoTitle(allChannelData))}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary',
                              'absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-4 rtl:pl-4'
                            )}
                          >
                            <CheckIcon className='w-4 h-4' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                )}
                {options.map((channel: any, index: number) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-primary' : 'text-gray-900',
                        'cursor-default select-none relative py-1.5 px-2.5'
                      )
                    }
                    value={channel}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className='flex items-center'>
                          <img
                            src={renderChannelLogo(
                              !!channel.platform_type
                                ? channel.platform_type
                                : 'all'
                            )}
                            alt='channel logo'
                            className='flex-shrink-0 w-4 h-4'
                          />
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ltr:ml-2 rtl:mr-2 block truncate'
                            )}
                          >
                            {getPlatformInfoTitle(channel)}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary',
                              'absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-4 rtl:pl-4'
                            )}
                          >
                            <CheckIcon className='w-4 h-4' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default ChannelListDropdown;
