import {
  useDispatch,
  useAutomationWorkflow,
  useSelector,
} from 'pages/automationWorkflow/export';
import { ITriggerList } from 'pages/automationWorkflow/interface';

const useTriggerActions = () => {
  const dispatch = useDispatch();
  const { selectedTrigger, orderStatusChange, selectedTriggerTab } =
    useAutomationWorkflow();

  // Destructure required state loading from the workflowAutomation
  const { fetchAutomationViewData: isLoadingAutomatioViewDataApi } =
    useSelector((state: any) => state.loading.effects.workflowAutomation);
  const { selectedProject } = useSelector((state: any) => state.dashboard);

  //change the UI of Triggers
  const updateConditionallyRenderingTriggerUI = (link: string) => {
    dispatch.workflowAutomation.updateConditionallyRenderingTriggerUI(link);
  };

  //Get the data of automation Workflow
  const selectedTriggerTabAction = (triggerType: string | null) => {
    dispatch.workflowAutomation.updateSelectedTriggerTab(triggerType);
    fetchAutomationViewData();
  };

  //Fetch Selected Trigger Data
  const fetchAutomationViewData = () => {
    dispatch.workflowAutomation.fetchAutomationViewData({
      selectedEcommerce: selectedProject?.has_ecommerce_connected
        ? selectedProject?.ecommerce_type
        : selectedTriggerTab,
      projectId: selectedProject?.id,
    });
  };

  const fetchSelectedTriggerDetailsData = () => {
    //Fetch Sepecific Executor Data
    dispatch.workflowAutomation.fetchSelectedTriggerDetails({
      ecommerceName: selectedTriggerTab,
      selectedTriggerCode: selectedTrigger?.trigger_code,
      projectId: selectedProject?.id,
    });
  };

  //Handle selected trigger details
  const handleSelectedTrigger = (trigger: ITriggerList) => {
    //Save selected Trigger data
    dispatch.workflowAutomation.updateSelectedTrigger({
      trigger_name: trigger?.trigger_name,
      trigger_code: trigger?.trigger_code,
      trigger_description: trigger?.trigger_description,
      trigger_icon: trigger?.trigger_icon,
    });
  };

  //Change the value of order status dropdown
  const changeOrderStatus = (
    statusType: string,
    statusCode: string | number
  ) => {
    if (statusType === 'form_status') {
      dispatch.workflowAutomation.updateOrderStatusChange({
        statusForm: statusCode,
        toStatus: orderStatusChange?.toStatus,
      });
    } else {
      dispatch.workflowAutomation.updateOrderStatusChange({
        statusForm: orderStatusChange?.statusForm,
        toStatus: statusCode,
      });
    }
  };

  return {
    changeOrderStatus,
    handleSelectedTrigger,
    fetchAutomationViewData,
    selectedTriggerTabAction,
    isLoadingAutomatioViewDataApi,
    fetchSelectedTriggerDetailsData,
    updateConditionallyRenderingTriggerUI,
  };
};

export default useTriggerActions;
