import React, { ReactElement, useEffect, useState } from 'react';
import TagCloud from '../AutomationMetrics/TagCloud';
import ReportingLineChart from '../AutomationMetrics/ReportingLineChart';
import TicketTable from '../AutomationMetrics/TicketTable';
import { connect } from 'react-redux';
import { analytics } from '../../../../utilities/content';
import DataStack from '../CommonComponent/DataStack';
import dayJs from 'dayjs';
import { navigate } from '@reach/router';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { Button } from 'evergreen-ui';
import { SelectedTeamInterface } from '../../../../index';
import { get_avg_ticket_per_overall_user } from 'utilities/utils';
import NewAlertModal from 'library/modal/NewAlertModal';

interface props {
  uniqueTicketCount: dataStackProps;
  avgTicketPerOverallUser: dataStackProps;
  ticketCreatedCount: dataStackProps;
  ticketResolvedCount: dataStackProps;
  avgProcessFRT: dataStackProps;
  textualVsAutomation: textualVsAutomationDataProps[];
  ticketTagCloud: tagCloudProps[];
  ticketListTable: ticketListTableProps[];
  agentList: agentListProps[];
  defaultGroup: agentListProps;
  setDefaultGroup: (val: any) => void;
  defaultDataLoader: boolean;
  ticketListLoader: boolean;
  exportCustomerTicket: (
    projectId: number,
    groupId: number | null
  ) => Promise<boolean>;
  exportIncomingVsAutomationChart: (projectId: number) => void;
  exportTagCloud: (projectId: number) => void;
  exportCustomerTicketLoader: boolean;
  incomingVsAutomationExportLoader: boolean;
  tagCloudExportLoader: boolean;
  totalTicketList: number;
  limit: number;
  offset: number;
  userEmail: string;
  setOffset: (val: number) => void;
  handleTicketListNext: () => void;
  handleTicketListPrevious: () => void;
  handleAgentSelect: (agentId: number) => void;
  updateStateData: (key: string, value: any) => void;
  loader: boolean;
  selectedProject: SelectedTeamInterface;
}

interface dataStackProps {
  current_value: number | string;
  growth_direction: '+' | '-' | null;
  growth_value: string;
  previous_value: number | string;
  span: number;
}

interface textualVsAutomationDataProps {
  textual_message: string | number;
  automated_message: string | number;
  timestamp: number;
}

interface tagCloudProps {
  name: string;
  count: number;
}

interface ticketListTableProps {
  id: number;
  description: string;
  created_at: string | number;
  customer: any;
  tags: [];
  resolved_at: string | number;
  resolved_by: any;
  first_assignment_time: number | string | null;
  resolution_time: number | string | null;
  first_response_time: number | string | null;
  average_response_time: number | string | null;
}

interface agentListProps {
  id: number | null;
  email: string;
  avatar: string | null;
  full_name: string;
}

interface ExportStateProps {
  show: boolean;
  success: boolean;
  message: {
    title: string;
    description: string | ReactElement;
  };
}

const AutomationMetrics: React.FC<props> = ({
  uniqueTicketCount,
  avgTicketPerOverallUser,
  ticketCreatedCount,
  ticketResolvedCount,
  avgProcessFRT,
  loader,
  textualVsAutomation,
  ticketTagCloud,
  ticketListTable,
  agentList,
  defaultGroup,
  setDefaultGroup,
  defaultDataLoader,
  ticketListLoader,
  exportCustomerTicket,
  exportCustomerTicketLoader,
  exportIncomingVsAutomationChart,
  incomingVsAutomationExportLoader,
  exportTagCloud,
  tagCloudExportLoader,
  totalTicketList,
  handleAgentSelect,
  userEmail,
  limit,
  offset,
  setOffset,
  handleTicketListPrevious,
  handleTicketListNext,
  selectedProject,
  updateStateData,
}) => {
  const perPageTagLimit = 5;
  const [tagCloudOffset, setTagCloudOffset] = React.useState(0);
  const [sortedData, setSortedData] = useState(ticketTagCloud);
  const [sort, setSort] = useState('decending');
  const [tableReportExportResponse, setTableReportExportResponse] =
    useState<ExportStateProps>({
      show: false,
      success: false,
      message: {
        title: '',
        description: '',
      },
    });

  useEffect(() => {
    const value = get_avg_ticket_per_overall_user(
      ticketCreatedCount,
      uniqueTicketCount
    );
    updateStateData('avgTicketPerOverallUser', value);
    //eslint-disable-next-line
  }, [ticketCreatedCount, uniqueTicketCount]);

  const handleDataSort = () => {
    setSortedData(sortedData.reverse());
    setTagCloudOffset(0);
  };

  const getDataCards = (projectID: number) => {
    let data = [
      {
        ...uniqueTicketCount,
        name: analytics.automationMetrics.dataCard[0].title,
        loader: loader,
        toolTip: analytics.automationMetrics.dataCard[0].toolTip,
      },
      {
        ...avgTicketPerOverallUser,
        name: analytics.automationMetrics.dataCard[1].title,
        loader: loader,
        toolTip: analytics.automationMetrics.dataCard[1].toolTip,
      },
      {
        ...ticketCreatedCount,
        name: analytics.automationMetrics.dataCard[2].title,
        loader: loader,
        toolTip: analytics.automationMetrics.dataCard[2].toolTip,
      },
      {
        ...ticketResolvedCount,
        name: analytics.automationMetrics.dataCard[3].title,
        loader: loader,
        toolTip: analytics.automationMetrics.dataCard[3].toolTip,
      },
    ];
    if (projectID === 943) {
      data = [
        ...data,
        {
          ...avgProcessFRT,
          name: analytics.automationMetrics.dataCard[4].title,
          loader: loader,
          toolTip: analytics.automationMetrics.dataCard[4].toolTip,
        },
      ];
    }

    return data;
  };

  useEffect(() => {
    setSortedData(ticketTagCloud);
  }, [ticketTagCloud]);

  const overlay = (selectedProject: SelectedTeamInterface) => {
    return (
      <div
        className='z-10 absolute w-full h-full'
        aria-labelledby='modal-title'
        role='dialog'
        aria-modal='true'
      >
        <div className='flex flex-col items-center justify-center h-full bg-white bg-opacity-95 rounded-lg'>
          <LockClosedIcon className='w-10 h-10 p-2.5 m-5 text-blue-900 bg-blue-100 rounded-[50%]' />
          <p className='text-sm font-medium text-gray-900'>
            Upgrade to Premium
          </p>
          <p className='text-sm font-normal text-gray-500'>
            Add Superpowers to your support arsenal!
          </p>
          {selectedProject.is_creator ? (
            <Button
              onClick={() => navigate('/settings/billing')}
              className='button-alice__gradient m-5'
            >
              Upgrade Now
            </Button>
          ) : (
            <p className='text-sm font-normal text-gray-500'>
              Contact your team owner to upgrade subscription plan.
            </p>
          )}
        </div>
      </div>
    );
  };

  const handleExportTicketTableReport = async () => {
    const res = await exportCustomerTicket(
      selectedProject?.id,
      defaultGroup.id
    );
    if (res) {
      setTableReportExportResponse({
        show: true,
        success: true,
        message: {
          title: 'Download In Process',
          description: (
            <span className='text-sm text-gray-500 font-normal'>
              Your export is currently processing. When it is ready, you will
              receive an email at{' '}
              <span className='text-sm text-gray-700 font-bold'>
                {userEmail}
              </span>
            </span>
          ),
        },
      });
    } else {
      setTableReportExportResponse({
        show: true,
        success: false,
        message: {
          title: 'Failed',
          description: 'Failed to Export Data',
        },
      });
    }
  };

  return (
    <div className='p-4'>
      <DataStack column={4} data={getDataCards(selectedProject?.id)} />
      <div className='grid grid-cols-1 gap-5 m-3 my-6 md:grid-cols-2'>
        <div className='relative inline-block'>
          {selectedProject?.subscription_plan?.plan_type === 'free' &&
            overlay(selectedProject)}
          <ReportingLineChart
            title={analytics.automationMetrics.textVsAutomation.title}
            loader={defaultDataLoader}
            toolTipInfo={analytics.automationMetrics.textVsAutomation.toolTip}
            data={textualVsAutomation.map((elem: any, index: number) =>
              index === 0 || index === textualVsAutomation?.length - 1
                ? {
                    ...elem,
                    name: dayJs(elem.timestamp * 1000).format('MMM D'),
                  }
                : { ...elem }
            )}
            exportData={() =>
              exportIncomingVsAutomationChart(selectedProject.id)
            }
            exportLoader={incomingVsAutomationExportLoader}
          />
        </div>
        <div className='inline-block'>
          <TagCloud
            title={analytics.automationMetrics.tagCloud.title}
            loader={defaultDataLoader}
            sort={sort}
            setSort={setSort}
            handleSortedData={handleDataSort}
            data={sortedData.slice(
              tagCloudOffset,
              tagCloudOffset + perPageTagLimit
            )}
            exportData={() => exportTagCloud(selectedProject.id)}
            exportLoader={tagCloudExportLoader}
            limit={perPageTagLimit}
            total={ticketTagCloud?.length || 0}
            offset={tagCloudOffset}
            setOffset={setTagCloudOffset}
          />
        </div>
      </div>
      <TicketTable
        title={analytics.automationMetrics.ticketDetails.title}
        group={agentList}
        defaultGroup={defaultGroup}
        loader={ticketListLoader}
        toolTipInfo={analytics.automationMetrics.ticketDetails.toolTip}
        tableData={ticketListTable}
        total={totalTicketList}
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        handleTicketListNext={handleTicketListNext}
        handleTicketListPrevious={handleTicketListPrevious}
        exportLoader={exportCustomerTicketLoader}
        exportData={() => handleExportTicketTableReport()}
        onAgentSelect={(agent: any) => {
          handleAgentSelect(agent.id);
          setDefaultGroup(agent);
          setOffset(0);
        }}
      />
      <NewAlertModal
        shouldShowCancelButton={false}
        isShown={tableReportExportResponse?.show}
        title={tableReportExportResponse?.message?.title}
        description={tableReportExportResponse?.message?.description}
        intent={tableReportExportResponse?.success ? 'primary' : 'danger'}
        shouldRenderDescriptionAsNode={tableReportExportResponse?.success}
        confirmButtonTitle='Got it!'
        onConfirm={() => {
          setTableReportExportResponse({
            ...tableReportExportResponse,
            show: false,
          });
        }}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  userEmail: state.auth.email,
  ticketResolvedCount: state.reporting.ticketResolvedCount,
  uniqueTicketCount: state.reporting.uniqueTicketCount,
  avgTicketPerOverallUser: state.reporting.avgTicketPerOverallUser,
  ticketCreatedCount: state.reporting.ticketCreatedCount,
  avgProcessFRT: state.reporting.avgProcessFRT,
  loader: state.loading.effects.reporting.fetchUserReportData,
  defaultDataLoader:
    state.loading.effects.reporting.fetchUserReportDataDefaultAppend,
  textualVsAutomation: state.reporting.textualVsAutomation,
  ticketTagCloud: state.reporting.ticketTagCloud,
  ticketListTable: state.reporting.ticketListTable,
  totalTicketList: state.reporting.totalTicketList,
  agentList: state.reporting.agentList,
  ticketListLoader: state.loading.effects.reporting.fetchAutomationTicketTable,
  exportCustomerTicketLoader:
    state.loading.effects.reporting.exportCustomerTicket,
  incomingVsAutomationExportLoader:
    state.loading.effects.reporting.exportIncomingVsAutomationChart,
  tagCloudExportLoader: state.loading.effects.reporting.exportTagCloud,
});

const mapDispatch = (dispatch: any) => ({
  exportCustomerTicket: (projectId: number, groupId: number | null) =>
    dispatch.reporting.exportCustomerTicket({ projectId, groupId }),
  exportTagCloud: (projectId: number) =>
    dispatch.reporting.exportTagCloud(projectId),
  exportIncomingVsAutomationChart: (projectId: number) =>
    dispatch.reporting.exportIncomingVsAutomationChart(projectId),
  updateStateData: (key: string, value: any) =>
    dispatch.reporting.updateStateData({ key, value }),
});

const AutomationMetricsContainer = connect(
  mapState,
  mapDispatch
)(AutomationMetrics);

export default AutomationMetricsContainer;
