import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'evergreen-ui';
import AgentDetailsTable from '../AgentMetrics/AgentDetailsTable';
import DataStack from '../CommonComponent/DataStack';
import { analytics } from '../../../../utilities/content';
import useTranslation from 'components/customHooks/useTranslation';

interface props {
  averageQueueTime: dataStackProps;
  averageResolutionTime: dataStackProps;
  averageFirstResponseTime: dataStackProps;
  agentAverageResponseTime: dataStackProps;
  averageSalesConversion: dataStackProps;
  averageOnlineTime: dataStackProps;
  loader: boolean;
  agentStats: any;
  statLoader: boolean;
  exportAgentDetails: (projectId: number) => void;
  exportLoader: boolean;
  selectedProject: any;
  handleBusinessHourToggle: (val: any) => void;
  isBusinessHourEnabled: boolean;
}

interface dataStackProps {
  current_value: number | string;
  growth_direction: '+' | '-' | null;
  growth_value: string;
  previous_value: number | string;
  span: number;
  name?: string;
  value?: number | string;
}

const AgentMetrics: React.FC<props> = ({
  averageQueueTime,
  averageResolutionTime,
  averageFirstResponseTime,
  agentAverageResponseTime,
  averageSalesConversion,
  averageOnlineTime,
  loader,
  agentStats,
  statLoader,
  exportLoader,
  exportAgentDetails,
  selectedProject,
  handleBusinessHourToggle,
  isBusinessHourEnabled,
}) => {
  const { t } = useTranslation();
  const perPageLimit = 6;
  const [offset, setOffset] = useState(0);
  const [searchAgent, setSearchAgent] = useState('');
  const [isSwitchClicked, setIsSwitchClicked] = React.useState(
    isBusinessHourEnabled
  );
  const salesCardData = [
    {
      ...averageFirstResponseTime,
      name: analytics.agentMetrics.dataCard[0].title,
      loader: loader,
      toolTip: analytics.agentMetrics.dataCard[0].toolTip,
    },
    {
      ...averageResolutionTime,
      name: analytics.agentMetrics.dataCard[1].title,
      loader: loader,
      toolTip: analytics.agentMetrics.dataCard[1].toolTip,
    },
    {
      ...agentAverageResponseTime,
      name: analytics.agentMetrics.dataCard[2].title,
      loader: loader,
      toolTip: analytics.agentMetrics.dataCard[2].toolTip,
    },
  ];
  const updatedSalesCardData = !selectedProject?.has_ecommerce_connected
    ? salesCardData
    : [
        ...salesCardData,
        {
          ...averageSalesConversion,
          name: analytics.agentMetrics.dataCard[3].title,
          loader: loader,
          toolTip: analytics.agentMetrics.dataCard[3].toolTip,
        },
      ];

  return (
    <div className='p-4'>
      <div className='p-3'>
        <div className='flex w-full border border-gray-100 rounded-md bg-white shadow '>
          <div className='flex flex-col justify-start w-1/2 text text-gray-900 text-base p-4'>
            <label className='text text-gray-900 text-base'>
              {t('Business Hour Only')}
            </label>
            <p className='text text-gray-500 text-sm'>
              {t('See how your agents perform during the business hours')}
            </p>
          </div>
          <div className='flex justify-end w-1/2 p-6'>
            <Switch
              className='switch-rd'
              onChange={() => {
                handleBusinessHourToggle(!isSwitchClicked);
                setIsSwitchClicked(!isSwitchClicked);
              }}
              checked={isSwitchClicked}
              hasCheckIcon={false}
              height={24}
            />
          </div>
        </div>
      </div>
      <DataStack
        column={4}
        data={
          updatedSalesCardData

          // will be added after be api is ready
          // {...averageOnlineTime, name: 'Average Time Online', loader: loader,
          //     toolTip: 'Average Time Online'
          // },
        }
      />
      <AgentDetailsTable
        title={analytics.agentMetrics.agentDetails.title}
        loader={statLoader}
        toolTipInfo={analytics.agentMetrics.agentDetails.toolTip}
        query={searchAgent}
        setQuery={setSearchAgent}
        exportLoader={exportLoader}
        handleExport={() => exportAgentDetails(selectedProject.id)}
        total={agentStats?.length}
        limit={perPageLimit}
        offset={offset}
        setOffset={setOffset}
        hasEcommerceConnected={selectedProject.has_ecommerce_connected}
        tableData={
          !!agentStats
            ? agentStats
                .filter(
                  (elem: any) =>
                    !!elem.agent_name &&
                    elem.agent_name
                      .toLowerCase()
                      .includes(searchAgent.toLowerCase())
                )
                .slice(offset, offset + perPageLimit)
            : []
        }
        currency={
          selectedProject?.currency_symbol
            ? selectedProject.currency_symbol
            : ''
        }
      />
    </div>
  );
};

const mapState = (state: any) => ({
  averageQueueTime: state.reporting.averageQueueTime,
  averageResolutionTime: state.reporting.averageResolutionTime,
  averageFirstResponseTime: state.reporting.averageFirstResponseTime,
  agentAverageResponseTime: state.reporting.agentAverageResponseTime,
  averageSalesConversion: state.reporting.averageSalesConversion,
  averageOnlineTime: state.reporting.averageOnlineTime,
  agentStats: state.reporting.agentStats,
  selectedProject: state.dashboard.selectedProject,
  isBusinessHourEnabled: state.reporting.isBusinessHourEnabled,
  loader:
    state.loading.effects.reporting.fetchUserReportDataUserMetricsLineGraph,
  statLoader: state.loading.effects.reporting.fetchUserReportDataDefaultAppend,
  exportLoader: state.loading.effects.reporting.exportAgentDetails,
});

const mapDispatch = (dispatch: any) => ({
  exportAgentDetails: (projectId: number) =>
    dispatch.reporting.exportAgentDetails(projectId),
  fetchUserReportDataDefaultAppend: (projectId: number, endpoint: string) =>
    dispatch.reporting.fetchUserReportDataDefaultAppend({
      projectId,
      endpoint,
    }),
});

const AgentMetricsContainer = connect(mapState, mapDispatch)(AgentMetrics);

export default AgentMetricsContainer;
