import React, { useState } from 'react';
import { connect } from 'react-redux';
import EmptyTicketListView from './components/EmptyTicketListView';
import LeftBarHeader from './components/LeftbarHeader';
import TicketCard from './components/TicketCard';
import TicketCardLoader from './components/TicketCardLoader';
import TicketOrder from './components/TicketOrder';

import { useLocation, useParams } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface } from 'index';
import useBulkAction from 'pages/inbox/hooks/useBulkAction';
import useLeftBarHook from 'pages/inbox/hooks/useLeftBarHook';
import {
  EcommerceCustomerInterface,
  IPinResponse,
  ISearchState,
  ITicketQueueData,
  QueryParameterInterface,
  TicketFilterInterface,
  TicketInterface,
  TicketListAPIResponseType,
  UpdateSingleTicketReducerInterface,
} from 'pages/inbox/inboxInterface';
import { classNames, getIsoDatetimeString } from '../../../../utilities/utils';
import {
  SecondaryFilterData,
  ticketFilterDefaultValue,
} from '../../utils/contents';
import {
  getQueryParameterValueFromFilterData,
  isFilterAppliedWithOutPrivateView,
} from '../../utils/functions';
import BotTicketCard from './components/BotTicketCard';
import TicketFilter from './components/TicketFilter';

interface Props {
  limit: number;
  offset: number;
  userId: number;
  ticketOrder: string;
  searchState: ISearchState;
  ticketQueue: ITicketQueueData;
  ticketList: TicketInterface[];
  selectedTicket: TicketInterface;
  selectedProject: SelectedTeamInterface;
  pusherConnection: any;
  ticketFilterData: TicketFilterInterface;
  ticketLoading: boolean;
  totalTicketCount: number;
  selectedQueueType: string;
  hasFilterApplied: boolean;
  hasSupervisorAccess: boolean;
  ticketScrollLoading: boolean;
  selectedSecondaryQueueType: string;
  createWhatsappTicketLoading: boolean;
  ticketIsResolvedStatus: number;
  setShowAccessDeniedModal: (
    show: boolean,
    title: string,
    subTitle: string
  ) => void;
  updateTicketList: (payload: UpdateSingleTicketReducerInterface) => void;
  updateSingleTicket: (payload: UpdateSingleTicketReducerInterface) => void;
  handleOnticketClick: (ticket: TicketInterface) => void;
  updateTicketPinStatus: (
    ticketId: String,
    isPinned: boolean
  ) => Promise<IPinResponse>;
  updateSateData: (key: string, value: any) => void;
  fetchTicketFilterData: (projectId: number) => void;
  whatsappCustomerListLoading: boolean;
  fetchTicketsList: (
    projectId: number,
    queryParameters: QueryParameterInterface
  ) => boolean;
  fetchTicketsListOnScroll: (
    projectId: number,
    queryParameters: QueryParameterInterface
  ) => TicketListAPIResponseType;
  removeTicketFromListOnRemoveEvent: (
    payload: UpdateSingleTicketReducerInterface
  ) => void;
  getWhatsappCustomerList: (
    teamId: number,
    whatsappChannelId: string | number,
    searchName: string
  ) => void;
  createWhatsappTicket: (
    teamId: number,
    whatsappChannelId: string,
    phone: string | null
  ) => void;
  fetchTicketsBadgeCount: (projectId: number) => boolean;
  updateEcommerceCustomerDetails: (
    payload: EcommerceCustomerInterface | null
  ) => void;
}

interface ISecondaryFilter {
  label: string;
  value: string;
}

const LeftBar: React.FC<Props> = ({
  limit,
  offset,
  userId,
  ticketList,
  ticketOrder,
  ticketQueue,
  ticketLoading,
  selectedTicket,
  selectedQueueType,
  updateSateData,
  searchState,
  fetchTicketsList,
  ticketFilterData,
  selectedProject,
  hasFilterApplied,
  updateTicketList,
  pusherConnection,
  totalTicketCount,
  updateSingleTicket,
  hasSupervisorAccess,
  ticketIsResolvedStatus,
  ticketScrollLoading,
  handleOnticketClick,
  createWhatsappTicket,
  updateTicketPinStatus,
  fetchTicketFilterData,
  selectedSecondaryQueueType,
  getWhatsappCustomerList,
  fetchTicketsListOnScroll,
  setShowAccessDeniedModal,
  createWhatsappTicketLoading,
  whatsappCustomerListLoading,
  removeTicketFromListOnRemoveEvent,
  fetchTicketsBadgeCount,
  updateEcommerceCustomerDetails,
}) => {
  const { t } = useTranslation();
  const [ticketQueueType, setTicketQueueType] =
    React.useState(selectedQueueType);

  const [ticketCreationPusherChannel, setTicketCreationPusherChannel] =
    React.useState('');
  const [ticketUpdatePusherChannel, setTicketUpdatePusherChannel] =
    React.useState('');
  const [ticketResolvePusherChannel, setTicketResolvePusherChannel] =
    React.useState('');
  const [ticketRemovePusherChannel, setTicketRemovePusherChannel] =
    React.useState('');
  const [isOnScrollTicketFetching, setOnScrollTicketFetching] =
    React.useState(false);

  const [isTicketHoldEnabled, setIsTicketHoldEnabled] = React.useState(false);

  const [shouldStopAPICallForTicketList, setShouldStopAPICallForTicketList] =
    useState(false);

  const [componentDidMount, setComponentDidMount] = React.useState(false);

  const { projectId } = useParams();
  const location = useLocation();

  const {
    showBulkIcon,
    handleBulkToggle,
    isBulkActionActive,
    isTicketSelectedForBulk,
    handleBulkTicketSelection,
  } = useBulkAction();

  const { checkIfProjectExist, inboxSearchState, getTicketConfigurationData } =
    useLeftBarHook();

  const getQueryParametersForTicketList = (parameters: {
    order?: string;
    newOffset?: number;
    isResolved?: number;
    queueType: string;
    isReplied?: number | null;
    searchKeyword?: string;
    filterData?: TicketFilterInterface;
    isHold?: boolean;
  }) => {
    let defaultIsRepliedValue =
      selectedSecondaryQueueType === 'all'
        ? null
        : selectedSecondaryQueueType === 'replied'
        ? 1
        : 0;

    let {
      order = ticketOrder,
      newOffset = 0,
      queueType,
      searchKeyword = '',
      isHold = false,
      filterData = ticketFilterData,
      isReplied = defaultIsRepliedValue,
      isResolved = ticketIsResolvedStatus,
    } = parameters;

    let queryParameters = {
      limit: limit,
      offset: newOffset,
      order: order,
      queue: queueType,
      search: searchKeyword,
      is_resolved: isResolved,
      is_replied: isReplied,
      is_on_hold: isHold ? 1 : 0,
      channels: getQueryParameterValueFromFilterData('channels', filterData),
      agents: getQueryParameterValueFromFilterData('agents', filterData),
      groups: getQueryParameterValueFromFilterData('groups', filterData),
      tags: getQueryParameterValueFromFilterData('tags', filterData),
      priorities: getQueryParameterValueFromFilterData(
        'priorities',
        filterData
      ),
      start:
        getIsoDatetimeString(
          filterData?.startDate,
          selectedProject?.timezone_offset
        ) || '',
      end:
        getIsoDatetimeString(
          filterData?.endDate,
          selectedProject?.timezone_offset
        ) || '',
    };
    return queryParameters;
  };

  const callTicketListAPI = async (
    queryParameters: QueryParameterInterface
  ) => {
    await fetchTicketsList(projectId, queryParameters);
  };

  const handleTicketQueueOnChange = (queue: string) => {
    // this line is commented out becasue we do not want to reset the filter on queue change
    // updateSateData('hasFilterApplied', false);

    let { ...queryParameters } = getQueryParametersForTicketList({
      filterData: ticketFilterDefaultValue,
      queueType: queue,
      isResolved: 0,
    });

    updateSateData('ticketQueue', {
      type: queue,
      isResolved: false,
      privateView: '',
    });
    updateSateData('selectedSecondaryQueueType', 'all');
    callTicketListAPI(queryParameters);
    fetchTicketsBadgeCount(selectedProject.id);
  };

  const handleClosedTicketQueue = (queue: string) => {
    // this line is commented out becasue it creates bugs in whole filter operation. Below is the issue link
    // https://github.com/alice-labs/alice-v3/issues/2452
    // updateSateData('hasFilterApplied', false);
    let queryParameters = getQueryParametersForTicketList({
      filterData: ticketFilterDefaultValue,
      queueType: queue,
      isResolved: 1,
    });
    updateSateData('ticketQueue', {
      type: queue,
      isResolved: true,
      privateView: '',
    });
    callTicketListAPI(queryParameters);
  };

  const handleApplyOrClearForFilterButton = (
    filterData: TicketFilterInterface
  ) => {
    let queryParameters = getQueryParametersForTicketList({
      queueType: ticketQueueType,
      filterData: filterData,
    });

    let isFilterApplied =
      JSON.stringify(ticketFilterDefaultValue) !== JSON.stringify(filterData);

    callTicketListAPI(queryParameters);
    updateSateData('hasFilterApplied', isFilterApplied);

    if (isBulkActionActive) {
      handleBulkToggle();
    }
  };

  const handleOrderOnChange = (order: string) => {
    let queryParameters = getQueryParametersForTicketList({
      queueType: ticketQueueType,
      order: order !== 'unreplied' ? order : 'desc',
      isResolved: ticketIsResolvedStatus,
    });

    updateSateData('ticketOrder', order);
    callTicketListAPI(queryParameters);
  };

  const fetchAndHandleTicketsListOnScroll = async () => {
    let queryParameters = getQueryParametersForTicketList({
      queueType: ticketQueueType,
      newOffset: limit + offset,
      order: ticketOrder,
    });

    setOnScrollTicketFetching(true);
    const data = await fetchTicketsListOnScroll(
      selectedProject?.id,
      queryParameters
    );
    setOnScrollTicketFetching(false);

    if (data.dataSource.length === 0) {
      setShouldStopAPICallForTicketList(true);
    }
  };

  const handleTicketListScroll = async () => {
    let el = document.getElementById('ticket-list-bar');
    if (!!el && el.scrollTop + el.offsetHeight > el.scrollHeight - 1) {
      // check if ticketLength === total
      let shouldCallAPIForClosedQueue =
        !isOnScrollTicketFetching &&
        ticketIsResolvedStatus === 1 &&
        !shouldStopAPICallForTicketList;

      let shouldCallAPIForAllQueue =
        !shouldStopAPICallForTicketList &&
        !isOnScrollTicketFetching &&
        ticketList.length < totalTicketCount;

      if (shouldCallAPIForClosedQueue || shouldCallAPIForAllQueue) {
        await fetchAndHandleTicketsListOnScroll();
      }
    }
  };

  const channelEventEmitterForTicket = () => {
    if (pusherConnection) {
      // unsubcribe already exsited subscription
      if (!!ticketCreationPusherChannel)
        pusherConnection.unsubscribe(ticketCreationPusherChannel);
      if (!!ticketUpdatePusherChannel)
        pusherConnection.unsubscribe(ticketUpdatePusherChannel);
      if (!!ticketResolvePusherChannel)
        pusherConnection.unsubscribe(ticketResolvePusherChannel);
      if (!!ticketRemovePusherChannel)
        pusherConnection.unsubscribe(ticketRemovePusherChannel);

      // channel subscriptions
      const ticketCreate = pusherConnection.subscribe(
        `${userId}-ticket-created`
      );
      setTicketCreationPusherChannel(`${userId}-ticket-created`);
      const ticketUpdate = pusherConnection.subscribe(
        `${userId}-ticket-updated`
      );
      setTicketUpdatePusherChannel(`${userId}-ticket-updated`);
      const ticketRemove = pusherConnection.subscribe(
        `${userId}-ticket-removed`
      );
      setTicketRemovePusherChannel(`${userId}-ticket-removed`);
      const ticketResolve = pusherConnection.subscribe(
        `${userId}-ticket-resolved`
      );
      setTicketResolvePusherChannel(`${userId}-ticket-resolved`);

      // channel event handlers
      ticketRemove.bind(`ticket-removed`, (ticketData: TicketInterface) => {
        //TODO: This event is being called when a ticket is reassigned from self to
        //other agent/group. Need to fix this
        removeTicketFromListOnRemoveEvent({
          userId: userId,
          ticketData: ticketData,
          projectId: selectedProject?.id,
        });
      });

      ticketResolve.bind(`ticket-resolved`, (ticketData: TicketInterface) => {
        console.log('ticket-resolved');
      });

      ticketCreate.bind(`ticket-created`, (ticketData: TicketInterface) => {
        // NOTE: We can not check filter data before calling reducer method to update ticket list, because reducer method is binded
        // currently we are comparing filterdata with ticketData inside reducer function as we are facing
        // an issue where we are not getting local updated filter data inside pusher event handler
        updateTicketList({
          userId: userId,
          ticketData: ticketData,
          projectId: selectedProject?.id, // TODO: to be removed
        });
      });

      ticketUpdate.bind(`ticket-updated`, (ticketData: TicketInterface) => {
        // NOTE: We can not check filter data before calling reducer method to update ticket list, because reducer method is binded
        // currently we are comparing filterdata with ticketData inside reducer function as we are facing
        // an issue where we are not getting local updated filter data inside pusher event handler
        updateSingleTicket({
          userId: userId,
          ticketData: ticketData,
          projectId: selectedProject?.id, // TODO: to be removed
        });
      });
    }
  };

  const handleSearchStateChange = () => {
    if (inboxSearchState?.searchQuery.includes('search-by-link')) {
      let queueType = 'self';
      if (hasSupervisorAccess) queueType = 'all';
      setTicketQueueType(queueType);
      let queryParameters = getQueryParametersForTicketList({
        filterData: ticketFilterDefaultValue,
        queueType: queueType,
        isResolved: 0,
        isReplied: null,
      });
      callTicketListAPI(queryParameters);
    } else updateSateData('ticketList', searchState?.leftbarPreviousState);
    updateSateData(
      'selectedTicket',
      searchState?.leftbarPreviousState
        ? searchState?.leftbarPreviousState[0]
        : []
    );
    updateSateData('searchState', {
      searchQuery: '',
      isSearchApplied: false,
      leftbarPreviousState: [],
    });
  };

  const handleSecondaryFilterOnClick = (filterType: string) => {
    if (filterType === 'hold') {
      let queryParameter = getQueryParametersForTicketList({
        queueType: ticketQueueType,
        isHold: true,
      });
      callTicketListAPI(queryParameter);
    } else if (
      ['all', 'self'].includes(ticketQueueType) &&
      ticketIsResolvedStatus !== 1
    ) {
      let isReplied =
        filterType === 'all' ? null : filterType === 'replied' ? 1 : 0;

      let queryParameters = getQueryParametersForTicketList({
        queueType: ticketQueueType,
        order: ticketOrder,
        isResolved: 0,
        isHold: false,
        isReplied: isReplied,
      });
      callTicketListAPI(queryParameters);
    }
  };

  const getRepliedUnrepliedFilter = () => {
    // Check conditions for displaying the filter buttons
    if (
      // Condition 1: Check if the ticket queue type is 'all' or 'self', and the ticket is not resolved,
      // there is a valid ticket list, the queue type is not 'private', and the ticket list is not empty,
      // OR
      // Condition 2: Check if the selected secondary queue type is not 'all'
      (['all', 'self'].includes(ticketQueueType) &&
        ticketIsResolvedStatus !== 1 &&
        !!ticketList &&
        ticketQueue.type !== 'private' &&
        ticketList.length !== 0) ||
      selectedSecondaryQueueType !== 'all'
    ) {
      // Render the filter buttons

      const secondaryFilters: ISecondaryFilter[] =
        !!selectedProject &&
        ['enterprise', 'business'].includes(
          selectedProject?.subscription_plan?.plan_type
        ) &&
        isTicketHoldEnabled
          ? SecondaryFilterData
          : SecondaryFilterData.filter((data) => data.value !== 'hold');
      return (
        <div className='flex flex-wrap gap-1 px-2 py-3 border-b-2 border-gray-100 ltr:border-l-2 rtl:border-r-2'>
          {secondaryFilters.map((secondaryFilter: ISecondaryFilter) => (
            <button
              key={secondaryFilter?.value}
              className={`px-3 py-2 rounded-full font-medium  hover:bg-gray-50 ${
                selectedSecondaryQueueType === secondaryFilter?.value
                  ? 'bg-gray-100 rounded-3xl text-gray-600 font-semibold'
                  : 'text-gray-500'
              }`}
              onClick={() => {
                // Handle filter button click
                handleSecondaryFilterOnClick(secondaryFilter?.value);
                updateSateData(
                  'selectedSecondaryQueueType',
                  secondaryFilter?.value
                );
              }}
            >
              {t(secondaryFilter?.label)}
            </button>
          ))}
        </div>
      );
    } else {
      // Don't render anything if conditions are not met
      return null;
    }
  };

  const getTicketList = async () => {
    let queueType = 'self';
    if (hasSupervisorAccess) queueType = 'all';
    setTicketQueueType(queueType);
    let queryParameters = getQueryParametersForTicketList({
      filterData: ticketFilterDefaultValue,
      queueType: queueType,
      isResolved: 0,
      isReplied: null,
    });
    fetchTicketFilterData(projectId);

    // Get the query string from the URL
    const queryString = window.location.search;

    // Check if the query string is not empty
    const hasQueryParams = queryString !== '';

    if (!componentDidMount && hasQueryParams) {
      // Create a URLSearchParams object from the query string
      const params = new URLSearchParams(queryString);

      // Get the value  from param
      const customerId = params.get('customer_id') || '';
      const ticketId = params.get('ticket_id') || '';

      const response = await checkIfProjectExist(
        projectId,
        customerId,
        ticketId
      );
      if (!response?.success) {
        setShowAccessDeniedModal(true, response?.title, response?.subTitle);
        callTicketListAPI(queryParameters);
      }
    } else {
      callTicketListAPI(queryParameters);
    }
    setComponentDidMount(true);
  };

  const setTicketHoldEnabled = async () => {
    const ticketConfigurationData = await getTicketConfigurationData();
    if (
      !!ticketConfigurationData &&
      ticketConfigurationData?.hasOwnProperty(
        'has_enabled_ticket_hold_management'
      )
    ) {
      setIsTicketHoldEnabled(
        ticketConfigurationData?.has_enabled_ticket_hold_management?.status
      );
    } else {
      setIsTicketHoldEnabled(false);
    }
  };

  React.useEffect(() => {
    updateSateData('offset', 0);
    updateSateData('ticketFilterData', ticketFilterDefaultValue);
    getTicketList();
    setTicketHoldEnabled();

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    updateSateData('offset', 0);
    // eslint-disable-next-line
  }, [ticketFilterData]);

  React.useEffect(() => {
    setTicketQueueType(selectedQueueType);
    /**
     * this commented code is responsible for calling ticketlist api 2nd time
     * for my assign queue, so commenting this for now but may
     * need to modify in future
     */
    if (selectedQueueType === 'self' && ticketIsResolvedStatus === 0) {
      let { ...queryParameters } = getQueryParametersForTicketList({
        queueType: 'self',
        isResolved: 0,
      });
      updateSateData('selectedSecondaryQueueType', 'all');
      callTicketListAPI(queryParameters);
    }
    //eslint-disable-next-line
  }, [selectedQueueType]);

  React.useEffect(() => {
    channelEventEmitterForTicket();
    // eslint-disable-next-line
  }, [pusherConnection]);

  React.useEffect(() => {
    if (!componentDidMount) return;

    const { pathname } = location;
    window.history.replaceState({}, document.title, pathname);
    //eslint-disable-next-line
  }, [selectedTicket]);

  const handleTicketClick = (ticketData: TicketInterface) => {
    if (!isBulkActionActive) {
      handleOnticketClick(ticketData);
    } else {
      handleBulkTicketSelection(ticketData?.id);
    }
    updateEcommerceCustomerDetails(null);
  };

  const renderAgentTicketList = () => {
    return ticketList.map((ticket: TicketInterface, index: number) => (
      <div key={index}>
        <TicketCard
          key={index}
          ticketData={ticket}
          currentTicket={selectedTicket}
          isBulkActive={isBulkActionActive}
          updatePinStatus={updateTicketPinStatus}
          handleOnticketClick={handleTicketClick}
          isTicketSelectedForBulk={isTicketSelectedForBulk}
          showAgentAvatar={
            ticketQueueType === 'all' && ticket.assigned_agent !== userId
          }
        />
      </div>
    ));
  };

  const renderBotTicketList = () => {
    return ticketList.map((ticket: TicketInterface, index: number) => (
      <div key={index}>
        <BotTicketCard
          key={index}
          ticketData={ticket}
          currentTicket={selectedTicket}
          isBulkActive={isBulkActionActive}
          handleOnticketClick={handleTicketClick}
          isTicketSelectedForBulk={isTicketSelectedForBulk}
        />
      </div>
    ));
  };

  const renderTicketList = () => {
    if (ticketQueueType === 'bot') {
      return renderBotTicketList();
    } else {
      return renderAgentTicketList();
    }
  };

  const renderFilterAndSort = () => {
    if (!searchState?.isSearchApplied && !!ticketList) {
      return (
        <>
          <TicketOrder handleOrderOnChange={handleOrderOnChange} />
          <TicketFilter
            hasSupervisorAccess={hasSupervisorAccess}
            handleApplyOrClearFilter={handleApplyOrClearForFilterButton}
          />
        </>
      );
    } else return '';
  };

  return (
    <>
      <LeftBarHeader
        searchState={searchState}
        showBulkIcon={showBulkIcon}
        queueType={ticketQueueType}
        ticketLoading={ticketLoading}
        updateSateData={updateSateData}
        selectedProject={selectedProject}
        totalTicketCount={totalTicketCount}
        handleBulkToggle={handleBulkToggle}
        isBulkActionActive={isBulkActionActive}
        hasSupervisorAccess={hasSupervisorAccess}
        createWhatsappTicket={createWhatsappTicket}
        ticketIsResolvedStatus={ticketIsResolvedStatus}
        getWhatsappCustomerList={getWhatsappCustomerList}
        handleClosedTicketQueue={handleClosedTicketQueue}
        handleSearchStateChange={handleSearchStateChange}
        handleTicketQueueOnChange={handleTicketQueueOnChange}
        createWhatsappTicketLoading={createWhatsappTicketLoading}
        whatsappCustomerListLoading={whatsappCustomerListLoading}
        handleApplyOrClearFilter={handleApplyOrClearForFilterButton}
      />
      <div className='flex justify-between'>{renderFilterAndSort()}</div>
      {getRepliedUnrepliedFilter()}
      {ticketLoading && <TicketCardLoader />}
      {!!ticketList && ticketList.length !== 0 && !ticketLoading && (
        <div
          id='ticket-list-bar'
          onScroll={() => handleTicketListScroll()}
          className='relative h-full overflow-auto border-l-2 pb-72 border-l-gray-100'
        >
          {renderTicketList()}
          <div
            className={classNames(
              'justify-center items-center my-8 mx-2',
              isOnScrollTicketFetching ? 'flex' : 'hidden'
            )}
          >
            <div
              className='inline-block w-6 h-6 text-green-500 border rounded-full spinner-border animate-spin'
              role='status'
            />
          </div>
        </div>
      )}
      {!!ticketList && ticketList.length === 0 && (
        <div className='flex items-center justify-center h-full border-l-2 border-gray-100 pb-28'>
          <EmptyTicketListView
            isFilterApplied={isFilterAppliedWithOutPrivateView(
              ticketQueue?.type,
              ticketFilterData
            )}
          />
        </div>
      )}
      {ticketScrollLoading && <TicketCardLoader />}
    </>
  );
};

const mapState = (state: any) => ({
  userId: state.auth.id,
  limit: state.inbox.limit,
  offset: state.inbox.offset,
  searchState: state.inbox.searchState,
  ticketOrder: state.inbox.ticketOrder,
  selectedQueueType: state.inbox.selectedQueueType,
  selectedProject: state.dashboard.selectedProject,
  ticketFilterData: state.inbox.ticketFilterData,
  totalTicketCount: state.inbox.totalTicketCount,
  hasFilterApplied: state.inbox.hasFilterApplied,
  ticketIsResolvedStatus: state.inbox.ticketIsResolvedStatus,
  ticketLoading: state.loading.effects.inbox.fetchTicketsList,
  selectedSecondaryQueueType: state.inbox.selectedSecondaryQueueType,
  ticketScrollLoading: state.loading.effects.inbox.fetchTicketsOnScroll,
  createWhatsappTicketLoading: state.loading.effects.inbox.createWhatsappTicket,
  whatsappCustomerListLoading:
    state.loading.effects.inbox.getWhatsappCustomerList,
});

const mapDispatch = (dispatch: any) => ({
  updateSateData: (key: string, value: any) =>
    dispatch.inbox.updateSateData({ key, value }),
  updateTicketPinStatus: (ticketId: String, isPinned: boolean) =>
    dispatch.inbox.updateTicketPinStatus({ ticketId, isPinned }),
  updateTicketList: (payload: {
    ticketData: TicketInterface;
    userId: number;
    projectId: number;
  }) => dispatch.inbox.updateTicketList(payload),
  updateSingleTicket: (payload: {
    ticketData: TicketInterface;
    userId: number;
    projectId: number;
  }) => dispatch.inbox.updateSingleTicket(payload),
  fetchTicketsList: (
    projectId: number,
    queryParameters: QueryParameterInterface
  ) => dispatch.inbox.fetchTicketsList({ projectId, queryParameters }),
  fetchTicketsListOnScroll: (
    projectId: number,
    queryParameters: QueryParameterInterface
  ) => dispatch.inbox.fetchTicketsListOnScroll({ projectId, queryParameters }),
  fetchTicketFilterData: (projectId: number) =>
    dispatch.inbox.fetchTicketFilterData(projectId),
  removeTicketFromListOnRemoveEvent: (payload: {
    ticketData: TicketInterface;
    userId: number;
    projectId: number;
  }) => dispatch.inbox.removeTicketFromListOnRemoveEvent(payload),
  createWhatsappTicket: (
    teamId: number,
    whatsappChannelId: string,
    phone: string | null
  ) =>
    dispatch.inbox.createWhatsappTicket({ teamId, whatsappChannelId, phone }),
  getWhatsappCustomerList: (
    teamId: number,
    whatsappChannelId: string | number,
    searchName: string
  ) =>
    dispatch.inbox.getWhatsappCustomerList({
      teamId,
      whatsappChannelId,
      searchName,
    }),
  fetchTicketsBadgeCount: (projectId: number) =>
    dispatch.inbox.fetchTicketsBadgeCount(projectId),
  updateEcommerceCustomerDetails: (
    payload: EcommerceCustomerInterface | null
  ) => dispatch.ecommerce.updateEcommerceCustomerDetails(payload),
});

export default connect(mapState, mapDispatch)(LeftBar);
