import useDatalab from 'pages/datalab/hooks/useDatalab';
import notFoundImg from '../../../../assets/icons/emptySearchIcon.svg';
import { Plus, Button, useState, type FC } from '../../export';
import DatalabSideSheet from './DatalabSideSheet';
import useTranslation from 'components/customHooks/useTranslation';

interface NoDataFoundProps {
  hasButton?: boolean;
  headingText?: string;
  descriptionText?: string;
  buttonText?: string;
}

export const NoDataFound: FC<NoDataFoundProps> = ({
  hasButton = true,
  headingText = 'No inputs found.',
  descriptionText = 'To add and view the data, you need to create inputs first.',
  buttonText = 'Create New Input',
}) => {
  const { t } = useTranslation();
  const [isSideSheetOpen, setIsSideSheetOpen] = useState(false);
  const { selectedDatalabInfo } = useDatalab();
  const handleSheetClose = () => {
    setIsSideSheetOpen((old) => !old);
  };
  return (
    <>
      <div className='flex items-center justify-center w-full'>
        <div className='flex flex-col items-center max-w-xs mb-20 mt-52'>
          <img src={notFoundImg} alt='empty' />
          <h6 className='pt-2'>{t(headingText)}</h6>
          <p className='pt-2 pb-3 text-center'>{t(descriptionText)}</p>
          {hasButton && (
            <>
              <Button onClick={handleSheetClose} className='text-white'>
                <Plus className='w-4 h-4 ltr:mr-2 rtl:ml-2 text-white' />
                {t(buttonText)}
              </Button>
              <DatalabSideSheet
                isOpen={isSideSheetOpen}
                datalabEntries={{}}
                datalabShape={selectedDatalabInfo}
                header='Enter new Data'
                mode='CREATE'
                onClose={handleSheetClose}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
