import React from 'react';
import BlockContainer from './BlockContainer';
import icon from '../../pages/builder/assets/images/icons/ticket.svg';
import TitleHolderBuilder from '../elements/TitleHolderBuilder';
import {
  updateOnChangeText,
  updateOnSelectAttribute,
} from '../../../utilities/utils';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import TextCharInfo from '../elements/TextCharInfo';
import TagsDropdown from '../elements/TagsDropdown';
import SelectDropdown from '../elements/SelectDropdown';
import { Textarea } from 'libraryV2/ui/textarea';
import { Checkbox } from 'libraryV2/ui/checkbox';
import { cn } from 'libraryV2/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Tag {
  id: number;
  name: string;
  created_at: string;
}
interface props {
  id: number;
  save: boolean;
  type: string;
  saveBlock: any;
  attributeList: any;
  apiList: any;
  deleteBlock: any;
  sequences: any;
  ticketTags: any;
  copyBlock: any;
  moveBlock: any;
  saveLoader: boolean;
  hasWriteAccess: boolean;
  agentList: any;
  agentGroupList: any;
  agents: any;
  selectedTicketTags: Tag[];
  updateAgent: (value: any) => void;
  agentTag: any;
  updateAgentTag: (value: any) => void;
  priority: number | string;
  updatePriority: (value: any) => void;
  note: string;
  updateNote: (value: any) => void;
  agentIds: any;
  resolveSequence: any;
  updateResolveSequence: (value: any) => void;
  updateNoAgentGroup: () => void;
  disabledBot: boolean;
  updateDisabledBot: (checked: boolean) => void;
  updateTicketTag: (ticketTags: any[], tagIds: number[]) => void;
}

const TicketBlock: React.FC<props> = ({
  id,
  save,
  type,
  saveBlock,
  attributeList,
  apiList,
  deleteBlock,
  sequences,
  copyBlock,
  moveBlock,
  saveLoader,
  hasWriteAccess,
  agentList,
  agentGroupList,
  agents,
  updateAgent,
  agentTag,
  updateAgentTag,
  updateNoAgentGroup,
  disabledBot,
  selectedTicketTags,
  updateDisabledBot,
  priority,
  updatePriority,
  note,
  updateNote,
  resolveSequence,
  updateResolveSequence,
  ticketTags,
  updateTicketTag,
}) => {
  const { t } = useTranslation();
  const [showAttribute, setShowAttribute] = React.useState(false);
  const [showAPI, setShowAPI] = React.useState(false);
  const maxCount = 620;

  const getAgentOrGroups = () => {
    let agentsListLocal: any = [];
    let groupsListLocal: any = [];

    if (!!agentList) {
      agentList.forEach((elem: any) => {
        agentsListLocal = [
          ...agentsListLocal,
          {
            ...elem,
            value: elem.admin.id,
            label: elem.admin.full_name,
            type: 'agent',
          },
        ];
      });
    }
    if (!!agentGroupList) {
      agentGroupList.forEach((group: any) => {
        groupsListLocal = [
          ...groupsListLocal,
          {
            ...group,
            value: group.id,
            label: `${group.name} ${group.is_smart ? '(SMART)' : ''}`,
            type: 'group',
          },
        ];
      });
    }
    let data = [
      {
        label: 'General',
        options: [{ value: null, label: 'Unassigned', type: 'general' }],
      },
      {
        label: 'Agents',
        options: agentsListLocal,
      },
      {
        label: 'Groups',
        options: groupsListLocal,
      },
    ];
    return data;
  };

  const getValueOfAgentGroup = () => {
    let localData = null;

    if (agentTag.length > 0) {
      localData = { value: agentTag[0].id, label: agentTag[0].name };
    } else if (agents.length > 0) {
      localData = { value: agents[0].id, label: agents[0].full_name };
    }

    return localData;
  };

  const updateTicketTagList = (tagList: Tag[]) => {
    const tagIds: number[] = [];
    const tags: any[] = [];
    tagList.forEach((tag: Tag) => {
      tagIds.push(tag?.id);
      tags.push({ id: tag?.id, name: tag?.name });
    });
    updateTicketTag(tags, tagIds);
  };

  return (
    <BlockContainer
      id={id}
      title={t('Ticket')}
      subtitle={t('Ticket block creates a ticket in your inbox.')}
      save={save}
      isLoading={saveLoader}
      icon={icon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      {/* ====== Start from here ====== */}
      <div className='flex flex-col'>
        {/* ROW 1 */}
        <div className='flex'>
          <div className='flex-1'>
            <div className='flex w-full justify-between'>
              <div className='w-full ltr:mr-2 rtl:ml-2'>
                <TitleHolderBuilder
                  title={t('Agent/Group')}
                  tooltipInfo={t(
                    'Select the agent/group you want to assign to this ticket'
                  )}
                />
                <SelectDropdown
                  onChange={(option: any) => {
                    if (option.type === 'agent') {
                      updateAgent(option);
                    } else if (option.type === 'group') {
                      updateAgentTag(option);
                    } else {
                      updateNoAgentGroup();
                    }
                  }}
                  options={getAgentOrGroups()}
                  value={getValueOfAgentGroup()}
                  placeholder={t('Search Agents/Group')}
                />
              </div>

              <div className='w-full'>
                <TitleHolderBuilder
                  title={t('Tag')}
                  tooltipInfo={t('Add tags to the ticket to categorize it')}
                />
                <TagsDropdown
                  ticketTags={ticketTags}
                  selectedTicketTags={selectedTicketTags}
                  updateTicketTags={updateTicketTagList}
                />
              </div>
            </div>
          </div>
        </div>

        {/* ROW 2 */}
        <div className='flex mt-20px'>
          <div className='flex-1'>
            <div className='flex w-full justify-between'>
              <div className='w-full ltr:mr-2 rtl:ml-2'>
                <TitleHolderBuilder
                  title={t('Set Priority')}
                  tooltipInfo={t('Set a priority for the ticket')}
                />
                <SelectDropdown
                  withSearch={false}
                  value={
                    priority === 0
                      ? { label: 'Low', value: 0 }
                      : priority === 1
                      ? {
                          label: 'Medium',
                          value: 1,
                        }
                      : priority === 2
                      ? { label: 'High', value: 2 }
                      : null
                  }
                  onChange={(option: any) => {
                    updatePriority(option);
                  }}
                  options={[
                    { label: 'Low', value: 0 },
                    { label: 'Medium', value: 1 },
                    { label: 'High', value: 2 },
                  ]}
                  placeholder={t('Select Agent/Group')}
                />
              </div>

              <div className='w-full'>
                <TitleHolderBuilder
                  title={t('Resolved sequence')}
                  tooltipInfo={t(
                    'Redirect users to this sequence if someone resolves the ticket'
                  )}
                />

                <SelectDropdown
                  withSearch={true}
                  value={
                    !!resolveSequence
                      ? {
                          label: resolveSequence.title,
                          value: resolveSequence.id,
                        }
                      : null
                  }
                  onChange={(option: any) => {
                    updateResolveSequence({
                      id: option.value,
                      title: option.label,
                    });
                  }}
                  placeholder={t('Search Sequence')}
                  options={sequences.map((seq: any) => ({
                    label: seq.title,
                    value: seq.id,
                  }))}
                />
              </div>
            </div>
          </div>
        </div>

        {/* ROW 3 */}
        <div className='flex flex-column mt-20px'>
          <TitleHolderBuilder
            title={t('Notes')}
            tooltipInfo={t(
              'Write an internal note to the agent/group. Your customers cannot see this note.'
            )}
          />
          <AttributeAPIContainer
            attributeList={attributeList}
            showAttribute={showAttribute}
            setShowAttribute={setShowAttribute}
            apiList={apiList.filter((api: any) => api.return_type === type)}
            showAPI={showAPI}
            setShowAPI={setShowAPI}
            handleSelect={(value) => {
              (note + value).length + 2 <= maxCount &&
                updateOnSelectAttribute(
                  value,
                  showAttribute,
                  showAPI,
                  setShowAttribute,
                  setShowAPI,
                  updateNote,
                  note,
                  apiList
                );
            }}
          >
            <div className='relative'>
              <Textarea
                required={true}
                rows={3}
                value={note}
                disabled={!hasWriteAccess}
                onChange={(e: any) =>
                  e.target.value.length <= maxCount &&
                  updateOnChangeText(
                    e.target.value,
                    setShowAttribute,
                    setShowAPI,
                    updateNote
                  )
                }
                className='block w-full mb-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-primary focus:border-primary sm:text-sm cursor-text'
                placeholder={t('Write your email body here')}
              />
              <div className='absolute bottom-4 right-4'>
                <TextCharInfo data={note} count={maxCount} />
              </div>
            </div>
          </AttributeAPIContainer>
        </div>

        {/* ROW 4 */}
        <div className='flex align-center mt-10px'>
          <Checkbox
            className={cn(
              !disabledBot && 'bg-primary text-white border-primary',
              'rounded shadow-none',
              disabledBot && 'border-gray-600'
            )}
            checked={!disabledBot}
            onCheckedChange={(checked: boolean) => {
              updateDisabledBot(!checked);
            }}
          />{' '}
          &nbsp;
          <span style={{ fontSize: '0.75rem', fontWeight: 500 }}>
            {t('Turn on bot?')}
          </span>
        </div>
      </div>
    </BlockContainer>
  );
};

TicketBlock.defaultProps = {
  deleteBlock: (blockId: number, SequenceId: number) =>
    console.log('delete from default props', blockId, SequenceId),
};

export default TicketBlock;
