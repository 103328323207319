import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Check as CheckIcon,
  ChevronDown,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  cn,
  TrashIcon,
  Input,
  FC,
  CalendarDays,
  Calendar,
  format,
  Checkbox,
  Badge,
} from '../../../export';

interface FilterFieldProps {
  data: any;
  formFieldList: any[];
  valuePath?: string;
  removeFilterFromList: () => void;
  handleFilterDataOnChange: (updatedData: any) => void;
}

const FilterField: React.FC<FilterFieldProps> = ({
  data,
  formFieldList,
  removeFilterFromList,
  handleFilterDataOnChange,
}) => {
  const [seletedFieldFilterOptions, setSeletedFieldFilterOptions] = useState(
    []
  );
  const [selectionOptions, setSeletectionOptions] = useState<string[]>([]);
  const [isColumnPopoverOpen, setColumnPopoverOpen] = useState(false);
  const [isOperatorPopoverOpen, setOperatorPopoverOpen] = useState(false);
  const [isSingleSelectPopoverOpen, setIsSingleSelectPopoverOpen] =
    useState(false);
  const [selectedMultipleOptions, setSelectedMultipleOptions] = useState<
    string[]
  >([]);
  const [isMultipleSelectPopoverOpen, setIsMultipleSelectPopoverOpen] =
    useState(false);
  const [selectedFieldType, setSelectedFieldType] = useState('');
  const [date, setDate] = useState<Date | undefined>(new Date());
  const [time, setTime] = useState<string>('');
  const [isDateTimePopoverOpen, setIsDateTimePopoverOpen] =
    useState<boolean>(false);

  // set the selected field type and options when the component mounts
  useEffect(() => {
    const selectedField = formFieldList.find(
      (field) => field.slug === data.slug
    );

    if (selectedField) {
      setSelectedFieldType(selectedField.type);
      setSeletectionOptions(selectedField.choices);
      setSeletedFieldFilterOptions(selectedField.filter_options);
    }

    // eslint-disable-next-line
  }, []);

  const handleSingleOptionSelection = (option: string) => {
    setIsSingleSelectPopoverOpen(false);
    handleFilterDataOnChange({ ...data, value: option });
  };

  const handleMultipleSelectedOption = (option: string) => {
    let updatedSelectedMultipleOptions;

    if (selectedMultipleOptions.includes(option)) {
      updatedSelectedMultipleOptions = selectedMultipleOptions.filter(
        (item) => item !== option
      );
    } else {
      updatedSelectedMultipleOptions = [...selectedMultipleOptions, option];
    }

    setSelectedMultipleOptions(updatedSelectedMultipleOptions);
    handleFilterDataOnChange({
      ...data,
      value: updatedSelectedMultipleOptions,
    });
  };

  const handleOperatorSelection = useCallback(
    (selectedOperator: string) => {
      setOperatorPopoverOpen(false);
      handleFilterDataOnChange({ ...data, operator: selectedOperator });
    },
    [data, handleFilterDataOnChange]
  );

  const handleInputValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    // setInputValue(event.target.value);

    handleFilterDataOnChange({ ...data, value });
  };

  const handleOnDateSelect = (date: Date | undefined) => {
    if (date) {
      setDate(date);
      handleFilterDataOnChange({ ...data, value: date.toISOString() });
    }
  };

  const handleOnTimeSelect = (time: string) => {
    if (time) {
      setDate(date);
      handleFilterDataOnChange({ ...data, value: time });
    }
  };

  const handleColumnSelection = useCallback(
    (
      selectedColumnSlug: string,
      filedType: string,
      selectionOptions: string[],
      filterOptions: any
    ) => {
      setColumnPopoverOpen(false);
      setSelectedFieldType(filedType);
      setSeletectionOptions(selectionOptions);
      setSeletedFieldFilterOptions(filterOptions);
      
      if (filedType === 'datetime') {
        handleFilterDataOnChange({
          ...data,
          slug: selectedColumnSlug,
          value: new Date().toISOString(),
        });
      } else {
        handleFilterDataOnChange({ ...data, slug: selectedColumnSlug });
      }
    },
    [data, handleFilterDataOnChange]
  );

  const handleTimeChange = (newTime: string) => {
    setTime(newTime);
    const newDate = date ? new Date(date) : new Date();
    const times = newTime.split(':').map((v) => parseInt(v));
    newDate.setHours(times[0], times[1]);
    setDate(newDate);
    setIsDateTimePopoverOpen(false); // Close the popover when both date and time are selected
  };

  // React.useEffect(() => {
  // handleFilterDataOnChange({ ...data, value: inputValue });
  // eslint-disable-next-line
  // }, [inputDebounce]);

  const ColumnSelectionAndDeleteField: FC = () => (
    <div className='flex gap-2'>
      <Popover
        open={isColumnPopoverOpen}
        modal={true}
        onOpenChange={setColumnPopoverOpen}
      >
        <PopoverTrigger asChild>
          <Button
            type='button'
            variant='outline'
            role='combobox'
            aria-expanded={isColumnPopoverOpen}
            className={`w-full justify-between font-normal bg-white ${
              isColumnPopoverOpen ? 'border border-primary' : ''
            }`}
          >
            <span className='text-muted-foreground'>
              {data?.slug
                ? formFieldList.find(
                    (field: any) =>
                      field.slug === data?.slug ||
                      field.name === data?.slug ||
                      field.label_agent === data?.slug
                  )?.name
                : 'Select column'}
            </span>
            <ChevronDown className='w-4 h-4 ml-2 opacity-50 shrink-0' />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='p-0 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]'>
          <Command>
            <CommandInput
              placeholder='Search...'
              className='border-none h-9 focus:ring-0'
            />
            <CommandList className='max-h-[300px] min-w-[90vw] bg-background text-zinc-900 lg:min-w-form-width'>
              <CommandEmpty>No data found.</CommandEmpty>
              <CommandGroup>
                {formFieldList.map((field: any, index: number) => (
                  <CommandItem
                    key={`${field.slug}-${index}`}
                    value={field.slug}
                    onSelect={() =>
                      handleColumnSelection(
                        field.slug,
                        field.type,
                        field.choices,
                        field.filter_options
                      )
                    }
                  >
                    <CheckIcon
                      className={cn(
                        'mr-2 h-4 w-4 text-primary',
                        data?.slug === field.slug ? 'opacity-100' : 'opacity-0'
                      )}
                    />
                    {field.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <Button
        type='button'
        variant='outline'
        onClick={removeFilterFromList}
        className='p-2 justify-between font-normal bg-white border-[#FF5757]'
      >
        <TrashIcon className='w-4 h-4 shrink-0 text-[#FF5757]' />
      </Button>
    </div>
  );

  // OPERATOR SELECTION FIELD
  const OperatorSelectionField: FC = () => (
    <Popover
      open={isOperatorPopoverOpen}
      modal={true}
      onOpenChange={setOperatorPopoverOpen}
    >
      <PopoverTrigger asChild>
        <Button
          type='button'
          variant='outline'
          role='combobox'
          aria-expanded={isOperatorPopoverOpen}
          className={`w-full justify-between font-normal bg-white ${
            isOperatorPopoverOpen ? 'border border-primary' : ''
          }`}
        >
          <span className='text-muted-foreground'>
            {data?.operator
              ? seletedFieldFilterOptions.find(
                  (option: any) => option?.filter_by === data?.operator
                  //@ts-ignore
                )?.verbose
              : 'Select operator'}
          </span>
          <ChevronDown className='w-4 h-4 ml-2 opacity-50 shrink-0' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='p-0 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]'>
        <Command>
          <CommandInput
            placeholder='Search...'
            className='border-none h-9 focus:ring-0'
          />
          <CommandList className='max-h-[300px] min-w-[90vw] bg-background text-zinc-900 lg:min-w-form-width'>
            <CommandEmpty>No data found.</CommandEmpty>
            <CommandGroup>
              {seletedFieldFilterOptions.map((filter: any, index: number) => (
                <CommandItem
                  key={`${filter.filter_by}-${index}`}
                  value={filter.verbose}
                  onSelect={() => handleOperatorSelection(filter.filter_by)}
                >
                  <CheckIcon
                    className={cn(
                      'mr-2 h-4 w-4 text-primary',
                      data?.operator === filter.filter_by
                        ? 'opacity-100'
                        : 'opacity-0'
                    )}
                  />
                  {filter.verbose}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );

  const DateSelectionField: FC = () => {
    return (
      <Popover modal={true}>
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            className={cn('w-full justify-start	font-normal bg-white')}
          >
            <CalendarDays className='w-4 h-4 mr-2 opacity-50' />
            {date ? (
              format(date, 'dd/MM/yyyy')
            ) : (
              <span className='text-muted-foreground'>
                {data?.placeholder ?? 'Select date'}
              </span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0 bg-white right-5' align='start'>
          <Calendar
            mode='single'
            selected={date}
            onSelect={handleOnDateSelect}
            disabled={(date) =>
              date > new Date() || date < new Date('1900-01-01')
            }
            initialFocus
          />
        </PopoverContent>
      </Popover>
    );
  };

  const DateTimeSelectionField: FC = () => {
    return (
      <Popover
        open={isDateTimePopoverOpen}
        onOpenChange={setIsDateTimePopoverOpen}
      >
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            className={cn('w-full justify-start	font-normal bg-white')}
            onClick={() => setIsDateTimePopoverOpen(true)} // Open the popover when the button is clicked
          >
            <CalendarDays className='w-4 h-4 mr-2 opacity-50' />
            {date ? (
              format(date, 'MMM dd, yyyy h:mm a')
            ) : (
              <span className='text-muted-foreground'>
                {data?.placeholder ?? 'Select date time'}
              </span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0 bg-white right-5' align='start'>
          <Calendar
            mode='single'
            selected={date}
            onSelect={handleOnDateSelect}
            disabled={(date) =>
              date > new Date() || date < new Date('1900-01-01')
            }
            initialFocus
            footer={
              <div className='mt-2 border-t'>
                <input
                  className='w-full px-2 py-1 mt-3 border rounded-md focus:outline-none focus:ring-0 focus:border-green-500'
                  type='time'
                  value={time}
                  onChange={(e) => handleTimeChange(e.target.value)}
                />
              </div>
            }
          />
        </PopoverContent>
      </Popover>
    );
  };

  const TimeSelectionField: FC = () => {
    return (
      <input
        className='w-full px-2 py-1 border border-[#DFDFE2] font-normal text-base rounded-md focus:outline-none focus:ring-0 focus:border-primary'
        type='time'
        value={data?.value}
        onChange={(e) => handleOnTimeSelect(e.target.value)}
      />
    );
  };

  const SingleSelectionField = () => {
    return (
      <Popover
        modal={true}
        open={isSingleSelectPopoverOpen}
        onOpenChange={setIsSingleSelectPopoverOpen}
      >
        <PopoverTrigger asChild>
          <Button
            type='button'
            variant='outline'
            role='combobox'
            aria-expanded={isSingleSelectPopoverOpen}
            className={`w-full justify-between font-normal bg-white ${
              isSingleSelectPopoverOpen ? 'border border-primary' : ''
            }`}
          >
            {data?.value ? (
              <span className='bg-[#F4F4F5] px-2 py-1 rounded-md'>
                {data.value}
              </span>
            ) : (
              <span className='text-muted-foreground'>Select option </span>
            )}
            <ChevronDown className='w-4 h-4 ml-2 opacity-50 shrink-0' />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='p-0 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]'>
          <Command>
            <CommandInput
              placeholder='Search...'
              className='border-none h-9 focus:ring-0'
            />
            <CommandList className='max-h-[300px] min-w-[90vw] bg-background text-zinc-900 lg:min-w-form-width'>
              <CommandEmpty>No data found.</CommandEmpty>
              <CommandGroup>
                {selectionOptions.map((option: any, index: number) => (
                  <CommandItem
                    key={`${option}-${index}`}
                    value={option.name}
                    onSelect={() => handleSingleOptionSelection(option.name)}
                  >
                    <CheckIcon
                      className={cn(
                        'mr-2 h-4 w-4 text-primary',
                        data?.value === option.name
                          ? 'opacity-100'
                          : 'opacity-0'
                      )}
                    />
                    {option.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  };

  const MultiSelectField: FC = () => {
    const renderSelectedItems = () => {
      return selectedMultipleOptions?.length ? (
        <Badge className='border-0 shadow-none bg-primary text-zinc-100'>
          + {selectedMultipleOptions?.length} Selected
        </Badge>
      ) : (
        data.placeholder || 'Select Options'
      );
    };
    return (
      <Popover
        modal={true}
        open={isMultipleSelectPopoverOpen}
        onOpenChange={setIsMultipleSelectPopoverOpen}
      >
        <PopoverTrigger asChild>
          <Button
            type='button'
            variant='outline'
            role='combobox'
            aria-expanded={isMultipleSelectPopoverOpen}
            className={`w-full justify-between font-normal bg-white ${
              isSingleSelectPopoverOpen ? 'border border-primary' : ''
            }`}
          >
            {renderSelectedItems()}
            <ChevronDown className='w-4 h-4 ml-2 opacity-50 shrink-0' />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='p-0 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]'>
          <Command>
            <CommandInput
              placeholder='Search...'
              className='border-none h-9 focus:ring-0'
            />
            <CommandList className='max-h-[300px] min-w-[90vw] bg-background text-zinc-900 lg:min-w-form-width'>
              <CommandEmpty>No data found.</CommandEmpty>
              <CommandGroup>
                {selectionOptions.map((option: any, index: number) => (
                  <CommandItem
                    key={option.name + index}
                    value={option.name}
                    onSelect={() => {
                      handleMultipleSelectedOption(option.name);
                    }}
                    className='flex items-center gap-2'
                  >
                    <div className='flex items-center'>
                      <Checkbox
                        checked={data?.value.includes(option.name)}
                        className={cn(
                          'data-[state=checked]:bg-[#04B25F] rounded-sm data-[state=checked]:text-white'
                        )}
                      />
                    </div>

                    {option.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <div className='bg-[#FAFAFA] p-3 border-[#DFDFE2] border border-dashed rounded-md my-4'>
      <ColumnSelectionAndDeleteField />
      {data?.slug?.length !== 0 && (
        <div className='flex gap-2 mt-2'>
          <div className='flex-1'>
            <OperatorSelectionField />
          </div>
          <div className='flex-1'>
            {{
              date: <DateSelectionField />,
              datetime: <DateTimeSelectionField />,
              time: <TimeSelectionField />,
              singleselect: <SingleSelectionField />,
              multiselect: <MultiSelectField />,
            }[selectedFieldType] || (
              <Input
                value={data?.value || ''}
                type='text'
                placeholder='Enter value'
                onChange={handleInputValueChange}
                className='bg-white border-[#DFDFE2] focus:border-primary focus-visible:ring-0 mt-0'
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(FilterField);
