import { useDispatch, useSelector } from 'pages/automationWorkflow/export';
import {
  IExecutorItem,
  IAssignVariableTypes,
  IChildrenUiScreen,
} from 'pages/automationWorkflow/interface';

const useActions = () => {
  const dispatch = useDispatch();
  const {
    triggerInfo,
    channelList,
    executorDetails,
    whatsappTemplateList,
    selectTemporaryExecutor,
    selectedSaveActionsData,
    executorOfcommonComponents,
  } = useSelector((state: any) => state.workflowAutomation);

  const { platformList } = useSelector((state: any) => state.dashboard);

  //Select Single Executor
  const selectExecutor = (executorTagName: string) => {
    if (executorTagName === 'general') {
      dispatch.workflowAutomation.updateSelectedTemporaryExecutor(
        executorOfcommonComponents
      );
    } else {
      const executorTagDetails = executorDetails[executorTagName] || [];
      dispatch.workflowAutomation.updateSelectedTemporaryExecutor(
        executorTagDetails
      );
    }
  };

  //change the UI of conditions
  const updateConditionallyRenderingActionsUI = (value: string) => {
    dispatch.workflowAutomation.updateRenderingChangeUIOfActions(value);
  };

  //Handle action Executor
  const handleSelectedExecutor = (data: {
    id: number | string;
    name: string;
    data?: any;
  }) => {
    updateConditionallyRenderingActionsUI(data?.name);
    selecteTemporaryExecutorDetails(data);
    if (data?.name === 'whatsapp_template') {
      handleWhatsappTemplate();
    }
  };

  //Handle Whatsapp Template
  const handleWhatsappTemplate = () => {
    const tempChannelList = platformList
      .filter((channel: any) => channel?.platform_type === 'whatsapp_bsp')
      .map((channel: any) => ({
        label: channel?.title,
        id: channel?.id,
      }));

    dispatch.workflowAutomation.updateChannelList(tempChannelList);
  };

  //Update Selected Channel
  const handleUpdateSelectedChannel = (channelId: number) => {
    const tempSelectedChannel = channelList.filter(
      (option: { id: number; label: string }) => option?.id === channelId
    );
    // Reset general data and delay value
    const updatedData = {
      general: {
        delay: {
          valueOfDelay: null,
          timeOfDelay: 'minute',
        },
      },
      eCommerce: {}, // You may need to adjust this if there's specific eCommerce data
      whatsapp: {
        ...selectedSaveActionsData?.whatsapp,
        selectedChannelId: tempSelectedChannel[0]?.id,
      },
    };
    dispatch.workflowAutomation.updateSelectedChannel(tempSelectedChannel[0]);
    dispatch.workflowAutomation.fetchWhatsappTemplates(channelId);
    dispatch.workflowAutomation.updateSaveSelectedActionsData(updatedData);
  };

  //Select Temporary details of single Executor
  const selecteTemporaryExecutorDetails = (data: any) => {
    const selectedSingleTemporaryExecutor = selectTemporaryExecutor.filter(
      (option: any) => option?.name === data?.id
    );
    if (data?.name === 'add_delay') {
      const updatedData = {
        ...selectedSaveActionsData.general.delay,
        data: data?.data,
      };
      dispatch.workflowAutomation.updateSaveSelectedActionsData({
        ...selectedSaveActionsData,
        general: {
          ...selectedSaveActionsData.general,
          data: updatedData?.data,
        },
        eCommerce: {},
      });
    } else if (data?.name === 'whatsapp_template') {
      const updatedData = {
        ...selectedSaveActionsData.whatsapp,
        data: data?.data,
      };
      dispatch.workflowAutomation.updateSaveSelectedActionsData({
        ...selectedSaveActionsData,
        whatsapp: {
          ...selectedSaveActionsData.whatsapp,
          data: updatedData?.data,
        },
        eCommerce: {},
      });
    }

    dispatch.workflowAutomation.updateSelectedTemporaryExecutorDetailsTag(
      selectedSingleTemporaryExecutor[0]
    );
  };

  //Save selected delay value
  const saveSelectedDelayValue = (type: string, value: string) => {
    const updatedDelay = {
      ...selectedSaveActionsData.general.delay,
      [type === 'value' ? 'valueOfDelay' : 'timeOfDelay']: value,
    };

    dispatch.workflowAutomation.updateSaveSelectedActionsData({
      ...selectedSaveActionsData,
      general: {
        ...selectedSaveActionsData.general,
        delay: updatedDelay,
        data: executorOfcommonComponents[0]?.data?.col_1_label_options[0],
      },
      whatsapp: {
        selectedTemplate: null,
        phoneVariable: null,
        selectedChannelId: null,
      },
    });
  };

  //Select Whatsapp template
  const handleSelectWhatsappTemplate = (selectedTemplateData: {
    id: string;
    label: string;
    body: string;
  }) => {
    const selectedWhatsappTemplate = whatsappTemplateList.filter(
      (whatsapp: any) => whatsapp?.id === selectedTemplateData?.id
    );
    if (selectedWhatsappTemplate.length) {
      dispatch.workflowAutomation.updateSelectedWhatsappData(
        selectedWhatsappTemplate[0] ?? []
      );
      highlightAttributeFromTextWithCountAndItems(selectedTemplateData);
    }
  };

  // Updates the left side of the phone variable trigger information in the selected actions data
  const updateSelectedTriggerInfoLeftValue = (triggerInfoName: string) => {
    const updatedGeneral = {
      ...selectedSaveActionsData,
      whatsapp: {
        ...selectedSaveActionsData?.whatsapp,
        phoneVariable: {
          ...selectedSaveActionsData?.whatsapp?.phoneVariable,
          selectedTriggerInfo: triggerInfoName,
        },
      },
    };

    dispatch.workflowAutomation.updateSaveSelectedActionsData({
      ...selectedSaveActionsData,
      whatsapp: {
        ...selectedSaveActionsData.whatsapp,
        phoneVariable: updatedGeneral?.whatsapp?.phoneVariable,
      },
    });
  };

  // Updates the right side of the phone variable trigger information in the selected actions data
  const updateSelectedTriggerInfoRightValue = (triggerInfoValue: string) => {
    const triggerInfoValueObj = triggerInfo[
      selectedSaveActionsData?.whatsapp?.phoneVariable?.selectedTriggerInfo
    ].filter((item: any) => item?.name === triggerInfoValue);
    const updatedGeneral = {
      ...selectedSaveActionsData?.whatsapp,
      phoneVariable: {
        ...selectedSaveActionsData?.whatsapp?.phoneVariable,
        selectedTriggerInfoValue: triggerInfoValue,
        phoneVariableInfoObj: triggerInfoValueObj[0],
      },
    };
    dispatch.workflowAutomation.updateSaveSelectedActionsData({
      ...selectedSaveActionsData,
      whatsapp: {
        ...selectedSaveActionsData?.whatsapp,
        phoneVariable: updatedGeneral?.phoneVariable,
      },
    });
  };

  /** Highlights patterns in text, returns {processedText, count, matchedItems}. */
  const highlightAttributeFromTextWithCountAndItems = (selectedTemplateData: {
    id: string;
    label: string;
    body: string;
  }) => {
    let count = 0;
    const matchedItems: IAssignVariableTypes[] = [];

    selectedTemplateData?.body
      .split(/({{[^{}]*}})/)
      .reduce((result: string, item: string, index: number) => {
        if (item.match(/{{[^{}]*}}/)) {
          // If the item is a {{...}} pattern, increment the counter, add to the array, and wrap it in a span with specific styles
          count++;
          matchedItems.push({
            item,
            variableCategory: '',
            variableValue: '',
            fallbackValue: '',
          });
          return (
            result +
            `<span id=${
              item + '_' + index
            } class='px-1 py-1 rounded-md text-orange-400'>${item}</span>`
          );
        } else {
          // If the item is not a {{...}} pattern, keep it unchanged
          return result + item;
        }
      }, '');

    // Update selectedSaveActionsData with new count and matchedItems
    const updatedData = {
      ...selectedSaveActionsData?.whatsapp,
      selectedTemplate: {
        ...selectedSaveActionsData?.whatsapp?.selectedTemplate,
        variableCount: count,
        variables: matchedItems,
        label: selectedTemplateData?.label,
        id: selectedTemplateData?.id,
      },
    };

    // Dispatch the updated data
    dispatch.workflowAutomation.updateSaveSelectedActionsData({
      ...selectedSaveActionsData,
      whatsapp: {
        ...selectedSaveActionsData?.whatsapp,
        selectedWhatsappTemplateData: selectedTemplateData,
        selectedTemplate: updatedData.selectedTemplate,
      },
    });
  };

  // Determines the column span for a button based on its index and the total number of buttons
  const getButtonColSpan = (length: number, index: number) =>
    length % 2 !== 0 && length === index + 1 ? 'col-span-2' : '';

  // Updates the category or value of an assign variable based on the type (category or value)
  const updateSelectedAssignVariableCategoryAndValue = (
    assignVariableType: 'category' | 'value',
    newValue: string,
    assignVariableIndex: number
  ) => {
    const updatedData = { ...selectedSaveActionsData };

    // Access the specific variable object based on the index
    const variableToUpdate =
      updatedData?.whatsapp?.selectedTemplate?.variables?.[assignVariableIndex];

    if (variableToUpdate) {
      // Update the specific value based on the assignVariableType
      if (assignVariableType === 'category') {
        variableToUpdate.variableCategory = newValue;
      } else if (assignVariableType === 'value') {
        variableToUpdate.variableValue = newValue;
      }

      // Dispatch the updated data
      dispatch.workflowAutomation.updateSaveSelectedActionsData(updatedData);
    }
  };

  // Updates the fallback value of an assign variable based on its index
  const updateSelectedAssignVariableFallbackValue = (
    fallbackValue: string,
    assignVariableIndex: number
  ) => {
    const updatedData = { ...selectedSaveActionsData };

    // Access the specific variable object based on the index
    const variableToUpdate =
      updatedData?.whatsapp?.selectedTemplate?.variables?.[assignVariableIndex];

    variableToUpdate.fallbackValue = fallbackValue;

    // Dispatch the updated data
    dispatch.workflowAutomation.updateSaveSelectedActionsData(updatedData);
  };

  // Function to render the UI type for executor details
  const renderChangeExecutorDetailsUIType = (name: string) => {
    switch (name) {
      case 'Send Whatsapp Template':
        return 'whatsapp_template';
      case 'delay representation':
        return 'add_delay';
      case 'Discount Forms':
        return 'discount_forms';
      case 'Add Tags on Customer':
        return 'add_tags_on_customer';
      case 'Add Tags on Order':
        return 'add_tags_on_order';
      case 'Remove Tags from Customer':
        return 'remove_tags_from_customer';
      case 'Remove Tags from Order':
        return 'remove_tags_from_order';
      case 'Remove Tags':
        return 'remove_tags';
      default:
        return '';
    }
  };

  // Handle executor click
  const handleExecutorClick = (executor: IExecutorItem) => {
    if (executor?.name === 'delay representation') {
      handleDelayRepresentationClick(executor);
    } else if (executor?.name === 'Discount Forms') {
      updateConditionallyRenderingActionsUI(
        renderChangeExecutorDetailsUIType(executor?.name)
      );
      handleDiscountExecutorClick();
    } else if (executor?.name === 'Add Tags on Customer') {
      updateConditionallyRenderingActionsUI(
        renderChangeExecutorDetailsUIType(executor?.name)
      );
      handleAddTagOnCustomerExecutorClick();
    } else if (executor?.name === 'Add Tags on Order') {
      updateConditionallyRenderingActionsUI(
        renderChangeExecutorDetailsUIType(executor?.name)
      );
      handleAddTagOnOrderExecutorClick();
    } else if (executor?.name === 'Remove Tags from Customer') {
      updateConditionallyRenderingActionsUI(
        renderChangeExecutorDetailsUIType(executor?.name)
      );
      handleRemoveTagFromCustomerExecutorClick();
    } else if (executor?.name === 'Remove Tags from Order') {
      updateConditionallyRenderingActionsUI(
        renderChangeExecutorDetailsUIType(executor?.name)
      );
      handleRemoveTagFromOrderExecutorClick();
    } else if (executor?.name === 'Remove Tags') {
      updateConditionallyRenderingActionsUI(
        renderChangeExecutorDetailsUIType(executor?.name)
      );
      handleRemoveTagExecutorClick();
    } else {
      handleDefaultExecutorClick(executor);
    }
  };

  // Handle click for 'delay representation'
  const handleDelayRepresentationClick = (executor: IExecutorItem) => {
    const updatedExecutor = {
      name: renderChangeExecutorDetailsUIType(executor?.name),
      id: executor?.name,
      data: executor?.data?.col_1_label_options[0],
    };
    handleSelectedExecutor(updatedExecutor);
  };

  // Handle click for other executors
  const handleDefaultExecutorClick = (executor: IExecutorItem) => {
    const updatedExecutor = {
      name: renderChangeExecutorDetailsUIType(executor?.name),
      id: executor?.name,
      data: executor,
    };
    handleSelectedExecutor(updatedExecutor);
  };

  const handleAddTagOnCustomerExecutorClick = () => {
    const getAddTagDetails = executorDetails?.ecommerce.filter(
      (item: any) => item?.name === 'Add Tags on Customer'
    );

    const {
      component,
      data: { routing_model, screens },
    } = getAddTagDetails[0];

    const currentRoute = Object.keys(routing_model)[0];
    const currentScreen = screens.find(({ id }: any) => id === currentRoute);

    const getFooterData = currentScreen.layout.children[0]?.children.filter(
      (v: any) => v?.type === 'Footer'
    );
    const removeBracesString = getFooterData[0]?.['on-click-action']?.store_on
      .replace(/\$\{|\}/g, '')
      .split('.');

    const payloadName = removeBracesString[removeBracesString?.length - 1];

    const add_tags_payload = currentScreen.layout.children[0]?.children.reduce(
      (payload: any, child: IChildrenUiScreen) => {
        if (child && child.name && child.type !== 'Footer') {
          return {
            ...payload,
            [child.name]:
              child.name === 'plain_tag' || child.name === 'variable_tag'
                ? []
                : '', // Initialize 'add_tag' as an array, others as empty string
          };
        }
        return payload;
      },
      {}
    );
    const updatedComponent = {
      ...component,
      definition: {
        ...component.definition,
        stored_data: {
          ...component.definition.stored_data,
          [payloadName]: add_tags_payload, // Add the new discount_payload here
        },
      },
    };

    const updateActionData = {
      ...selectedSaveActionsData,
      eCommerce: {
        addTagsOnCustomer: {
          ...selectedSaveActionsData.eCommerce.addTagsOnCustomer,
          routingModel: routing_model,
          screens,
          component: updatedComponent,
          currentRoute: currentRoute,
          currentScreen: currentScreen,
          extranalData: {
            dataStoreLocation: removeBracesString.join('.'),
            payload: add_tags_payload,
          },
        },
      },
    };
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: updateActionData,
    });
  };

  const handleRemoveTagFromCustomerExecutorClick = () => {
    const getRemoveTagDetails = executorDetails?.ecommerce.filter(
      (item: any) => item?.name === 'Remove Tags from Customer'
    );

    const {
      component,
      data: { routing_model, screens },
    } = getRemoveTagDetails[0];

    const currentRoute = Object.keys(routing_model)[0];
    const currentScreen = screens.find(({ id }: any) => id === currentRoute);

    const getFooterData = currentScreen.layout.children[0]?.children.filter(
      (v: any) => v?.type === 'Footer'
    );
    const removeBracesString = getFooterData[0]?.['on-click-action']?.store_on
      .replace(/\$\{|\}/g, '')
      .split('.');

    const payloadName = removeBracesString[removeBracesString?.length - 1];

    const remove_tags_payload =
      currentScreen.layout.children[0]?.children.reduce(
        (payload: any, child: IChildrenUiScreen) => {
          if (child && child.name && child.type !== 'Footer') {
            return {
              ...payload,
              [child.name]:
                child.name === 'plain_tag' || child.name === 'variable_tag'
                  ? []
                  : '', // Initialize 'add_tag' as an array, others as empty string
            };
          }
          return payload;
        },
        {}
      );
    const updatedComponent = {
      ...component,
      definition: {
        ...component.definition,
        stored_data: {
          ...component.definition.stored_data,
          [payloadName]: remove_tags_payload,
        },
      },
    };

    const updateActionData = {
      ...selectedSaveActionsData,
      eCommerce: {
        removeTagsOnCustomer: {
          ...selectedSaveActionsData.eCommerce.removeTagsOnCustomer,
          routingModel: routing_model,
          screens,
          component: updatedComponent,
          currentRoute: currentRoute,
          currentScreen: currentScreen,
          extranalData: {
            dataStoreLocation: removeBracesString.join('.'),
            payload: remove_tags_payload,
          },
        },
      },
    };
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: updateActionData,
    });
  };

  const handleRemoveTagFromOrderExecutorClick = () => {
    const getRemoveTagDetails = executorDetails?.ecommerce.filter(
      (item: any) => item?.name === 'Remove Tags from Order'
    );

    const {
      component,
      data: { routing_model, screens },
    } = getRemoveTagDetails[0];

    const currentRoute = Object.keys(routing_model)[0];
    const currentScreen = screens.find(({ id }: any) => id === currentRoute);

    const getFooterData = currentScreen.layout.children[0]?.children.filter(
      (v: any) => v?.type === 'Footer'
    );
    const removeBracesString = getFooterData[0]?.['on-click-action']?.store_on
      .replace(/\$\{|\}/g, '')
      .split('.');

    const remove_tags_payload =
      currentScreen.layout.children[0]?.children.reduce(
        (payload: any, child: IChildrenUiScreen) => {
          if (child && child.name && child.type !== 'Footer') {
            return {
              ...payload,
              [child.name]:
                child.name === 'plain_tag' || child.name === 'variable_tag'
                  ? []
                  : '', // Initialize 'add_tag' as an array, others as empty string
            };
          }
          return payload;
        },
        {}
      );

    const updateActionData = {
      ...selectedSaveActionsData,
      eCommerce: {
        removeTagsOnOrder: {
          ...selectedSaveActionsData.eCommerce.removeTagsOnOrder,
          routingModel: routing_model,
          screens,
          component,
          currentRoute: currentRoute,
          currentScreen: currentScreen,
          extranalData: {
            dataStoreLocation: removeBracesString.join('.'),
            payload: remove_tags_payload,
          },
        },
      },
    };
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: updateActionData,
    });
  };

  const handleAddTagOnOrderExecutorClick = () => {
    const getAddTagDetails = executorDetails?.ecommerce.filter(
      (item: any) => item?.name === 'Add Tags on Order'
    );

    const {
      component,
      data: { routing_model, screens },
    } = getAddTagDetails[0];

    const currentRoute = Object.keys(routing_model)[0];
    const currentScreen = screens.find(({ id }: any) => id === currentRoute);

    const getFooterData = currentScreen.layout.children[0]?.children.filter(
      (v: any) => v?.type === 'Footer'
    );
    const removeBracesString = getFooterData[0]?.['on-click-action']?.store_on
      .replace(/\$\{|\}/g, '')
      .split('.');

    const add_tags_payload = currentScreen.layout.children[0]?.children.reduce(
      (payload: any, child: IChildrenUiScreen) => {
        if (child && child.name && child.type !== 'Footer') {
          return {
            ...payload,
            [child.name]:
              child.name === 'plain_tag' || child.name === 'variable_tag'
                ? []
                : '', // Initialize 'add_tag' as an array, others as empty string
          };
        }
        return payload;
      },
      {}
    );

    const updateActionData = {
      ...selectedSaveActionsData,
      eCommerce: {
        addTagsOnOrder: {
          ...selectedSaveActionsData.eCommerce.addTagsOnCustomer,
          routingModel: routing_model,
          screens,
          component,
          currentRoute: currentRoute,
          currentScreen: currentScreen,
          extranalData: {
            dataStoreLocation: removeBracesString.join('.'),
            payload: add_tags_payload,
          },
        },
      },
    };
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: updateActionData,
    });
  };

  const handleRemoveTagExecutorClick = () => {
    const getAddTagDetails = executorDetails?.ecommerce.filter(
      (item: any) => item?.name === 'Remove Tags'
    );

    const {
      component,
      data: { routing_model, screens },
    } = getAddTagDetails[0];

    const currentRoute = Object.keys(routing_model)[0];
    const currentScreen = screens.find(({ id }: any) => id === currentRoute);

    const remove_tags_payload =
      currentScreen.layout.children[0]?.children.reduce(
        (payload: any, child: IChildrenUiScreen) => {
          if (child && child.name && child.type !== 'Footer') {
            return {
              ...payload,
              [child.name]:
                child.name === 'remove_tag' || child.name === 'add_variable'
                  ? []
                  : '', // Initialize 'add_tag' as an array, others as empty string
            };
          }
          return payload;
        },
        {}
      );
    const updatedComponent = {
      ...component,
      definition: {
        ...component.definition,
        stored_data: {
          ...component.definition.stored_data,
          remove_tags_payload, // Add the new discount_payload here
        },
      },
    };

    const updateActionData = {
      ...selectedSaveActionsData,
      eCommerce: {
        removeTags: {
          ...selectedSaveActionsData.eCommerce.addTags,
          routingModel: routing_model,
          screens,
          component: updatedComponent,
          currentRoute: currentRoute,
          currentScreen: currentScreen,
        },
      },
    };
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: updateActionData,
    });
  };

  const handleDiscountExecutorClick = () => {
    const getDiscountDetails = executorDetails?.ecommerce.filter(
      (item: any) => item?.name === 'Discount Forms'
    );
    const {
      component,
      data: { routing_model, screens },
    } = getDiscountDetails[0];

    const currentRoute = Object.keys(routing_model)[0];
    const currentScreen = screens.find(({ id }: any) => id === currentRoute);

    const discount_payload = currentScreen.layout.children[0]?.children.reduce(
      (payload: any, child: IChildrenUiScreen) => {
        if (child.required) {
          return {
            ...payload,
            [child.name]: '', // Initialize with an empty string or default value
          };
        }
        return payload;
      },
      {}
    );
    const updatedComponent = {
      ...component,
      definition: {
        ...component.definition,
        stored_data: {
          ...component.definition.stored_data,
          discount_payload, // Add the new discount_payload here
        },
      },
    };

    const updateActionData = {
      ...selectedSaveActionsData,
      eCommerce: {
        discount: {
          ...selectedSaveActionsData.eCommerce.discount,
          routingModel: routing_model,
          screens,
          component: updatedComponent,
          currentRoute: currentRoute,
          currentScreen: currentScreen,
        },
      },
    };

    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: updateActionData,
    });
  };

  const updateStoredData = (name: string, value: string) => {
    const { component } = selectedSaveActionsData.eCommerce?.discount;
    const storedData = component.definition.stored_data || {};
    const discountPayload = storedData.discount_payload || {};

    const updatedDiscountPayload = {
      ...discountPayload,
      [name]: value,
    };

    const updatedStoredData = {
      ...storedData,
      discount_payload: updatedDiscountPayload,
    };

    const updatedComponent = {
      ...component,
      definition: {
        ...component.definition,
        stored_data: updatedStoredData,
      },
    };

    const updateActionData = {
      ...selectedSaveActionsData,
      eCommerce: {
        ...selectedSaveActionsData.eCommerce,
        discount: {
          ...selectedSaveActionsData.eCommerce?.discount,
          component: updatedComponent,
        },
      },
    };

    // Dispatch Redux action to update stored data
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: updateActionData,
    });
  };

  const updateAddTagDataOnCustomer = (name: string, value: string) => {
    const { extranalData } =
      selectedSaveActionsData.eCommerce?.addTagsOnCustomer;

    const updatedAddTagOnCustomerPayload = {
      ...extranalData?.payload,
      [name]: [...(extranalData?.payload[name] || []), value],
    };

    const updatedStoredData = {
      ...extranalData,
      payload: updatedAddTagOnCustomerPayload,
    };
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: {
        ...selectedSaveActionsData,
        eCommerce: {
          ...selectedSaveActionsData?.eCommerce,
          addTagsOnCustomer: {
            ...selectedSaveActionsData.eCommerce.addTagsOnCustomer,
            extranalData: updatedStoredData,
          },
        },
      },
    });
  };

  const updateRemoveTagDataOnCustomer = (name: string, value: string) => {
    const { extranalData } =
      selectedSaveActionsData.eCommerce?.removeTagsOnCustomer;

    const updatedRemoveTagOnCustomerPayload = {
      ...extranalData?.payload,
      [name]: [...(extranalData?.payload[name] || []), value],
    };

    const updatedStoredData = {
      ...extranalData,
      payload: updatedRemoveTagOnCustomerPayload,
    };
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: {
        ...selectedSaveActionsData,
        eCommerce: {
          ...selectedSaveActionsData?.eCommerce,
          removeTagsOnCustomer: {
            ...selectedSaveActionsData.eCommerce.removeTagsOnCustomer,
            extranalData: updatedStoredData,
          },
        },
      },
    });
  };

  const updateAddTagDataOnOrder = (name: string, value: string) => {
    const { extranalData } = selectedSaveActionsData.eCommerce?.addTagsOnOrder;

    const updatedAddTagOnCustomerPayload = {
      ...extranalData?.payload,
      [name]: [...(extranalData?.payload[name] || []), value],
    };

    const updatedStoredData = {
      ...extranalData,
      payload: updatedAddTagOnCustomerPayload,
    };
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: {
        ...selectedSaveActionsData,
        eCommerce: {
          ...selectedSaveActionsData?.eCommerce,
          addTagsOnOrder: {
            ...selectedSaveActionsData.eCommerce.addTagsOnOrder,
            extranalData: updatedStoredData,
          },
        },
      },
    });
  };

  const updateRemoveTagDataOnOrder = (name: string, value: string) => {
    const { extranalData } =
      selectedSaveActionsData.eCommerce?.removeTagsOnOrder;

    const updatedRemoveTagOnCustomerPayload = {
      ...extranalData?.payload,
      [name]: [...(extranalData?.payload[name] || []), value],
    };

    const updatedStoredData = {
      ...extranalData,
      payload: updatedRemoveTagOnCustomerPayload,
    };
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: {
        ...selectedSaveActionsData,
        eCommerce: {
          ...selectedSaveActionsData?.eCommerce,
          removeTagsOnOrder: {
            ...selectedSaveActionsData.eCommerce.removeTagsOnOrder,
            extranalData: updatedStoredData,
          },
        },
      },
    });
  };

  const updateRemoveTagData = (name: string, value: string) => {
    const { component } = selectedSaveActionsData.eCommerce?.removeTags;
    const storedData = component.definition.stored_data || {};
    const removeTagPayload = storedData.remove_tags_payload || {};

    const isArrayField = name === 'remove_tag' || name === 'add_variable';

    const updatedAddTagPayload = {
      ...removeTagPayload,
      [name]: isArrayField ? [...(removeTagPayload[name] || []), value] : value,
    };

    const updatedStoredData = {
      ...storedData,
      remove_tags_payload: updatedAddTagPayload,
    };

    const updatedComponent = {
      ...component,
      definition: {
        ...component.definition,
        stored_data: updatedStoredData,
      },
    };

    dispatchUpdatedData(updatedComponent, 'removeTags');
  };

  const updateAddTagVariablePayload = (
    leftValue: string,
    triggerName: string,
    triggerRightValue: string,
    typeName: string
  ) => {
    const { extranalData } =
      selectedSaveActionsData.eCommerce?.addTagsOnCustomer;

    const triggerInfoValueObj = triggerInfo[leftValue].filter(
      (item: any) => item?.name === triggerName
    );

    const updatedAddTagOnCustomerPayload = {
      ...extranalData?.payload,
      [typeName]: [
        ...(extranalData?.payload[typeName] || []),
        {
          ...triggerInfoValueObj[0],
          name: triggerRightValue.replace(/{{|}}/g, ''),
          triggerValue: leftValue,
        },
      ],
      plain_tag: [
        ...extranalData?.payload?.plain_tag,
        `{{${triggerInfoValueObj[0]?.name}}}`,
      ],
    };

    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: {
        ...selectedSaveActionsData,
        eCommerce: {
          ...selectedSaveActionsData?.eCommerce,
          addTagsOnCustomer: {
            ...selectedSaveActionsData.eCommerce.addTagsOnCustomer,
            extranalData: {
              ...selectedSaveActionsData.eCommerce.addTagsOnCustomer
                ?.extranalData,
              payload: updatedAddTagOnCustomerPayload,
            },
          },
        },
      },
    });
  };

  const updateRemoveTagOnCustomerVariablePayload = (
    leftValue: string,
    triggerName: string,
    triggerRightValue: string,
    typeName: string
  ) => {
    const { extranalData } =
      selectedSaveActionsData.eCommerce?.removeTagsOnCustomer;

    const triggerInfoValueObj = triggerInfo[leftValue].filter(
      (item: any) => item?.name === triggerName
    );

    const updatedRemoveTagOnCustomerPayload = {
      ...extranalData?.payload,
      [typeName]: [
        ...(extranalData?.payload[typeName] || []),
        {
          ...triggerInfoValueObj[0],
          name: triggerRightValue.replace(/{{|}}/g, ''),
          triggerValue: leftValue,
        },
      ],
      plain_tag: [
        ...extranalData?.payload?.plain_tag,
        `{{${triggerInfoValueObj[0]?.name}}}`,
      ],
    };

    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: {
        ...selectedSaveActionsData,
        eCommerce: {
          ...selectedSaveActionsData?.eCommerce,
          removeTagsOnCustomer: {
            ...selectedSaveActionsData.eCommerce.removeTagsOnCustomer,
            extranalData: {
              ...selectedSaveActionsData.eCommerce.removeTagsOnCustomer
                ?.extranalData,
              payload: updatedRemoveTagOnCustomerPayload,
            },
          },
        },
      },
    });
  };

  const updateAddTagOnOrderVariablePayload = (
    leftValue: string,
    triggerName: string,
    triggerRightValue: string,
    typeName: string
  ) => {
    const { extranalData } = selectedSaveActionsData.eCommerce?.addTagsOnOrder;

    const triggerInfoValueObj = triggerInfo[leftValue].filter(
      (item: any) => item?.name === triggerName
    );

    const updatedAddTagOnOrderPayload = {
      ...extranalData?.payload,
      [typeName]: [
        ...(extranalData?.payload[typeName] || []),
        {
          ...triggerInfoValueObj[0],
          name: triggerRightValue.replace(/{{|}}/g, ''),
          triggerValue: leftValue,
        },
      ],
      plain_tag: [
        ...extranalData?.payload?.plain_tag,
        `{{${triggerInfoValueObj[0]?.name}}}`,
      ],
    };

    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: {
        ...selectedSaveActionsData,
        eCommerce: {
          ...selectedSaveActionsData?.eCommerce,
          addTagsOnOrder: {
            ...selectedSaveActionsData.eCommerce.addTagsOnOrder,
            extranalData: {
              ...selectedSaveActionsData.eCommerce.addTagsOnOrder?.extranalData,
              payload: updatedAddTagOnOrderPayload,
            },
          },
        },
      },
    });
  };

  const updateRemoveTagOnOrderVariablePayload = (
    leftValue: string,
    triggerName: string,
    triggerRightValue: string,
    typeName: string
  ) => {
    const { extranalData } =
      selectedSaveActionsData.eCommerce?.removeTagsOnOrder;

    const triggerInfoValueObj = triggerInfo[leftValue].filter(
      (item: any) => item?.name === triggerName
    );

    const updatedRemoveTagOnOrderPayload = {
      ...extranalData?.payload,
      [typeName]: [
        ...(extranalData?.payload[typeName] || []),
        {
          ...triggerInfoValueObj[0],
          name: triggerRightValue.replace(/{{|}}/g, ''),
          triggerValue: leftValue,
        },
      ],
      plain_tag: [
        ...extranalData?.payload?.plain_tag,
        `{{${triggerInfoValueObj[0]?.name}}}`,
      ],
    };

    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: {
        ...selectedSaveActionsData,
        eCommerce: {
          ...selectedSaveActionsData?.eCommerce,
          removeTagsOnOrder: {
            ...selectedSaveActionsData.eCommerce.removeTagsOnOrder,
            extranalData: {
              ...selectedSaveActionsData.eCommerce.removeTagsOnOrder
                ?.extranalData,
              payload: updatedRemoveTagOnOrderPayload,
            },
          },
        },
      },
    });
  };

  const updateRemoveTagVariablePayload = (
    leftValue: string,
    triggerName: string,
    triggerRightValue: string
  ) => {
    const { component } = selectedSaveActionsData.eCommerce?.removeTags;
    const storedData = component.definition.stored_data || {};
    const removeTagPayload = storedData.remove_tags_payload || {};

    const triggerInfoValueObj = triggerInfo[leftValue].filter(
      (item: any) => item?.name === triggerName
    );

    if (triggerInfoValueObj.length > 0) {
      const updatedRemoveTagsPayload = {
        ...removeTagPayload,
        remove_tag: [
          ...(Array.isArray(removeTagPayload.remove_tag)
            ? removeTagPayload.remove_tag
            : []),
          triggerRightValue,
        ],
        add_variable: [
          ...(Array.isArray(removeTagPayload.add_variable)
            ? removeTagPayload.add_variable
            : []),
          triggerInfoValueObj[0],
        ],
      };

      const updatedStoredData = {
        ...storedData,
        remove_tags_payload: updatedRemoveTagsPayload,
      };

      const updatedComponent = {
        ...component,
        definition: {
          ...component.definition,
          stored_data: updatedStoredData,
        },
      };
      dispatchUpdatedData(updatedComponent, 'removeTags');
    }
  };

  const removeSelectedAddTagsOnCustomer = (index: number, value: string) => {
    const { extranalData } =
      selectedSaveActionsData.eCommerce?.addTagsOnCustomer;

    // Get the current add_tag array
    const addPlainTagsArray = extranalData?.payload?.plain_tag || [];
    const addVariableTagsArray = extranalData?.payload?.variable_tag || [];

    // Remove braces from the value
    const removeBraces = value.replace(/{{|}}/g, '');

    // Find the index of the item in add_variable array
    const variableIndex = addVariableTagsArray.findIndex(
      (item: any) => item?.name === removeBraces
    );

    // Check if the element at the specified index matches the given value
    if (addPlainTagsArray[index] === value) {
      // Remove the element from the add_tag array
      const updatedAddTagsArray = [
        ...addPlainTagsArray.slice(0, index),
        ...addPlainTagsArray.slice(index + 1),
      ];

      // Remove the element from the add_variable array if it exists
      const updatedAddVariablesArray =
        variableIndex !== -1
          ? [
              ...addVariableTagsArray.slice(0, variableIndex),
              ...addVariableTagsArray.slice(variableIndex + 1),
            ]
          : addVariableTagsArray;

      // Update the payload with the modified arrays
      const updatedAddTagPayload = {
        ...extranalData?.payload,
        plain_tag: updatedAddTagsArray,
        variable_tag: updatedAddVariablesArray,
      };

      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'selectedSaveActionsData',
        value: {
          ...selectedSaveActionsData,
          eCommerce: {
            ...selectedSaveActionsData?.eCommerce,
            addTagsOnCustomer: {
              ...selectedSaveActionsData.eCommerce.addTagsOnCustomer,
              extranalData: {
                ...selectedSaveActionsData.eCommerce.addTagsOnCustomer
                  ?.extranalData,
                payload: updatedAddTagPayload,
              },
            },
          },
        },
      });
    }
  };

  const removeSelectedRemoveTagsOnCustomer = (index: number, value: string) => {
    const { extranalData } =
      selectedSaveActionsData.eCommerce?.removeTagsOnCustomer;

    // Get the current add_tag array
    const removePlainTagsArray = extranalData?.payload?.plain_tag || [];
    const removeVariableTagsArray = extranalData?.payload?.variable_tag || [];

    // Remove braces from the value
    const removeBraces = value.replace(/{{|}}/g, '');

    // Find the index of the item in add_variable array
    const variableIndex = removeVariableTagsArray.findIndex(
      (item: any) => item?.name === removeBraces
    );

    // Check if the element at the specified index matches the given value
    if (removePlainTagsArray[index] === value) {
      // Remove the element from the add_tag array
      const updatedAddTagsArray = [
        ...removePlainTagsArray.slice(0, index),
        ...removePlainTagsArray.slice(index + 1),
      ];

      // Remove the element from the add_variable array if it exists
      const updatedAddVariablesArray =
        variableIndex !== -1
          ? [
              ...removeVariableTagsArray.slice(0, variableIndex),
              ...removeVariableTagsArray.slice(variableIndex + 1),
            ]
          : removeVariableTagsArray;

      // Update the payload with the modified arrays
      const updatedAddTagPayload = {
        ...extranalData?.payload,
        plain_tag: updatedAddTagsArray,
        variable_tag: updatedAddVariablesArray,
      };

      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'selectedSaveActionsData',
        value: {
          ...selectedSaveActionsData,
          eCommerce: {
            ...selectedSaveActionsData?.eCommerce,
            removeTagsOnCustomer: {
              ...selectedSaveActionsData.eCommerce.removeTagsOnCustomer,
              extranalData: {
                ...selectedSaveActionsData.eCommerce.removeTagsOnCustomer
                  ?.extranalData,
                payload: updatedAddTagPayload,
              },
            },
          },
        },
      });
    }
  };

  const removeSelectedAddTagsOnOrder = (index: number, value: string) => {
    const { extranalData } = selectedSaveActionsData.eCommerce?.addTagsOnOrder;

    // Get the current add_tag array
    const addPlainTagsArray = extranalData?.payload?.plain_tag || [];
    const addVariableTagsArray = extranalData?.payload?.variable_tag || [];

    // Remove braces from the value
    const removeBraces = value.replace(/{{|}}/g, '');

    // Find the index of the item in add_variable array
    const variableIndex = addVariableTagsArray.findIndex(
      (item: any) => item?.name === removeBraces
    );

    // Check if the element at the specified index matches the given value
    if (addPlainTagsArray[index] === value) {
      // Remove the element from the add_tag array
      const updatedAddTagsArray = [
        ...addPlainTagsArray.slice(0, index),
        ...addPlainTagsArray.slice(index + 1),
      ];

      // Remove the element from the add_variable array if it exists
      const updatedAddVariablesArray =
        variableIndex !== -1
          ? [
              ...addVariableTagsArray.slice(0, variableIndex),
              ...addVariableTagsArray.slice(variableIndex + 1),
            ]
          : addVariableTagsArray;

      // Update the payload with the modified arrays
      const updatedAddTagPayload = {
        ...extranalData?.payload,
        plain_tag: updatedAddTagsArray,
        variable_tag: updatedAddVariablesArray,
      };

      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'selectedSaveActionsData',
        value: {
          ...selectedSaveActionsData,
          eCommerce: {
            ...selectedSaveActionsData?.eCommerce,
            addTagsOnOrder: {
              ...selectedSaveActionsData.eCommerce.addTagsOnOrder,
              extranalData: {
                ...selectedSaveActionsData.eCommerce.addTagsOnOrder
                  ?.extranalData,
                payload: updatedAddTagPayload,
              },
            },
          },
        },
      });
    }
  };

  const removeSelectedRemoveTagsOnOrder = (index: number, value: string) => {
    const { extranalData } =
      selectedSaveActionsData.eCommerce?.removeTagsOnOrder;

    // Get the current add_tag array
    const removePlainTagsArray = extranalData?.payload?.plain_tag || [];
    const removeVariableTagsArray = extranalData?.payload?.variable_tag || [];

    // Remove braces from the value
    const removeBraces = value.replace(/{{|}}/g, '');

    // Find the index of the item in add_variable array
    const variableIndex = removeVariableTagsArray.findIndex(
      (item: any) => item?.name === removeBraces
    );

    // Check if the element at the specified index matches the given value
    if (removePlainTagsArray[index] === value) {
      // Remove the element from the add_tag array
      const updatedAddTagsArray = [
        ...removePlainTagsArray.slice(0, index),
        ...removePlainTagsArray.slice(index + 1),
      ];

      // Remove the element from the add_variable array if it exists
      const updatedRemoveVariablesArray =
        variableIndex !== -1
          ? [
              ...removeVariableTagsArray.slice(0, variableIndex),
              ...removeVariableTagsArray.slice(variableIndex + 1),
            ]
          : removeVariableTagsArray;

      // Update the payload with the modified arrays
      const updatedAddTagPayload = {
        ...extranalData?.payload,
        plain_tag: updatedAddTagsArray,
        variable_tag: updatedRemoveVariablesArray,
      };

      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'selectedSaveActionsData',
        value: {
          ...selectedSaveActionsData,
          eCommerce: {
            ...selectedSaveActionsData?.eCommerce,
            removeTagsOnOrder: {
              ...selectedSaveActionsData.eCommerce.removeTagsOnOrder,
              extranalData: {
                ...selectedSaveActionsData.eCommerce.removeTagsOnOrder
                  ?.extranalData,
                payload: updatedAddTagPayload,
              },
            },
          },
        },
      });
    }
  };

  const removeSelectedTag = (index: number, value: string) => {
    const { component } = selectedSaveActionsData.eCommerce?.removeTags;
    const storedData = component.definition.stored_data || {};
    const removeTagPayload = storedData.remove_tags_payload || {};

    // Get the current remove_tag and add_variable arrays
    const removeTagsArray = removeTagPayload.remove_tag || [];
    const addVariablesArray = removeTagPayload.add_variable || [];

    // Remove braces from the value
    const removeBraces = value.replace(/{{|}}/g, '');

    // Find the index of the item in add_variable array
    const variableIndex = addVariablesArray.findIndex(
      (item: any) => item?.name === removeBraces
    );

    // Check if the element at the specified index in remove_tag matches the given value
    if (removeTagsArray[index] === value) {
      // Remove the element from the remove_tag array
      const updatedRemoveTagsArray = [
        ...removeTagsArray.slice(0, index),
        ...removeTagsArray.slice(index + 1),
      ];

      // Remove the element from the add_variable array if it exists
      const updatedAddVariablesArray =
        variableIndex !== -1
          ? [
              ...addVariablesArray.slice(0, variableIndex),
              ...addVariablesArray.slice(variableIndex + 1),
            ]
          : addVariablesArray;

      // Update the payload with the modified arrays
      const updatedRemoveTagPayload = {
        ...removeTagPayload,
        remove_tag: updatedRemoveTagsArray,
        add_variable: updatedAddVariablesArray,
      };

      // Update the stored data with the new payload
      const updatedStoredData = {
        ...storedData,
        remove_tags_payload: updatedRemoveTagPayload,
      };

      // Update the component with the new stored data
      const updatedComponent = {
        ...component,
        definition: {
          ...component.definition,
          stored_data: updatedStoredData,
        },
      };

      // Dispatch Redux action to update stored data
      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'selectedSaveActionsData',
        value: {
          ...selectedSaveActionsData,
          eCommerce: {
            ...selectedSaveActionsData.eCommerce,
            removeTags: {
              ...selectedSaveActionsData.eCommerce?.removeTags,
              component: updatedComponent,
            },
          },
        },
      });
    }
  };

  const dispatchUpdatedData = (updatedComponent: any, key: string) => {
    const updateActionData = {
      ...selectedSaveActionsData,
      eCommerce: {
        ...selectedSaveActionsData.eCommerce,
        [key]: {
          ...selectedSaveActionsData.eCommerce[key],
          component: updatedComponent,
        },
      },
    };

    // Dispatch Redux action to update stored data
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: updateActionData,
    });
  };

  return {
    removeSelectedAddTagsOnCustomer,
    removeSelectedAddTagsOnOrder,
    removeSelectedTag,
    selectExecutor,
    updateRemoveTagDataOnOrder,
    updateAddTagDataOnCustomer,
    updateAddTagDataOnOrder,
    updateRemoveTagDataOnCustomer,
    removeSelectedRemoveTagsOnOrder,
    updateRemoveTagOnOrderVariablePayload,
    updateRemoveTagOnCustomerVariablePayload,
    getButtonColSpan,
    updateStoredData,
    updateRemoveTagData,
    handleExecutorClick,
    handleSelectedExecutor,
    saveSelectedDelayValue,
    updateAddTagOnOrderVariablePayload,
    removeSelectedRemoveTagsOnCustomer,
    updateAddTagVariablePayload,
    handleUpdateSelectedChannel,
    handleSelectWhatsappTemplate,
    updateRemoveTagVariablePayload,
    selecteTemporaryExecutorDetails,
    renderChangeExecutorDetailsUIType,
    updateSelectedTriggerInfoLeftValue,
    updateSelectedTriggerInfoRightValue,
    updateConditionallyRenderingActionsUI,
    updateSelectedAssignVariableFallbackValue,
    highlightAttributeFromTextWithCountAndItems,
    updateSelectedAssignVariableCategoryAndValue,
  };
};

export default useActions;
