import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'libraryV2/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'libraryV2/ui/popover';
import { Button } from 'libraryV2/ui/button';
import { classNames } from 'utilities/utils';

interface Props {
  value: string;
  items: any[];
  className?: string;
  valueIndex?: string;
  titleIndex?: string;
  subTitleIndex?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

const CustomComboBox: React.FC<Props> = ({
  value,
  items,
  onChange,
  titleIndex,
  valueIndex,
  subTitleIndex,
  className = '',
  placeholder = 'Select an item',
}) => {
  const [open, setOpen] = React.useState(false);

  const getItemValue = (item: any) => {
    return !!valueIndex ? item[valueIndex] : item;
  };

  const getItemTitle = (item: any) => {
    return !!titleIndex ? item[titleIndex] : getItemValue(item);
  };

  const getItemSubTitle = (item: any) => {
    return !!subTitleIndex ? item[subTitleIndex] : '';
  };

  const getSelectedItemTitle = () => {
    const selectedItem = items.find((item) => getItemValue(item) === value);
    return selectedItem ? getItemTitle(selectedItem) : value;
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          role='combobox'
          aria-expanded={open}
          className={classNames(
            'w-full text-left items-center justify-between flex',
            className
          )}
        >
          {value ? (titleIndex ? getSelectedItemTitle() : value) : placeholder}
          <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[20vw] ml-2 p-0 bg-white'>
        <Command className='bg-white w-[20vw]'>
          <CommandInput
            placeholder='Search...'
            className='border-0 border-transparent focus:ring-0 focus:ring-offset-0 focus:border-0 focus:border-transparent'
          />
          <CommandList className='w-full'>
            <CommandEmpty>No items found.</CommandEmpty>
            <CommandGroup>
              {items.map((item: any, index: number) => {
                const itemValue = getItemValue(item);
                const itemTitle = getItemTitle(item);
                const itemSubTitle = getItemSubTitle(item);
                const isSelected = value === itemValue;
                return (
                  <CommandItem
                    key={index}
                    keywords={[itemTitle]}
                    value={itemValue}
                    onSelect={(currentValue) => {
                      onChange(isSelected ? '' : currentValue);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={classNames(
                        'mr-2 h-4 w-4',
                        isSelected ? 'opacity-100' : 'opacity-0'
                      )}
                    />
                    {itemTitle}
                    {!!itemSubTitle && ` - ${itemSubTitle}`}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default CustomComboBox;
