import { FC, useState } from 'react';
import {
  Button,
  PlusIcon,
  useDatalab,
  toast,
  DeleteAlert,
} from '../../../export';

import { IFilterDataArr } from '../../../interface';
import FilterField from './FilterField';
import FilterViewAddModal from './FilterViewAddModal';

interface Props {
  onSideSheetClose: () => void;
  onApplyFilter: (a: any) => void;
}

const Filter: FC<Props> = ({ onSideSheetClose, onApplyFilter }) => {
  const {
    pageChange,
    storedFilterData,
    updatePageChange,
    selectedDatalabInfo,
    fetchDatalabEntries,

    // Data lab filter api actions
    appliedFilterView,
    updateAppliedFilterView,
    createDatalabFilter,
    updateDatalabFilter,
    deleteDatalabFilter,
  } = useDatalab();

  const [filterData, setFilterData] =
    useState<IFilterDataArr>(appliedFilterView);
  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false);
  const [openFilterTitleDialog, setOpenFilterTitleDialog] =
    useState<boolean>(false);
  const [isFilterTitleExist, setIsFilterTitleExist] = useState<boolean>(false);

  // Add new filter
  const handleAddNewFilter = () => {
    const newFilter = {
      title: '',
      filter_options: [
        {
          slug: '',
          operator: '',
          value: '',
        },
      ],
    };

    setFilterData(newFilter);
  };

  // Add new filter option
  const handleOnAddNewFilterOption = () => {
    // before adding new filter option, check if the last filter option's slug, operator and value are not empty
    const lastFilterOption = filterData?.filter_options?.slice(-1)[0];
    if (
      !lastFilterOption?.slug ||
      !lastFilterOption?.operator ||
      !lastFilterOption?.value
    ) {
      toast({
        //@ts-ignore
        title: <p className='text-red-500'>Could not add new filter</p>,
        description: `Please fill all the fields. Slug, Operator and Value are required.`,
      });
      return;
    }

    const newFilterOption = {
      slug: '',
      operator: '',
      value: '',
    };

    setFilterData((prevFilterData) => {
      return {
        ...prevFilterData,
        filter_options: [...prevFilterData.filter_options, newFilterOption],
      };
    });
  };

  // Update filter option data
  const handleFilterDataOnChange = (index: number, updatedData: any) => {
    const newFilterData = { ...filterData };

    // Ensure filter_options is an array
    newFilterData.filter_options = newFilterData.filter_options || [];
    newFilterData.filter_options[index] = updatedData;

    setFilterData(newFilterData);
  };

  // Remove filter option from list
  const removeFilterOptionFromList = (index: number) => {
    const updatedFilterData = { ...filterData };

    const filterOptions = updatedFilterData?.filter_options?.filter(
      (_, i) => i !== index
    );

    setFilterData({
      ...updatedFilterData,
      filter_options: [...filterOptions],
    });

    if (filterOptions.length === 0) {
      // update applied filter view to empty object
      updateAppliedFilterView(null);
      onSideSheetClose();
    }
  };

  const handleApplyFilter = () => {
    onApplyFilter(filterData);
    onSideSheetClose();
  };

  const handleCancelFilter = () => {
    setFilterData({ title: '', filter_options: [] });
    onSideSheetClose();
  };

  const handleOnClearFilter = () => {
    setFilterData({
      title: '',
      filter_options: [
        {
          slug: '',
          operator: '',
          value: '',
        },
      ],
    });
  };

  const toggleFilterTitleDialog = () => {
    setOpenFilterTitleDialog(!openFilterTitleDialog);
  };

  // Save new filter
  const handleOnSaveNewFilter = (title: any) => {
    const isTitleExist = storedFilterData.some(
      (filter: any) => filter.title === title
    );

    if (isTitleExist) {
      setIsFilterTitleExist(true);
      return;
    }

    const newFilterData = { ...filterData, title };
    setFilterData(newFilterData);

    setIsFilterTitleExist(false);
    createDatalabFilter(newFilterData);

    onApplyFilter(newFilterData);
    setOpenFilterTitleDialog(false);
    onSideSheetClose();
  };

  const handleOnUpdateFilter = () => {
    updateDatalabFilter(filterData.id, filterData);
    onApplyFilter(filterData);
    onSideSheetClose();
  };

  const handleOnDeleteFilter = () => {
    deleteDatalabFilter(appliedFilterView.id);

    updatePageChange(pageChange.limit, 0);
    fetchDatalabEntries();

    onSideSheetClose();
  };

  // check filter data's filter options last element is empty or not ({slug: '', operator: '', value: ''})
  const isFilterDataEmpty = () => {
    const lastFilterOption = filterData?.filter_options?.slice(-1)[0];
    return (
      !lastFilterOption?.slug ||
      !lastFilterOption?.operator ||
      !lastFilterOption?.value
    );
  };

  const filterDataIsEmpty = isFilterDataEmpty();
  const filterOptionLength = filterData?.filter_options?.length || 0;

  return (
    <div className='h-full overflow-y-scroll scrollbar-hide'>
      {filterData?.filter_options?.length ? (
        <>
          {filterData?.filter_options?.map((data, index) => (
            <FilterField
              key={index}
              data={data}
              formFieldList={selectedDatalabInfo?.lab_fields}
              removeFilterFromList={() => removeFilterOptionFromList(index)}
              handleFilterDataOnChange={(updatedData: any) =>
                handleFilterDataOnChange(index, updatedData)
              }
            />
          ))}
          <AddNewFilterButton onClick={handleOnAddNewFilterOption} />

          <div className='fixed flex bottom-6 right-6'>
            <div className='flex justify-between gap-2'>
              <div>
                {appliedFilterView?.created_at && (
                  <Button
                    className='ml-1 text-white bg-red-500'
                    onClick={() => setOpenDiscardDialog(true)}
                  >
                    Delete
                  </Button>
                )}
              </div>
              <div className='flex gap-2'>
                {filterOptionLength < 2 && (
                  <Button
                    variant='outline'
                    onClick={() => handleCancelFilter()}
                  >
                    Cancel
                  </Button>
                )}

                {filterOptionLength >= 2 && (
                  <Button
                    variant='outline'
                    className='bg-[#F4F4F5] hover:bg-[#FFFFFF]'
                    onClick={handleOnClearFilter}
                  >
                    Clear Filter
                  </Button>
                )}

                {appliedFilterView?.created_at ? (
                  <Button
                    variant='outline'
                    className='hover:bg-[#F4F4F5]'
                    onClick={handleOnUpdateFilter}
                  >
                    Update View
                  </Button>
                ) : (
                  <FilterViewAddModal
                    filterData={filterData}
                    isFilterTitleExist={isFilterTitleExist}
                    openFilterTitleDialog={openFilterTitleDialog}
                    toggleFilterTitleDialog={toggleFilterTitleDialog}
                    filterDataIsEmpty={filterDataIsEmpty}
                    handleOnSaveNewFilter={handleOnSaveNewFilter}
                  />
                )}
                <Button
                  className={`${
                    filterDataIsEmpty
                      ? 'bg-[#F4F4F5] text-[#A1A1AA]'
                      : 'text-white'
                  }`}
                  onClick={handleApplyFilter}
                  disabled={filterDataIsEmpty}
                >
                  Apply Filter
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <AddNewFilterView onClick={handleAddNewFilter} />
      )}
      {openDiscardDialog && (
        <DeleteAlert
          open={openDiscardDialog}
          onOpenChange={setOpenDiscardDialog}
          title={'Discard filter'}
          description={`All entered data will be discarded`}
          onCancel={() => setOpenDiscardDialog(false)}
          onConfirm={handleOnDeleteFilter}
        />
      )}
    </div>
  );
};

const AddNewFilterButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button className='flex gap-2 mt-4' variant='outline' onClick={onClick}>
    <PlusIcon className='w-4 h-4' stroke='2' />
    <span>Add New Filter</span>
  </Button>
);

const AddNewFilterView: FC<{ onClick: () => void }> = ({ onClick }) => (
  <div className='flex flex-col items-center justify-center h-full'>
    <span>No filter added.</span>
    <AddNewFilterButton onClick={onClick} />
  </div>
);

export default Filter;