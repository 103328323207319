import { IAssignVariableTypes } from 'pages/automationWorkflow/interface';
import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';

interface VariableDropdownProps {
  variables: IAssignVariableTypes[];
  triggerInfo: any;
  updateSelectedAssignVariableCategoryAndValue: Function;
  variableIndex: number;
}

const VariableDropdown: React.FC<VariableDropdownProps> = ({
  variables,
  triggerInfo,
  updateSelectedAssignVariableCategoryAndValue,
  variableIndex,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className='py-2 px-4 text-gray-500 text-sm w-full text-left bg-white border  rounded-md cursor-pointer focus:outline-none'>
          <span className='px-2 py-1 text-sm font-regular text-gray-500'>
            {variables[variableIndex]?.variableValue === ''
              ? 'Select variables'
              : `{{${variables[variableIndex]?.variableValue}}}`}
          </span>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent className='shadow-lg bg-white z-30 w-64 divide-y divide-gray-100 rounded-md'>
        <DropdownMenuGroup asChild>
          <>
            {Object.keys(triggerInfo).map(
              (item: string, categoryIndex: number) => (
                <DropdownMenuSub key={categoryIndex}>
                  <DropdownMenuSubTrigger
                    onClick={() =>
                      updateSelectedAssignVariableCategoryAndValue(
                        'category',
                        item,
                        variableIndex
                      )
                    }
                    className='w-full border-none pt-0'
                  >
                    <div
                      className={`${
                        Object.keys(triggerInfo).length - 1 === categoryIndex
                          ? 'border-none pb-0'
                          : 'border-b pb-2'
                      } w-full`}
                    >
                      <div
                        className={`${
                          variables[variableIndex]?.variableCategory === item
                            ? 'bg-gray-100'
                            : 'bg-white'
                        } w-full flex justify-between items-center cursor-pointer hover:bg-gray-100 py-1 text-gray-700 rounded-md`}
                      >
                        <h3 className='ml-3 font-medium text-base'>{item}</h3>
                      </div>
                    </div>
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent className='bg-white z-40 shadow-md h-full max-h-96 overflow-y-auto mb-3'>
                      {triggerInfo[item].length !== 0 &&
                        triggerInfo[item].map(
                          (triggerValue: any, childIndex: number) => (
                            <DropdownMenuItem key={childIndex} className='pt-0'>
                              <div
                                className='border-b p-2 pt-1 w-full'
                                onClick={() => {
                                  updateSelectedAssignVariableCategoryAndValue(
                                    'value',
                                    triggerValue?.name,
                                    variableIndex
                                  );
                                  updateSelectedAssignVariableCategoryAndValue(
                                    'category',
                                    item,
                                    variableIndex
                                  );
                                }}
                              >
                                <div
                                  className={`${
                                    variables[variableIndex]?.variableValue ===
                                    triggerValue?.name
                                      ? 'bg-gray-100'
                                      : 'bg-white'
                                  } flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md`}
                                >
                                  <div className='text-gray-600'>{`{{${triggerValue?.name}}}`}</div>
                                </div>
                              </div>
                            </DropdownMenuItem>
                          )
                        )}
                      {triggerInfo[item].length === 0 && (
                        <div className='p-2'>
                          <div
                            className={` bg-white flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md`}
                          >
                            <div className='text-gray-600'>
                              No Variable found
                            </div>
                          </div>
                        </div>
                      )}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              )
            )}
          </>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default VariableDropdown;
