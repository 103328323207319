import React from 'react';
import { connect } from 'react-redux';
import OrderTypeOptionPopover from './OrderTypeOptionPopover';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  step: number;
  setStep: (step: number) => void;
  isNextDisable: boolean;
  onNext: () => void;
  openSuccessModal: boolean;
  setOpenSuccessModal: (payload: boolean) => void;
  isOrderPlaceLoader: boolean;
  handleOrderSubmit: () => void;
  orderType: string;
  setOrderType: (payload: string) => void;
  toggleEcommerceModal: (value: boolean) => void;
  updateOpenShopifyModal: (payload: boolean) => void;
  openModal: boolean;
  isCalculateDraftLoading: boolean;
  setShowRightbar: (payload: boolean) => void;
}

const FormFooter: React.FC<Props> = ({
  step,
  setStep,
  isNextDisable,
  onNext,
  isOrderPlaceLoader,
  orderType,
  setOrderType,
  handleOrderSubmit,
  toggleEcommerceModal,
  updateOpenShopifyModal,
  openModal,
  isCalculateDraftLoading,
  setShowRightbar,
}) => {
  const { t } = useTranslation();
  const loadingIcon = () => {
    return (
      <div className='flex justify-center items-center'>
        <div
          className={`${
            isNextDisable && step !== 3
              ? 'border-t-gray-700 border-b-gray-700 border-l-gray-700 '
              : 'border-t-gray-300 border-b-gray-300 border-l-gray-300 '
          }
          border-r-transparent animate-spin inline-block h-5 w-5 border-2 rounded-full`}
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  };

  const renderViewCartButtonView = () => {
    return (
      <button
        className='w-1/2 rounded-md border border-gray-300 p-2 text-gray-600
        text-sm font-medium'
        onClick={() => {
          toggleEcommerceModal(!openModal);
          setShowRightbar(false);
          updateOpenShopifyModal(false);
        }}
      >
        {t('View Cart')}
      </button>
    );
  };

  const renderBackButtonView = () => {
    return (
      <button
        className={` ${
          step !== 3 ? 'w-1/2' : 'w-[35%]'
        } rounded-md border border-gray-300 p-2 text-gray-600
       text-sm font-medium`}
        onClick={() => {
          if (step > 1) setStep(step - 1);
        }}
      >
        {t('Back')}
      </button>
    );
  };

  const renderNextButtonView = () => {
    return (
      <button
        className={`w-1/2 rounded-md ${
          isNextDisable
            ? 'bg-[#D2D6DB] text-[#6B7280] border-green-gray'
            : 'bg-primary text-white border-green-500'
        } border p-2
        text-sm`}
        disabled={isNextDisable}
        onClick={() => {
          if (!isCalculateDraftLoading) {
            onNext();
          }
        }}
      >
        {isCalculateDraftLoading ? <>{loadingIcon()}</> : t('Next')}
      </button>
    );
  };

  const renderOrderTypeOptionButtonView = () => {
    return (
      <div className='flex w-[65%]'>
        <button
          className='border border-green-500 ltr:rounded-l rtl:rounded-r
          text-center bg-green-500 py-2 text-white w-[100%] capitalize ltr:mr-[2px]'
          onClick={() => {
            if (!isOrderPlaceLoader) {
              handleOrderSubmit();
            }
          }}
        >
          {isOrderPlaceLoader ? <>{loadingIcon()}</> : `${orderType} Order`}
        </button>
        <OrderTypeOptionPopover
          setOrderType={setOrderType}
          orderType={orderType}
        />
      </div>
    );
  };

  return (
    <div className='flex border-t border-t-gray-200 p-3 justify-between items-center gap-2 bg-white'>
      {step === 1 && renderViewCartButtonView()}
      {step !== 1 && renderBackButtonView()}
      {step !== 3 && renderNextButtonView()}
      {step === 3 && renderOrderTypeOptionButtonView()}
    </div>
  );
};

const mapState = (state: any) => ({
  isOrderPlaceLoader: state.loading.effects.ecommerce.createOrder,
  openModal: state.ecommerce.openModal,
  isCalculateDraftLoading: state.loading.effects.ecommerce.calculateDraftOrder,
});
const mapDispatch = (dispatch: any) => ({
  toggleEcommerceModal: (value: boolean) =>
    dispatch.ecommerce.updateEcommerceStateData({ key: 'openModal', value }),
  updateOpenShopifyModal: (payload: boolean) =>
    dispatch.ecommerce.updateOpenShopifyModal(payload),
});

export default connect(mapState, mapDispatch)(FormFooter);
