import { FormDataAxios } from 'utilities/utils';
import { axios, config } from '../export';
import { toast } from 'libraryV2/ui/use-toast';

const initialState = {
  datalabEntries: [],
  dateRange: {
    from: null,
    to: null,
  },
  selectedDatalabInfo: {},
  isLoading: false,
  isDeleted: false,
  isImported: false,
  totalRowCount: null,
  pageChange: {
    limit: 10,
    offset: 0,
  },
  storedFilterData: [],
  appliedFilterView: null,
  isFilterApplied: false,
};

export const datalabList = {
  state: { ...initialState },
  reducers: {
    updateFetchDataLab(state, payload) {
      return { ...state, datalabEntries: payload };
    },
    updateSelectedDatalabInfo(state, payload) {
      return { ...state, selectedDatalabInfo: payload };
    },
    setLoadingState(state, payload) {
      return { ...state, isLoading: payload };
    },
    updateDateRange(state, payload) {
      return { ...state, dateRange: payload };
    },
    updateDeleteStatus(state, payload) {
      return { ...state, isDeleted: payload };
    },
    updateIsImportedStatus(state, payload) {
      return { ...state, isImported: payload };
    },
    updateTotalRowCount(state, payload) {
      return { ...state, totalRowCount: payload };
    },
    updateAppliedFilterView(state, payload) {
      return { ...state, appliedFilterView: payload };
    },
    fetchUpdatedFilterData(state, payload) {
      return { ...state, storedFilterData: payload, appliedFilterView: null };
    },
    addNewFilterData(state, payload) {
      const { storedFilterData } = state;
      const updatedFilterData = [...storedFilterData, { ...payload }];

      return {
        ...state,
        storedFilterData: updatedFilterData,
        appliedFilterView: payload,
      };
    },
    updateFilterData(state, payload) {
      const updatedFilterData = state.storedFilterData.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
      return {
        ...state,
        storedFilterData: updatedFilterData,
        appliedFilterView: payload,
      };
    },
    removeFilterData(state, payload) {
      const updatedFilterData = state.storedFilterData.filter(
        (item) => item.id !== payload
      );
      return { ...state, storedFilterData: updatedFilterData, appliedFilterView: null };
    },
    updatePageChange(state, payload) {
      return { ...state, pageChange: payload };
    },
    updateIsFilterState(state, updatedData) {
      return { ...state, isFilterApplied: updatedData };
    },
    removeDeletedData(state, ids) {
      const updatedLabsData = state.datalabEntries.filter(
        (item) => !ids.includes(item.id)
      );
      return {
        ...state,
        datalabEntries: updatedLabsData,
        totalRowCount: state.totalRowCount - (ids?.length || 0),
      };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch, rootState) => ({
    async fetchDataLabInfo({ projectId, labId }) {
      try {
        const res = await axios.get(
          config.datalab.getDatalabInfo(projectId, labId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.datalabList.updateSelectedDatalabInfo(res.data.dataSource);
        }
      } catch (err) {
        throw new Error('Failed to fetch Datalab info!');
      }
    },
    async fetchDatalabList({
      projectId,
      labId,
      dateRange,
      pageChange,
      filterOptions = [],
    }) {
      try {
        let updatedFilterOptions = JSON.stringify(filterOptions);
        const datalabListRes = await axios.get(
          config.datalab.datalabListData(projectId, labId),
          {
            params: {
              start: dateRange.start,
              end: dateRange.end,
              limit: pageChange.limit,
              offset: pageChange.offset,
              filter_options: updatedFilterOptions || null,
            },
          }
        );
        if (datalabListRes?.status === 200) {
          dispatch.datalabList.updateFetchDataLab(
            datalabListRes?.data?.dataSource
          );
          dispatch.datalabList.updateTotalRowCount(datalabListRes.data.count);
        }
        // incase user reloads the page we need to reset the filter state if previously applied
        dispatch.datalabList.updateIsFilterState(false);
        return true;
      } catch (err) {
        console.log(err);
        if (err.response.status === 500) {
          toast({
            title: <p className='text-red-500'>Something went wrong</p>,
            description: `Request for getting entries has been failed. Please try again`,
          });
        } else {
          toast({
            title: <p className='text-red-500'>Failed to Get Lab Entired</p>,
            description:
              err?.response?.data?.error ||
              `Request for getting entries has been failed. Please try again`,
          });
        }
        return false;
      }
    },

    async fetchExportDatalabList({ labId, dateRange }) {
      try {
        const exportResult = await axios.get(
          config.datalab.datalabExport(labId),
          {
            params: {
              start: dateRange.start,
              end: dateRange.end,
            },
          }
        );
        if (exportResult?.status === 200) {
          return exportResult?.data?.success;
        }
      } catch (err) {
        console.log(err);
      }
    },

    async importDatalabList({ labId, file }) {
      const formData = new FormData();
      formData.append('file', file);
      const axiosInstance = FormDataAxios();
      try {
        const res = await axiosInstance.post(
          config.datalab.datalabImport(labId),
          formData
        );
        if (res.status === 200 && res.data.success) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },

    async deleteDatalabList({ labId, data }) {
      try {
        const deleteResult = await axios.delete(
          config.datalab.datalabDeleteList(labId),
          {
            data: { ids: data },
          }
        );
        if (deleteResult?.status === 200 && deleteResult?.data?.success) {
          dispatch.datalabList.removeDeletedData(data);
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async createDatalabEntry({ labId, data }) {
      try {
        const createResult = await axios.post(
          config.datalab.datalabCreateEntry(labId),
          data
        );
        if (createResult?.status === 201) {
          console.log('Created');
        }
      } catch (err) {
        console.log(err);
      }
    },

    async updateDatalabEntry({ labId, data }) {
      try {
        const createResult = await axios.post(
          // update api *
          config.datalab.datalabCreateEntry(labId),
          data
        );
        if (createResult?.status === 201) {
          console.log('Created');
        }
      } catch (err) {
        console.log(err);
      }
    },

    // Start: data lab filter api call

    // Fetch filter data
    async fetchFilterViewData(labId) {
      try {
        const res = await axios.get(config.datalab.datalabGetFilterData(labId));

        if (res.status === 200 && res.data.success) {
          dispatch.datalabList.fetchUpdatedFilterData(res.data.dataSource);
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Failed to Fetch Filter View</p>,
          description: err?.response?.data?.error || `Please try again`,
        });
      }
    },

    // Create filter data
    async createFilterView({ labId, data }) {
      try {
        const res = await axios.post(
          config.datalab.datalabCreateFilter(labId),
          data
        );

        if (res.status === 200 && res.data.success) {
          dispatch.datalabList.addNewFilterData(res.data.dataSource);
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Failed to Create Filter View</p>,
          description:
            err?.response?.data?.error ||
            `Something went wrong! Please try again`,
        });
      }
    },

    // Update filter data
    async updateFilterViewData({ labId, filterId, data }) {
      try {
        const res = await axios.patch(
          config.datalab.datalabUpdateFilter(labId, filterId),
          data
        );

        if (res.status === 200 && res.data.success) {
          dispatch.datalabList.updateFilterData(res.data.dataSource);
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Failed to Update Filter View</p>,
          description:
            err?.response?.data?.error ||
            `Fill all the fields and Please try again`,
        });
      }
    },

    // Delete filter data
    async deleteFilterViewData({ labId, filterId }) {
      try {
        const res = await axios.delete(
          config.datalab.datalabDeleteFilter(labId, filterId)
        );

        if (res.status === 200 && res.data.success) {
          dispatch.datalabList.removeFilterData(filterId);
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Failed to Delete Filter View</p>,
          description: err?.response?.data?.error || `Please try again`,
        });
      }
    },

    // End: data lab filter api call
  }),
};
