import Editor from '../editor';
import LeftSideBar from '../leftSideBar';
import Navbar from '../navbar';
// import { useActions } from 'pages/datalab/export';

const Layout = () => {
  // const { clearState } = useActions();
  // clearState();
  return (
    <>
      <Navbar />
      <div className='grid grid-rows-3 grid-flow-col gap-4 ltr:ml-px rtl:mr-px bg-white'>
        <LeftSideBar />
        <Editor />
      </div>
    </>
  );
};

export default Layout;
