import React, { useEffect, useState } from 'react';
import Linkify from 'react-linkify';

import useMessageBlocksAction from 'pages/inbox/hooks/useMessageBlocksAction';
import {
  CommentStatusPayloadInterface,
  ConversationBlockReplyDataInterface,
  conversationStatusType,
} from 'pages/inbox/inboxInterface';
import defaultMediaImage from '../../../assets/images/defaultMediaImage.svg';
import videoIcon from '../../../assets/images/videoIcon.svg';
import Avatar from '../components/conversation/Avatar';
import MessageStatus from '../components/conversation/MessageStatus';
import Time from '../components/conversation/Time';
import DateBar from './DateBar';

interface Props {
  blockData: ConversationBlockReplyDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  isSent: conversationStatusType | string;
  enableDateBar: boolean;
  report?: any; // TODO: format report
  updateFeedCommentStatus?: (payload: CommentStatusPayloadInterface) => void;
}

const ReferralBlock: React.FC<Props> = ({
  blockData,
  avatar,
  name,
  time,
  source,
  isSent,
  enableDateBar,
  report,
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const { isZigzagView } = useMessageBlocksAction({ source });

  const isAdmin = ['admin', 'echo'].includes(source);
  const borderRadius =
    (isAdmin || source === 'bot') && isZigzagView
      ? 'rounded-xl ltr:rounded-tr-[4px] rtl:rounded-tl-[4px]'
      : 'rounded-xl ltr:rounded-tl-[4px] rtl:rounded-tr-[4px]';

  const backgroundColor =
    (isAdmin || source === 'bot') && blockData?.type === 'text'
      ? 'bg-[#FAFAFA]'
      : isAdmin || source === 'bot'
      ? 'bg-white'
      : 'bg-white';

  useEffect(() => {
    if (
      !!blockData &&
      !!blockData?.urls?.length &&
      !!blockData?.urls[0] &&
      blockData?.sub_type === 'image'
    ) {
      setImageUrl(blockData?.urls[0]);
    } else {
      setImageUrl(defaultMediaImage);
    }
  }, [blockData]);

  const getSourceNameColor = () => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
      case 'echo':
        return 'text-gray-900';
      default:
        return 'text-green-500';
    }
  };

  const onImageError = () => {
    setImageUrl(defaultMediaImage);
  };

  const instanceHasTitle = () => {
    return !!blockData && (!!blockData?.title || !!blockData?.ad_title);
  };

  const getInstanceTitle = () => {
    return !!blockData && (blockData?.title || blockData?.ad_title || '');
  };

  const getImageBlocks = () => {
    return (
      <div className='flex gap-4 -mb-[2px] p-3 max-w-sm bg-[#FAFAFA] rounded-t-xl'>
        <img
          className={`cursor-pointer object-cover rounded-md h-16 w-16`}
          src={imageUrl}
          alt='media'
          onError={() => {
            onImageError();
          }}
        />
        <div className='leading-5 text-sm text-gray-700 w-[fit-content]'>
          {renderTitleAndDescription()}
        </div>
      </div>
    );
  };

  // remove support for blockData?.url
  const getThumbnailForVideoBlock = () => {
    if (
      !!blockData &&
      blockData?.urls?.length &&
      !!blockData?.urls[0] &&
      blockData?.sub_type === 'video'
    )
      return blockData?.urls[0];
    else {
      return defaultMediaImage;
    }
  };

  const getVideoBlocks = () => {
    return (
      <div
        className={`flex gap-4 -mb-1 rounded-t-xl bg-[#F4F4F5] px-3 py-2 w-max`}
      >
        <div className='relative cursor-pointer h-[70px] w-[70px]'>
          <div
            className={`absolute bg-gray-500 bg-opacity-40 rounded-md h-full w-full`}
          />
          <video className={`object-fill rounded-md h-full w-full`}>
            <source
              data-testid='media-video-block'
              src={getThumbnailForVideoBlock()}
            />
          </video>
          <img
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[25.6px] h-[25.6px]`}
            src={videoIcon}
            alt='videoIcon'
          />
        </div>

        <div className='leading-5 text-sm text-gray-700 w-max'>
          {renderTitleAndDescription()}
        </div>
      </div>
    );
  };

  const renderTitleAndDescription = () => {
    return (
      <>
        {instanceHasTitle() && (
          <p
            className={`w-auto h-auto ${
              !!blockData?.description ? 'line-clamp-1' : 'line-clamp-3'
            } sm:text-sm break-words whitespace-pre-wrap mt-0 font-medium`}
          >
            {getInstanceTitle()}
          </p>
        )}
        {blockData?.description && (
          <p
            className={`w-auto h-auto ${
              instanceHasTitle() ? 'line-clamp-2' : 'line-clamp-3'
            } sm:text-sm break-words whitespace-pre-wrap mt-0`}
          >
            {blockData?.description}
          </p>
        )}
      </>
    );
  };

  const renderTextBlock = () => {
    return (
      <div
        className={`flex gap-4 -mb-1 rounded-t-xl bg-[#F4F4F5] px-3 py-2 w-[fit-content]`}
      >
        <div className='text-sm leading-5 text-gray-700'>
          {renderTitleAndDescription()}
        </div>
      </div>
    );
  };

  const renderName = () => {
    if (!!blockData && blockData?.payload?.includes('http')) {
      return (
        <a
          className='hover:underline'
          target='_blank'
          href={blockData?.payload}
          rel='noreferrer noopener'
        >
          {name}
        </a>
      );
    } else {
      return name;
    }
  };

  const renderMessageText = () => {
    if (!!blockData && !!blockData?.text) {
      return (
        <div
          className={`border ${borderRadius} ${backgroundColor} p-2 flex w-[fit-content] border-gray-200`}
        >
          <p
            data-testid='text-message-block'
            className={`h-auto rtl:order-1 ${
              !!blockData?.success ? 'text-gray-900' : 'text-gray-500'
            } sm:text-sm break-words whitespace-pre-wrap`}
          >
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  target='blank'
                  className='text-link'
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {blockData?.success ? blockData?.text : blockData?.error}
            </Linkify>
          </p>

          <div className='flex gap-1 justify-end flex-1 -mb-2 ml-4 self-end pb-2'>
            <Time time={time} isMerged={false} isFeedComment={false} />
            {['admin', 'bot', 'echo'].includes(source) && (
              <MessageStatus
                success={isSent}
                isMerged={false}
                report={report}
              />
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderBlockData = () => {
    return (
      <div className='w-[80%]'>
        <div
          className={`flex gap-2 ${
            isZigzagView ? 'justify-end' : 'justify-start'
          }`}
        >
          <div
            className={`my-1.5 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()}`}
          >
            {renderName()}
          </div>
        </div>

        {/* Media Content */}
        <div
          className={`flex flex-col w-full ${
            isZigzagView ? 'items-end' : 'items-start'
          }`}
        >
          <div
            className={`flex gap-2 ${
              isZigzagView ? 'justify-start' : 'justify-end'
            } `}
          >
            {!!blockData && blockData?.sub_type?.includes('video')
              ? getVideoBlocks()
              : !!blockData && blockData?.sub_type?.includes('image')
              ? getImageBlocks()
              : !!blockData && !blockData?.sub_type && renderTextBlock()}
          </div>
          {renderMessageText()}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        // className={`relative group flex w-full h-auto hover:bg-gray-100 py-1`}
        className={`relative group flex ${
          isZigzagView ? 'flex-row-reverse' : 'flex-start'
        } w-full h-auto pt-0.5 pb-1.5 hover:bg-gray-100`}
      >
        <Avatar
          avatar={avatar}
          isReplyComment={false}
          isParentComment={false}
        />
        {!!blockData && renderBlockData()}
      </div>

      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default ReferralBlock;
