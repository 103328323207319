import { IEcommerceChannelProperty } from 'pages/integration/interface';
import React from 'react';
import { getAllMarketPlaceData } from 'utilities/utils';

interface Props {
  elementData: IEcommerceChannelProperty;
}

const EcommerceCard: React.FC<Props> = ({ elementData }) => {
  return (
    <div className='p-5 border border-gray-200 rounded w-fit'>
      <div className='flex justify-between align-top'>
        <div className='flex gap-2.5 align-top'>
          <img
            src={
              getAllMarketPlaceData.find(
                (platform) =>
                  platform.platform_type === elementData.ecommerce_type
              )?.icon
            }
            className='w-9 h-9'
            alt={'icon_box'}
          />
          <div className='mt-[-17px]'>
            <p className='font-medium text-base align-center mt-3 mb-1 capitalize'>
              {elementData.ecommerce_type}
              {/* {elementData.project_name} */}
            </p>
            <div className='flex gap-[3px]'>
              <a
                rel='noreferrer'
                href={elementData.store_url}
                target='_blank'
                className='max-w-[200px] text-ellipsis overflow-hidden text-blue-500 text-sm'
              >
                {elementData.store_url}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EcommerceCard;
