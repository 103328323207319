import useEmailSignature from 'components/customHooks/useEmailSignature';
import useTranslation from 'components/customHooks/useTranslation';
import Button from 'library/button';
import { Card, CardContent, CardDescription } from 'libraryV2/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';
import { PlusIcon, User } from 'lucide-react';
import TextInputWithSubLabel from 'pages/integration/components/common/components/TextInputWithSubLabel';
import { IDataObject } from 'pages/integration/hooks/useCustomEmail';
import { ICustomEmailUserData } from 'pages/integration/interface';
import { EmailSignatureVariables } from 'pages/integration/utils/content';
import React from 'react';

interface Props {
  settings: ICustomEmailUserData;
  handleDataChange: (data: IDataObject) => void;
}

const DisplaySetup: React.FC<Props> = ({ settings, handleDataChange }) => {
  const { t } = useTranslation();
  const { emailSignatureValueReplacer } = useEmailSignature();
  const handleKeyDown = (e) => {
    // Handle Shift+Enter to add newline character
    if (e.key === 'Enter' || (e.key === 'Enter' && e.shiftKey)) {
      e.preventDefault(); // Prevent default newline behavior
      const updatedSignature = `${settings.emailSignature}\n`; // Append newline
      handleDataChange({
        emailSignature: updatedSignature,
      });
    }
  };
  return (
    <div className='w-full'>
      <div className='grid grid-cols-2 gap-4'>
        <div>
          <TextInputWithSubLabel
            label={t('Channel Name')}
            sublabel=''
            value={settings.channelName}
            type='text'
            hintText={t(
              'The name is private. We use this info to identify this channel on MyAlice.'
            )}
            placeHolder={t('Enter channel name')}
            handleChange={(value: string) =>
              handleDataChange({ channelName: value })
            }
          />

          <TextInputWithSubLabel
            label={t('Email Name')}
            sublabel={t('')}
            value={settings.displayName}
            type='text'
            placeHolder={t('ex: Company Support')}
            handleChange={(value: string) =>
              handleDataChange({ displayName: value })
            }
          />
          <span className=' block my-5' />

          <div className='my-4 rtl:text-right'>
            <span className='text-sm font-medium text-gray-600'>
              {t('Email Signature')}
            </span>
            <textarea
              className='rounded-md border border-gray-300 w-full p-3 mt-2 without-ring text-sm focus:border-primary'
              rows={7}
              onKeyDown={handleKeyDown}
              value={settings?.emailSignature}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                const { value } = e.target;
                const normalizeValue = value.replace(/\r?\n/g, '\n');
                handleDataChange({
                  emailSignature: normalizeValue,
                });
              }}
            ></textarea>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  size='xs'
                  className='text-purple-500 bg-purple-100 float-right mt-2'
                >
                  <PlusIcon className='w-4 h-5 text-purple-500 ltr:mr-1 rtl:ml-1' />
                  {t('Add Variables')}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className='bg-white'>
                {EmailSignatureVariables.map(
                  (data: { key: string; value: string }, index: number) => (
                    <DropdownMenuItem
                      key={index}
                      onClick={() => {
                        handleDataChange({
                          emailSignature: `${settings?.emailSignature} {{${data?.key}}} `,
                        });
                      }}
                    >
                      {data?.key}
                    </DropdownMenuItem>
                  )
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <div className='flex flex-col rtl:text-right'>
          <span className='text-base text-gray-900 font-medium'>
            {t('Preview')}
          </span>
          <div className='mt-1'>
            <Card>
              <CardDescription className='p-4'>
                <div className='flex justify-between items-center'>
                  <div className='p-3 rounded-full bg-gray-500 flex justify-center items-center rtl:order-1'>
                    <User className='w-5 h-5 text-white' />
                  </div>
                  <div className='w-full px-2'>
                    <span className='text-sm text-gray-600 font-semibold block '>
                      {settings?.displayName}
                    </span>
                    <span className='text-xs text-gray-500 font-medium block '>
                      To: customer@example.com
                    </span>
                  </div>
                </div>
              </CardDescription>
              <div className='w-full bg-gray-300 h-[1px]' />
              <CardContent>
                <div className=' my-2'>
                  <div className='w-10 h-3 rounded bg-gray-200' />
                  <div className='w-15 h-3 rounded bg-gray-200 mt-1' />
                  <div className='w-15 h-3 rounded bg-gray-200 mt-1' />
                </div>
                <div className='break-words whitespace-normal mt-4 text-gray-700'>
                  {settings?.emailSignature
                    .split('\n')
                    .map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {emailSignatureValueReplacer(line)}
                        {index <
                          settings?.emailSignature.split('\n').length - 1 && (
                          <br />
                        )}{' '}
                        {/* Render <br> after each line except the last */}
                      </React.Fragment>
                    ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplaySetup;
