import { Button } from './button';
import {
  ColumnDef,
  flexRender,
  useReactTable,
  getCoreRowModel,
  Table as ReactTable,
} from '@tanstack/react-table';
import {
  Select,
  SelectItem,
  SelectValue,
  SelectTrigger,
  SelectContent,
} from './select';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './table';
import emptySearchIcon from '../../assets/icons/emptySearchIcon.svg';

// Define the pagination props
interface DataTablePaginationProps<TData> {
  table?: ReactTable<TData>;
  limit: number;
  offset: number;
  totalRowCount: number;
  onLimitChange: (newLimit: number) => void;
  onPageChange: (newOffset: number) => void;
}

// Define the main props using conditional types
interface DataTablePropsBase<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

// Define a type that enforces pagination props if hasPagination is true
type DataTableProps<TData, TValue> = DataTablePropsBase<TData, TValue> &
  (
    | { hasPagination?: false; paginationProps?: never }
    | { hasPagination: true; paginationProps: DataTablePaginationProps<TData> }
  );

export function DataTable<TData, TValue>({
  columns,
  data,
  hasPagination = false,
  paginationProps,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className='border rounded-md'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='text-center h-[500px]'
                >
                  <div className='flex flex-col justify-center'>
                    <img
                      src={emptySearchIcon}
                      alt='emptySearchIcon'
                      className='h-20 mb-4'
                    />
                    <h3 className='mb-2 text-center text-textPrimary'>
                      No Results Found
                    </h3>
                    <span className='subtitle-medium'>
                      Please search with different keyword.{' '}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {hasPagination && paginationProps ? (
          <TablePagination {...paginationProps} table={table} />
        ) : null}
      </div>
    </>
  );
}

export function TablePagination<TData>({
  table,
  limit,
  offset,
  totalRowCount,
  onLimitChange,
  onPageChange,
}: DataTablePaginationProps<TData>) {
  const handleLimitChange = (newLimit: string) => {
    onLimitChange(Number(newLimit));
  };

  const handlePageChange = (newOffset: number) => {
    onPageChange(newOffset);
  };

  const currentPage = Math.ceil(offset / limit) + Number(!!totalRowCount);
  const totalPage = Math.ceil(totalRowCount / limit);

  const renderPageLimitSelectionMenu = () => {
    return (
      <div className='flex items-center space-x-2 bg-white'>
        <p className='text-sm font-medium'>Rows per page</p>
        <Select value={`${limit}`} onValueChange={handleLimitChange}>
          <SelectTrigger className='h-8 w-[70px] focus:border-primary focus:ring-0'>
            <SelectValue placeholder={`${limit}`} />
          </SelectTrigger>
          <SelectContent side='top' className='bg-white'>
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <SelectItem
                key={pageSize}
                value={`${pageSize}`}
                className='bg-white cursor-pointer'
              >
                {pageSize}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  return (
    <div className='flex items-center justify-between w-full px-2 py-3'>
      <div className='flex-1 text-sm text-muted-foreground subtitle_medium'>
        {table && table.getFilteredSelectedRowModel().rows.length} of{' '}
        {totalRowCount}
        row(s) selected.
      </div>
      <div className='flex items-center space-x-6 lg:space-x-8'>
        {renderPageLimitSelectionMenu()}
        <div className='flex items-center justify-center text-sm font-medium'>
          {`Page ${currentPage} of ${totalPage}`}
        </div>
        <div className='flex items-center space-x-2'>
          <Button
            variant='outline'
            className='hidden w-8 h-8 p-0 lg:flex'
            onClick={() => handlePageChange(0)}
            disabled={offset === 0}
          >
            <span className='sr-only'>Go to first page</span>
            <DoubleArrowLeftIcon className='w-4 h-4' />
          </Button>
          <Button
            variant='outline'
            className='w-8 h-8 p-0'
            onClick={() => handlePageChange(offset - limit)}
            disabled={offset === 0}
          >
            <span className='sr-only'>Go to previous page</span>
            <ChevronLeftIcon className='w-4 h-4' />
          </Button>
          <Button
            variant='outline'
            className='w-8 h-8 p-0'
            onClick={() => handlePageChange(offset + limit)}
            disabled={offset + limit >= totalRowCount}
          >
            <span className='sr-only'>Go to next page</span>
            <ChevronRightIcon className='w-4 h-4' />
          </Button>
          <Button
            variant='outline'
            className='hidden w-8 h-8 p-0 lg:flex'
            onClick={() =>
              handlePageChange((Math.ceil(totalRowCount / limit) - 1) * limit)
            }
            disabled={offset + limit >= totalRowCount}
          >
            <span className='sr-only'>Go to last page</span>
            <DoubleArrowRightIcon className='w-4 h-4' />
          </Button>
        </div>
      </div>
    </div>
  );
}
