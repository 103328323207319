import React from 'react';
import openBoxIcon from '../../../../assets/images/openBox.svg';
import SavedReplyPreview from './SavedReplyPreview';

import { XMarkIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import { ChevronRightIcon } from 'evergreen-ui';
import { SavedRepliesInterface } from 'pages/inbox/inboxInterface';

interface Props {
  inputEvent: any;
  inputValue: string;
  bottom?: string;
  savedReplies: SavedRepliesInterface[];
  setInputValue: (payload: string) => void;
  setIsSavedReplyOpened: (value: boolean) => void;
}

const SavedReplies: React.FC<Props> = ({
  inputEvent,
  inputValue,
  savedReplies,
  bottom = 'bottom-[21.5%]',
  setInputValue,
  setIsSavedReplyOpened,
}) => {
  const { t } = useTranslation();
  const [hoveredPreviewIndex, setHoveredPreviewIndex] =
    React.useState<number>(0);

  const elements = React.useRef<any>([]);

  const replaceLastHashWithTag = (
    inputString: string,
    savedReplyTitle: string
  ) => {
    // Step 1: Find all occurrences of substrings starting with '#' in the 'inputString'.
    const regex = /#([^#]+)/g;
    let matches;
    let lastMatch;
    while ((matches = regex.exec(inputString)) !== null) {
      lastMatch = matches[0]; // Use matches[0] to get the whole matched substring.
    }

    // Step 2: If the last match is found, replace it with '@', else return the original 'inputString'.
    if (lastMatch) {
      const replacedString = inputString.replace(lastMatch, '');
      return replacedString;
    } else if (inputString.endsWith('#')) {
      const replacedString = inputString.slice(0, -1) + '';
      return replacedString;
    } else {
      return inputString;
    }
  };

  const handleSavedReplyOnSelect = (reply: SavedRepliesInterface) => {
    let previousInputValue = replaceLastHashWithTag(inputValue, reply?.text);
    let newInputValue = '';
    if (previousInputValue.length === 0) newInputValue = reply?.text;
    else newInputValue = previousInputValue + reply?.text;
    setInputValue(newInputValue);
    setIsSavedReplyOpened(false);
  };

  React.useEffect(() => {
    if (
      !!elements &&
      !!elements.current &&
      !!hoveredPreviewIndex &&
      !!elements.current[hoveredPreviewIndex]
    ) {
      elements.current[hoveredPreviewIndex]?.focus();
    }
  }, [hoveredPreviewIndex]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event) return;
    let text = inputValue;
    if (event.key === 'Enter') {
      event.preventDefault();

      const target = event.target as HTMLTextAreaElement;
      const cursorPosition = target.selectionStart;

      const hashIndex = text.lastIndexOf('#', cursorPosition);

      if (
        hashIndex !== -1 &&
        !/\s/.test(text.slice(hashIndex, cursorPosition))
      ) {
        const wordEndPosition = cursorPosition;

        const savedRepliedText = savedReplies[hoveredPreviewIndex]?.text;

        const updatedText =
          text.slice(0, hashIndex) +
          `${savedRepliedText}` +
          text.slice(wordEndPosition);

        setInputValue(updatedText);

        setTimeout(() => {
          target.selectionStart = hashIndex + `${savedRepliedText}`.length;
          target.selectionEnd = hashIndex + `${savedRepliedText}`.length;
        }, 0);
      }
      setIsSavedReplyOpened(false);
    }

    if (event.key === 'ArrowDown') {
      setHoveredPreviewIndex(
        Math.min(hoveredPreviewIndex + 1, elements.current.length - 1)
      );
    }
    if (event.key === 'ArrowUp') {
      setHoveredPreviewIndex(Math.max(hoveredPreviewIndex - 1, 0));
    }
  };

  const renderHeaderElementsView = () => {
    return (
      <div className='flex justify-between p-3'>
        <p className='w-[60%] text-gray-800 font-semibold text-sm mb-2'>
          {t('Saved Replies')}
        </p>
        <div className='w-[40%] flex justify-end'>
          <XMarkIcon
            className='w-5 h-5 text-gray-500 cursor-pointer'
            onClick={() => {
              setIsSavedReplyOpened(false);
            }}
          />
        </div>
      </div>
    );
  };

  const renderSavedReplyElementView = () => {
    return (
      <div className='max-h-[260px] overflow-auto mb-3 w-[270px]'>
        {savedReplies.map((reply: SavedRepliesInterface, index: number) => (
          <div
            key={index}
            ref={(element) => (elements.current[index] = element)}
          >
            <div
              className={`py-[12px] flex ${
                hoveredPreviewIndex === index ? 'bg-gray-200' : ''
              } p-3 `}
              onClick={() => {
                handleSavedReplyOnSelect(reply);
              }}
              onMouseEnter={() => {
                setHoveredPreviewIndex(index);
              }}
            >
              <p className='w-[80%] text-gray-900 font-normal text-sm cursor-pointer'>
                # {reply.title}
              </p>
              <div className='w-[20%] flex justify-end'>
                <ChevronRightIcon className='w-5 h-5 text-gray-500 cursor-pointer rtl:rotate-180' />
              </div>
            </div>
            {hoveredPreviewIndex === index && (
              <SavedReplyPreview savedReply={reply?.text} />
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderEmptySavedRepliesView = () => {
    return (
      <div className='mx-3 my-16'>
        <span className='flex justify-center'>
          <img src={openBoxIcon} alt='not-ticket' />
        </span>
        <div className='flex justify-center mt-3 text-sm text-center text-gray-500'>
          <span>
            Oops! You have no saved replies.
            <br />
            Better start now than never.
          </span>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    handleKeyDown(inputEvent);
    // eslint-disable-next-line
  }, [inputEvent]);

  return (
    <>
      {savedReplies.length === 0 &&
      inputValue.length > 1 &&
      inputValue.includes('#') ? null : (
        <div className={`absolute z-10 ${bottom}`}>
          <div className='relative bg-white border border-gray-200 rounded-md'>
            <div className='w-full'>
              <div>
                {renderHeaderElementsView()}
                {!!savedReplies &&
                  savedReplies.length !== 0 &&
                  renderSavedReplyElementView()}
                {!!savedReplies &&
                  savedReplies.length === 0 &&
                  renderEmptySavedRepliesView()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SavedReplies;
