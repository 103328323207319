import {
  Checkbox,
  ColumnDef,
  DataTableRowActions,
  DataTableColumnHeader,
} from '../../../export';

type LabsDataType = Record<string, any>;

function createDynamicColumns(
  labId: string,
  labsData: LabsDataType[],
  lab_fields: any,
  hasReadAccess: boolean,
  hasWriteAccess: boolean,
  hasDeleteAccess: boolean,
  deleteBulkDatalabList: (labId: string, rowIds: string[]) => void
) {
  if (!Array.isArray(labsData) || labsData.length === 0) {
    return [];
  }

  const ensureSlugExists = (objectA: any = {}, arrayB: any = []) => {
    // Extract slugs from arrayB
    let slugs = arrayB.map((item: { slug: string }) => item.slug);

    // Check if each slug exists as a key in objectA
    slugs.forEach((slug: string) => {
      if (!(slug in objectA)) {
        // If slug doesn't exist in objectA, create it with an empty string as value
        objectA[slug] = '';
      }
    });

    return objectA;
  };

  const firstRow = ensureSlugExists(labsData[0], lab_fields);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text;
    }
    const extensionMatch = text.match(/\.(\w+)$/);
    if (extensionMatch) {
      const extension = extensionMatch[0];
      const baseLength = maxLength - extension.length;
      return `${text.slice(0, baseLength)}...${extension}`;
    }
    return `${text.slice(0, maxLength)}...`;
  };

  const createCellRenderer =
    (key: string) =>
    ({ row }: any) => {
      let cellValue = row.getValue(key);
      if (cellValue && typeof cellValue === 'object') {
        return null;
      }
      if (cellValue === null || cellValue === '' || cellValue === undefined) {
        return <div>--</div>;
      }
      if (typeof cellValue === 'string') {
        cellValue = truncateText(cellValue, 17);
      }
      return <div>{cellValue}</div>;
    };

  const dynamicColumns: ColumnDef<LabsDataType>[] = Object.keys(firstRow)
    // .filter((key) => {
    //   const value = firstRow[key];
    //   return typeof value !== 'object';
    // })
    .map((key) => ({
      accessorKey: key,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={key} />
      ),
      cell: createCellRenderer(key),
      enableSorting: true,
      enableHiding: true,
    }));

  // Add the select component column
  dynamicColumns.unshift({
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && 'indeterminate')
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
        className='translate-y-[2px] border-gray-500'
      />
    ),
    cell: ({ row }: any) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
        className='translate-y-[2px] border-gray-500'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  });

  // Add the actions column
  dynamicColumns.push({
    id: 'actions',
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        labId={labId}
        hasReadAccess={hasReadAccess}
        hasWriteAccess={hasWriteAccess}
        hasDeleteAccess={hasDeleteAccess}
        deleteBulkDatalabList={deleteBulkDatalabList}
      />
    ),
    enableSorting: false,
    enableHiding: false,
  });

  return dynamicColumns;
}

export default createDynamicColumns;
