import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import EmptyDoughnut from '../../assets/images/EmptyDoughnut.svg';
import { analytics } from '../../../../utilities/content';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  data: CsatResponseDistProps[];
  loader: boolean;
}

interface CsatResponseDistProps {
  rating: number;
  count: number;
  percentage: string;
}

const ResponseDisCsat: React.FC<Prop> = ({ data, loader }) => {
  const { t } = useTranslation();
  const dataOfColor = ['#F43F5E', '#fbbf24', '#9ca3af', '#3b82f6', '#04a056'];
  const renderData: any = {
    datasets: [
      {
        data: data.map((elem) => elem.count),
        backgroundColor: [...dataOfColor],
      },
    ],
  };
  return (
    <div className='h-full overflow-hidden bg-white shadow sm:rounded-lg'>
      <div className='px-4 py-5 sm:p-6'>
        <div className='flex text-base font-normal text-gray-900'>
          {t(analytics.CSAT.responseDistribution.title)}
          <span
            className='mt-1 ltr:ml-2 rtl:mr-2'
            title={t(analytics.CSAT.responseDistribution.toolTip)}
          >
            <InformationCircleIcon className='w-4 h-4' />
          </span>
        </div>
        <div className='mt-1'>
          {!loader && data.length !== 0 && (
            <Doughnut
              className='p-10 -mt-4 -mb-4 2xl:p-40 sm:p-20 sm:-mt-8 sm:-mb-8 2xl:-mt-24 2xl:-mb-24'
              type={'doughnut'}
              data={renderData}
            />
          )}
          <div className='mt-1'>
            {!loader &&
              data.map((data, index) => (
                <div
                  key={index}
                  className='flex items-center p-2 mt-1 space-between'
                >
                  <div className={'flex items-center'}>
                    <div
                      className='w-5 h-5 rounded-sm'
                      style={{ background: dataOfColor[index] }}
                    />
                    <div className='ltr:ml-2 rtl:mr-2 font-sans text-xs font-medium'>
                      {data.rating}
                    </div>
                  </div>
                  <div className='font-sans text-xs font-normal'>
                    {data.percentage}
                  </div>
                  <div className='font-sans text-xs font-normal'>
                    {data.count} {t('Response')}
                  </div>
                </div>
              ))}
          </div>
          {(loader || data.length === 0) && (
            <div>
              <div className='flex justify-center p-8'>
                <img
                  src={EmptyDoughnut}
                  className={`${
                    !loader ? 'animation-pulse' : ''
                  } w-64 2xl:w-80`}
                  alt='empty doughnut'
                />
              </div>
              <div className='mt-4'>
                {dataOfColor.map((color, index) => (
                  <div
                    key={index}
                    className='flex items-center p-2 mt-1 mb-2 space-between'
                  >
                    <div className={'flex items-center'}>
                      <div
                        className='w-5 h-5 rounded-sm'
                        style={{ background: color }}
                      />
                      <div className='ml-2 font-sans text-xs font-medium'>
                        {loader ? 'Loading...' : t('Not Available')}
                      </div>
                    </div>
                    <div className='font-sans text-xs font-normal'>
                      {loader ? 'Loading...' : '0%'}
                    </div>
                    <div className='font-sans text-xs font-normal'>
                      {loader ? 'Loading...' : `0 ${t('Response')}`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ResponseDisCsat);
