import React, { forwardRef, Fragment, useEffect, useRef } from 'react';
import {
  CalendarIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { Transition } from '@headlessui/react';
import { DateRangePicker } from 'react-date-range';
import { Button } from '../../library';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useTranslation from 'components/customHooks/useTranslation';
import { AdjustEndDateTimeRange, CombineDateAndTime } from 'utilities/utils';
import { endOfDay } from 'date-fns';
const advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

interface Prop {
  dateRange: any;
  finalDateRange: any;

  handleFilter?: () => void;
  handleDateRangeChange?: (item: any) => void;
  handleCancel?: () => void;
  closeOnOutsideClick?: boolean;
  allowFutureSelection?: boolean;
}

const DatePickerDropdown: React.FC<Prop> = ({
  dateRange,
  finalDateRange,
  handleFilter,
  handleDateRangeChange,
  handleCancel,
  allowFutureSelection = false,
  closeOnOutsideClick = true,
}) => {
  const [open, setOpen] = React.useState(false);
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [localDateRange, setLocalDateRange] = React.useState(
    !!dateRange ? { selection: { ...dateRange[0] } } : null
  );
  const WrappedRef: any = useRef(null);
  const { t } = useTranslation();

  const updateDateRangeWithTime = () => {
    if (!!handleDateRangeChange && !!localDateRange) {
      const combinedStartDate = CombineDateAndTime(
        localDateRange?.selection.startDate,
        startTime
      );

      const combinedEndDate = CombineDateAndTime(
        localDateRange?.selection.endDate,
        endTime
      );

      const val = {
        selection: {
          startDate: combinedStartDate,
          endDate: combinedEndDate,
          key: 'selection',
        },
      };

      !!handleDateRangeChange && handleDateRangeChange(val);
    }
  };

  useEffect(() => {
    updateDateRangeWithTime();
    //eslint-disable-next-line
  }, [startTime, endTime]);

  useEffect(() => {
    if (!!localDateRange) {
      let finalEndDateOnRange = !!localDateRange
        ? localDateRange.selection.endDate
        : null;

      if (!!finalEndDateOnRange) {
        const time = AdjustEndDateTimeRange(finalEndDateOnRange);
        setEndTime(time);
      }
      startTime.setHours(0, 0, 0, 0);
      setStartTime(startTime);
    }
    //eslint-disable-next-line
  }, [localDateRange]);

  useEffect(() => {
    /**
     * event for focus outside of this component to close it
     */
    function handleClickOutside(event: any) {
      if (
        !!closeOnOutsideClick &&
        !!WrappedRef &&
        WrappedRef.current &&
        !WrappedRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    // Bind the event listener
    if (closeOnOutsideClick) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      if (closeOnOutsideClick) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
    // eslint-disable-next-line
  }, [WrappedRef]);

  const RenderCustomTimeInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className='border-0 bg-white text-xs text-gray-500 text-center'
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  const getMaxDate = () => {
    return endOfDay(new Date());
  };

  return (
    <div
      ref={WrappedRef}
      className='relative w-full mt-4 ml-0 md:w-auto ltr:ml-3 rtl:mr-3 md:mt-1'
    >
      <>
        <div
          onClick={() => setOpen(true)}
          className={`flex justify-start text-sans items-center relative w-full md:w-auto bg-white border border-gray-300 rounded-md shadow-sm px-3 py-2 text-left cursor-pointer focus:outline-none sm:text-xs`}
        >
          <CalendarIcon
            className={`${open ? 'text-primary' : 'text-gray-500'}
                  ltr:mr-2 rtl:ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
            aria-hidden='true'
          />
          <span className='font-sans text-xs'>
            {dayjs(finalDateRange[0].startDate).format('Do MMM')}&nbsp; - &nbsp;
            {dayjs(finalDateRange[0].endDate).format('Do MMM, YYYY')}
          </span>
        </div>
        <Transition
          show={open}
          as={Fragment}
          enter='transition ease-out duration-200'
          enterFrom='opacity-0 translate-y-1'
          enterTo='opacity-100 translate-y-0'
          leave='transition ease-in duration-150'
          leaveFrom='opacity-100 translate-y-0'
          leaveTo='opacity-0 translate-y-1'
        >
          {open ? (
            <div className='absolute z-50 px-4 -mt-40 transform translate-x-0 bg-white w-min md:mt-3 md:-translate-x-2/3 md:rtl:translate-x-2/3 sm:px-0'>
              <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                <div className='relative'>
                  <DateRangePicker
                    onChange={(changedValue: any) => {
                      setLocalDateRange(changedValue);
                    }}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    rangeColors={['#04B25F', '#038E4C']}
                    months={1}
                    ranges={dateRange}
                    direction='horizontal'
                    maxDate={allowFutureSelection ? null : getMaxDate()}
                  />
                </div>
                <div className='grid grid-cols-2 gap-2 p-2 ltr:ml-auto rtl:mr-auto w-[61%]'>
                  <div className='grid grid-cols-2 gap-1 rounded-md border border-gray-100 divide-x py-1.5 px-2 items-center justify-center'>
                    <span className='text-xs text-gray-600 block col-span-1'>
                      {dayjs(dateRange[0].startDate).format('Do MMM')}
                    </span>
                    <DatePicker
                      selected={startTime}
                      onChange={(date) => !!date && setStartTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={10}
                      timeCaption='Time'
                      dateFormat='hh:mm aa'
                      customInput={<RenderCustomTimeInput />}
                      className='col-span-1'
                    />
                  </div>

                  <div className='grid grid-cols-2 gap-1 rounded-md border border-gray-100 divide-x py-1.5 px-2 items-center justify-center'>
                    <span className='text-xs text-gray-600 block col-span-1'>
                      {dayjs(dateRange[0].endDate).format('Do MMM')}
                    </span>
                    <DatePicker
                      selected={endTime}
                      onChange={(date) => !!date && setEndTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={10}
                      timeCaption='Time'
                      dateFormat='hh:mm aa'
                      customInput={<RenderCustomTimeInput />}
                      className='col-span-1'
                    />
                  </div>
                </div>
                <div className='flex items-center justify-end p-3'>
                  <Button
                    intent={'primary'}
                    size={'sm'}
                    className='ltr:mr-3 rtl:ml-3'
                    icon={<MagnifyingGlassIcon />}
                    onClick={() => {
                      !!handleFilter && handleFilter();
                      setOpen(false);
                    }}
                  >
                    {t('Filter')}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    intent={'default'}
                    size={'sm'}
                    icon={<XMarkIcon />}
                    onClick={() => {
                      setOpen(false);
                      !!handleCancel && handleCancel();
                    }}
                  >
                    {t('Close')}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </Transition>
      </>
    </div>
  );
};

export default DatePickerDropdown;
