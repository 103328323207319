import React from 'react';
import { Fragment } from 'react';
import TicketFilterCheckBox from './TicketFilterCheckBox';

import { Combobox, Transition } from '@headlessui/react';
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import { PriorityOptions } from '../../../utils/contents';

type OptionListType =
  | AgentListInterface
  | PlatformInterface
  | GroupInterface
  | TicketTagInterface;

// type AllOptionType =
//   | TicketFilterOptionInterface
//   | TicketFilterAgentOptionInterface
//   | TicketFilterChannelOptionInterface
//   | TicketFilterAgentGroupOptionInterface;

interface Props {
  // ToDo: need to define type for each props\
  tagList: TicketTagInterface[];
  agentList: AgentListInterface[];
  filterData: TicketFilterInterface;
  agentGroup: GroupInterface[];
  focusedField: string;
  isDateOptionOpened: boolean;
  channelList: PlatformInterface[];
  setFocusedField: (value: string) => void;
  option: { type: string; label: string };
  handleFilterData: (value: TicketFilterInterface) => void;
}

const TicketFilterDropDown: React.FC<Props> = ({
  option,
  tagList,
  agentList,
  agentGroup,
  channelList,
  filterData,
  focusedField,
  setFocusedField,
  handleFilterData,
  isDateOptionOpened,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState({
    label: '',
    tsx: null,
  });

  const isOpen = focusedField === option.type;

  const getInputDisplayString = (fieldType: string) => {
    const processDisplayString = (listData: OptionListType[], key: string) => {
      let displayString = '';
      let data = {};
      if (listData.length !== 0) {
        displayString +=
          //@ts-ignore
          fieldType === 'agents' ? listData[0].admin[key] : listData[0][key];
        displayString =
          displayString.length > 10
            ? displayString.substring(0, 20) + '...'
            : displayString;
      }
      if (listData.length > 1) {
        data = {
          label: `${displayString}   `,
          tsx: (
            <span className='ml-3'>
              {displayString}{' '}
              <span className='ml-1 px-1 py-[2px] rounded bg-green-100 text-green-800'>
                +{listData.length - 1}
              </span>{' '}
            </span>
          ),
        };
      } else {
        data = {
          label: displayString,
        };
      }
      return data;
    };

    switch (fieldType) {
      case 'ticketStatus':
        let ticketStatus =
          filterData?.ticketStatus === 0 ? 'Pending' : 'Resolved';
        setSelectedOption({ label: ticketStatus, tsx: selectedOption.tsx });
        break;
      case 'channels':
        let selectedChannelList = !channelList
          ? []
          : channelList?.filter((channel: PlatformInterface) =>
              filterData?.channels.includes(channel.id)
            );
        let displayString = processDisplayString(selectedChannelList, 'title');
        setSelectedOption({
          label: displayString.label,
          tsx: displayString.tsx,
        });
        break;
      case 'tags':
        let selectedTagList = !tagList
          ? []
          : tagList?.filter((tag: TicketTagInterface) =>
              filterData?.tags?.includes(tag.id)
            );
        let tagDisplayString = processDisplayString(selectedTagList, 'name');
        setSelectedOption({
          label: tagDisplayString.label,
          tsx: tagDisplayString.tsx,
        });
        break;
      case 'groups':
        let selectedAgentGroupList = !agentGroup
          ? []
          : agentGroup?.filter((group: GroupInterface) =>
              filterData?.groups?.includes(group.id)
            );
        let groupsDisplayString = processDisplayString(
          selectedAgentGroupList,
          'name'
        );
        setSelectedOption({
          label: groupsDisplayString.label,
          tsx: groupsDisplayString.tsx,
        });
        break;
      case 'agents':
        let selectedAgentList = !agentList
          ? []
          : agentList?.filter((agent: AgentListInterface) =>
              filterData?.agents?.includes(agent.admin.id)
            );
        let agentsDisplayString = processDisplayString(
          selectedAgentList,
          'full_name'
        );
        setSelectedOption({
          label: agentsDisplayString.label,
          tsx: agentsDisplayString.tsx,
        });
        break;
      case 'priorities':
        let selectedPriorityList = PriorityOptions?.filter(
          (priority: { label: string; value: string | number }) =>
            filterData?.priorities?.includes(priority.value)
        );
        let priorityString = processDisplayString(
          selectedPriorityList,
          'label'
        );
        setSelectedOption({
          label: priorityString.label,
          tsx: priorityString.tsx,
        });
        break;
      default:
        break;
    }
  };

  const handleOptionSelection = (filedType: string, value: any) => {
    // switch (filedType) {
    // case 'ticketStatus':
    //   handleFilterData({ ...filterData, ticketStatus: value });
    //   break;
    // case 'channels':
    // case 'groups':
    // case 'agents':
    // case 'tags':
    //   let seletedOptionIds: ListType[] = [];
    //   if (value === 'all') {
    //     if (!filterData[filedType].includes('all')) {
    //       // let filedOptions = getDropDownData(filedType);
    //       // !!filedOptions &&
    //       //   filedOptions.forEach((option: AllOptionType) => {
    //       //     seletedOptionIds = [...seletedOptionIds, option.value];
    //       //   });
    //       seletedOptionIds = [];
    //     }
    //   } else {
    //     if (filterData[filedType].includes(value)) {
    //       seletedOptionIds = filterData[filedType].filter(
    //         (id: string | number) => id !== value && id !== 'all'
    //       );
    //     } else {
    //       seletedOptionIds = [...filterData[filedType], value];
    //     }
    //   }
    handleFilterData({ ...filterData, [filedType]: value });
    setQuery('');
    setFocusedField('');
    //   break;
    // default:
  };

  const getFilterData = (filteredData: any) => {
    return filteredData.length === 0
      ? []
      : [
          { label: t('Unselect All'), value: 'all', platFormType: '' },
          ...filteredData,
        ];
  };

  const getDropDownData = (fieldType: string) => {
    switch (fieldType) {
      case 'ticketStatus':
        let ticketStatusOptions = [
          { label: 'Pending', value: 0 },
          { label: 'Resolved', value: 1 },
        ];
        return ticketStatusOptions;

      case 'channels':
        if ((!!channelList && channelList.length === 0) || !channelList)
          return [];
        let channelOptions: TicketFilterChannelOptionInterface[] = [
          { label: t('Unselect All'), value: 'all', platFormType: '' },
        ];
        !!channelList &&
          channelList?.forEach((platform: PlatformInterface) => {
            channelOptions = [
              ...channelOptions,
              {
                value: platform.id,
                label: platform.title,
                platFormType: platform.platform_type,
              },
            ];
          });
        // fieldwise option filteration
        if (query.length !== 0) {
          channelOptions = getFilterData([
            ...channelOptions?.filter((channel) => {
              return channel.label.toLowerCase()?.includes(query.toLowerCase());
            }),
          ]);
        }
        return channelOptions;
      case 'tags':
        if ((!!tagList && tagList.length === 0) || !tagList) return [];
        let tagOptions: TicketFilterOptionInterface[] = [
          { label: t('Unselect All'), value: 'all' },
        ];
        !!tagList &&
          tagList.forEach((tag: TicketTagInterface) => {
            tagOptions = [
              ...tagOptions,
              {
                value: tag.id,
                label: tag.name,
              },
            ];
          });
        // fieldwise option filteration
        if (query.length !== 0) {
          tagOptions = getFilterData([
            ...tagOptions?.filter((tag) => {
              return tag.label.toLowerCase().includes(query.toLowerCase());
            }),
          ]);
        }
        return tagOptions;
      case 'priorities':
        let priorityOptions: Array<{ label: string; value: string | number }> =
          [{ label: t('Unselect All'), value: 'all' }];

        !!PriorityOptions &&
          PriorityOptions.forEach(
            (priority: { label: string; value: string | number }) => {
              priorityOptions = [
                ...priorityOptions,
                {
                  value: priority.value,
                  label: priority.label,
                },
              ];
            }
          );
        // fieldwise option filteration
        if (query.length !== 0) {
          priorityOptions = getFilterData([
            ...priorityOptions?.filter((priority) => {
              return priority.label.toLowerCase().includes(query.toLowerCase());
            }),
          ]);
        }
        return priorityOptions;
      case 'groups':
        if ((!!agentGroup && agentGroup.length === 0) || !agentGroup) return [];
        let groupOptions: TicketFilterAgentGroupOptionInterface[] = [
          {
            label: t('Unselect All'),
            value: 'all',
            image: '',
            isSmart: false,
          },
        ];
        !!agentGroup &&
          agentGroup.forEach((group: GroupInterface) => {
            groupOptions = [
              ...groupOptions,
              {
                value: group.id,
                label: group.name,
                image: group.image,
                isSmart: group.is_smart,
              },
            ];
          });
        // fieldwise option filteration
        if (query.length !== 0) {
          groupOptions = getFilterData([
            ...groupOptions?.filter((group) => {
              return group.label.toLowerCase().includes(query.toLowerCase());
            }),
          ]);
        }
        return groupOptions;
      case 'agents':
        if ((!!agentList && agentList.length === 0) || !agentList) return [];
        let agentOptions: TicketFilterAgentOptionInterface[] = [
          {
            label: t('Unselect All'),
            value: 'all',
            image: '',
          },
        ];
        !!agentList &&
          agentList.forEach((agent: AgentListInterface) => {
            agentOptions = [
              ...agentOptions,
              {
                value: agent.admin.id,
                label: agent.admin?.full_name,
                image: !!agent.admin?.avatar ? agent.admin?.avatar : '',
              },
            ];
          });
        // fieldwise option filteration
        if (query.length !== 0) {
          agentOptions = getFilterData([
            ...agentOptions?.filter((agent) => {
              return agent.label.toLowerCase().includes(query.toLowerCase());
            }),
          ]);
        }
        return agentOptions;
      default:
        return null;
    }
  };

  React.useEffect(() => {
    getInputDisplayString(option.type);
    // eslint-disable-next-line
  }, [filterData]);

  React.useEffect(() => {
    setFocusedField('');
    // eslint-disable-next-line
  }, [isDateOptionOpened]);

  const showSearchBox = () => {
    return isOpen || !selectedOption.tsx;
  };

  return (
    <Combobox as='div' value={selectedOption} onChange={() => console.log('')}>
      <div className='relative mt-1'>
        <Combobox.Label className='block text-sm font-medium text-gray-600'>
          {t(option.label)}
        </Combobox.Label>
        <Combobox.Button className='relative w-full'>
          {isOpen && (
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <MagnifyingGlassIcon
                className='w-5 h-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
          )}
          {!showSearchBox() && (
            <div
              onClick={() => setFocusedField(option.type)}
              className={`w-full inline-block text-left pr-10  py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm`}
            >
              {selectedOption.tsx}
            </div>
          )}
          {showSearchBox() && (
            <input
              key={option.type}
              onChange={(event) => setQuery(event.target.value)}
              onClick={() => setFocusedField(option.type)}
              placeholder={
                focusedField.length !== 0 && focusedField === option.type
                  ? `${t(`Search ${option.label}`)}`
                  : `${t(`Select ${option.label}`)}`
              }
              autoComplete='new-password'
              value={isOpen ? query : selectedOption.label}
              className={`w-full pr-10  py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm ${
                isOpen ? 'pl-10' : 'pl-3'
              }`}
            />
          )}
          <div className='absolute inset-y-0 right-0 flex items-center '>
            <ChevronUpDownIcon
              aria-hidden='true'
              onClick={() =>
                setFocusedField(focusedField.length !== 0 ? '' : option.type)
              }
              className='px-2 text-gray-400 cursor-pointer w-9 h-9 rounded-r-md focus:outline-none'
            />
          </div>
        </Combobox.Button>
        <Transition
          show={isOpen}
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Combobox.Options className='absolute z-10 w-full pt-2 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            <TicketFilterCheckBox
              filedType={option.type}
              handleCancelOnClick={setFocusedField}
              options={getDropDownData(option.type)}
              selectedOptions={filterData[option.type]}
              handleOptionSelection={handleOptionSelection}
            />
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default TicketFilterDropDown;
