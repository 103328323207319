import { FC } from 'react';
import { navigate } from '../../export';
import { ISLAData } from '../../interface';
import {
  Wrapper,
  Policy,
  Reminder,
  Esclation,
  useSLAConfig,
} from '../../export';

interface Props {
  slaData: ISLAData[];
  localSLAData: ISLAData[];
}

const SLAConfiguration: FC<Props> = () => {
  const {
    clearSLAData,
    localSLAData,
    hasSLADataChanged,
    isSLAUpdateLoading,
    hasEmptyReminderValues,
    hasEmptyEscalationValues,
    handleSLAUpdateSaveButton,
  } = useSLAConfig();
  return (
    <Wrapper
      title={localSLAData?.name}
      disableSaveButton={
        !hasSLADataChanged() ||
        hasEmptyReminderValues() ||
        hasEmptyEscalationValues()
      }
      onSave={handleSLAUpdateSaveButton}
      isConfimrationLoading={isSLAUpdateLoading}
      onBackClick={() => {
        navigate('/settings/sla-settings');
        clearSLAData();
      }}
    >
      <Policy policies={localSLAData?.policies} />
      <Reminder reminders={localSLAData?.reminders} />
      <Esclation escalations={localSLAData?.escalations} />
    </Wrapper>
  );
};

export default SLAConfiguration;
