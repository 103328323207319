import React from 'react';
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import { Menu } from 'library/menu/Menu';
import useTranslation from 'components/customHooks/useTranslation';
import { CheckIcon } from '@heroicons/react/24/solid';

interface CountryDetailsProps {
  id: number;
  name: string;
  code: string;
  mobile_code: string;
  capital: string;
}

interface Props {
  disable?: boolean;
  loading?: boolean;
  placeholder?: string;
  selected: CountryDetailsProps | null;
  options: CountryDetailsProps[];
  handleChange: (
    value:
      | { id: number; name: string; code: string }
      | { id: number; name: string }
  ) => void;
}

const CountryListDropdown: React.FC<Props> = ({
  selected,
  options,
  handleChange,
  disable = false,
  loading = false,
  placeholder = 'Search Your Country',
}) => {
  const [countrySearch, setCountrySearch] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState(selected);
  const [toggleDropdown, setToggleDropdown] = React.useState(false);

  const { t } = useTranslation();

  React.useEffect(() => {
    setSelectedCountry(selected);
  }, [selected]);

  const renderNoCountryFound = () => {
    return (
      options.filter((elemFilter) =>
        elemFilter?.name
          .toLowerCase()
          .includes(countrySearch.trim().toLowerCase())
      ).length === 0 && (
        <div className='flex items-center justify-center p-2 mt-2 text-xs font-medium text-gray-600 rounded-md cursor-default'>
          <ExclamationTriangleIcon className='w-4 h-4' />
          <span className='ml-2'>{t('Not Found')}</span>
        </div>
      )
    );
  };

  const loadingView = () => {
    return (
      <span className='ml-1 w-5 h-5 flex items-center'>
        <div
          className='inline-block w-5 h-5 text-primary border-2 rounded-full spinner-border animate-spin'
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      </span>
    );
  };

  const renderCountrySearchModuleView = () => {
    return (
      <div className='flex py-2 bg-white -top-px'>
        <div className=' relative w-full border-gray-300 rounded-md shadow-sm'>
          <div className=' absolute inset-y-0 left-0 flex items-center w-full pl-3 pointer-events-none'>
            <MagnifyingGlassIcon
              className='w-5 h-5 text-gray-400'
              aria-hidden='true'
            />
          </div>
          <input
            type='search'
            value={countrySearch}
            disabled={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCountrySearch(e.target.value);
            }}
            className='block w-full pl-10 border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500 sm:text-sm'
            placeholder={t(placeholder)}
          />
        </div>
      </div>
    );
  };

  const renderCountryDropdownView = (options: CountryDetailsProps[]) => {
    return (
      <Menu>
        {options
          .filter((elemFilter) =>
            elemFilter?.name
              .toLowerCase()
              .includes(countrySearch.trim().toLowerCase())
          )
          ?.map((countryOption: CountryDetailsProps) => {
            return (
              <div
                key={countryOption?.id}
                data-value={countryOption}
                id={countryOption.name}
                onClick={() => {
                  setToggleDropdown(!toggleDropdown);
                  handleChange({
                    id: countryOption?.id,
                    name: countryOption?.name,
                    code: countryOption?.code ?? '',
                  });
                }}
                className='p-3 text-left cursor-pointer hover:bg-primary hover:text-white hover:font-semibold'
              >
                <span className='flex align-center'>
                  <span className='block text-sm font-medium truncate'>
                    {countryOption.name}
                  </span>
                  {selected?.id === countryOption?.id && (
                    <CheckIcon className='text-gray-700 ml-auto w-5 h-5' />
                  )}
                </span>
              </div>
            );
          })}
        {renderNoCountryFound()}
      </Menu>
    );
  };

  const renderSelectCountryButtonView = () => {
    return (
      <button
        disabled={disable}
        className='relative w-full px-3 h-11 mt-1 border border-gray-300
        rounded-md shadow-sm focus:border-primary focus:ring-1 focus:ring-primary text-left'
        onClick={() => setToggleDropdown(!toggleDropdown)}
      >
        <span className='flex items-center'>
          <span className='block text-sm font-medium truncate'>
            {selectedCountry?.name || 'Select a country'}
          </span>
        </span>

        <span className='absolute right-[10px] top-[11px]'>
          <ChevronUpDownIcon className='w-5 h-5' aria-hidden='true' />
        </span>
      </button>
    );
  };

  return (
    <div className='relative'>
      {renderSelectCountryButtonView()}
      {toggleDropdown && (
        <div className='bg-white absolute w-full mt-1 z-10 border rounded-md'>
          <div className='p-1 sticky border-white rounded-md'>
            {renderCountrySearchModuleView()}
          </div>
          <div className='relative z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {loading && (
              <div className='w-full p-4 flex justify-center items-center'>
                {loadingView()}
              </div>
            )}
            {!loading && renderCountryDropdownView(options)}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountryListDropdown;
