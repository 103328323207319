import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { XMarkIcon } from '@heroicons/react/20/solid';
import emailIconSet from '../../../../../assets/icons/channelIconSet/email.svg';
import { channelListProps } from 'pages/integration/interface';
import React from 'react';
import { Link } from 'library';
import FooterContainer from '../../availableIntegrations/Email/Footer';
import { SelectedTeamInterface } from 'index';
import { toaster } from 'evergreen-ui';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  key: string;
  open: boolean;
  channelData: channelListProps;
  selectedProject: SelectedTeamInterface;
  handleClose: () => void;
  handleChannelEdit: (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) => Promise<boolean>;
}

const EditEmailIntegration: React.FC<Props> = ({
  open = true,
  handleClose,
  channelData,
  selectedProject,
  handleChannelEdit,
}) => {
  const { t } = useTranslation();
  const { isPartnerRestricted } = usePartner();
  const shouldHideHelpLink = isPartnerRestricted(
    'EditEmailIntegration/node/help-doc'
  );

  const [emailChannelTitle, setEmailChannelTitle] = React.useState(
    channelData?.title
  );

  const getModalCustomFooter = (
    description: string,
    link: string,
    linkTitle: string
  ) => {
    return (
      <div
        className={`flex justify-end gap-2 ${
          shouldHideHelpLink ? 'hidden' : ''
        }`}
      >
        <label htmlFor='remember_me' className='block text-sm text-gray-900'>
          {t(description)}{' '}
          <Link url={link} openInNewTab={true}>
            {t(linkTitle)}
          </Link>
        </label>
      </div>
    );
  };

  const handleConfirm = async () => {
    let res = await handleChannelEdit(
      selectedProject.id,
      channelData.id,
      {
        title: emailChannelTitle,
        name: channelData.name,
      },
      `${emailChannelTitle} successfully updated`
    );
    if (res) {
      handleClose();
      toaster.success(t(`Email settings have been successfully updated`));
    } else {
      toaster.danger(
        t(
          'Something went wrong while updating this channel. Please try again later.'
        )
      );
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const renderEditView = (channelData: channelListProps) => {
    return (
      <>
        <div className='p-6 h-[93%] flex flex-col space-between'>
          <div>
            {' '}
            <div className='flex flex-col'>
              <label className='mb-1 rtl:text-right capitalize text-gray-600 text-sm font-medium'>
                {t('Title')}
                <span className='text-red-500'>*</span>
              </label>
              <input
                type='text'
                className='rounded-md border border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 text-sm leading-5'
                placeholder={t('My Email Channel')}
                name={'email_title'}
                value={emailChannelTitle}
                onChange={(e) => {
                  setEmailChannelTitle(e.target.value);
                }}
              />
            </div>
            <p className='text-gray-500 flex text-xs leading-5 mb-3'>
              {t(
                'The title is private. We use this info to identify this channel on MyAlice.'
              )}
            </p>
            <div className='flex flex-col'>
              <label className='mb-1 flex capitalize text-gray-600 text-sm font-medium'>
                {t('Email Address')}
                <span className='text-red-500'>*</span>
              </label>
              <span
                className='rounded-md border flex text-gray-600 bg-gray-200 border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-3'
              >
                {channelData?.primary_id}
              </span>
              <span />
            </div>
          </div>

          <FooterContainer
            cancelText={t('Cancel')}
            confirmText={t('Save Changes')}
            isConfirmButtonLoading={false}
            disableConfirm={
              emailChannelTitle === '' ||
              emailChannelTitle === channelData?.title
            }
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
            customFooterContent={getModalCustomFooter(
              t('Need Help?'),
              'https://docs.myalice.ai/connect-social-channels/connect-web-app/connect-live-chat',
              t('Read Our Guide')
            )}
          />
        </div>
      </>
    );
  };
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className='w-[650px] h-[700px] relative transform overflow-hidden rounded-lg bg-white
                text-left align-middle shadow-xl transition-all'
                >
                  <div className='w-full px-4 py-3 flex justify-start items-center border-b gap-2 border-gray-200'>
                    <img
                      src={emailIconSet}
                      width={18}
                      height={18}
                      alt='social_channel'
                    />
                    <span className='text-gray-700 font-semibold text-base leading-6'>
                      {t('Edit Email Settings')}
                    </span>

                    <XMarkIcon
                      color='#6B7280'
                      height={20}
                      className='float-right cursor-pointer ltr:ml-auto rtl:mr-auto'
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </div>

                  {renderEditView(channelData)}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

const mapState = <T extends { auth: any; dashboard: any }>(state: T) => ({
  selectedProject: state.dashboard.selectedProject,
});
const mapDispatch = (dispatch: any) => ({
  handleChannelEdit: async (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) =>
    dispatch.integration.editChannel({
      teamId,
      channelId,
      data,
      alertMessage,
      hideToaster: true,
    }),
});

export default connect(mapState, mapDispatch)(EditEmailIntegration);
