import React from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import EmptyImage from '../../assets/emptyData.svg';
import ReactTooltip from 'react-tooltip';
import Spinner from './Spinner';
import { connect } from 'react-redux';
import useTranslation from 'components/customHooks/useTranslation';
import moment from 'moment';

interface Props {
  webhookList: WebhookInterface[];
  setOpenDeleteModal: (payload: boolean) => void;
  openDeleteModal: boolean;
  setSingleWebhook: (payload: string) => void;
  setIsEdit: (payload: boolean) => void;
  setEditData: (payload: {
    id: string | null;
    event: string;
    endpoint: string;
  }) => void;
  setOpenCreateModal: (payload: boolean) => void;
  loadingWebhookList: boolean;
}

const WebhookList: React.FC<Props> = ({
  webhookList,
  setOpenDeleteModal,
  openDeleteModal,
  setSingleWebhook,
  setIsEdit,
  setEditData,
  setOpenCreateModal,
  loadingWebhookList,
}) => {
  const { t } = useTranslation();
  const badge = (event_type: string) => {
    if (
      [
        'ticket.created',
        'ticket.resolved',
        'ticket.tagged',
        'ticket.reopened',
      ].includes(event_type)
    ) {
      return (
        <span className='inline-flex items-center rounded-md bg-blue-100 px-2.5 py-0.5 text-sm font-medium text-blue-800'>
          {t(event_type)}
        </span>
      );
    } else if (
      ['message.read', 'message.delivered', 'message.sent'].includes(event_type)
    ) {
      return (
        <span className='inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800'>
          {t(event_type)}
        </span>
      );
    } else if (event_type === 'customer.created') {
      return (
        <span className='inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-sm font-medium text-yellow-800'>
          {t(event_type)}
        </span>
      );
    } else {
      return (
        <span className='inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800 rtl:text-right'>
          {t(event_type)}
        </span>
      );
    }
  };

  return (
    <div className='mt-8 flex flex-col'>
      <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
          <div
            className='relative overflow-hidden ring-1 ring-black
          ring-opacity-5 md:rounded-lg max-h-[75vh] overflow-y-auto'
          >
            <table className='min-w-full table-fixed divide-y divide-gray-300'>
              <thead className='bg-[#F9FAFB]'>
                <tr>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 hidden border-b border-gray-300
                     bg-gray-50 bg-opacity-100 px-4 py-3.5 ltr:text-left rtl:text-right text-sm
                      font-medium text-[#6B7280] backdrop-blur backdrop-filter
                       sm:table-cell uppercase'
                  >
                    {t('Webhook Type')}
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 hidden border-b border-gray-300
                    bg-gray-50 bg-opacity-100 px-3 py-3.5 ltr:text-left rtl:text-right text-sm
                     font-medium text-[#6B7280] backdrop-blur
                     backdrop-filter sm:table-cell uppercase'
                  >
                    {t('Webhook URL')}
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 hidden border-b border-gray-300
                    bg-gray-50 bg-opacity-100 px-3 py-3.5 ltr:text-left rtl:text-right text-sm
                     font-medium text-[#6B7280] backdrop-blur
                     backdrop-filter sm:table-cell uppercase'
                  >
                    {t('Last Used At')}
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 hidden border-b border-gray-300
                    bg-gray-50 bg-opacity-100 px-4 py-3.5 text-center text-sm
                     font-medium text-[#6B7280] backdrop-blur
                     backdrop-filter sm:table-cell uppercase'
                  >
                    {t('Action')}
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {webhookList.length > 0 &&
                  webhookList.map((item, index) => (
                    <tr key={index} className=''>
                      <td className='px-4'>{badge(item.event)}</td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[60%] rtl:text-right'>
                        {item.endpoint}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[60%] rtl:text-right'>
                        {item.last_used_at
                          ? moment(item.last_used_at * 1000).fromNow()
                          : t('Never used')}
                      </td>
                      <td className='whitespace-nowrap py-4 px-4 ltr:text-left rtl:text-right text-sm font-medium w-[20%]'>
                        <div className='flex justify-end gap-4'>
                          {/* @ts-ignore */}
                          <ReactTooltip
                            id='edit'
                            place='top'
                            type='dark'
                            effect='float'
                          />
                          <PencilIcon
                            data-for='edit'
                            data-tip={t('Edit')}
                            className='w-5 h-5 text-gray-400 cursor-pointer'
                            onClick={() => {
                              setIsEdit(true);
                              setEditData({
                                id: item.id,
                                event: item.event,
                                endpoint: item.endpoint,
                              });
                              setOpenCreateModal(true);
                            }}
                          />
                          {/* @ts-ignore */}
                          <ReactTooltip
                            id='delete'
                            place='top'
                            type='dark'
                            effect='float'
                          />
                          <TrashIcon
                            data-for='delete'
                            data-tip={t('Delete')}
                            className='w-5 h-5 text-red-500 cursor-pointer'
                            onClick={() => {
                              setSingleWebhook(item.id);
                              setOpenDeleteModal(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                {webhookList.length === 0 && (
                  <tr>
                    <td colSpan={7}>
                      {loadingWebhookList && (
                        <div className='w-full flex flex-col items-center justify-center min-h-[500px]'>
                          <Spinner
                            color='border-r-transparent border-t-gray-300 border-b-gray-300 border-l-gray-300'
                            size='w-16 h-16'
                          />
                        </div>
                      )}
                      {!loadingWebhookList && (
                        <div className='w-full flex flex-col items-center justify-center min-h-[500px]'>
                          <img src={EmptyImage} className='w-40 mb-2' alt='' />
                          <p className='text-gray-400 text-sm'>
                            {t('You need to create a webhook first')}
                          </p>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  loadingWebhookList: state.loading.effects.settings.fetchWebhooks,
});
const mapDispatch = () => ({});

export default connect(mapState, mapDispatch)(WebhookList);
