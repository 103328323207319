import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import { DragTypes, IDataLabField } from 'pages/datalab/export';

import './dropZone.css';

interface IDropZoneProps {
  data: any;
  onDrop: any;
  isLast?: boolean;
  className?: string;
  style?: any;
}

interface IItem {
  field: IDataLabField;
  type: string;
  index: number;
}

const ACCEPTS = [
  DragTypes.FIELD,
  DragTypes.BLOCK_FIELD,
  DragTypes.MULTI_CHOICE,
  DragTypes.GROUP_FIELD,
];

const DropZone = ({
  data,
  onDrop,
  isLast,
  className,
  style = {},
}: IDropZoneProps) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item: IItem, monitor) => {
      onDrop(data, item);
    },
    canDrop: (item, monitor) => {
      const dropZonePath = data.index;
      const splitDropZonePath = dropZonePath.split('-');
      const itemPath = `${item.index}`;

      // sidebar items can always be dropped anywhere
      if (!itemPath) {
        return true;
      }

      const splitItemPath = itemPath.split('-');

      // limit columns when dragging from one row to another row
      const dropZonePathRowIndex = splitDropZonePath[0];
      const itemPathRowIndex = splitItemPath[0];
      const diffRow = dropZonePathRowIndex !== itemPathRowIndex;
      if (
        diffRow &&
        splitDropZonePath.length === 2 &&
        data.childrenCount >= 3
      ) {
        return false;
      }

      // Invalid (Can't drop a parent field (row) into a child (column))
      const parentDropInChild = splitItemPath.length < splitDropZonePath.length;
      if (parentDropInChild) return false;

      // Current item can't possible move to it's own location
      if (itemPath === dropZonePath) return false;

      // Current area
      if (splitItemPath.length === splitDropZonePath.length) {
        const pathToItem = splitItemPath.slice(0, -1).join('-');
        const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

        const pathToDropZone = splitDropZonePath.slice(0, -1).join('-');
        const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

        if (pathToItem === pathToDropZone) {
          const nextDropZoneIndex = currentItemIndex + 1;
          if (nextDropZoneIndex === currentDropZoneIndex) return false;
        }
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;

  // drop zone classes
  const dropZoneClasses = classNames(
    'dropZone',
    { active: isActive, isLast },
    className,
    { active: isActive, isLast }
  );

  return <div className={dropZoneClasses} style={{ ...style }} ref={drop} />;
};
export default DropZone;
