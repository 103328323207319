import React from 'react';
import { PencilIcon } from '@heroicons/react/24/outline';
import DeliveryTypeDropdown from './DeliveryTypeDropdown';
import DiscountTypeDropdown from './DiscountTypeDropdown';
import { Switch } from '@headlessui/react';
import ProductCard from './ProductCard';
import { connect } from 'react-redux';
import {
  calculateDiscount,
  getCartSubtotal,
  getCurrentOrderFormInformationValue,
} from 'pages/inbox/utils/functions';
import { SelectedTeamInterface } from 'index';
import useTranslation from 'components/customHooks/useTranslation';
import {
  CalculateDraftOrderProperty,
  CreateDraftOrderProperty,
  ICustomerCartInfo,
  OrderFormInformationType,
  ShippingMethodInterface,
} from 'pages/inbox/inboxInterface.d';

interface Props {
  currencySymbol: string;
  createDraftOrder: CreateDraftOrderProperty;
  setCreateDraftOrder: (
    CreateDraftOrderProperty: CreateDraftOrderProperty
  ) => void;
  calculateDraftOrderProperty: CalculateDraftOrderProperty;
  customerInfo: ICustomerCartInfo;
  selectedProject: SelectedTeamInterface;
  calculateDraftOrder: (payload: {
    projectId: number;
    data: CreateDraftOrderProperty;
  }) => boolean;
  toggleEcommerceModal: (value: boolean) => void;
  openModal: boolean;
  updateOpenShopifyModal: (payload: boolean) => void;
  toggleProductView: (payload: boolean) => void;
  updateOrderInformationState: (payload: {
    key: number | string;
    data: OrderFormInformationType;
  }) => void;
  orderFormInformation: {
    [key: string]: OrderFormInformationType;
  };
}

const CartDetails: React.FC<Props> = ({
  currencySymbol,
  createDraftOrder,
  setCreateDraftOrder,
  calculateDraftOrderProperty,
  customerInfo,
  calculateDraftOrder,
  selectedProject,
  toggleEcommerceModal,
  openModal,
  updateOpenShopifyModal,
  toggleProductView,
  updateOrderInformationState,
  orderFormInformation,
}) => {
  const debounceTimeout = React.useRef(null);
  const { t } = useTranslation();

  const handleChange = (
    value: string,
    shippingMethod?: ShippingMethodInterface
  ) => {
    const inputValue = value;
    // @ts-ignore
    clearTimeout(debounceTimeout.current);
    // @ts-ignore
    debounceTimeout.current = setTimeout(() => {
      if (!!shippingMethod && createDraftOrder) {
        calculateDraftOrder({
          projectId: selectedProject?.id,
          data: {
            ...createDraftOrder,
            shipping_line: shippingMethod,
          },
        });
      } else if (createDraftOrder) {
        calculateDraftOrder({
          projectId: selectedProject?.id,
          data: {
            ...createDraftOrder,
            shipping_line: {
              ...createDraftOrder?.shipping_line,
              shipping_cost: parseInt(inputValue),
            },
          },
        });
      }
    }, 500);
  };

  const handleDiscountChange = (value: string) => {
    if (createDraftOrder) {
      const inputValue = value;
      // @ts-ignore
      clearTimeout(debounceTimeout.current);
      // @ts-ignore
      debounceTimeout.current = setTimeout(() => {
        calculateDraftOrder({
          projectId: selectedProject?.id,
          data: {
            ...createDraftOrder,
            discount_amount: inputValue,
          },
        });
      }, 500);
    }
  };

  const toggleApplyTax = () => {
    return (
      <Switch
        checked={createDraftOrder?.apply_tax}
        onChange={(value: boolean) => {
          updateOrderInformationState({
            key: customerInfo?.customer_id,
            data: {
              ...getCurrentOrderFormInformationValue(
                orderFormInformation,
                customerInfo?.customer_id
              ),
              apply_tax: value,
            },
          });

          if (createDraftOrder) {
            calculateDraftOrder({
              projectId: selectedProject?.id,
              data: { ...createDraftOrder, apply_tax: value },
            });
          }
        }}
        className={`${
          createDraftOrder?.apply_tax ? 'bg-green-500' : 'bg-gray-200'
        }
          relative inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer
          rounded-full border-2 border-transparent transition-colors duration-200
          ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white
          focus-visible:ring-opacity-75`}
      >
        <span className='sr-only'>Use setting</span>
        <span
          aria-hidden='true'
          className={`${
            createDraftOrder?.apply_tax
              ? 'ltr:translate-x-5 rtl:translate-x-0'
              : 'ltr:translate-x-0 rtl:-translate-x-5'
          }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full
            bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    );
  };

  const renderPriceCalculationView = () => {
    return (
      <>
        {' '}
        {createDraftOrder && (
          <div className='px-3'>
            <div className='flex w-full mb-2'>
              <p className='w-1/2 text-xs font-normal text-gray-500'>
                {t('Subtotal')}
              </p>
              <div className='w-1/2 flex justify-end'>
                <p className='text-xs text-gray-900 font-normal'>
                  {currencySymbol}
                  {getCartSubtotal(customerInfo?.alice_cart)}
                </p>
              </div>
            </div>
            <div className='flex w-full mb-2'>
              <p className='w-1/2 text-xs font-normal text-gray-500'>
                {t('Tax & Shipping')}
              </p>
              <div className='w-1/2 flex justify-end'>
                <p className='text-xs text-gray-900 font-normal'>
                  {currencySymbol}
                  {calculateDraftOrderProperty?.total_tax +
                    calculateDraftOrderProperty?.total_shipping_cost}
                </p>
              </div>
            </div>
            <div className='flex w-full pb-3 border-b-2 border-gray-200'>
              <p className='w-1/2 text-xs font-normal text-gray-500'>
                {t('Total Discount')}
              </p>
              <div className='w-1/2 flex justify-end'>
                <p
                  className={`text-xs ${
                    calculateDiscount(
                      createDraftOrder?.discount_type,
                      parseInt(getCartSubtotal(customerInfo?.alice_cart)),
                      parseInt(createDraftOrder?.discount_amount)
                    ) > 0
                      ? 'text-red-500'
                      : 'text-gray-900'
                  } font-normal`}
                >
                  {calculateDiscount(
                    createDraftOrder.discount_type,
                    parseInt(getCartSubtotal(customerInfo?.alice_cart)),
                    parseInt(createDraftOrder?.discount_amount)
                  ) > 0
                    ? '-'
                    : ''}
                  {currencySymbol}
                  {calculateDiscount(
                    createDraftOrder?.discount_type,
                    parseInt(getCartSubtotal(customerInfo?.alice_cart)),
                    parseInt(createDraftOrder?.discount_amount)
                  )
                    ? calculateDiscount(
                        createDraftOrder?.discount_type,
                        parseInt(getCartSubtotal(customerInfo?.alice_cart)),
                        parseInt(createDraftOrder?.discount_amount)
                      ).toFixed(2)
                    : 0}
                </p>
              </div>
            </div>
            <div className='flex w-full mt-2 mb-4'>
              <p className='w-1/2 text-xs'>Total</p>
              <div className='w-1/2 flex justify-end'>
                <p className={`text-green-600 text-base font-semibold`}>
                  {currencySymbol}
                  {calculateDraftOrderProperty?.total_cost}
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const deliveryInput = () => {
    return (
      <input
        className={`w-[30%] px-2 rounded-md h-[37px] border border-gray-200 ${
          createDraftOrder?.shipping_line?.method_id ===
            'my_alice_zero_shipping' || createDraftOrder?.shipping_line === null
            ? 'bg-gray-200'
            : 'bg-white'
        } focus:border-green-500 focus:outline-none focus:ring-0`}
        disabled={
          createDraftOrder?.shipping_line?.method_id !==
          'my_alice_zero_shipping'
            ? false
            : true
        }
        value={
          createDraftOrder?.shipping_line
            ? createDraftOrder?.shipping_line?.shipping_cost
            : 0
        }
        type='number'
        onChange={(e) => {
          handleChange(e.target.value);
          updateOrderInformationState({
            key: customerInfo.customer_id,
            data: {
              ...getCurrentOrderFormInformationValue(
                orderFormInformation,
                customerInfo.customer_id
              ),
              shipping_line: {
                ...createDraftOrder?.shipping_line,
                shipping_cost: e.target.value,
              },
            },
          });
        }}
      />
    );
  };

  const renderDiscountInputView = () => {
    return (
      <>
        {createDraftOrder && (
          <div className='relative w-[30%]'>
            {createDraftOrder?.discount_type === 'PERCENTAGE' && (
              <span className='absolute ltr:right-1 rtl:right-2 top-[10px]'>
                %
              </span>
            )}
            <input
              type='number'
              className={`w-full px-2 rounded-md  border border-gray-200
              focus:border-green-500 focus:outline-none focus:ring-0 text-sm ltr:pr-2 rtl:pr-6 ${
                createDraftOrder?.discount_type?.length > 0
                  ? 'bg-white'
                  : 'bg-gray-300'
              }`}
              disabled={
                createDraftOrder?.discount_type?.length > 0 ? false : true
              }
              value={createDraftOrder?.discount_amount}
              onChange={(e) => {
                if (
                  createDraftOrder?.discount_type === 'PERCENTAGE' &&
                  parseInt(e?.target?.value) > 99
                ) {
                  return;
                }

                handleDiscountChange(e?.target?.value);
                updateOrderInformationState({
                  key: customerInfo?.customer_id,
                  data: {
                    ...getCurrentOrderFormInformationValue(
                      orderFormInformation,
                      customerInfo?.customer_id
                    ),
                    discount_amount: e?.target?.value,
                  },
                });
              }}
            />
          </div>
        )}
      </>
    );
  };

  const renderProductsView = () => {
    return (
      <div className='p-3 mb-3'>
        {customerInfo?.alice_cart.map((product) => {
          return (
            <ProductCard
              product={product}
              key={product?.product_id}
              currencySymbol={currencySymbol}
            />
          );
        })}
        <div
          className='flex gap-2 mt-4 cursor-pointer'
          onClick={() => {
            toggleEcommerceModal(!openModal);
            toggleProductView(!openModal);
            updateOpenShopifyModal(false);
          }}
        >
          <PencilIcon className='w-4 h-4 text-blue-500 font-medium' />
          <p className='text-blue-500 text-sm font-medium'>
            {t('Update cart')}
          </p>
        </div>
      </div>
    );
  };

  const renderDeliveryOptionView = () => {
    return (
      <div className='px-3 mb-2 flex gap-2 items-center'>
        <DeliveryTypeDropdown
          shippingMethods={
            calculateDraftOrderProperty?.available_shipping_lines
          }
          createDraftOrder={createDraftOrder}
          handleChange={handleChange}
          updateOrderInformationState={updateOrderInformationState}
        />
        {deliveryInput()}
      </div>
    );
  };

  const renderDiscountOptionView = () => {
    return (
      <div className='px-3 mb-2 flex gap-2 items-center'>
        <DiscountTypeDropdown
          setCreateDraftOrder={setCreateDraftOrder}
          createDraftOrder={createDraftOrder}
          handleChange={handleDiscountChange}
          updateOrderInformationState={updateOrderInformationState}
        />
        {renderDiscountInputView()}
      </div>
    );
  };

  const renderApplyTaxView = () => {
    return (
      <div className='px-3 mb-6'>
        <div className='border border-gray-200 rounded-lg flex px-2 py-2 items-center '>
          <p className='w-1/2'>{t('Apply tax/charges')}</p>
          <div className='w-1/2 flex justify-end'>{toggleApplyTax()}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      {createDraftOrder && (
        <div className='w-full'>
          {renderProductsView()}
          {renderDeliveryOptionView()}
          {renderDiscountOptionView()}
          {renderApplyTaxView()}
          {renderPriceCalculationView()}
        </div>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  calculateDraftOrderProperty: state.ecommerce.calculateDrftOrder,
  customerInfo: state.ecommerce.cart,
  selectedProject: state.dashboard.selectedProject,
  openModal: state.ecommerce.openModal,
  orderFormInformation: state.ecommerce.orderFormInformation,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
});
const mapDispatch = (dispatch: any) => ({
  calculateDraftOrder: (payload: {
    projectId: number;
    data: CreateDraftOrderProperty | null;
  }) => dispatch.ecommerce.calculateDraftOrder(payload),
  toggleEcommerceModal: (value: boolean) =>
    dispatch.ecommerce.updateEcommerceStateData({ key: 'openModal', value }),
  toggleProductView: (value: boolean) =>
    dispatch.ecommerce.updateEcommerceStateData({
      key: 'showProductView',
      value,
    }),
  updateOpenShopifyModal: (payload: boolean) =>
    dispatch.ecommerce.updateOpenShopifyModal(payload),
});

export default connect(mapState, mapDispatch)(CartDetails);
