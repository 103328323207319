import { useSelector } from 'react-redux';

const useMiddleBarHook = () => {
  const authData = useSelector((state: any) => state.auth);
  const inboxData = useSelector((state: any) => state.inbox);
  const dashboardData = useSelector((state: any) => state.dashboard);

  const { id } = authData;
  const { selectedQueueType } = inboxData;
  const { agentAccess, selectedProject } = dashboardData;

  const isUserRestrictedToAssignOtherAgentOrGroup = () => {
    const restrictedTeams = [12275, 375];
    const restrictedRoles = [1034];
    const restrictedQueue = ['unassigned'];

    if (
      restrictedTeams.includes(selectedProject?.id) &&
      restrictedQueue.includes(selectedQueueType)
    ) {
      const userAccess = agentAccess.find((accessObj: any) => {
        return accessObj?.admin?.id === id;
      });
      if (userAccess) {
        return restrictedRoles.includes(userAccess.role.id);
      }
    }
    return false;
  };

  const teamOhsogoCustomModificationObject = {
    isUserRestrictedToAssignOtherAgentOrGroup:
      isUserRestrictedToAssignOtherAgentOrGroup(),
  };

  return { teamOhsogoCustomModificationObject };
};

export default useMiddleBarHook;
