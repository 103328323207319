import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { connect } from 'react-redux';
import {
  ICustomerOrderProperty,
  SendMessageRequestBodyInterface,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import OrderStatus from './OrderStatus';
import ProductCard from '../shopify/ProductCard';
import {
  getCartSubtotal,
  getStringFromObject,
} from 'pages/inbox/utils/functions';
import { toaster } from 'evergreen-ui';
import PaymentStatus from './PaymentStatus';
import OrderEditOptionModal from './OrderEditOptionModal';
import { SelectedTeamInterface } from 'index';
import FeatureFlag from '../../../../../components/higherOrderComponents/FeatureFlag';
import CopyText from '../common/CopyText';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  setDisplayScreen: (payload: string) => void;
  fetchCustomerOrder: (
    payload: { customerId: number; orderId: number } | { customerId: number }
  ) => void;
  selectedTicket: TicketInterface;
  orderInfo: ICustomerOrderProperty;
  sendMessegengerChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => boolean;
  refundOrder: (projectId: number, orderId: string) => boolean;
  selectedProject: SelectedTeamInterface;
  cancelOrderFromList: (projectId: number, orderId: string) => boolean;
  isRefundLoading: boolean;
  isOrderCancelLoading: boolean;
}

const OrderDetails: React.FC<Props> = ({
  setDisplayScreen,
  fetchCustomerOrder,
  selectedTicket,
  orderInfo,
  sendMessegengerChannelMessage,
  refundOrder,
  selectedProject,
  cancelOrderFromList,
  isRefundLoading,
  isOrderCancelLoading,
}) => {
  const { t } = useTranslation();
  const [showRefundModal, setShowRefundModal] = React.useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = React.useState(false);
  const handleSendConfirmationLink = async (trackerLink: string) => {
    let payload = {
      action: 'direct_message',
      template: null,
      audio: null,
      image: null,
      text: trackerLink,
    };
    const res = await sendMessegengerChannelMessage(payload);
    if (res) {
      toaster.success('Tracker Link Send');
    }
  };

  const handleTextToCopy = () => {
    return (
      `Name: ${orderInfo?.shipping_address?.first_name} ${orderInfo?.shipping_address?.last_name}` +
      `\nEmail: ${orderInfo?.shipping_address?.email}` +
      `\nPhone: ${orderInfo?.shipping_address?.phone}` +
      `\nShipping Address: ${getStringFromObject(
        orderInfo?.shipping_address,
        ['address_one', 'address_two', 'city', 'state', 'postcode'],
        false
      )}` +
      `\nBilling Address: ${
        orderInfo?.sameAsShippingAddress
          ? 'Same as shipping address'
          : getStringFromObject(orderInfo?.billing_address, [], true)
      }` +
      `\nShipping Method: ${orderInfo?.shipping_line?.method_title}`
    );
  };

  const renderCalculatedPriceView = () => {
    return (
      <div className='pb-1 mb-2'>
        <div className='flex w-full mb-2'>
          <p className='w-1/2 text-sm font-normal text-gray-500'>
            {t('Subtotal')}
          </p>
          <div className='w-1/2 flex justify-end'>
            <p className='text-sm text-gray-900 font-normal'>
              ${getCartSubtotal(orderInfo?.products)}
            </p>
          </div>
        </div>
        <div className='flex w-full mb-2'>
          <p className='w-1/2 text-sm font-normal text-gray-500'>
            {t('Tax & Shipping')}
          </p>
          <div className='w-1/2 flex justify-end'>
            <p className='text-sm text-gray-900 font-normal'>
              ${orderInfo?.total_tax + orderInfo?.total_shipping_cost}
            </p>
          </div>
        </div>
        <div className='flex w-full'>
          <p className='w-1/2 text-sm font-normal text-gray-500'>
            {t('Total Discount')}
          </p>
          <div className='w-1/2 flex justify-end'>
            <p
              className={`text-sm ${
                orderInfo.total_discount > 0 ? 'text-red-500' : 'text-gray-900'
              } font-normal`}
            >
              {orderInfo.total_discount > 0 ? '-' : ''}$
              {orderInfo.total_discount}
            </p>
          </div>
        </div>
        <div className='flex w-full mt-2 mb-4'>
          <p className='w-1/2 text-sm'>{t('Total')}</p>
          <div className='w-1/2 flex justify-end text-xs'>
            <p className='text-sm text-green-600 font-semibold'>
              {orderInfo?.total_cost}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderAddressSummaryView = () => {
    return (
      <>
        <div className=''>
          <div className='px-3 py-1 bg-gray-100 rounded-md flex items-center mb-2 justify-between'>
            <div className='flex w-1/2 text-gray-700 font-medium'>
              {t('Billing & Shipping')}
            </div>
            <div className='flex w-1/2 flex-end'>
              <CopyText height={20} textToCopy={handleTextToCopy()} />
            </div>
          </div>
        </div>
        <div className='text-sm flex flex-col gap-2 py-3'>
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-500'>{t('Name')}</span>
            <span className='w-[62%] text-gray-900'>
              {orderInfo?.shipping_address?.first_name}{' '}
              {orderInfo?.shipping_address?.last_name}
            </span>
          </p>
          {orderInfo?.shipping_address?.email && (
            <p className='flex space-between'>
              <span className='w-[35%] text-gray-500'>{t('Email')}</span>
              <span className='w-[62%] text-gray-900'>
                {orderInfo?.shipping_address?.email}
              </span>
            </p>
          )}
          {orderInfo?.shipping_address?.phone && (
            <p className='flex space-between'>
              <span className='w-[35%] text-gray-500'>{t('Phone')}</span>
              <span className='w-[62%] text-gray-900'>
                {orderInfo?.shipping_address?.phone}
              </span>
            </p>
          )}
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-500'>
              {t('Shipping Address')}
            </span>
            <span className='w-[62%] text-gray-900'>
              {orderInfo?.shipping_address?.address_one}
              {orderInfo?.shipping_address?.address_two &&
                ', ' + orderInfo?.shipping_address?.address_two}
              {orderInfo?.shipping_address?.city &&
                ', ' + orderInfo?.shipping_address?.city}
              {orderInfo?.shipping_address?.state &&
                ', ' + orderInfo?.shipping_address?.state}
              {orderInfo?.shipping_address?.postcode &&
                ', ' + orderInfo?.shipping_address?.postcode}
            </span>
          </p>
          {orderInfo?.shipping_line && (
            <p className='flex space-between'>
              <span className='w-[35%] text-gray-500'>
                {t('Shipping method')}
              </span>
              <span className='w-[62%] text-gray-900'>
                {t(orderInfo?.shipping_line?.method_title)}
              </span>
            </p>
          )}
          {orderInfo?.total_tax > 0 && (
            <p className='flex space-between'>
              <span className='w-[35%] text-gray-500'>Tax/charges</span>
              <span className='w-[62%] text-gray-900'>
                ${orderInfo?.total_tax}
              </span>
            </p>
          )}
        </div>
      </>
    );
  };

  const renderOrderEditOptions = () => {
    return (
      <div className='px-3 mb-4'>
        <div className='px-2 py-1 border border-gray-200 rounded-md flex items-center'>
          <div className='w-1/3 text-[#0078CF] font-normal text-center capitalize cursor-pointer'>
            {t('update')}
          </div>
          <div
            className='w-1/3 text-[#0078CF] font-normal text-center capitalize cursor-pointer'
            onClick={() => setShowRefundModal(true)}
          >
            {t('Refund')}
          </div>
          <div
            className='w-1/3 text-[#0078CF] font-normal text-center capitalize cursor-pointer'
            onClick={() => setShowCancelOrderModal(true)}
          >
            {t('cancel')}
          </div>
        </div>
      </div>
    );
  };

  const renderSendTrackerButton = () => {
    if (orderInfo?.status !== 'OPEN')
      return (
        <div className='px-3 mb-2'>
          <button
            className='px-2 py-1 rounded-md border border-gray-200 capitalize bg-white w-full'
            onClick={() => handleSendConfirmationLink(orderInfo?.permalink)}
          >
            {t('send tracker link')}
          </button>
        </div>
      );
    else return <></>;
  };

  const renderInvoiceView = () => {
    return (
      <div className='px-3'>
        <div className='px-3 py-1 bg-gray-100 rounded-md flex items-center mb-2'>
          <div className='flex w-1/2 text-gray-700 font-normal'>
            {t('Order Summary')}
          </div>
          <div className='flex w-1/2 justify-end text-[#0078CF] font-normal'>
            {t('Invoice')}
          </div>
        </div>
      </div>
    );
  };

  const renderModalHeader = () => {
    return (
      <div className='flex items-center gap-2 font-semibold text-sm leading-5 text-gray-900 w-[95%] cursor-default'>
        <ArrowLeftIcon
          className='w-4 h-4 cursor-pointer'
          onClick={() => {
            fetchCustomerOrder({
              customerId: selectedTicket.customer_id,
            });
            setDisplayScreen('');
          }}
        />
        <span className='w-20 truncate'>
          {orderInfo?.shopify_order_name || orderInfo?.id}
        </span>
      </div>
    );
  };

  const renderProductsView = () => {
    return (
      <div className='px-3 pt-3'>
        <div className='mb-3 border-b border-gray-200'>
          {orderInfo?.products?.map((product, index) => {
            return <ProductCard product={product} key={index} />;
          })}
        </div>
      </div>
    );
  };

  return (
    <div className='mb-3'>
      <div className='flex items-center p-3'>
        {renderModalHeader()}
        <div className='w-fit flex items-center justify-end gap-2'>
          <PaymentStatus status={orderInfo?.payment_status} />
          <OrderStatus status={orderInfo?.status} />
        </div>
      </div>
      {renderInvoiceView()}
      {renderProductsView()}
      <div className='px-3'>{renderCalculatedPriceView()}</div>
      {renderSendTrackerButton()}
      <FeatureFlag
        allowedEnvironment={['staging', 'dev']}
        allowedTeam={{
          allowedTeamList: [9, 343, 1379, 1451, 2694],
          teamId: selectedProject?.id,
        }}
      >
        {renderOrderEditOptions()}
      </FeatureFlag>

      <div className='px-3'>{renderAddressSummaryView()}</div>
      <OrderEditOptionModal
        isShown={showRefundModal}
        setShown={setShowRefundModal}
        intent={'danger'}
        deleteTitle={t('Refund Order')}
        onCancelCallback={() => setShowRefundModal(false)}
        onDeleteCallback={refundOrder}
        hasConfirmText={true}
        confirmText={t('REFUND') as 'REFUND'}
        title={t('REFUND ORDER?')}
        description={t(
          "You're about to refund an order. The process will be carried out according to your return and refund policy"
        )}
        projectId={selectedProject?.id}
        orderId={orderInfo?.id}
        cancelTitle={t('Back to Order Details')}
        isLoading={isRefundLoading}
      />
      <OrderEditOptionModal
        isShown={showCancelOrderModal}
        setShown={setShowCancelOrderModal}
        deleteTitle={t('Cancel Order')}
        onCancelCallback={() => setShowCancelOrderModal(false)}
        onDeleteCallback={cancelOrderFromList}
        hasConfirmText={true}
        confirmText={t('CANCEL') as 'CANCEL'}
        title={t('Cancel Order?')}
        intent='danger'
        description={t(
          "You're about to cancel an order. The process will be carried out according to your cancellation policy."
        )}
        projectId={selectedProject?.id}
        orderId={orderInfo?.id}
        cancelTitle={t('Back to Order Details')}
        isLoading={isOrderCancelLoading}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  customerOrderList: state.ecommerce.customerOrderList,
  selectedTicket: state.inbox.selectedTicket,
  isOrderListLoading: state.loading.effects.ecommerce.fetchCustomerOrder,
  selectedProject: state.dashboard.selectedProject,
  isRefundLoading: state.loading.effects.ecommerce.refundOrder,
  isOrderCancelLoading: state.loading.effects.ecommerce.cancelOrderFromList,
});
const mapDispatch = (dispatch: any) => ({
  fetchCustomerOrder: (
    payload: { customerId: number; orderId: number } | { customerId: number }
  ) => dispatch.ecommerce.fetchCustomerOrder(payload),
  sendMessegengerChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => dispatch.inbox.sendMessegengerChannelMessage(requestBody),
  refundOrder: (projectId: number, orderId: string) =>
    dispatch.ecommerce.refundOrder({
      projectId,
      orderId,
    }),
  cancelOrderFromList: (projectId: number, orderId: string) =>
    dispatch.ecommerce.cancelOrderFromList({
      projectId,
      orderId,
    }),
});

export default connect(mapState, mapDispatch)(OrderDetails);
