import {
  axios,
  config,
  format,
  useParams,
  useDispatch,
  useSelector,
  GLOBAL_UTILITY_METHODS,
} from '../export';

import { IFormEntryPayload, TFilterData } from '../interface';

const useDatalab = () => {
  const dispatch = useDispatch();
  const { projectId, labId } = useParams();

  const dashboardData = useSelector((state: any) => state?.dashboard);

  const datalabEntries = useSelector(
    (state: any) => state?.datalabList?.datalabEntries
  );

  const datalabListData = useSelector((state: any) => state?.datalabList);

  const {
    dateRange,
    pageChange,
    totalRowCount,
    isFilterApplied,
    storedFilterData,
    selectedDatalabInfo,
    appliedFilterView,
  } = datalabListData;

  const { permission } = dashboardData;

  const { lab_fields } = selectedDatalabInfo;

  const { fetchDataLabInfo: isDatalabLoading } = useSelector(
    (state: any) => state.loading.effects.datalabList
  );

  const submitDatalabNewEntries = async ({
    formId,
    payload,
  }: {
    formId: number;
    payload: any;
  }) => {
    const result = await axios.post(
      config.datalab.datalabCreateEntry(formId),
      payload
    );
    dispatch.datalabList.fetchDatalabList({
      projectId,
      labId,
      dateRange,
      pageChange,
      filterOptions: [],
    });
    return result;
  };

  const updateDatalabPageChange = async (newLimit = 10, offset = 0) => {
    const pageChange = {
      limit: newLimit,
      offset: offset,
    };
    dispatch.datalabList.fetchDatalabList({
      projectId,
      labId,
      dateRange,
      pageChange,
      filterOptions: [],
    });
  };

  const updateDateRange = (start: Date, end: Date) => {
    const formattedStart = format(start, 'yyyy-MM-dd');
    const formattedEnd = format(end, 'yyyy-MM-dd');
    const changedDateRange = {
      start: formattedStart,
      end: formattedEnd,
    };
    dispatch.datalabList.updateDateRange(changedDateRange);
  };

  const updateDateRangeFilter = (start: Date, end: Date) => {
    const formattedStart = format(start, 'yyyy-MM-dd');
    const formattedEnd = format(end, 'yyyy-MM-dd');
    const changedDateRange = {
      start: formattedStart,
      end: formattedEnd,
    };
    dispatch.datalabList.updateDateRange(changedDateRange);
    dispatch.datalabList.fetchDatalabList({
      projectId,
      labId,
      dateRange: changedDateRange,
      pageChange,
      filterOptions: [],
    });
  };

  const updateDatalabEntry = async ({
    formId,
    fieldId,
    payload,
  }: {
    formId: number;
    fieldId: string;
    payload: any;
  }) => {
    const result = await axios.put(
      config.datalab.updateCreateEntry(formId, fieldId),
      payload
    );
    dispatch.datalabList.fetchDatalabList({
      projectId,
      labId,
      dateRange,
      pageChange,
      filterOptions: [],
    });
    return result;
  };

  const fetchDataLabInfo = () => {
    dispatch.datalabList.fetchDatalabList({
      projectId,
      labId,
      dateRange,
      pageChange,
      filterOptions: [],
    });
  };

  const fetchDatalabInfoAndEntries = ({
    dateRange,
    pageChange,
  }: IFormEntryPayload) => {
    dispatch.datalabList.fetchDataLabInfo({
      projectId,
      labId,
    });

    dispatch.datalabList.fetchDatalabList({
      projectId,
      labId,
      dateRange,
      pageChange,
      filterOptions: [],
    });
  };

  const fetchDatalabEntries = (
    filterOptions: TFilterData[] = [],
    updatedPage: { limit: number; offset: number } = pageChange
  ) => {
    let response = dispatch.datalabList.fetchDatalabList({
      projectId,
      labId,
      dateRange,
      pageChange: updatedPage,
      filterOptions,
    });
    return response;
  };

  const updatePageChange = (limit: number, offset: number) => {
    dispatch.datalabList.updatePageChange({
      limit,
      offset,
    });
  };

  const fetchExportDatalabData = async (labId: number, dateRange: any) => {
    const res = await dispatch.datalabList.fetchExportDatalabList({
      labId,
      dateRange,
    });
    return res;
  };

  const importDatalabFile = async (labId: number, file: File) => {
    const result = await dispatch.datalabList.importDatalabList({
      labId,
      file,
    });
    return result;
  };

  const deleteBulkDatalabList = async (labId: string, rowIds: string[]) => {
    try {
      await dispatch.datalabList.deleteDatalabList({
        labId,
        data: rowIds,
      });
    } catch (error) {
      throw error;
    }
  };

  const updateFilterData = (updatedFilterData: any) => {
    dispatch.datalabList.updateFilterData(updatedFilterData);
  };

  const clearState = () => {
    dispatch.datalabList.clearState();
    return;
  };

  const updateIsFilterState = (updatedData: boolean) => {
    dispatch.datalabList.updateIsFilterState(updatedData);
  };

  // Start: Data lab filter api actions

  const updateAppliedFilterView = (filterData: any) => {
    dispatch.datalabList.updateAppliedFilterView(filterData);
  };

  // GET
  const fetchDatalabFilterData = () => {
    dispatch.datalabList.fetchFilterViewData(labId);
  };

  // POST
  const createDatalabFilter = (data: any) => {
    dispatch.datalabList.createFilterView({ labId, data });
  };

  // PUT
  const updateDatalabFilter = (filterId: any, data: any) => {
    dispatch.datalabList.updateFilterViewData({ labId, filterId, data });
  };

  // DELETE
  const deleteDatalabFilter = (filterId: number) => {
    dispatch.datalabList.deleteFilterViewData({ labId, filterId });
  };

  // End: Data lab filter api actions

  const hasWriteAccess = GLOBAL_UTILITY_METHODS.checkPermission(
    permission?.list,
    'write:data_lab'
  );

  const hasDeleteAccess = GLOBAL_UTILITY_METHODS.checkPermission(
    permission?.list,
    'delete:data_lab'
  );

  const hasReadAccess = GLOBAL_UTILITY_METHODS.checkPermission(
    permission?.list,
    'read:data_lab'
  );

  return {
    labId,
    lab_fields,
    updateDateRange,
    totalRowCount,
    clearState,
    pageChange,
    dateRange,
    projectId,
    deleteBulkDatalabList,
    importDatalabFile,
    fetchExportDatalabData,
    fetchDataLabInfo,
    isDatalabLoading,
    datalabEntries,
    updatePageChange,
    updateDatalabEntry,
    selectedDatalabInfo,
    updateDateRangeFilter,
    submitDatalabNewEntries,
    updateDatalabPageChange,
    fetchDatalabInfoAndEntries,
    fetchDatalabEntries,
    updateIsFilterState,
    isFilterApplied,
    updateFilterData,
    storedFilterData,
    hasReadAccess,
    hasWriteAccess,
    hasDeleteAccess,

    // Data lab filter api actions
    appliedFilterView,
    updateAppliedFilterView,
    fetchDatalabFilterData,
    createDatalabFilter,
    updateDatalabFilter,
    deleteDatalabFilter,
  };
};

export default useDatalab;
