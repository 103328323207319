// Shorten the string
export const truncate = (str: string, n = 15) =>
  str.length > n ? `${str.substr(0, n)}...` : str;

export const platinum_color = '#E4E4E7';
export const tertiary_color = '#A1A1AA';

export const nextRandomId = () => {
  const currentTimestamp = new Date().getTime();

  return `random_${currentTimestamp + Math.floor(Math.random() * 1000)}`;
};
