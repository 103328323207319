import React from 'react';
import { connect } from 'react-redux';
import EcommerceCard from './components/EcommerceCard';
import { IEcommerceChannelProperty } from 'pages/integration/interface';

interface Props {
  integratedEcommerceData: IEcommerceChannelProperty;
}

const EcommerceIndex: React.FC<Props> = ({ integratedEcommerceData }) => {
  return (
    <div>
      <EcommerceCard elementData={integratedEcommerceData} />
    </div>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(EcommerceIndex);
