import { useSelector, useDispatch } from 'react-redux';
import { NodeType } from './interface';

type primitiveDataType = null | string | number | boolean;

const useAutomationWorkflow = () => {
  const dispatch = useDispatch();
  const workflowAutomationData = useSelector(
    (state: any) => state.workflowAutomation
  );

  const isViewLog: boolean =
    workflowAutomationData.automationFlowDisplayViewType === 'viewLog';

  const isValidNodeType = (): boolean => {
    let selectedNodeType = workflowAutomationData?.selectedNodeType;
    return Object.values(NodeType).includes(selectedNodeType as NodeType);
  };

  const updatePrimitiveStateData = (updatedData: {
    key: string;
    value: primitiveDataType;
  }) => {
    dispatch.workflowAutomation.updatePrimitiveStateData(updatedData);
  };

  const clearStateData = () => {
    dispatch.workflowAutomation.clearState();
  };

  const handleSideSheetClose = () => {
    updatePrimitiveStateData({ key: 'showSlider', value: false });
    updatePrimitiveStateData({ key: 'selectedNodeType', value: null });
  };

  return {
    isViewLog,
    clearStateData,
    isValidNodeType,
    handleSideSheetClose,
    updatePrimitiveStateData,
    ...workflowAutomationData,
  };
};

export default useAutomationWorkflow;
