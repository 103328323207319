import React from 'react';
import { connect } from 'react-redux';
import CustomerInformation from './CustomerInformation';
import OrderSummary from './OrderSummary';
import ConfigShipping from './ConfigShipping';
import {
  WooCommerceAPIErrorInterface,
  ICustomerCartInfo,
  ResponseModalDataInterface,
  OrderFormInformationType,
} from 'pages/inbox/inboxInterface';
import { getCurrentOrderFormInformationValue } from 'pages/inbox/utils/functions';
import useTranslation from 'components/customHooks/useTranslation';

interface PaymentMethodProps {
  description: string;
  is_enabled: boolean;
  method_id: string;
  method_title: string;
}

interface ShippingMethodProps {
  method_id: string;
  method_title: string;
}

interface Props {
  projectId: number;
  currencySymbol: string;
  customerCart: ICustomerCartInfo;
  responseModalData: ResponseModalDataInterface;
  orderFormInformation: OrderFormInformationType;
  sendCheckoutLink: (data: string) => void;
  setShowRightbar: (value: boolean) => void;
  setOpenResponseModal: (data: boolean) => void;
  setShowResponseModalLoader: (data: boolean) => void;
  updateEcommerceStateData: (key: string, value: any) => void;
  fetchWoocommerceCoupons: (projectId: number) => Promise<void>;
  setResponseModalData: (data: ResponseModalDataInterface) => void;
  fetchPaymentMethods: (
    projectId: number
  ) => Promise<PaymentMethodProps[] | WooCommerceAPIErrorInterface>;
  fetchShippingMethods: (
    projectId: number
  ) => Promise<ShippingMethodProps[] | WooCommerceAPIErrorInterface>;
  updateOrderInformationState: (payload: {
    key: number | string;
    data: any;
  }) => void;
}

const CreateWooCommerceOrder: React.FC<Props> = ({
  projectId,
  customerCart,
  currencySymbol,
  responseModalData,
  orderFormInformation,
  setShowRightbar,
  sendCheckoutLink,
  fetchPaymentMethods,
  fetchShippingMethods,
  setOpenResponseModal,
  setResponseModalData,
  fetchWoocommerceCoupons,
  updateEcommerceStateData,
  setShowResponseModalLoader,
  updateOrderInformationState,
}) => {
  const { t } = useTranslation();
  const initialOrderInformation = {
    paymentMethod: '',
    deliveryType: 'Free shipping',
    couponOrDiscount: '',
    wooCommerceSubTotal: 0,
    wooCommerceDiscount: 0,
    wooCommerceShippingCost: 0,
    wooCommerceTotal: 0,
  };

  const initialWooCommerceOrderInformation = {
    customer_id: customerCart?.customer_id,
    billing_address: customerCart?.billing_address,
    shipping_address: customerCart?.shipping_address,
    wooCommerceOrderInformation: initialOrderInformation,
    sameAsShippingAddress: true,
  };
  const [step, setStep] = React.useState(0);
  const [wooCommerceOrderFormInformation, setWooCommerceOrderInformation] =
    React.useState<OrderFormInformationType>(
      initialWooCommerceOrderInformation
    );
  const initialCustomerBillingInformation = customerCart?.billing_address;

  const handleSetWooCommerceObjectInformation = (data: {
    objectKeyName: string;
    childObjectKeyName: string;
    value: any;
  }) => {
    let currentOrderInformation = getCurrentOrderFormInformationValue(
      orderFormInformation,
      customerCart?.customer_id
    );

    let updatedData = {
      ...currentOrderInformation[data.objectKeyName],
      [data.childObjectKeyName]: data.value,
    };

    if (
      data.objectKeyName === 'shipping_address' &&
      wooCommerceOrderFormInformation.sameAsShippingAddress
    ) {
      handleSetWooCommercePropertyInformation([
        {
          key: 'shipping_address',
          value: updatedData,
        },
        {
          key: 'billing_address',
          value: updatedData,
        },
      ]);
    } else {
      updateOrderInformationState({
        key: customerCart.customer_id,
        data: {
          ...currentOrderInformation,
          [data.objectKeyName]: updatedData,
        },
      });

      setWooCommerceOrderInformation({
        ...currentOrderInformation,
        [data.objectKeyName]: updatedData,
      });
    }
  };

  // updateEcommerceStateData('orderFormInformation', {});

  const handleSetWooCommercePropertyInformation = (
    data: {
      key: string;
      value: any;
    }[]
  ) => {
    let updatedData = wooCommerceOrderFormInformation;
    data.forEach((item) => {
      updatedData[item.key] = item.value;
    });

    updateOrderInformationState({
      key: customerCart.customer_id,
      data: updatedData,
    });

    setWooCommerceOrderInformation(updatedData);
  };

  const fetchOrderMethods = async () => {
    await fetchPaymentMethods(projectId);
    await fetchShippingMethods(projectId);
    await fetchWoocommerceCoupons(projectId);
  };

  React.useEffect(() => {
    fetchOrderMethods();

    if (!orderFormInformation?.hasOwnProperty(customerCart?.customer_id)) {
      let data: OrderFormInformationType = {
        ...customerCart,
      };
      data.sameAsShippingAddress = true;
      data.wooCommerceOrderInformation = initialOrderInformation;

      updateOrderInformationState({
        key: customerCart.customer_id,
        data: data,
      });
    } else {
      setWooCommerceOrderInformation(
        getCurrentOrderFormInformationValue(
          orderFormInformation,
          customerCart?.customer_id
        )
      );
    }
    // eslint-disable-next-line
  }, []);

  const modalHeaderWrapper = (title: React.ReactNode) => {
    return (
      <div className='flex space-between p-3 h-[6.5vh]'>
        <p className='text-base font-semibold'>{title}</p>
        <button
          className='text-base font-semibold text-gray-700'
          onClick={() => {
            updateEcommerceStateData('showDiscardAlert', true);
          }}
        >
          &#x2715;
        </button>
      </div>
    );
  };

  const renderModalHeaderView = (step: number) => {
    switch (step) {
      case 2:
        return modalHeaderWrapper(
          <p>
            {t('Create Order')}&nbsp;&nbsp;
            <span className='bg-gray-100 text-sm font-medium text-gray-800 p-1 rounded'>
              2/3
            </span>
          </p>
        );
      case 3:
        return modalHeaderWrapper(
          <p>
            {t('Order Summary')}&nbsp;&nbsp;
            <span className='bg-gray-100 text-sm font-medium text-gray-800 p-1 rounded'>
              3/3
            </span>
          </p>
        );
      default:
        return modalHeaderWrapper(
          <p>
            {t('Create Order')}&nbsp;&nbsp;
            <span className='bg-gray-100 text-sm font-medium text-gray-800 p-1 rounded'>
              1/3
            </span>
          </p>
        );
    }
  };

  const renderWooCommerceOrderAmountsView = (
    step: number,
    orderInformation = wooCommerceOrderFormInformation.wooCommerceOrderInformation
  ) => {
    let discountTextColor = !!orderInformation.wooCommerceDiscount
      ? 'text-[#DC2626]'
      : 'text-gray-900';

    return (
      <div
        className={`${
          step === 3
            ? 'border border-transparent border-t-gray-200 py-3'
            : 'pb-3'
        } text-xs leading-5 flex flex-col gap-2 text-gray-500`}
      >
        <p className='flex space-between'>
          <span>{t('Subtotal')}</span>
          <span className='font-xs leading-4 text-gray-900'>
            {currencySymbol}
            {orderInformation.wooCommerceSubTotal
              ? orderInformation.wooCommerceSubTotal
              : 0}
          </span>
        </p>
        <p className='flex space-between'>
          <span>Shipping</span>
          <span className='font-xs leading-4 text-gray-900'>
            {currencySymbol}
            {orderInformation.wooCommerceShippingCost
              ? orderInformation.wooCommerceShippingCost
              : 0}
          </span>
        </p>
        <p className={`flex space-between ${step === 2 ? ' pb-2' : ''}`}>
          <span>{t('Total Discount')}</span>
          <span className={`${discountTextColor} font-xs leading-4`}>
            {!!orderInformation.wooCommerceDiscount && (
              <>
                -{currencySymbol}
                {orderInformation.wooCommerceDiscount}
              </>
            )}
            {!orderInformation?.wooCommerceDiscount && `${currencySymbol}0`}
          </span>
        </p>
        <p
          className={`flex space-between ${
            step === 2 ? 'border border-transparent border-t-gray-200 pt-3' : ''
          }`}
        >
          <span className='text-gray-900 text-xs font-normal'>
            {t('Total')}
          </span>
          <span
            className={`text-[#038E4C] font-semibold leading-4 ${
              step === 2 ? 'text-sm' : 'text-xs'
            }`}
          >
            {currencySymbol}
            {orderInformation.wooCommerceTotal
              ? orderInformation.wooCommerceTotal.toFixed(2)
              : 0}
          </span>
        </p>
      </div>
    );
  };

  const showProductStep = (step: number) => {
    switch (step) {
      case 2:
        return (
          <ConfigShipping
            orderInformation={
              wooCommerceOrderFormInformation?.wooCommerceOrderInformation
            }
            setStep={setStep}
            setShowRightbar={setShowRightbar}
            modalHeader={renderModalHeaderView(2)}
            renderWooCommerceOrderAmountsView={
              renderWooCommerceOrderAmountsView
            }
            handleSetWooCommerceObjectInformation={
              handleSetWooCommerceObjectInformation
            }
            handleSetWooCommercePropertyInformation={
              handleSetWooCommercePropertyInformation
            }
          />
        );
      case 3:
        return (
          <OrderSummary
            orderInformation={
              wooCommerceOrderFormInformation?.wooCommerceOrderInformation
            }
            billingInformation={
              wooCommerceOrderFormInformation?.billing_address
            }
            shippingInformation={
              wooCommerceOrderFormInformation?.shipping_address
            }
            sameAsShippingAddress={
              wooCommerceOrderFormInformation?.sameAsShippingAddress
            }
            responseModalData={responseModalData}
            sendCheckoutLink={sendCheckoutLink}
            setOpenResponseModal={setOpenResponseModal}
            setResponseModalData={setResponseModalData}
            setShowResponseModalLoader={setShowResponseModalLoader}
            setStep={setStep}
            setShowRightbar={setShowRightbar}
            modalHeader={renderModalHeaderView(3)}
            renderWooCommerceOrderAmountsView={
              renderWooCommerceOrderAmountsView
            }
          />
        );
      default:
        return (
          <CustomerInformation
            billingInformation={
              wooCommerceOrderFormInformation?.billing_address
            }
            shippingInformation={
              wooCommerceOrderFormInformation?.shipping_address
            }
            sameAsShippingAddress={
              wooCommerceOrderFormInformation?.sameAsShippingAddress
            }
            initialCustomerBillingInformation={
              initialCustomerBillingInformation
            }
            setStep={setStep}
            setShowRightbar={setShowRightbar}
            modalHeader={renderModalHeaderView(1)}
            handleSetWooCommerceObjectInformation={
              handleSetWooCommerceObjectInformation
            }
            handleSetWooCommercePropertyInformation={
              handleSetWooCommercePropertyInformation
            }
          />
        );
    }
  };
  return (
    <div className='h-full w-[23.6%] fixed ltr:right-0 rtl:left-0 bg-white '>
      <div>
        {!!wooCommerceOrderFormInformation ? showProductStep(step) : ''}
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  projectId: state.dashboard?.selectedProject?.id || null,
  customerCart: state.ecommerce.cart,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
  orderFormInformation: state.ecommerce.orderFormInformation,
});

const mapDispatch = (dispatch: any) => ({
  updateEcommerceStateData: (key: string, value: any) =>
    dispatch.ecommerce.updateEcommerceStateData({ key, value }),
  fetchPaymentMethods: (projectId: number) =>
    dispatch.ecommerce.fetchPaymentMethods({ projectId }),
  fetchShippingMethods: (projectId: number) =>
    dispatch.ecommerce.fetchShippingMethods({ projectId }),
  fetchWoocommerceCoupons: (projectId: number) =>
    dispatch.ecommerce.fetchWoocommerceCoupons({ projectId }),
  updateOrderInformationState: (payload: { key: number | string; data: any }) =>
    dispatch.ecommerce.updateOrderFormInformation(payload),
});

export default connect(mapState, mapDispatch)(CreateWooCommerceOrder);
