import useTranslation from 'components/customHooks/useTranslation';
import { DragDropIcon } from 'pages/datalab/export';
import BlockList from './blockList';

const FormEditor = () => {
  const { t } = useTranslation();

  return (
    <div className='row-span-2 col-span-2 mt-6 w-full h-full'>
      <BlockList />
      <div
        className='flex justify-center items-center rounded border-dashed border-2 border-gray-300 mt-4'
        style={{ width: '580px', height: '52px' }}
      >
        <DragDropIcon />
        <span className='text-sm text-zinc-500 ml-2 rtl:order-first'>
          {t('Drag items from left bar')}
        </span>
      </div>
    </div>
  );
};

export default FormEditor;
