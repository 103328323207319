import React from 'react';
import { LinearLoaderComponent } from '../../../../utilities/utils';
import TableHeader from '../CommonComponent/TableHeader';
import EmptyTableCellsIcon from '../../assets/images/EmptyTableIcon.svg';
import useTranslation from 'components/customHooks/useTranslation';

interface props {
  loader: boolean;
  tableData: tableDataProps[];
  hasEcommerceConnected: boolean;
  currency: string;
}

interface tableDataProps {
  agent_name: string;
  total_resolved: string;
  total_assigned: string;
  average_resolution_time: string;
  average_response_time: string;
  average_initial_response_time: string;
  total_orders_placed: string;
  total_sales_value: string;
  average_csat: string;
}

const AgentDetailsTableData: React.FC<props> = ({
  tableData,
  loader,
  hasEcommerceConnected,
  currency,
}) => {
  const { t } = useTranslation();
  const headerShow = [
    'Agent name',
    'Assigned/Replied/Resolved',
    'Avg. Resolution Time',
    'Avg. Response Time',
    'Avg. First Response Time',
    'avg. csat',
  ];
  const headerTitle = !hasEcommerceConnected
    ? headerShow
    : [...headerShow, 'Number of Orders', 'Total sales'];
  return (
    <div className='flex flex-col'>
      <div className='-my-2'>
        <div className='inline-block min-w-full py-2 align-middle'>
          {tableData?.length !== 0 && (
            <>
              <table className='min-w-full min-h-full border-t border-b divide-y divide-gray-200'>
                <TableHeader data={headerTitle} />
                {loader && <LinearLoaderComponent />}
                <tbody className='divide-y divide-gray-200'>
                  {!!tableData &&
                    tableData.map((details: any, index: number) => (
                      <tr key={index}>
                        <td className='px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap'>
                          {details?.agent_name}
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                          {details?.total_assigned} / {details?.total_replied} /{' '}
                          {details?.total_resolved}
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-900 whitespace-nowrap'>
                          {details?.average_resolution_time
                            ? details.average_resolution_time
                            : '--'}
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-900 whitespace-nowrap'>
                          {details?.average_response_time
                            ? details.average_response_time
                            : '--'}
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-900 whitespace-nowrap'>
                          {details?.average_initial_response_time
                            ? details.average_initial_response_time
                            : '--'}
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-900 whitespace-nowrap'>
                          {details?.average_csat ? details.average_csat : '--'}
                        </td>
                        {hasEcommerceConnected && (
                          <td className='px-6 py-4 text-sm text-gray-900 whitespace-nowrap'>
                            {details.total_orders_placed}
                          </td>
                        )}
                        {hasEcommerceConnected && (
                          <td className='px-6 py-4 text-sm text-gray-900 whitespace-nowrap'>
                            {currency}
                            {details.total_sales_value}
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
          {!loader && tableData.length === 0 && (
            <div className='flex flex-col items-center justify-center min-w-full min-h-full p-8 bg-white border-l border-r shadow'>
              <img
                src={EmptyTableCellsIcon}
                alt={'empty table'}
                className={'w-32 mt-8'}
              />
              <p className='mt-8 mb-8 font-sans text-sm font-normal'>
                {t('No data available for this timeline')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentDetailsTableData;
