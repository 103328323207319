import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  responseData: CannedresponsePayloadType | null;
  setResponseData: (payload: CannedresponsePayloadType) => void;
}

const DropDown: React.FC<Props> = ({ setResponseData, responseData }) => {
  const { t } = useTranslation();
  const visibles = ['Everyone', 'Only me'];

  return (
    <div className=' w-full'>
      <Listbox
        value={t(responseData?.for_team === true ? 'Everyone' : 'Only me')}
        onChange={(value) => {
          //  @ts-ignore
          if (value === 'Everyone') {
            //  @ts-ignore
            setResponseData({ ...responseData, for_team: true });
          } else {
            //  @ts-ignore
            setResponseData({ ...responseData, for_team: false });
          }
        }}
      >
        <div className=' mt-1'>
          <Listbox.Button
            className='relative w-full cursor-default rounded-lg
          bg-white py-2 ltr:pl-3 ltr:pr-10 rtl:pl-10 rtl:pr-3 ltr:text-left rtl:text-right border border-gray-200 focus:border-2 focus:border-green-500 focus:outline-none
 sm:text-sm'
          >
            <span className='block truncate'>
              {t(responseData?.for_team === true ? 'Everyone' : 'Only me')}
            </span>
            <span className='pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-2 rtl:pl-2'>
              <ChevronDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options
              className='absolute w-[95.5%] max-h-60 overflow-auto
              rounded-md bg-white py-1 text-base border border-gray-200
              focus:outline-none sm:text-sm z-10'
            >
              {visibles &&
                visibles.map((visible, visibleIdx: number) => (
                  <Listbox.Option
                    key={visibleIdx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-3 pr-4 ${
                        active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                      }`
                    }
                    value={visible}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate text-sm ${
                            selected ? 'font-semibold' : 'font-normal'
                          }`}
                        >
                          {t(visible)}
                        </span>
                        {selected ? (
                          <span className='absolute inset-y-0 right-0 flex items-center mr-2.5 text-amber-600'>
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default DropDown;
