import { useRef } from 'react';
import { DragTypes, FormFields } from 'pages/datalab/export';
import { useDrag } from 'react-dnd';
import { IDataLabField } from 'pages/datalab/interface';
interface IProps {
  field: IDataLabField;
  index: number;
  type?: string;
}

const BlockEdit: React.FC<IProps> = ({
  field,
  index,
  type = DragTypes.BLOCK_FIELD,
}) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type,
    item: { field, index, type },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (!field || !field.type) {
    return null;
  }

  const defaultFormField = FormFields[field.type] ?? FormFields['text'];

  const { Component } = defaultFormField;

  if (!Component && !defaultFormField) {
    return null;
  }

  drag(ref);

  return (
    <Component
      field={field}
      nodeRef={ref}
      isDragging={isDragging}
      isSmall={type === DragTypes.GROUP_FIELD}
    />
  );
};

export default BlockEdit;
