import React, { useState } from 'react';
import { WhatsappTemplateActionType } from '../../components/CreateWhatsAppTemplateV2';
import {
  channelListProps,
  sequenceProps,
  IWhatsappTemplateButton,
} from 'pages/integration/interface';
import { PlusIcon } from '@heroicons/react/24/solid';
import WhatsappTemplateButton from './WhatsappTemplateButton';
import useWhatsappTemplateData, {
  IWhatsappCarouselCard,
} from 'pages/integration/hooks/useWhatsappTemplateData';
import {
  CarouselButtonDeleteTooltip,
  CarouselButtonTypeSelectTooltip,
  CarouselTemplateButtonLimits,
} from 'pages/integration/utils/content';
import useTranslation from 'components/customHooks/useTranslation';
import { cn } from 'libraryV2/utils';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'libraryV2/ui/dialog';
import { Button } from 'libraryV2/ui/button';

interface Props {
  actionType: WhatsappTemplateActionType;
  sequence: sequenceProps[];
  channelData?: channelListProps;
  activeCard: IWhatsappCarouselCard;
}

const CarouselTemplateButtonBlock: React.FC<Props> = ({
  actionType,
  sequence,
  activeCard,
}) => {
  const { t } = useTranslation();
  const [deleteButtonData, setDeleteButtonData] = useState<{
    openDeleteModal: boolean;
    buttonId: number | null;
  }>({ openDeleteModal: false, buttonId: null });
  const {
    addCarouselTemplateButton,
    updateSingleCarouselCardData,
    deleteCarouselCardButton,
  } = useWhatsappTemplateData();

  const buttonList: IWhatsappTemplateButton[] = activeCard?.buttons || [];

  const isTemplateActionView = actionType === 'VIEW';
  const isAddButtonDisabled =
    activeCard.buttons.length >=
    CarouselTemplateButtonLimits.maxTotalButtonLimit;

  const shouldHideAddButton =
    isAddButtonDisabled || isTemplateActionView || activeCard.id !== 1;

  const shouldHideButtonSectionDescription =
    isTemplateActionView && buttonList.length === 0;

  const handleAddButton = () => {
    addCarouselTemplateButton(activeCard!);
  };

  const handleCarouselButtonChange = (button: IWhatsappTemplateButton) => {
    const targetButton = activeCard.buttons.map((c) => {
      if (c.id === button.id) {
        return button;
      }
      return c;
    });
    updateSingleCarouselCardData({ ...activeCard, buttons: targetButton });
  };

  const toggleDeleteButtonModal = (
    button?: IWhatsappTemplateButton | undefined
  ) => {
    if (button !== undefined && !deleteButtonData.openDeleteModal) {
      setDeleteButtonData({ openDeleteModal: true, buttonId: button?.id });
    } else {
      setDeleteButtonData({ openDeleteModal: false, buttonId: null });
    }
  };

  const handleDeleteButton = () => {
    const buttonId = deleteButtonData.buttonId;
    if (buttonId) {
      deleteCarouselCardButton(buttonId);
    }
    toggleDeleteButtonModal();
  };

  const renderDeleteButtonModal = () => {
    return (
      <Dialog open={deleteButtonData.openDeleteModal}>
        <DialogContent
          hideCloseButton={true}
          className='sm:max-w-[425px] bg-white'
        >
          <DialogTitle className='text-zinc-900 text-lg font-semibold leading-7'>
            Delete Button
          </DialogTitle>

          <p className='text-zinc-500 text-sm font-normal'>
            Are you sure you want to delete button? This action cannot be undone
            and will permanently remove all the same type of button in the
            subsequent cards.
          </p>

          <DialogFooter>
            <Button
              onClick={() => toggleDeleteButtonModal()}
              type='button'
              variant='default'
              className='bg-[#F4F4F5] text-zinc-900 shadow-none hover:bg-[#F4F4F5]'
            >
              {t('No')}
            </Button>

            <Button
              onClick={() => handleDeleteButton()}
              type='button'
              variant='destructive'
              className='bg-[#FF0000] text-white text-sm font-medium'
            >
              {t('Yes! Delete')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div
      className={`bg-white mt-2 shadow-sm rounded-md flex flex-col ${
        shouldHideButtonSectionDescription ? 'hidden' : 'visible'
      }`}
    >
      <div className='flex gap-1 flex-col'>
        <h3 className='block text-sm font-medium text-gray-700'>
          {t('Button Properties')}
        </h3>
        <p className='leading-5 font-normal text-sm text-gray-600'>
          {t(
            `You have to add at least 1 button and you can add up to two buttons. Once you add buttons in the first card, you can't change button type or delete button from subsequent cards.`
          )}
        </p>
      </div>
      <div className={`${buttonList.length ? 'mt-4' : 'hidden'}`}>
        <div className='flex flex-col gap-3'>
          {buttonList?.map((singleButton) => (
            <div className='w-full' key={activeCard.id + singleButton.id}>
              <WhatsappTemplateButton
                actionType={actionType}
                sequenceList={sequence}
                buttonList={activeCard.buttons}
                buttonData={singleButton}
                buttonLimits={CarouselTemplateButtonLimits}
                handleUpdateButton={handleCarouselButtonChange}
                handleDeleteButton={toggleDeleteButtonModal}
                shouldDisableButtonVariable={true}
                shouldShowSelectMenuToolTip={activeCard.id !== 1}
                selectMenuTooltip={CarouselButtonTypeSelectTooltip}
                showSelectMenuSublabel={false}
                shouldDisableSelectMenu={activeCard.id !== 1}
                shouldDisableDeleteButton={activeCard.id !== 1}
                deleteButtonTooltip={
                  activeCard.id === 1 ? '' : CarouselButtonDeleteTooltip
                }
                allowedButtonTypes={
                  singleButton.id === 1
                    ? ['url', 'phone_number', 'sequence']
                    : ['sequence']
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className={cn(`mt-4`, shouldHideAddButton ? 'hidden' : 'block')}>
        <button
          onClick={handleAddButton}
          disabled={isAddButtonDisabled}
          className={`px-3 py-2 flex gap-2 items-center border rounded-md border-gray-300 ${
            isAddButtonDisabled
              ? 'cursor-not-allowed bg-gray-200 text-gray-500'
              : ''
          }`}
        >
          <PlusIcon className='w-4 h-4 text-gray-500 font-bold stroke-2' />
          <span className='text-sm font-medium text-gray-700'>
            {t('Add Buttons')}
          </span>
        </button>
      </div>
      {deleteButtonData.openDeleteModal ? renderDeleteButtonModal() : null}
    </div>
  );
};
export default CarouselTemplateButtonBlock;
