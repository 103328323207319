import useTranslation from 'components/customHooks/useTranslation';
import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { cn } from 'libraryV2/utils';
import React from 'react';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

interface Props {
  time: number;
  source?: string;
  isMerged: boolean;
  isFeedPost?: boolean;
  isFeedComment?: boolean;
  conversationType?: string;
}

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'Just now',
    m: 'Just now',
    mm: '%dm',
    h: '1 h',
    hh: '%d h',
    d: '1 d',
    dd: '%d d',
    M: '1 mo',
    MM: '%d mo',
    y: '1 y',
    yy: '%d y',
  },
});

const Time: React.FC<Props> = ({
  time,
  isMerged,
  source = 'customer',
  isFeedPost = false,
  isFeedComment = false,
  conversationType = 'text',
}) => {
  const { dashboardLanguage, isRtlLanguage } = useTranslation();
  const isAdminReply = ['admin', 'echo', 'bot'].includes(source);
  const isArabic = dashboardLanguage === 'arabic';
  const timeLocaleFormat = isArabic ? 'ar' : 'en';

  const formatTime = (timestamp: number) => {
    if (isArabic) {
      const arabicTime = dayjs(timestamp).locale('ar').format('h:mm A');
      return arabicTime.replace('AM', 'ص').replace('PM', 'م');
    }
    return dayjs(timestamp).locale('en').format('h:mm A');
  };

  const formatRelativeTime = (timestamp: number) => {
    return dayjs(timestamp).locale(timeLocaleFormat).fromNow(true);
  };

  return (
    <>
      {!isMerged && !isFeedPost && !isFeedComment && (
        <p
          data-testid='conversation-block-time'
          className={cn(
            `w-auto h-auto font-normal rtl:order-1 p-[0px] sm:text-[10px] text-gray-500 flex justify-start items-center flex-shrink-0 relative whitespace-nowrap z-[9]`,
            conversationType === 'attachment' &&
              'absolute bottom-1 right-6 mt-0 text-white',
            conversationType === 'attachment' && !isAdminReply
              ? 'w-full'
              : 'w-auto',
            conversationType === 'attachment' &&
              !isAdminReply &&
              isRtlLanguage &&
              'right-3'
          )}
        >
          {formatTime(time)}
        </p>
      )}
      {!!isMerged && !isFeedPost && !isFeedComment && (
        <p
          data-testid='conversation-block-time'
          className={`content-center invisible rtl:order-1 w-16 h-auto px-6 font-normal sm:text-xs group-hover:visible ${
            isAdminReply ? 'text-white' : 'text-gray-500'
          }`}
        >
          {formatTime(time)}
        </p>
      )}
      {!!isFeedPost && <>{formatRelativeTime(time)}</>}
      {!!isFeedComment && (
        <p
          data-testid='conversation-block-time'
          className='w-auto h-auto mt-2 rtl:order-1 font-normal text-gray-500 sm:text-xs'
        >
          {formatRelativeTime(time)}
        </p>
      )}
    </>
  );
};

export default Time;
