import { axios, config, sortBy } from 'pages/datalab/export';
import { cloneDeep } from 'lodash';
import { toast } from 'libraryV2/ui/use-toast';

const initialState = {
  controller: {
    isLoading: true,
    labId: null,
    projectId: null,
    selectedFieldId: null,
  },
  dataLabData: {
    id: null,
    title: '',
    description: '',
    logo: {
      url: '',
    },
    button: {
      name: '',
      font_color: '#eeeeee',
      background_color: '#007B65',
    },

    lab_fields: [],
    type: 'form',
    webhook_url: '',
  },
  unsavedTrack: {
    isDirty: false, // if the user dirty the form
    field: {},
  }, // in this temp we store current field settings if the user update the field or dirty the field
};

export const datalabFormBuilder = {
  state: { ...initialState },
  reducers: {
    setSelectedField(state, payload) {
      const { controller } = state;
      const { fieldId } = payload;

      return {
        ...state,
        controller: {
          ...controller,
          selectedFieldId: fieldId,
        },
      };
    },

    updateControllerInfo(state, payload) {
      const { controller } = state;

      return {
        ...state,
        controller: {
          ...controller,
          ...payload,
        },
      };
    },

    updateDataLabDataInfo(state, payload) {
      const updatedState = { ...state };

      updatedState.dataLabData = payload;

      // check if there is lab_fields in the payload and if not, sort the fields by serial and set it to the state
      if (payload?.lab_fields && payload.lab_fields.length > 0) {
        updatedState.dataLabData.lab_fields = sortBy(
          payload.lab_fields,
          'serial'
        );
      }

      updatedState.controller = {
        ...state.controller,
        isLoading: false,
        selectedFieldId: null,
      };

      return updatedState;
    },

    updateDataLabFormDataInfo(state, payload) {
      const updatedState = { ...state };

      updatedState.dataLabData = {
        ...state.dataLabData,
        ...payload,
      };

      updatedState.controller = {
        ...state.controller,
        isLoading: false,
        selectedFieldId: null,
      };

      return updatedState;
    },

    updateDataLabData(state, payload) {
      const { dataLabData } = state;

      return {
        ...state,
        dataLabData: {
          ...dataLabData,
          ...payload,
        },
      };
    },

    updateCreateFieldPost(state, payload) {
      let updatedLabFields = cloneDeep(state.dataLabData.lab_fields);

      // For existing fields: check if payload field has parent_id, if yes, we need to add the field to the parent field's children array
      if (payload.parent_id) {
        const parentField = updatedLabFields.find(
          (field) => field.id === payload.parent_id
        );

        if (payload.type === 'group') {
          delete payload.fieldRandomId;
          parentField.children = [...parentField.children, payload];
        } else {
          parentField.children = parentField.children?.map((field) => {
            if (field.id === payload.fieldRandomId) {
              delete payload.fieldRandomId;
              return payload;
            }

            return field;
          });
        }

        // update the parent field in the lab_fields array
        updatedLabFields = updatedLabFields.map((field) => {
          if (field.id === payload.parent_id) {
            return parentField;
          }

          return field;
        });
      } else {
        if (payload.type === 'group') {
          delete payload.fieldRandomId;
          updatedLabFields = [...updatedLabFields, payload];
        } else {
          updatedLabFields = updatedLabFields.map((field) => {
            if (field.id === payload.fieldRandomId) {
              delete payload.fieldRandomId;
              return payload;
            }

            return field;
          });
        }
      }

      return {
        ...state,
        dataLabData: {
          ...state.dataLabData,
          lab_fields: [...updatedLabFields],
        },
        controller: {
          ...state.controller,
          selectedFieldId: payload.parent_id ? payload.parent_id : null,
        },
      };
    },

    addTempFieldToLabFields(state, payload) {
      const updatedDataLab = cloneDeep(state.dataLabData);

      // For existing fields: check if payload field has parent_id, if yes, we need to add the field to the parent field's children array
      if (payload.parent_id) {
        const parentField = updatedDataLab.lab_fields.find(
          (field) => field.id === payload.parent_id
        );

        parentField.children = [...parentField.children, payload];

        // update the parent field in the lab_fields array
        updatedDataLab.lab_fields = updatedDataLab.lab_fields.map((field) => {
          if (field.id === payload.parent_id) {
            return parentField;
          }

          return field;
        });
      } else {
        updatedDataLab.lab_fields = [...updatedDataLab.lab_fields, payload];
      }

      return {
        ...state,
        controller: {
          ...state.controller,
          selectedFieldId: payload.parent_id ? payload.parent_id : payload.id,
        },
        dataLabData: updatedDataLab,
      };
    },

    updateKeyUpdate(state, payload) {
      const currentEditingLabDataLocal = cloneDeep(state.dataLabData);
      currentEditingLabDataLocal.lab_fields =
        currentEditingLabDataLocal.lab_fields.map((elem) => {
          if (elem.id === payload.id) {
            return { ...payload, is_saved: true };
          }
          return { ...elem };
        });

      return {
        ...state,
        dataLabData: currentEditingLabDataLocal,
        controller: {
          ...state.controller,
          selectedFieldId: null,
        },
        unsavedTrack: {
          isDirty: false,
          field: {},
        },
      };
    },

    updateDeleteField(state, payload) {
      let labFields = cloneDeep(state.dataLabData.lab_fields);
      // first, we need find the field with the payload id
      // second, check if the field has parent_id, if yes, we need to remove the field from the parent field's children array

      labFields = labFields.filter((field) => {
        if (field.id !== payload && field.type === 'group') {
          field.children = field.children.filter(
            (child) => child.id !== payload
          );

          return field;
        }

        return field.id !== payload;
      });

      return {
        ...state,
        dataLabData: {
          ...state.dataLabData,
          lab_fields: [...labFields],
        },
        controller: {
          ...state.controller,
          selectedFieldId: null,
        },
      };
    },

    updateFieldOrder(state, payload) {
      const currentEditingLabDataLocal = cloneDeep(state.dataLabData);
      // currentEditingLabDataLocal.lab_fields = sortBy(payload, 'serial');
      const labFields = currentEditingLabDataLocal.lab_fields;

      // check if the payload has parent_id, if yes, we need to update the parent field's children array
      if (payload) {
        // get the parent id from the first element of the payload
        const parentId = payload[0].parent_id;

        // find the parent field from the lab_fields array
        if (parentId) {
          // find the parent field
          const parentField = labFields.find((field) => field.id === parentId);
          parentField.children = sortBy(payload, 'serial');

          // update the parent field in the lab_fields array
          currentEditingLabDataLocal.lab_fields = labFields.map((field) => {
            if (field.id === parentId) {
              return parentField;
            }
            return field;
          });
        } else {
          // if there is no parent_id, we need to sort the lab_fields array
          currentEditingLabDataLocal.lab_fields = sortBy(payload, 'serial');
        }
      }

      return {
        ...state,
        dataLabData: currentEditingLabDataLocal,
      };
    },

    updateUnsaveTracker(state, payload) {
      const { unsavedTrack } = state;
      return {
        ...state,
        unsavedTrack: {
          ...unsavedTrack,
          ...payload,
        },
      };
    },

    clearState() {
      return initialState;
    },
  },

  effects: (dispatch) => ({
    async fetchDataLabInfo(payload) {
      /*
        payload = {
          projectId : num,
          labId: num,
        }
       */

      const { projectId, labId } = payload;
      dispatch.datalabFormBuilder.updateControllerInfo({
        isLoading: true,
      });

      try {
        const res = await axios.get(
          config.datalabFormBuilder.datalabAction(projectId, labId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.datalabFormBuilder.updateDataLabDataInfo(
            res.data.dataSource
          );
          dispatch.datalabFormBuilder.updateControllerInfo({
            projectId,
            labId,
          });
          dispatch.datalabFormBuilder.updateUnsaveTracker({
            isDirty: false,
            field: {},
          });
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Failed to Get Lab Info</p>,
          description: `Request for getting info about lab has been failed. Please try again`,
        });
      }
    },

    async updateDataLabInfo(payload, rootState) {
      /*
      payload = {
        projectId : num,
        labId: num,
      }
       */

      const { dataLabData } = rootState.datalabFormBuilder;
      const { projectId, labId } = payload;

      try {
        const res = await axios.put(
          config.datalabFormBuilder.datalabAction(projectId, labId),
          dataLabData
        );
        if (res.status === 200 && res.data.success) {
          toast({
            title: <p className='text-green-500'>Updated Successfully</p>,
            description: 'Data Lab Info Has Been Updated Successfully',
          });
          dispatch.datalabFormBuilder.updateDataLabFormDataInfo(
            res.data.dataSource
          );
        } else {
          toast({
            title: <p className='text-red-500'>Failed to Update Lab Info</p>,
            description: `Your request for updating data lab info is failed. Please try again`,
          });
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Exception Error</p>,
          description: `There are some exception error in the request`,
        });
      }
    },

    async createDataLabField(payload) {
      /*
      payload = {
        labId : num,
        data: object { field payload }
      }
       */

      const fieldRandomId = payload.data.id;

      try {
        const res = await axios.post(
          config.datalabFormBuilder.field(payload.labId),
          payload.data
        );

        if (res.status === 200 && res.data.success) {
          toast({
            title: <p className='text-green-500'>Created Successfully</p>,
            description: 'New Field Has Been Created Successfully',
          });

          const data = {
            ...res.data.dataSource,
            fieldRandomId,
          };

          dispatch.datalabFormBuilder.updateCreateFieldPost(data);
        } else {
          toast({
            title: <p className='text-red-500'>Failed to Create Field</p>,
            description: `Your request for create this field is failed. Please try again`,
          });
        }
      } catch (err) {
        if (err.response?.data?.error) {
          toast({
            title: <p className='text-red-500'>Failed to Create Field</p>,
            description: err.response.data.error,
          });
        } else {
          toast({
            title: <p className='text-red-500'>Exception Error</p>,
            description: `There are some exception error in the request`,
          });
        }
      }
    },

    async updateDataLabField(payload) {
      /*
      payload = {
        labId : num,
        fieldId: num,
        data: object { field payload }
      }
       */
      try {
        const res = await axios.put(
          config.datalabFormBuilder.fieldAction(payload.labId, payload.fieldId),
          payload.data
        );
        if (res.status === 200 && res.data.success) {
          toast({
            title: <p className='text-green-500'>Updated Successfully</p>,
            description: 'Field Has Been Updated Successfully',
          });

          dispatch.datalabFormBuilder.updateKeyUpdate(res.data.dataSource);
        } else {
          toast({
            title: <p className='text-red-500'>Failed to Update Field</p>,
            description: `Your request for updating this field is failed. Please try again`,
          });
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Exception Error</p>,
          description: `There are some exception error in the request`,
        });
      }
    },

    async deleteDataLabField(payload) {
      /*
      payload = {
        labId : num,
        fieldId: num,
      }
       */

      const { fieldId } = payload;

      if (typeof fieldId === 'string' && fieldId.startsWith('random_')) {
        dispatch.datalabFormBuilder.updateDeleteField(payload.fieldId);
        return;
      }

      try {
        const res = await axios.delete(
          config.datalabFormBuilder.fieldAction(payload.labId, payload.fieldId)
        );
        if (res.status === 200 && res.data.success) {
          toast({
            title: <p className='text-green-500'>Deleted Successfully</p>,
            description: 'Field Has Been Deleted Successfully',
          });
          dispatch.datalabFormBuilder.updateDeleteField(payload.fieldId);
        } else {
          toast({
            title: <p className='text-red-500'>Failed to Delete Field</p>,
            description: `Your request for deleting this field is failed. Please try again`,
          });
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Exception Error</p>,
          description: `There are some exception error in the request`,
        });
      }
    },

    async updateDataLabFieldOrder(payload) {
      /*
      payload = {
        labId : num,
        data: object { field payload }
      }
       */
      try {
        const res = await axios.post(
          config.datalabFormBuilder.fieldSerial(payload.labId),
          { fields: payload.data }
        );

        if (res.status === 200 && res.data.success) {
          toast({
            title: <p className='text-green-500'>Updated Successfully</p>,
            description: 'Field Order Has Been Updated Successfully',
          });
          dispatch.datalabFormBuilder.updateFieldOrder(res.data.dataSource);
        } else {
          toast({
            title: <p className='text-red-500'>Failed to Update Field Order</p>,
            description: `Your request for updating field order is failed. Please try again`,
          });
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Exception Error</p>,
          description: `There are some exception error in the request`,
        });
      }
    },
  }),
};
