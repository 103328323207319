import React from 'react';
import { Bar, BarChart, Tooltip, XAxis, Cell } from 'recharts';
import { Spinner } from 'evergreen-ui';

interface Props {
  graphData: graphDataProps[];
  color: string;
  loader: boolean;
}
interface graphDataProps {
  name: string;
  value: string | number;
}

const DataBarChart: React.FC<Props> = ({ graphData, color, loader }) => {
  const barColors = ['#36C17F', '#3B82F6', '#3B82F6', '#3B82F6'];
  return (
    <div className='reporting-container__user-card-container-elem-graph'>
      {!loader && graphData.length > 0 && (
        <BarChart width={190} height={120} data={graphData}>
          <Tooltip />
          <Bar dataKey='value' fill={color}>
            {graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
            ))}
          </Bar>
          <XAxis dataKey='name' tickLine={false} />
        </BarChart>
      )}
      {!loader && graphData?.length === 0 && (
        <p className='w-1/2 mt-20 border-b-2 border-gray-900' />
      )}
      {loader && (
        <div
          className='flex justify-center align-center'
          style={{ height: 120 }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};
export default DataBarChart;
