import './assets/scss/settings.scss';
import React, { Component, Fragment } from 'react';
import { Menu } from 'evergreen-ui';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { checkPermission, settingsNavData } from '../../../utilities/utils';
import {
  BotIconVector,
  TicketConfigIcon,
  InboxPreferencesIcon,
  SLAConfigurationIcon,
} from './assets/iconComponent/settingNavIconSet';
import { withTranslationHookHoc } from '../../components/HOC/withTranslationHookHoc';
import { isPartnerRestricted } from '../../../components/customHooks/usePartner';
import { environment } from '../../../utilities/config';
import { env as environmentStatus } from '../../../utilities/config';
class Settings extends Component {
  constructor(props) {
    super(props);
    this.currentLink = '';
    this.readAccess = 'read:settings';
    this.shouldDisableBilling = isPartnerRestricted(
      'Settings/node/plan_billing'
    );
  }

  getElement = () => {
    return document.getElementById(this.props.location.hash.replace('#', ''));
  };

  updateCurrentActiveSettings = () => {
    const url = this.props.location.href;
    settingsNavData.forEach((group) => {
      group.data.forEach((elem) => {
        if (url.endsWith(elem.link)) {
          this.props.updateActiveSettings(elem.id);
        }
      });
    });
  };

  componentDidUpdate(prevState, prevProps, snapShot) {
    if (this.currentLink !== this.props.location.href) {
      this.currentLink = this.props.location.href;
      this.updateCurrentActiveSettings();
    }
    if (!!this.getElement()) {
      this.getElement().scrollIntoView({ behavior: 'smooth' });
    }
  }

  async componentDidMount() {
    await this.props.fetchPublicKey();

    this.currentLink = this.props.location.href;
    this.updateCurrentActiveSettings();
    this.props.updateSelectedNav(-1);
    !!this.getElement() &&
      this.getElement().scrollIntoView({
        behavior: 'smooth',
      });
  }

  renderSingleSettingsView = (
    isProUser,
    singleSettings,
    activeSettings,
    selectedProjectId,
    singleSettingsIndex,
    updateActiveSettings
  ) => {
    const { id, isPro, title, icon, link } = singleSettings;

    const marginLeftMap = {
      [this.props.getTranslatedText('Chatbot Settings')]: '-15px',
      [this.props.getTranslatedText('Ticket Configuration')]: '-5px',
      [this.props.getTranslatedText('Inbox Preferences')]: '-5px',
      [this.props.getTranslatedText('SLA Configurations')]: '-5px',
    };

    const marginLeft = marginLeftMap[title] || '';

    const style = {
      marginLeft,
    };

    const getIcon = () => {
      switch (icon) {
        case 'bot':
          return <BotIconVector className='w-8 h-8 ltr:ml-3 rtl:-mr-4' />;
        case 'ticketConfig':
          return <TicketConfigIcon className='w-4 h-4 ltr:ml-4 rtl:mr-4' />;
        case 'inboxPreferences':
          return (
            <InboxPreferencesIcon className='w-[17.5px] h-[16.25px] ltr:ml-4 rtl:mr-4' />
          );
        case 'slaConfiguration':
          return <SLAConfigurationIcon className='ltr:ml-4 rtl:mr-4' />;
        default:
          return icon;
      }
    };
    return (
      <>
        {environmentStatus === environment.PRODUCTION &&
        icon === 'slaConfiguration' ? null : (
          <Menu.Item
            key={singleSettingsIndex}
            icon={getIcon()}
            onSelect={() => {
              navigate(link);
              updateActiveSettings(id);
              const AllowedSingeSettingTitle = [
                'Manage Subscription',
                'Payment Method',
              ];
              if (
                !!title &&
                AllowedSingeSettingTitle.includes(
                  this.props.getTranslatedText(title)
                )
              ) {
              }
            }}
            className={` ${'ml-0'} ${
              activeSettings === id ? 'menu-elem menu-active' : 'menu-elem'
            }`}
          >
            <p style={style}>
              {this.props.getTranslatedText(title)}
              {isPro && !isProUser && (
                <span>
                  &nbsp;&nbsp;
                  <span className='p-1 text-xs leading-4 text-blue-900 bg-blue-100 rounded'>
                    {this.props.getTranslatedText('Pro')}
                  </span>
                </span>
              )}
            </p>
          </Menu.Item>
        )}
      </>
    );
  };

  render() {
    const { activeSettings, updateActiveSettings, selectedProject } =
      this.props;
    const hasPermission = selectedProject ? selectedProject.is_creator : false;

    const isProUser = selectedProject
      ? selectedProject?.subscription_plan?.plan_type !== 'free' ||
        this.props.trialRemaining > 0
      : false;
    const isEnterpriseOrBusinessPlan = [
      'enterprise',
      'business',
      'appsumo',
    ].includes(selectedProject?.subscription_plan?.plan_type);

    const isOnlyEnterpriseOrBusinessPlan = ['enterprise', 'business'].includes(
      selectedProject?.subscription_plan?.plan_type
    );

    return (
      <main className='dashboard'>
        <div className='layout__wrapper'>
          <div className='layout__sidebar'>
            <Menu>
              {settingsNavData
                .filter((navData) => {
                  if (navData.title.includes('Billing')) {
                    if (this.shouldDisableBilling) {
                      return false;
                    }
                    return hasPermission;
                  }
                  if (navData.title.includes('Developer')) {
                    return isEnterpriseOrBusinessPlan;
                  }
                  return true;
                })
                .map(
                  (groupedSettings, groupedSettingsIndex) =>
                    (groupedSettings.globalAccess ||
                      checkPermission(
                        this.props.permissionList,
                        this.readAccess
                      )) && (
                      <Fragment key={groupedSettingsIndex}>
                        <Menu.Group
                          title={this.props.getTranslatedText(
                            groupedSettings.title
                          )}
                        >
                          {groupedSettings.data
                            .filter((singleData) =>
                              singleData?.id === 27
                                ? isOnlyEnterpriseOrBusinessPlan
                                : true
                            )
                            .map(
                              (singleSettings, singleSettingsIndex) =>
                                (singleSettings.itemGlobalAccess ||
                                  checkPermission(
                                    this.props.permissionList,
                                    singleSettings?.permission?.readAccess
                                  )) &&
                                this.renderSingleSettingsView(
                                  isProUser,
                                  singleSettings,
                                  activeSettings,
                                  selectedProject?.id,
                                  singleSettingsIndex,
                                  updateActiveSettings
                                ) // Call the renderSingleSettingsView method
                            )}
                        </Menu.Group>
                        {groupedSettingsIndex !==
                          settingsNavData.length - 1 && <Menu.Divider />}
                      </Fragment>
                    )
                )}
            </Menu>
          </div>
          {!!this.props.publicKey && (
            <Elements stripe={loadStripe(this.props.publicKey)}>
              <div className='layout__content'>{this.props.children}</div>
            </Elements>
          )}
        </div>
      </main>
    );
  }
}

const mapState = (state) => ({
  publicKey: state.billing.publicKey,
  selectedProject: state.dashboard.selectedProject,
  email: state.auth.email,
  trialRemaining: state.auth.trial_remaining,
  activeSettings: state.settings.activeSettings,
  permissionList: state.dashboard.permission.list || [],
});

const mapDispatch = (dispatch) => ({
  fetchPublicKey: () => dispatch.billing.fetchPublicKey(),
  updateActiveSettings: (settingsId) =>
    dispatch.settings.updateActiveSettings(settingsId),
  updateSelectedNav: (navId) => dispatch.dashboard.updateSelectedNav(navId),
});

const SettingsContainer = connect(
  mapState,
  mapDispatch
)(withTranslationHookHoc(Settings));

export default SettingsContainer;
