import React from 'react';
import ReportingNavTab from './ReportingNavTab';
import {
  ChannelListDropdown,
  DatePickerDropdown,
} from '../../../../components';

interface tabProps {
  id: number;
  name: string;
}

interface props {
  platformList: any;
  selectedPlatformLocal: any;
  setSelectedPlatformLocal: (channel: any) => void;
  dateRange: any;
  setDateRange: (val: any) => void;
  finalDateRange: any;
  onDateFilter: () => void;
  currentTab: tabProps;
  updateStateData: (key: string, value: tabProps) => void;
  fetchAnalyticsData: () => void;
}

const ReportingNav: React.FC<props> = ({
  platformList,
  setSelectedPlatformLocal,
  selectedPlatformLocal,
  dateRange,
  setDateRange,
  finalDateRange,
  onDateFilter,
  currentTab,
  updateStateData,
  fetchAnalyticsData,
}) => {
  return (
    <div className='w-full h-full relative'>
      <div className='flex items-center justify-between bg-white border-b sticky-top z-20 top-0 flex-wrap ml-[2px] w-[calc(100% - 2px)]'>
        <ReportingNavTab
          currentTab={currentTab}
          updateStateData={updateStateData}
        />
        <div className='flex flex-wrap items-center w-full px-3 pt-0 pb-3 md:p-3 md:w-auto'>
          <ChannelListDropdown
            value={selectedPlatformLocal}
            onChange={(channel: any) => {
              setSelectedPlatformLocal(channel);
              setTimeout(async () => {
                await fetchAnalyticsData();
              }, 500);
            }}
            hasAllChannel={false}
            options={[
              ...(currentTab?.name !== 'WhatsApp Metrics'
                ? [
                    {
                      id: null,
                      type: 'all',
                      title: 'All Channels',
                    },
                  ]
                : []),
              ...(!platformList ? [] : platformList),
            ]}
          />
          <DatePickerDropdown
            dateRange={dateRange}
            handleDateRangeChange={(item: any) =>
              setDateRange([item.selection])
            }
            finalDateRange={finalDateRange}
            handleFilter={() => onDateFilter()}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportingNav;
