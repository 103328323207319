import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/24/outline';
import Button from 'library/button';
import useGoogleAuthentication from 'library/helper/hooks/useGoogleAuthentication';
import { Fragment, useEffect, useState } from 'react';

import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';
import { toaster } from 'evergreen-ui';
import { SelectedTeamInterface } from 'index';
import { CircleSpinner } from 'pages/inbox/assets/iconComponent/Spinner';
import {
  CustomEmailIcon,
  GmailIcon,
} from 'pages/integration/assets/icons/emailIcons';
import {
  channelCreateAPiResponseType,
  channelListProps,
} from 'pages/integration/interface';
import { initialUserProperties } from 'pages/integration/utils/content';
import { connect } from 'react-redux';
import CustomEmailSetup from './customEmail';
import GmailIntegration from './GmailIntegration';

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedProject: SelectedTeamInterface;
  handleChannelEdit: (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) => Promise<boolean>;

  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) => Promise<channelCreateAPiResponseType>;
}
const ConnectEmail: React.FC<Props> = ({
  open,
  handleClose,
  selectedProject,
  handleChannelEdit,
  createChannelIntegrations,
}) => {
  const { t } = useTranslation();
  const [selectedEmail, setSelectedEmail] = useState('');
  const [gmailUserAuthCode, setGmailUserAuthCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [openGmailSettings, setOpenGmailSettings] = useState(false);
  const googleLogin = useGoogleAuthentication(setGmailUserAuthCode);
  const [emailChannelProperties, setEmailChannelProperties] =
    useState<channelListProps>(initialUserProperties);

  const { isPartnerRestricted, currentPartnerInfo } = usePartner();
  const shouldReplaceMyalice = isPartnerRestricted(
    'ConnectEmail/node/email-connect'
  );
  const partnerName = shouldReplaceMyalice
    ? currentPartnerInfo?.shortName
    : 'MyAlice';

  const createGmailChannel = async () => {
    let payloadData = {
      title: '',
      auth_code: gmailUserAuthCode,
    };
    setLoading(true);
    let res = await createChannelIntegrations(
      'gmail',
      selectedProject?.id,
      payloadData
    );
    if (res.status === 200) {
      setEmailChannelProperties(res.data.dataSource);
      setOpenGmailSettings(true);
      handleClose();
    } else {
      setEmailChannelProperties(initialUserProperties);
      if (
        !!res?.data?.error &&
        res?.data?.error.includes('The account is already created.')
      ) {
        toaster.danger(res.data.error);
      } else {
        toaster.danger(
          t(
            'Something went wrong while creating a channel. Please login with google again!'
          )
        );
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (gmailUserAuthCode !== '') {
      createGmailChannel();
    }
    //eslint-disable-next-line
  }, [gmailUserAuthCode]);

  const renderCustomEmailSetup = () => {
    return (
      <CustomEmailSetup
        open={selectedEmail === 'custom'}
        handleClose={() => setSelectedEmail('')}
      />
    );
  };

  const handleEmailSelection = (type: string) => {
    setSelectedEmail(type);
    handleClose();
  };
  const rederInformationCard = () => {
    return (
      <div className='flex gap-2 justify-center px-4 py-4 mt-4 text-sm text-blue-800 border rounded-md bg-blue-50 border-grey-200 '>
        <div className='justify-center'>
          <InformationCircleIcon
            className='w-5 h-5 text-blue-400'
            aria-hidden='true'
          />
        </div>
        <p className='text-justify'>
          {t(
            `{{${partnerName}}}'s use and transfer of information received from Google APIs to any other app will adhere to`
          )}{' '}
          <a
            href={`https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes`}
            target='_blank'
            className='text-blue-500 hover:text-blue-900'
            rel='noreferrer'
          >
            <b>{t('Google API Services User Data Policy')}</b>
          </a>
          , {t('including the Limited Use requirements.')}
        </p>
      </div>
    );
  };
  const renderEmailChannelButtons = () => {
    return (
      <div>
        <div className='my-2 flex flex-col gap-3'>
          <Button isFullWidth={true} className='' onClick={() => googleLogin()}>
            <div className='flex w-full gap-2 items-center'>
              <GmailIcon classNames='w-4 h-4 ' />
              <span className=' text-base font-normal text-gray-700'>
                {t('Sign in with Google')}
              </span>
            </div>
          </Button>
          <Button
            isFullWidth={true}
            onClick={() => handleEmailSelection('custom')}
          >
            <div className='flex w-full gap-2 items-center'>
              <CustomEmailIcon classNames='w-4 h-4' />
              <span className=' text-base font-normal text-gray-700'>
                {t('Sign in with an Email')}
              </span>
            </div>
          </Button>
          {/* NOTE: may need for future update */}
          {/* <Button isFullWidth={true} isDisabled={true}>
            <OutlookIcon classNames='w-4 h-4 mr-3 ' />{' '}
            <span className=' text-base font-normal text-gray-700 mr-auto'>
              Sign in with Outlook
            </span>
            <span className='px-1 py-[2px] rounded text-xs font-medium text-green-800 bg-green-100'>
              Coming Soon
            </span>
          </Button> */}
        </div>
        {rederInformationCard()}
      </div>
    );
  };
  const renderEmailButtonModal = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-20 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative flex transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                  <div className='relative'>
                    <XMarkIcon
                      className='w-6 h-6 absolute ltr:right-0 rtl:left-0 cursor-pointer text-gray-400 hover:text-red-400'
                      onClick={handleClose}
                    />
                    <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
                      <CheckIcon
                        className='h-6 w-6 text-green-600'
                        aria-hidden='true'
                      />
                    </div>
                    <div className='mt-3 text-center sm:mt-5'>
                      <Dialog.Title
                        as='h3'
                        className='text-lg font-semibold leading-6 text-gray-900 mb-7'
                      >
                        {t('Select an email platform')}
                      </Dialog.Title>
                      {!loading && renderEmailChannelButtons()}
                      {loading && (
                        <div className='flex justify-center items-center'>
                          <CircleSpinner className='animate-spin ml-1 h-5 w-5 text-primary inline ' />
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <>
      {renderEmailButtonModal()}
      {renderCustomEmailSetup()}
      <GmailIntegration
        selectedProject={selectedProject}
        handleChannelEdit={handleChannelEdit}
        gmailChannelProperies={emailChannelProperties}
        open={openGmailSettings}
        handleClose={() => {
          setOpenGmailSettings(false);
        }}
      />
    </>
  );
};

const mapState = <T extends { auth: any; dashboard: any }>(state: T) => ({
  selectedProject: state.dashboard.selectedProject,
});
const mapDispatch = (dispatch: any) => ({
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) =>
    dispatch.integration.createChannelIntegrations({
      channelType,
      teamId,
      data,
      hideToaster: true,
    }),
  handleChannelEdit: async (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) =>
    dispatch.integration.editChannel({
      teamId,
      channelId,
      data,
      alertMessage,
      hideToaster: true,
    }),
});

export default connect(mapState, mapDispatch)(ConnectEmail);
