import React from 'react';
import Footer from '../../common/components/Footer';
import FacebookConnection from '../../common/components/FacebookConnection';
import InstagramConnection from '../../common/components/InstagramConnection';
import SelectablPages from '../../common/components/SelectablePages';
import WebchatScript from '../../common/pages/WebchatScript';
import ApplicationScript from '../../common/pages/ApplicationScript';

import { SideSheet } from '../../../../../library';
import { connect } from 'react-redux';
import { toaster } from 'evergreen-ui';
import { channelInfo } from '../../../../../utilities/utils';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import WhatsAppLogin from '../../../../../utilities/WhatsappConnection';
import CustomEmailReconnect from './CustomEmailReconnect';

import {
  channelListProps,
  facebookPageListProps,
  instagramMessengerConnectionAPiResponseType,
  instagramPagesListProps,
  instagramReconnectRequestProps,
  selectedProjectProps,
} from 'pages/integration/interface';
import GmailChannelReconnect from './GmailChannelReconnect';
import { GoogleOAuthProvider } from '@react-oauth/google';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  isOpen: boolean;
  channelData: channelListProps;
  selectedProject: selectedProjectProps;
  handleChannelReconnect: (
    teamId: number,
    channelId: string,
    payloadData: object,
    alertMessage: string
  ) => boolean;
  fetchFacebookPages: (
    id: string,
    token: string,
    type: string
  ) => Promise<facebookPageListProps[]>;
  fetchInstagramPages: (
    id: string,
    token: string,
    type: string,
    projectId: number
  ) => Promise<instagramPagesListProps[]>;
  setInitialState: () => void;
  instagramMessengerChannelConnectionValidation: (
    channelType: string,
    teamId: number,
    data: instagramReconnectRequestProps
  ) => Promise<instagramMessengerConnectionAPiResponseType>;
  fetchTeamDetails: (projectId: number) => void;
  updateSelectedProject: (data: any) => void;
  isGmailModalOpened?: boolean;
  setIsGmailModalOpened: () => void;
}

const initialSelectedPageData = {
  is_connected: false,
  url: '',
  name: '',
  avatar: '',
  username: '',
  primary_id: '',
  secondary_id: '',
  primary_token: '',
};

const initialPayloadData = {
  url: '',
  name: '',
  username: '',
  primary_id: '',
  secondary_id: '',
  primary_token: '',
  avatar: '',
  whitelisted_domains: [],
};

const ChannelReconnect: React.FC<Props> = ({
  isOpen,
  channelData,
  selectedProject,
  setInitialState,
  fetchFacebookPages,
  fetchInstagramPages,
  handleChannelReconnect,
  instagramMessengerChannelConnectionValidation,
  fetchTeamDetails,
  updateSelectedProject,
  isGmailModalOpened,
  setIsGmailModalOpened,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [pageList, setPageList] = React.useState<
    facebookPageListProps[] | null | instagramPagesListProps[]
  >(null);
  const [selectedPage, setSelectedPage] = React.useState(
    initialSelectedPageData
  );
  const [payloadData, setPayloadData] = React.useState(initialPayloadData);
  const [success, setSuccess] = React.useState(true);
  const [token, setToken] = React.useState('');

  const getLoadingPage = () => {
    return (
      <div className='flex flex-col items-center justify-center mt-52'>
        <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
        <span>{t('reconnecting channel')}...</span>
      </div>
    );
  };

  const checkConfirmButtons = () => {
    if (loading) return true;

    let platformType = channelData.platform_type;
    switch (step) {
      case 1:
        if (
          platformType === 'facebook_messenger' ||
          platformType === 'facebook_feed' ||
          platformType === 'instagram_messenger' ||
          platformType === 'instagram_feed'
        ) {
          return true;
        } else if (platformType === 'whatsapp_bsp') {
          return token === '';
        } else {
          return false;
        }
      case 2:
        if (!!pageList && pageList.length) {
          if (selectedPage.primary_id.length) return false;
          else return true;
        }
        return true;
      default:
        return false;
    }
  };

  const processFacebookResponse = async (response: any) => {
    setLoading(true);
    let platformType = channelData.platform_type;
    if (response.status !== 'unknown') {
      let data;
      platformType === 'facebook_messenger' || platformType === 'facebook_feed'
        ? (data = await fetchFacebookPages(
            response.id,
            response.accessToken,
            platformType
          ))
        : (data = await fetchInstagramPages(
            response.id,
            response.accessToken,
            platformType,
            selectedProject?.id
          ));

      if (!!data) {
        if (platformType === 'instagram_messenger' || 'instagram_feed') {
          data.forEach(
            (item) => (item['name'] = !!item.name ? item.name : item.username)
          );
        }
        setPageList(data);
        setStep(2);
        setLoading(false);
      } else {
        setStep(1);
        setLoading(false);
        setInitialState();
        toaster.warning(
          t('Something went wrong while fetching pagelist. Try again later')
        );
      }
    } else {
      toaster.warning(t("Could not Connect Channel"), {
        description: t(
          "Could not Connect Channel to update page information! Please Try Again."
        ),
      });
    }
  };

  const handleChannelReconnectOnConfirm = async (payloadData: object) => {
    let alertMessage = `Channel ${channelData.title} is successfuly reconnected`;

    let res = handleChannelReconnect(
      selectedProject.id,
      channelData.id,
      payloadData,
      alertMessage
    );

    if (res || !res) {
      setStep(1);
      setLoading(false);
      setPayloadData(initialPayloadData);
      setSelectedPage(initialSelectedPageData);
      setInitialState();
    }

    // updating selected project (to get the value of meta_invalid_token_hellobar_data) as the number of meta channels
    // connected through invalid token may change after reconnecting the channel.
    let data = await fetchTeamDetails(selectedProject?.id);
    updateSelectedProject(data);
  };

  const handleConfirm = async () => {
    setLoading(true);
    switch (step) {
      case 1:
        if (channelData.platform_type !== 'whatsapp_bsp') {
          await handleChannelReconnectOnConfirm(payloadData);
        } else {
          let data = { primary_token: token };
          await handleChannelReconnectOnConfirm(data);
        }
        break;
      case 2:
        let newPayloadData = {
          url: selectedPage.url,
          name: selectedPage.name,
          primary_id: selectedPage.primary_id,
          secondary_id: selectedPage.secondary_id,
          primary_token: selectedPage.primary_token,
          avatar: selectedPage.avatar,
          username: !!selectedPage.username ? selectedPage.username : '',
        };
        if (channelData.platform_type === 'instagram_messenger') {
          let connectionResponse =
            await instagramMessengerChannelConnectionValidation(
              'instagram_messenger',
              selectedProject.id,
              newPayloadData
            );
          if (connectionResponse.status !== 200) {
            setSuccess(false);
            setLoading(false);
            break;
          }
        }
        await handleChannelReconnectOnConfirm(newPayloadData);
        break;
      default:
        break;
    }
  };

  const getChannelInfo = () => {
    return {
      title: channelInfo(channelData.platform_type).title,
      description: `Reconnect your channel to ${
        channelInfo(channelData.platform_type).title
      }`,
    };
  };

  const getSanitizedPlatformName = (platformType: string) => {
    switch (platformType) {
      case 'viber_messenger':
        return 'viber';
      case 'line_messenger':
        return 'line';
      case 'telegram_messenger':
        return 'telegram';
      default:
        return '';
    }
  };

  const handleWhatsappToken = (token: string) => {
    setToken(token);
  };

  const getChannelPages = (platformType: string) => {
    switch (platformType) {
      case 'viber_messenger':
      case 'line_messenger':
      case 'telegram_messenger':
        return loading ? (
          getLoadingPage()
        ) : (
          <div>
            <div className='col-span-6 p-6 mt-5 sm:col-span-6'>
              <label
                htmlFor='primary_token'
                className='block text-sm font-medium text-gray-700'
              >
                Primary Token*
              </label>
              <input
                type='text'
                placeholder=''
                value={payloadData.primary_token}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPayloadData({
                    ...payloadData,
                    [e.target.name]: e.target.value,
                  })
                }
                name='primary_token'
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
              />
              <p className='mt-6 font-medium text-gray-500'>
                {`Primary token required to grant Alice the permission to access
                your ${getSanitizedPlatformName(
                  platformType
                )} account. You can view Alice documentation to learn how
                to collect the required informations from ${getSanitizedPlatformName(
                  platformType
                )}.`}
              </p>
            </div>
            <Footer
              channelName={channelInfo(channelData.platform_type).title}
            />
          </div>
        );
      case 'facebook_messenger':
      case 'facebook_feed':
      case 'instagram_messenger':
      case 'instagram_feed':
        return loading ? (
          getLoadingPage()
        ) : (
          <>
            {step === 1 ? (
              channelData.platform_type === 'facebook_messenger' ||
              channelData.platform_type === 'facebook_feed' ? (
                <FacebookConnection
                  loading={loading}
                  type={channelData.platform_type}
                  processFacebookResponse={processFacebookResponse}
                />
              ) : (
                <InstagramConnection
                  loading={loading}
                  type={channelData.platform_type}
                  processInstagramResponse={processFacebookResponse}
                />
              )
            ) : (
              <SelectablPages
                pageList={pageList?.length ? pageList : []}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                platformType={channelData.platform_type}
                success={success}
              />
            )}
          </>
        );
      case 'webchat':
        return (
          <WebchatScript
            platfromData={{
              id: channelData.id,
              primary_id: !!channelData?.primary_id
                ? channelData?.primary_id
                : '',
              access_token: channelData.access_token,
            }}
          />
        );

      case 'app_messenger':
        return (
          <ApplicationScript
            platfromData={{
              id: channelData.id,
              primary_id: !!channelData?.primary_id
                ? channelData?.primary_id
                : '',
              access_token: channelData.access_token,
            }}
          />
        );
      case 'whatsapp_bsp':
        return <WhatsAppLogin handleWhatsappToken={handleWhatsappToken} />;
      default:
        return (
          <p className='flex justify-center p-6 font-medium text-gray-500'>
            Press the 'Finish' button to reconnect this channel
          </p>
        );
    }
  };
  return (
    <>
      {channelData?.platform_type === 'custom_email' && (
        <CustomEmailReconnect
          channelData={channelData}
          open={isOpen}
          handleClose={() => setInitialState()}
        />
      )}

      {!['gmail', 'custom_email', 'office365'].includes(
        channelData?.platform_type
      ) && (
        <SideSheet
          open={isOpen}
          hasMinimizeButton={false}
          handleClose={() => setInitialState()}
          closeOnExternalClick={false}
          disableConfirm={checkConfirmButtons()}
          title={getChannelInfo().title}
          description={getChannelInfo().description}
          confirmText={'Finish'}
          cancelText={'Back'}
          handleCancel={() => setInitialState()}
          handleConfirm={() => handleConfirm()}
          hasLeftActionElement={false}
          hideCancel={true}
        >
          {!!getChannelPages(channelData.platform_type) ? (
            getChannelPages(channelData.platform_type)
          ) : (
            <p>loading</p>
          )}
        </SideSheet>
      )}
      {channelData?.platform_type === 'gmail' && (
        <GoogleOAuthProvider
          clientId={
            !!process.env.REACT_APP_GOOGLE_CLIENT_ID
              ? process.env.REACT_APP_GOOGLE_CLIENT_ID
              : ''
          }
        >
          <GmailChannelReconnect
            shouldOpen={isGmailModalOpened!}
            setShouldOpen={setIsGmailModalOpened}
            channelData={channelData}
          />
        </GoogleOAuthProvider>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  fetchFacebookPages: (id: string, token: string, type: string) =>
    dispatch.integration.fetchFacebookPages({ id, token, type }),
  fetchInstagramPages: (id: string, token: string, type: string, projectId: number) =>
    dispatch.integration.fetchInstagramPages({ id, token, type, projectId }),
  handleChannelReconnect: (
    teamId: number,
    channelId: string,
    payloadData: object,
    alertMessage: string
  ) =>
    dispatch.integration.reconnectChannel({
      teamId,
      channelId,
      payloadData,
      alertMessage,
    }),
  instagramMessengerChannelConnectionValidation: (
    channelType: string,
    teamId: number,
    data: instagramReconnectRequestProps
  ) =>
    dispatch.integration.instagramMessengerChannelConnectionValidation({
      channelType,
      teamId,
      data,
    }),
  fetchTeamDetails: (projectId: number) =>
    dispatch.dashboard.fetchTeamDetails(projectId),
  updateSelectedProject: (data: any) =>
    dispatch.dashboard.updateSelectedProject(data),
});

export default connect(mapState, mapDispatch)(ChannelReconnect);
