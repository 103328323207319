import React from 'react';
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import { LinearLoaderComponent } from '../../../../utilities/utils';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../../library';
import Pagination from '../CommonComponent/Pagination';
import AgentDetailsTableData from './AgentDetailsTableData';
import useTranslation from 'components/customHooks/useTranslation';

interface props {
  title: string;
  toolTipInfo: string;
  tableData: any[];
  loader: boolean;
  query: string;
  setQuery: (val: string) => void;
  handleExport: () => void;
  exportLoader: boolean;
  limit: number;
  offset: number;
  total: number;
  setOffset: (val: number) => void;
  hasEcommerceConnected: boolean;
  currency: string;
}

const AgentDetailsTable: React.FC<props> = ({
  title,
  toolTipInfo,
  tableData,
  loader,
  query,
  setQuery,
  exportLoader,
  handleExport,
  limit,
  offset,
  total,
  setOffset,
  hasEcommerceConnected,
  currency,
}) => {
  const { t } = useTranslation();
  const handleNextButton = () => {
    setOffset(offset + limit);
  };
  const handlePreviousButton = () => {
    setOffset(offset - limit);
  };

  return (
    <div className='m-3 overflow-hidden bg-white rounded-lg shadow '>
      <div className='px-4 py-5 border-b'>
        <div className='flex justify-between'>
          <div className='flex text-base font-normal text-gray-900'>
            {t(title)}
            <span className='mt-1 ltr:ml-2 rtl:mr-2' title={t(toolTipInfo)}>
              <InformationCircleIcon className='w-4 h-4' />
            </span>
          </div>
          <div className='flex justify-start'>
            <div className='relative rounded-md shadow-sm'>
              <div className='absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3 pointer-events-none'>
                <MagnifyingGlassIcon
                  className='w-5 h-5 text-gray-400'
                  aria-hidden='true'
                />
              </div>
              <input
                type='search'
                name='search'
                id='search'
                className='block w-full ltr:pl-10 rtl:pr-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
                placeholder={t('Search')}
                value={query}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setQuery(e.target.value);
                  setOffset(0);
                }}
              />
            </div>
            <Button
              intent='default'
              isDisabled={tableData.length === 0}
              size={'sm'}
              onClick={() => handleExport()}
              className={'ltr:ml-4 rtl:mr-4'}
            >
              {!exportLoader && (
                <ArrowDownTrayIcon
                  className='ltr:-ml-0.5 rtl:-mr-0.5 ltr:mr-2 rtl:ml-2 h-4 w-4'
                  aria-hidden='true'
                />
              )}
              {t('Export')}
            </Button>
          </div>
        </div>
      </div>
      {loader && <LinearLoaderComponent />}
      {!loader && (
        <AgentDetailsTableData
          loader={loader}
          tableData={tableData}
          hasEcommerceConnected={hasEcommerceConnected}
          currency={currency}
        />
      )}
      {!loader && tableData?.length !== 0 && (
        <Pagination
          offset={offset}
          tableDataLength={tableData?.length}
          total={total}
          limit={limit}
          handleNextButton={handleNextButton}
          handlePreviousButton={handlePreviousButton}
        />
      )}
    </div>
  );
};

export default AgentDetailsTable;
