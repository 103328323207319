import React from 'react';
import {
  MagnifyingGlassIcon,
  PlusIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/20/solid';
import ResponseList from '../component/canned-response/ResponseList';
import { connect } from 'react-redux';
import { SelectedTeamInterface } from 'index';
import CreateResponse from '../component/canned-response/CreateResponse';
import { toaster } from 'evergreen-ui';
import DeleteModal from '../component/canned-response/DeleteModal';
import { UserPropertyType } from '../../../../index';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { Link } from '@reach/router';
import Button from 'library/button';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  selectedProject: SelectedTeamInterface;
  cannedResponseList: CannedResponseInterface[];
  agentAccess: [AgentListInterface];
  userInfo: UserPropertyType;
  getResponseList: (projectId: number) => boolean;
  deleteCannedResponse: (payload: {
    projectId: number;
    cannedResponseId: number;
  }) => boolean;
}

const CannedResponse: React.FC<Props> = ({
  selectedProject,
  cannedResponseList,
  userInfo,
  agentAccess,
  getResponseList,
  deleteCannedResponse,
}) => {
  const { t } = useTranslation();
  const { isPartnerRestricted } = usePartner();
  const shouldHideWatchTutorial = isPartnerRestricted(
    'CannedResponse/node/watch-tutorial'
  );

  const [selectedCannedResponse, setSelectedCannedResponse] =
    React.useState<CannedResponseInterface>({
      id: 0,
      admin: 0,
      project: { id: 0, name: '' },
      title: '',
      text: '',
      for_team: false,
      tags: [],
    });
  const [openCreateModal, setOpenCreateModal] = React.useState<boolean>(false);
  const [filterResponse, setFilterResponse] =
    React.useState<CannedResponseInterface[]>(cannedResponseList);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isAgent, setIsAgent] = React.useState<boolean>(false);
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [editData, setEditData] = React.useState<CannedResponseInterface>({
    id: 0,
    admin: 0,
    project: { id: 0, name: '' },
    title: '',
    text: '',
    for_team: false,
    tags: [],
  });
  const [sliceData, setSliceData] = React.useState({
    limit: 9,
    offset: 0,
  });
  const userEmail = userInfo.email;

  const deleteSingleCannedResponse = async (type: boolean) => {
    let payload = {
      projectId: selectedProject.id,
      cannedResponseId: selectedCannedResponse.id,
    };

    const res = await deleteCannedResponse(payload);

    if (res) {
      toaster.success(t('Saved reply deleted successfully'));
      setOpenDeleteModal(false);
      setSelectedCannedResponse({
        id: 0,
        admin: 0,
        project: { id: 0, name: '' },
        title: '',
        text: '',
        for_team: false,
        tags: [],
      });
    } else {
      toaster.danger('Could not delete canned response. Please try again.');
      setOpenDeleteModal(false);
    }
  };

  const searchBar = () => {
    return (
      <div className='relative mt-1'>
        <div className='pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3'>
          <MagnifyingGlassIcon
            className='h-4 w-5 text-gray-400'
            aria-hidden='true'
          />
        </div>
        <input
          type='text'
          name='search'
          id='search'
          autoComplete='off'
          disabled={cannedResponseList?.length === 0}
          className={`block rounded-md border-gray-300 ltr:pl-10 rtl:pr-10
                  focus:border-green-500 focus:ring-green-500 sm:text-sm w-3/4 ${
                    cannedResponseList?.length === 0 ? 'bg-gray-200' : ''
                  }`}
          placeholder={t('Search saved replies')}
          onChange={(e) => {
            setIsSearching(true);
            setSliceData({ ...sliceData, limit: 9, offset: 0 });

            if (e.target.value === '') {
              setIsSearching(false);
              return setFilterResponse(cannedResponseList);
            }

            setFilterResponse(
              cannedResponseList.filter((elemFilter) =>
                elemFilter?.title
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              )
            );
          }}
        />
      </div>
    );
  };

  const isUserRoleAdmin = (agentAccess: [AgentListInterface]) => {
    for (let i = 0; i < agentAccess?.length; i++) {
      if (
        agentAccess[i]?.role?.name === 'Admin' &&
        agentAccess[i]?.admin?.email === userEmail
      ) {
        return true;
      } else if (
        [4088, 6996, 6997].includes(selectedProject.id) &&
        [18, 19, 21, 22, 24, 25].includes(agentAccess[i]?.role.id)
      ) {
        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    getResponseList(selectedProject?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setFilterResponse(cannedResponseList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cannedResponseList]);

  const isFreeUser = () => {
    return selectedProject?.subscription_plan?.plan_type === 'free';
  };
  const isProjectCreator = () => {
    return selectedProject?.creator?.email === userEmail;
  };

  const renderUpgradeToPremiumView = () => {
    return (
      <div className='w-full'>
        <div className='flex flex-col p-4 w-full align-center text-sm h-[100vh] justify-center align-center canned-response-div'>
          <div className='relative rounded-full bg-blue-100 h-[48px] w-[48px] flex justify-center align-center mb-5'>
            <LockClosedIcon className='w-[20px] h-[20px]' fill='#1E3A8A' />
          </div>
          <p className='text-gray-900'>{t('Upgrade to Premium')}</p>
          <p className='text-gray-500 '>
            {t('Add Superpower to your support arsenal!')}
          </p>
          {!isProjectCreator() && (
            <p className='font-normal text-sm text-gray-500'>
              {t('Contact your team owner to upgrade subscription plan.')}
            </p>
          )}
          {isProjectCreator() && (
            <Link
              to='/settings/billing'
              className='mt-5 inline-flex items-center text-center px-4 py-2 text-sm rounded-md border shadow-sm border-transparent bg-primary
                    hover:bg-primary-hover text-white focus:outline-none transition ease-out duration-200'
            >
              {t('Upgrade Now')}
            </Link>
          )}
        </div>
      </div>
    );
  };

  const renderCannedResponseView = () => {
    return (
      <div className='p-4 w-full'>
        <div className='flex mb-6 items-center'>
          <div className='w-1/2'>
            <p className='text-gray-900 text-lg font-semibold mt-3'>
              {t('Create Saved Replies')}
            </p>
          </div>
          <div
            id='watch-tutorial'
            className={`w-1/2 flex justify-end ${
              shouldHideWatchTutorial ? 'hidden' : 'visible'
            }`}
          >
            <a
              href={`https://docs.myalice.ai/`}
              target={'_blank'}
              rel='noreferrer'
            >
              <p className='text-[#0078CF] text-[14px] font-medium mt-3 flex gap-2 cursor-pointer'>
                {t('Watch Tutorial')}
                <span>
                  <ArrowTopRightOnSquareIcon className='w-5 h-5 text-[#0078CF]' />
                </span>
              </p>
            </a>
          </div>
        </div>
        <div>
          <div className='flex mb-5 items-center w-full'>
            <div className='w-1/2'>
              <div>{searchBar()}</div>
            </div>
            <div className='w-1/2 flex justify-end gap-2'>
              <Button
                intent='primary'
                onClick={() => {
                  setOpenCreateModal(true);
                  setIsAgent(!isUserRoleAdmin(agentAccess));
                }}
              >
                <PlusIcon className='w-4 h-4 text-white' />
                &nbsp;{t('Create new saved reply')}
              </Button>
            </div>
          </div>
          {filterResponse && (
            <ResponseList
              isSearching={isSearching}
              isUserRoleAdmin={isUserRoleAdmin(agentAccess)}
              userInfo={userInfo}
              responseList={filterResponse}
              sliceData={sliceData}
              setIsAgent={setIsAgent}
              setSelectedCannedResponse={setSelectedCannedResponse}
              setOpenDeleteModal={setOpenDeleteModal}
              setIsEdit={setIsEdit}
              setEditData={setEditData}
              setOpenCreateModal={setOpenCreateModal}
              setSliceData={setSliceData}
            />
          )}
        </div>

        <CreateResponse
          open={openCreateModal}
          isEdit={isEdit}
          isAgent={isAgent}
          editData={editData}
          setOpenCreateModal={setOpenCreateModal}
          setIsEdit={setIsEdit}
          setEditData={setEditData}
        />

        <DeleteModal
          isOpen={openDeleteModal}
          visibleToTeam={selectedCannedResponse.for_team}
          setOpenDeleteModal={setOpenDeleteModal}
          deleteSingleCannedResponse={deleteSingleCannedResponse}
        />
      </div>
    );
  };

  return (
    <div>
      {isFreeUser() && renderUpgradeToPremiumView()}
      {!isFreeUser() && renderCannedResponseView()}
    </div>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
  cannedResponseList: state.settings.cannedResponse,
  agentAccess: state.dashboard.agentAccess,
  userInfo: state.auth,
});

const mapDispatch = (dispatch: any) => ({
  getResponseList: (projectId: number) =>
    dispatch.settings.fetchCannedResponse(projectId),
  deleteCannedResponse: (payload: {
    projectId: number;
    cannedResponseId: number;
  }) => dispatch.settings.deleteCannedResponse(payload),
});

export default connect(mapState, mapDispatch)(CannedResponse);
