import React from 'react';
import InputTag from '../../../../../components/utilityComponent/InputTag';
import DropDownList from '../components/DropDownList';
import PersistentMenu from '../components/PersistentMenu';

import { cloneDeep } from 'lodash';
import { XCircleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  success?: boolean;
  actionType?: string; //create or update
  errorMessage?: string;
  sequences: sequenceProps[];
  messengerSettingData: facebookMessengerSettingDataProps;
  integratedNlpList: { id: string; name: string; provider: string }[];
  handleChange: (
    key: string,
    value: string | string[] | object | number
  ) => void;
}

const FacebookMessengerSetting: React.FC<Props> = ({
  success = true,
  sequences,
  handleChange,
  errorMessage,
  integratedNlpList,
  actionType = 'create',
  messengerSettingData,
}) => {
  const { t } = useTranslation();
  const { replaceWithPartnerName } = usePartner();
  const getSelectedNlp = () => {
    return integratedNlpList.filter(
      (item: any) =>
        item.id === messengerSettingData.connected_nlp_integration_id
    )[0];
  };

  const [selectedNlpData, setSelectedNlpData] = React.useState<any>(
    !!getSelectedNlp() ? getSelectedNlp() : null
  );
  const persistentDataLength = messengerSettingData.persistent_menu.length;

  const addPersistentData = () => {
    if (persistentDataLength < 3) {
      const data = [
        ...messengerSettingData.persistent_menu,
        {
          id:
            persistentDataLength !== 0
              ? messengerSettingData.persistent_menu[persistentDataLength - 1]
                  .id + 1
              : persistentDataLength,
          title: '',
          type: 'url',
          value: '',
        },
      ];
      handleChange('persistent_menu', data);
    }
  };
  const updatePersistentData = (index: number, key: string, value: any) => {
    let clonedData = cloneDeep(messengerSettingData.persistent_menu);
    clonedData[index][key] = value;
    handleChange('persistent_menu', clonedData);
  };

  const deletePersistentData = (id: number) => {
    let updatedData = [
      ...messengerSettingData.persistent_menu.slice(0, id),
      ...messengerSettingData.persistent_menu.slice(id + 1),
    ];
    handleChange('persistent_menu', updatedData);
  };
  return (
    <>
      {!success && (
        <div className='flex justify-center p-6'>
          <div className='justify-center gap-2'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='text-sm text-red-600'>{t(errorMessage ?? '')}</p>
        </div>
      )}
      <div className='p-6'>
        <div className='col-span-6 mt-5 sm:col-span-6'>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Title')}*
          </label>
          <input
            type='text'
            placeholder={t('My messenger channel')}
            value={messengerSettingData.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='title'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 text-sm text-gray-500'>
            {replaceWithPartnerName(
              t(
                'This title will be used in {{MyAlice}} to identify this channel. Give it a title that you can differentiate with later.'
              )
            )}
          </p>
        </div>
      </div>
      <div className='p-6 border-t'>
        <>
          <label
            htmlFor='nlp_selection'
            className='block mb-1 text-sm font-medium text-gray-700'
          >
            {t('Select NLP App')}
          </label>
          <DropDownList
            options={integratedNlpList}
            selectedValue={!!selectedNlpData ? selectedNlpData : {}}
            titleKeyName={'name'}
            isFullWidth={true}
            buttonClassName={'w-full'}
            placeHolder={t('Select NLP app')}
            handleSelectedOption={(value: any) => {
              if (
                value.id !== messengerSettingData.connected_nlp_integration_id
              ) {
                setSelectedNlpData(value);
                handleChange('connected_nlp_integration_id', value.id);
              } else {
                setSelectedNlpData(null);
                handleChange('connected_nlp_integration_id', '');
              }
            }}
          />{' '}
        </>
        <div className='mt-6'>
          <label
            htmlFor='url_whitelist'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Whitelist Domain')}
          </label>
          <InputTag
            handleChange={handleChange}
            tagList={messengerSettingData.whitelisted_domains}
          />
        </div>
      </div>
      <div className='p-6'>
        {actionType !== 'create' && (
          <div className='col-span-6 sm:col-span-6'>
            <label
              htmlFor='color'
              className='block text-sm font-medium text-gray-700'
            >
              {t('Persistent Menu')}
            </label>
            {messengerSettingData.persistent_menu.map(
              (menu: persistentMenuDataProps, i: number) => (
                <PersistentMenu
                  key={i}
                  id={menu.id}
                  data={menu}
                  isLastElement={
                    messengerSettingData.persistent_menu[
                      persistentDataLength - 1
                    ].id === menu.id && persistentDataLength < 3
                  }
                  sequences={sequences}
                  onDataChange={(key: string, value: any) => {
                    updatePersistentData(i, key, value);
                  }}
                  onAdd={() => addPersistentData()}
                  onDelete={() => deletePersistentData(i)}
                  dataTypes={['sequence', 'url']}
                />
              )
            )}
            {persistentDataLength < 3 && (
              <div
                className='mt-2 text-blue-500 cursor-pointer'
                onClick={() => addPersistentData()}
              >
                {t('Add more+')}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default FacebookMessengerSetting;
