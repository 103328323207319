import React from 'react';
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import { Menu } from 'library/menu/Menu';
import useTranslation from 'components/customHooks/useTranslation';
import { CheckIcon } from '@heroicons/react/24/solid';
import { classNames } from 'utilities/utils';

interface CityDetailsProps {
  id: number;
  name: string;
}

interface Props {
  disable?: boolean;
  loading?: boolean;
  placeholder: string;
  selected: CityDetailsProps | null;
  options: CityDetailsProps[];
  handleChange: (value: { id: number; name: string }) => void;
}

const CityListDropDown: React.FC<Props> = ({
  selected,
  options,
  handleChange,
  loading = false,
  disable = false,
  placeholder = 'Search Your City',
}) => {
  const [citySearch, setCitySearch] = React.useState('');
  const [selectedCity, setSelectedCity] = React.useState(selected);
  const [toggleDropdown, setToggleDropdown] = React.useState(false);

  const { t } = useTranslation();

  React.useEffect(() => {
    setSelectedCity(selected);
  }, [selected]);

  const loadingView = () => {
    return (
      <span className='ml-1 w-5 h-5 flex items-center'>
        <div
          className='inline-block w-5 h-5 text-primary border-2 rounded-full spinner-border animate-spin'
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      </span>
    );
  };

  const renderNoCityFound = () => {
    return (
      options.filter((elemFilter) =>
        elemFilter?.name.toLowerCase().includes(citySearch.trim().toLowerCase())
      ).length === 0 && (
        <div className='flex items-center justify-center p-2 mt-2 text-xs font-medium text-gray-600 rounded-md cursor-default'>
          <ExclamationTriangleIcon className='w-4 h-4' />
          <span className='ml-2'>{t('Not Found')}</span>
        </div>
      )
    );
  };

  const renderCitySearchModuleView = () => {
    return (
      <div className='flex py-2 bg-white -top-px'>
        <div className=' relative w-full border-gray-300 rounded-md shadow-sm'>
          <div className=' absolute inset-y-0 left-0 flex items-center w-full pl-3 pointer-events-none'>
            <MagnifyingGlassIcon
              className='w-5 h-5 text-gray-400'
              aria-hidden='true'
            />
          </div>
          <input
            type='search'
            value={citySearch}
            disabled={disable}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCitySearch(e.target.value);
            }}
            className='block w-full pl-10 border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500 sm:text-sm'
            placeholder={t(placeholder)}
          />
        </div>
      </div>
    );
  };

  const renderCityDropdownView = (options: CityDetailsProps[]) => {
    return (
      <Menu>
        {options
          .filter((elemFilter) =>
            elemFilter?.name
              .toLowerCase()
              .includes(citySearch.trim().toLowerCase())
          )
          ?.map((cityOption: CityDetailsProps) => {
            return (
              <div
                data-value={cityOption}
                id={cityOption.name}
                onClick={() => {
                  setToggleDropdown(!toggleDropdown);
                  handleChange({ id: cityOption?.id, name: cityOption?.name });
                }}
                className='p-3 text-left cursor-pointer hover:bg-primary hover:text-white hover:font-semibold'
              >
                <span className='flex align-center text-sm font-medium'>
                  <span className='block truncate'>{cityOption.name}</span>
                  {selected?.id === cityOption?.id && (
                    <CheckIcon className='text-gray-700 ml-auto w-5 h-5' />
                  )}
                </span>
              </div>
            );
          })}
        {renderNoCityFound()}
      </Menu>
    );
  };

  const renderSelectCityButtonView = () => {
    return (
      <button
        disabled={disable}
        className={classNames(
          'relative w-full px-3 h-11 mt-1 border border-gray-300 rounded-md shadow-sm  text-left',
          disable
            ? 'bg-gray-300 cursor-not-allowed'
            : ' cursor-pointer focus:border-primary focus:ring-1 focus:ring-primary'
        )}
        onClick={() => setToggleDropdown(!toggleDropdown)}
      >
        <span className='flex items-center'>
          <span className='block truncate text-sm font-medium'>
            {selectedCity?.name || 'Select a city'}
          </span>
        </span>

        <span className='absolute right-[10px] top-[11px]'>
          <ChevronUpDownIcon className='w-5 h-5' aria-hidden='true' />
        </span>
      </button>
    );
  };

  return (
    <div className='relative'>
      {renderSelectCityButtonView()}
      {toggleDropdown && (
        <div className='bg-white absolute w-full mt-1 z-10 border rounded-md'>
          <div className='p-1 sticky border-white rounded-md'>
            {renderCitySearchModuleView()}
          </div>
          <div className='relative z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {loading && (
              <div className='w-full p-4 flex justify-center items-center'>
                {loadingView()}{' '}
              </div>
            )}
            {!loading && renderCityDropdownView(options)}
          </div>
        </div>
      )}
    </div>
  );
};

export default CityListDropDown;
