import React from 'react';
import { Menu } from '@headlessui/react';

import {
  TrashIcon,
  SignalIcon,
  SignalSlashIcon,
  CodeBracketIcon,
  PencilIcon,
  Square2StackIcon,
  TicketIcon,
  NewspaperIcon,
} from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../../../../utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  platformType: string;
  isConnected: boolean;
  handleDisconnect: () => void;
  handleRemove: () => void;
  handleEdit: () => void;
  handleScriptCopy: () => void;
  handleChannelReconnect: () => void;
  handleClone: () => void;
  handleManageTemplate: () => void;
  handleAccountInfo: () => void;
  handleTicketSettings: () => void;
  isEcommerce: boolean;
}

const ChannelCardMenuItems: React.FC<Props> = ({
  platformType,
  isConnected,
  handleDisconnect,
  handleRemove,
  handleEdit,
  handleScriptCopy,
  handleChannelReconnect,
  handleClone,
  handleManageTemplate,
  handleAccountInfo,
  handleTicketSettings,
  isEcommerce,
}) => {
  const { t } = useTranslation();
  const loadConnectivityBtnForEmailChannels = () => {
    if (['office365'].includes(platformType) && !isConnected) {
      return false;
    } else {
      return true;
    }
  };
  const shouldLoadConnectivityBtnForEmailChannels =
    loadConnectivityBtnForEmailChannels();
  return (
    <>
      {platformType !== 'whatsapp_messenger' &&
        !isEcommerce &&
        shouldLoadConnectivityBtnForEmailChannels && (
          <div className='py-1 cursor-pointer'>
            <Menu.Item>
              {({ active }) => (
                <span
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex gap-3 items-center px-4 py-2 text-sm cursor-pointer'
                  )}
                  onClick={() => {
                    if (isConnected) handleDisconnect();
                    else handleChannelReconnect();
                  }}
                >
                  {isConnected ? (
                    <SignalSlashIcon
                      className='w-5 h-5 text-gray-400 group-hover:text-gray-500'
                      aria-hidden='true'
                    />
                  ) : (
                    <SignalIcon
                      className='w-5 h-5 text-gray-400 group-hover:text-gray-500'
                      aria-hidden='true'
                    />
                  )}
                  {isConnected ? t('Disconnect') : t('Connect')}
                </span>
              )}
            </Menu.Item>
          </div>
        )}
      {(platformType === 'webchat' || platformType === 'app_messenger') &&
        !isEcommerce && (
          <div className='py-1 cursor-pointer'>
            <Menu.Item>
              {({ active }) => (
                <span
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex gap-3 items-center px-4 py-2 text-sm cursor-pointer'
                  )}
                  onClick={() => handleScriptCopy()}
                >
                  <CodeBracketIcon
                    className='w-5 h-5 text-gray-400 group-hover:text-gray-500'
                    aria-hidden='true'
                  />
                  {platformType === 'webchat'
                    ? t('Get Plugin Code')
                    : t('Api Key')}
                </span>
              )}
            </Menu.Item>
          </div>
        )}
      {!['custom_email', 'office365'].includes(platformType) && (
        <div className='py-1 cursor-pointer'>
          <Menu.Item>
            {({ active }) => (
              <span
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex gap-3 items-center px-4 py-2 text-sm w-full cursor-pointer'
                )}
                onClick={() => handleEdit()}
              >
                <PencilIcon
                  className='w-5 h-5 text-gray-400 group-hover:text-gray-500'
                  aria-hidden='true'
                />
                {t('Edit')}
              </span>
            )}
          </Menu.Item>
        </div>
      )}
      {platformType === 'whatsapp_bsp' && (
        <div className='py-1 cursor-pointer'>
          <Menu.Item>
            {({ active }) => (
              <span
                className={classNames(
                  active
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-700 cursor-pointer',
                  'group flex gap-3 items-center px-4 py-2 text-sm cursor-pointer'
                )}
                onClick={async () => handleAccountInfo()}
              >
                <InformationCircleIcon
                  className='w-5 h-5 text-gray-600 group-hover:text-gray-500'
                  aria-hidden='true'
                />
                {t('Account Info')}
              </span>
            )}
          </Menu.Item>
        </div>
      )}
      {platformType === 'custom_email' && (
        <div className='py-1 cursor-pointer'>
          <Menu.Item>
            {({ active }) => (
              <span
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex gap-3 items-center px-4 py-2 text-sm w-full cursor-pointer'
                )}
                onClick={() => handleEdit()}
              >
                <PencilIcon
                  className='w-5 h-5 text-gray-400 group-hover:text-gray-500'
                  aria-hidden='true'
                />
                {t('Edit')}
              </span>
            )}
          </Menu.Item>
        </div>
      )}
      {platformType !== 'whatsapp_messenger' &&
        platformType !== 'whatsapp_bsp' &&
        !isEcommerce && (
          <div className='py-1 cursor-pointer'>
            <Menu.Item>
              {({ active }) => (
                <span
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex gap-3 items-center px-4 py-2 text-sm cursor-pointer'
                  )}
                  onClick={() => handleRemove()}
                >
                  <TrashIcon
                    className='w-5 h-5 text-gray-400 group-hover:text-gray-500'
                    aria-hidden='true'
                  />
                  {t('Delete')}
                </span>
              )}
            </Menu.Item>
          </div>
        )}
      {platformType !== 'gmail' && (
        <div className='py-1 cursor-pointer'>
          <Menu.Item>
            {({ active }) => (
              <span
                className={classNames(
                  active
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-700 cursor-pointer',
                  'group flex gap-3 items-center px-4 py-2 text-sm cursor-pointer'
                )}
                onClick={() => handleClone()}
              >
                <Square2StackIcon
                  className='w-5 h-5 text-gray-400 group-hover:text-gray-500'
                  aria-hidden='true'
                />
                {t('Clone')}
              </span>
            )}
          </Menu.Item>
        </div>
      )}

      {platformType === 'whatsapp_bsp' && (
        <div className='py-1 cursor-pointer'>
          <Menu.Item>
            {({ active }) => (
              <span
                className={classNames(
                  active
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-700 cursor-pointer',
                  'group flex gap-3 items-center px-4 py-2 text-sm cursor-pointer'
                )}
                onClick={() => handleManageTemplate()}
              >
                <NewspaperIcon
                  className='w-5 h-5 text-gray-400 group-hover:text-gray-500'
                  aria-hidden='true'
                />
                {t('Manage Templates')}
              </span>
            )}
          </Menu.Item>
        </div>
      )}

      {!['custom_email', 'gmail'].includes(platformType) && (
        <div className='py-1 cursor-pointer'>
          <Menu.Item>
            {({ active }) => (
              <span
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex gap-3 items-center px-4 py-2 text-sm cursor-pointer'
                )}
                onClick={() => handleTicketSettings()}
              >
                <TicketIcon
                  className='w-5 h-5 text-gray-400 group-hover:text-gray-500'
                  aria-hidden='true'
                />
                {t('Ticket Settings')}
              </span>
            )}
          </Menu.Item>
        </div>
      )}
    </>
  );
};
export default ChannelCardMenuItems;
