import React from 'react';
import Chatbox from './components/chatbox/Chatbox';
import EmailChatBox from './components/chatbox/EmailChatBox';

import {
  classNames,
  emailChannel,
  emailChannels,
  feedChannels,
} from '../../../../utilities/utils';
import ConversationBar from './components/conversation/ConversationBar';
import MiddleBarHeader from './components/topbar/MiddlebarHeader';

import { SelectedTeamInterface } from 'index';
import { cloneDeep } from 'lodash';
import {
  AgentListInterface,
  assignTicketRequestBody,
  BotConversationQueryInterface,
  CommentStatusPayloadInterface,
  ConversationQueryInterface,
  CustomerInfoInterface,
  CustomerReplyBlockInterface,
  GroupMemberInterface,
  IDatalabList,
  ITicketQueueData,
  SavedRepliesInterface,
  SendEmailMessageRequestBodyInterface,
  SendGmailMessageRequestBodyInterface,
  SendMessageRequestBodyInterface,
  TicketActionInterface,
  TicketFilterInterface,
  TicketInterface,
  UpdateSingleTicketReducerInterface,
} from 'pages/inbox/inboxInterface';
import { isFilterAppliedWithOutPrivateView } from 'pages/inbox/utils/functions';
import { connect } from 'react-redux';
import EmptyState from './components/EmptyState';
import CustomerReply from './messageBlocks/CustomerReply';

interface Props {
  userId: number;
  adminAvatar: string | null;
  conversationData: any; // tobe refactored
  showRightbar: boolean;
  pusherConnection: any;
  selectedQueueType: string;
  chatboxActiveNav: string;
  hasSupervisorAccess: boolean;
  agentGroup: GroupInterface[];
  conversationLoading: boolean;
  botConversationLoading: boolean;
  ticketQueue: ITicketQueueData;
  ticketIsResolvedStatus: number;
  savedReplies: SavedRepliesInterface[];
  datalabList: IDatalabList[];
  conversationLoadingOnScroll: boolean;
  botConversationLoadingOnScroll: boolean;
  resolveTicketLoading: boolean;
  ticketList: TicketInterface[];
  selectedTicket: TicketInterface;
  agentList: AgentListInterface[];
  isEcommerceModalOpen: boolean;
  ticketFilterData: TicketFilterInterface;
  ticketActions: TicketActionInterface[];
  selectedProject: SelectedTeamInterface;
  customerInformation: CustomerInfoInterface;

  updateShowUpgradeModal: (payload: boolean) => void;
  updateFeedCommentStatus: (payload: CommentStatusPayloadInterface) => void;
  toggleEcommerceModal: (value: boolean) => void;
  fetchGroupMembers: (customer_id: number) => Promise<GroupMemberInterface>;
  handleRightbarAppearence: (value: boolean) => void;
  updateSateData: (key: string, value: any) => void;
  updateInboxDatalabStateData: (key: string, value: any) => void;
  resolveTicket: (ticketId: number, status: boolean) => boolean;
  holdTicket: (
    ticketId: number,
    status: boolean,
    ticketData: TicketInterface
  ) => boolean;
  updateConversationData: (newConversation: any) => void;
  addConversationData: (event: any, adminId: number) => void;
  addFeedReplyViaPusher: (event: any, adminId: number) => void;
  removeTicketFromListOnAgentAssignment: (
    payload: UpdateSingleTicketReducerInterface
  ) => void;
  sendMessegengerChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => Promise<void>;
  sendFeedChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => void;
  sendEmailChannelMessage: (
    requestBody:
      | SendEmailMessageRequestBodyInterface
      | SendGmailMessageRequestBodyInterface
  ) => void;
  updateSingleTicket: (payload: UpdateSingleTicketReducerInterface) => void;
  assignTicket: (requestBody: assignTicketRequestBody) => Promise<boolean>;
  assignBotTicket: (requestBody: assignTicketRequestBody) => Promise<boolean>;
  reopenClosedTicket: (
    requestBody: assignTicketRequestBody
  ) => Promise<boolean>;
  fetchTicketConversationOnScroll: (
    query: ConversationQueryInterface
  ) => Promise<boolean>;
  fetchBotConversationOnScroll: (
    query: BotConversationQueryInterface
  ) => Promise<boolean>;
  getWhatsAppMesssageTemplates: (platformId: number) => boolean;
  handleCloseRightBar: () => void;
  updateSelectedNav: (navId: number) => void;
}

const MiddleBar: React.FC<Props> = ({
  userId,
  agentList,
  agentGroup,
  ticketList,
  holdTicket,
  adminAvatar,
  ticketQueue,
  savedReplies,
  datalabList,
  showRightbar,
  ticketActions,
  selectedTicket,
  selectedProject,
  pusherConnection,
  ticketFilterData,
  conversationData,
  chatboxActiveNav,
  selectedQueueType,
  fetchGroupMembers,
  hasSupervisorAccess,
  customerInformation,
  conversationLoading,
  resolveTicketLoading,
  isEcommerceModalOpen,
  botConversationLoading,
  ticketIsResolvedStatus,
  conversationLoadingOnScroll,
  botConversationLoadingOnScroll,
  assignTicket,
  resolveTicket,
  updateSateData,
  assignBotTicket,
  updateSelectedNav,
  updateSingleTicket,
  reopenClosedTicket,
  handleCloseRightBar,
  addConversationData,
  toggleEcommerceModal,
  addFeedReplyViaPusher,
  updateShowUpgradeModal,
  sendFeedChannelMessage,
  updateConversationData,
  sendEmailChannelMessage,
  updateFeedCommentStatus,
  handleRightbarAppearence,
  getWhatsAppMesssageTemplates,
  fetchBotConversationOnScroll,
  sendMessegengerChannelMessage,
  fetchTicketConversationOnScroll,
  updateInboxDatalabStateData,
  removeTicketFromListOnAgentAssignment,
}) => {
  const [customerPusherConnection, setCustomerPusherConnection] =
    React.useState<any>(null);

  const [lineCount, setLineCount] = React.useState(1);
  const [conversationBlockDataForReply, setConversationBlockDataForReply] =
    React.useState<CustomerReplyBlockInterface | null>(null);

  const handleTicketResolving = async (status: boolean) => {
    const resolveTicketId = selectedTicket?.id;
    await resolveTicket(resolveTicketId, status);
  };

  const updateBotTicketsAfterAssign = () => {
    let index = ticketList.findIndex(
      (ticket) => ticket?.customer_id === selectedTicket?.customer_id
    );
    const updatedTickets = ticketList.filter(
      (ticket) => ticket.customer_id !== selectedTicket?.customer_id
    );
    if (updatedTickets.length > 0 && index > -1) {
      updateSateData(
        'selectedTicket',
        index === ticketList.length - 1
          ? updatedTickets[0]
          : updatedTickets[index]
      );
      updateSateData('ticketList', [...updatedTickets]);
    }
  };

  const handleTicketAssignment = async (agentId: number) => {
    let selectedTicketLocal = cloneDeep(selectedTicket);
    if (!!agentId) {
      selectedTicketLocal = {
        ...selectedTicketLocal,
        assigned_agent: agentId,
        assigned_group: null,
      };
    }
    // TODO: uncomment this statements when AssignmentButton.tsx is disconnected from store
    // if (!!groupId) {
    //   selectedTicketLocal = {
    //     ...selectedTicketLocal,
    //     assigned_agent: null,
    //     assigned_group: groupId,
    //   };
    // }

    let requestBody = {
      ticketId: selectedTicket?.id,
      customer_id: selectedTicket?.customer_id,
      agentId: agentId,
      groupId: null,
      note: '',
      selectedTicket: selectedTicketLocal,
    };
    const res =
      selectedQueueType.toLowerCase() === 'bot'
        ? await assignBotTicket(requestBody)
        : await assignTicket(requestBody);
    if (res) {
      if (selectedQueueType.toLowerCase() === 'bot') {
        updateBotTicketsAfterAssign();
      } else
        updateSingleTicket({
          userId: userId,
          ticketData: selectedTicketLocal,
          projectId: selectedProject?.id,
        });
    }
  };

  const handleTicketMessageFromPusherEvent = () => {
    if (!!pusherConnection) {
      if (!!customerPusherConnection) {
        pusherConnection.unsubscribe(customerPusherConnection);
      }
      const messageChannel = pusherConnection.subscribe(
        `chat-C_${selectedTicket?.customer_id}`
      );
      setCustomerPusherConnection(messageChannel);

      messageChannel.bind(`messages`, (event: any) => {
        addConversationData(event, userId);
      });
    }
  };

  const handleTicketCommentFromPusherEvent = () => {
    if (!!pusherConnection) {
      if (!!customerPusherConnection) {
        pusherConnection.unsubscribe(customerPusherConnection);
      }
      const feedChannel = pusherConnection.subscribe(
        `chat-T_${selectedTicket?.id}`
      );
      setCustomerPusherConnection(feedChannel);

      feedChannel.bind(`messages`, (event: any) => {
        addFeedReplyViaPusher(event, userId);
      });
    }
  };

  const scrollToBottom = () => {
    const hasScrollBehavior =
      'scrollBehavior' in document.documentElement.style;
    const container = document.getElementById('conversation-bar');
    if (!!container) {
      if (hasScrollBehavior) {
        container.scroll({
          top: container.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        container.scrollTop = container?.scrollHeight;
      }
    }
  };

  const handleMessengerChannelMessage = async (
    messageRequestBody: SendMessageRequestBodyInterface
  ) => {
    await sendMessegengerChannelMessage(messageRequestBody);
    scrollToBottom();
  };

  const handleFeedChannelMessage = async (
    messageRequestBody: SendMessageRequestBodyInterface
  ) => {
    await sendFeedChannelMessage(messageRequestBody);
    scrollToBottom();
  };

  const handleEmailChannelMessage = async (
    messageRequestBody:
      | SendEmailMessageRequestBodyInterface
      | SendGmailMessageRequestBodyInterface
  ) => {
    await sendEmailChannelMessage(messageRequestBody);
    scrollToBottom();
  };
  const handleToggleEcommerceModal = () => {
    toggleEcommerceModal(!isEcommerceModalOpen);
    handleCloseRightBar();
  };

  React.useEffect(() => {
    let platformType = selectedTicket?.customer_platform_type;
    let chatboxActiveNav = '';

    if (feedChannels.includes(platformType)) {
      chatboxActiveNav = 'Comment';
    } else if (emailChannel.includes(platformType)) {
      chatboxActiveNav = 'Email';
    } else {
      chatboxActiveNav = 'Chat';
    }

    updateSateData('chatboxActiveNav', chatboxActiveNav);

    feedChannels.includes(platformType)
      ? handleTicketCommentFromPusherEvent()
      : handleTicketMessageFromPusherEvent();

    if (
      selectedTicket &&
      selectedTicket?.customer_platform_type === 'whatsapp_bsp'
    ) {
      getWhatsAppMesssageTemplates(selectedTicket.customer_platform_id);
    }
    toggleEcommerceModal(false);
    setConversationBlockDataForReply(null);
    // eslint-disable-next-line
  }, [selectedTicket?.id]);

  return (
    <>
      <div
        className={classNames(
          'w-full h-full',
          !ticketList || ticketList.length < 1 ? 'block' : 'hidden'
        )}
      >
        <EmptyState
          isClosed={ticketIsResolvedStatus === 1}
          isFilterApplied={isFilterAppliedWithOutPrivateView(
            ticketQueue?.type,
            ticketFilterData
          )}
          isPrivateView={ticketQueue?.type.toLowerCase() === 'private'}
          isUnassigned={ticketQueue?.type.toLowerCase() === 'unassigned'}
          isBot={ticketQueue?.type.toLowerCase() === 'bot'}
        />
      </div>
      <div
        className={classNames(
          'w-full h-full relative',
          ticketList.length > 0 ? 'block' : 'hidden'
        )}
      >
        <MiddleBarHeader
          userId={userId}
          agentList={agentList}
          agentGroup={agentGroup}
          holdTicket={holdTicket}
          ticketQueue={ticketQueue}
          assignTicket={assignTicket}
          showRightbar={showRightbar}
          ticketActions={ticketActions}
          selectedTicket={selectedTicket}
          updateStateData={updateSateData}
          selectedProject={selectedProject}
          assignBotTicket={assignBotTicket}
          selectedQueueType={selectedQueueType}
          reopenClosedTicket={reopenClosedTicket}
          updateSingleTicket={updateSingleTicket}
          hasSupervisorAccess={hasSupervisorAccess}
          conversationLoading={conversationLoading}
          resolveTicketLoading={resolveTicketLoading}
          handleTicketResolve={handleTicketResolving}
          handleRightbarAppearence={handleRightbarAppearence}
          removeTicketFromListOnAgentAssignment={
            removeTicketFromListOnAgentAssignment
          }
          updateBotTicketsAfterAssign={updateBotTicketsAfterAssign}
        />
        <ConversationBar
          userId={userId}
          lineCount={lineCount}
          ticketQueue={ticketQueue}
          adminAvatar={adminAvatar}
          selectedTicket={selectedTicket}
          conversationData={conversationData}
          customerInformation={customerInformation}
          conversationLoading={conversationLoading}
          botConversationLoading={botConversationLoading}
          conversationLoadingOnScroll={conversationLoadingOnScroll}
          botConversationLoadingOnScroll={botConversationLoadingOnScroll}
          updateFeedCommentStatus={updateFeedCommentStatus}
          updateConversationDataOnScroll={fetchTicketConversationOnScroll}
          updateBotConversationDataOnScroll={fetchBotConversationOnScroll}
          handleConversationDataForAdminReply={setConversationBlockDataForReply}
        />

        {!emailChannels.includes(selectedTicket?.customer_platform_type) && (
          <>
            {/* Render Customer Reply Block Preview if conversationBlockDataForReply exists */}
            {!!conversationBlockDataForReply &&
              ['whatsapp_bsp', 'telegram_messenger', 'telegram_group'].includes(
                selectedTicket?.customer_platform_type
              ) && (
                <div
                  style={{ bottom: `${200 + (lineCount - 1) * 16}px` }}
                  className={`absolute z-10 w-full bg-white`}
                >
                  <CustomerReply
                    blockData={conversationBlockDataForReply}
                    isConversationBlock={false}
                    customerName={
                      selectedTicket?.customer_full_name || 'Anonymous User'
                    }
                    handleCustomerReplyOnClose={() =>
                      setConversationBlockDataForReply(null)
                    }
                  />
                </div>
              )}
            <Chatbox
              userId={userId}
              lineCount={lineCount}
              ticketQueue={ticketQueue}
              datalabList={datalabList}
              savedReplies={savedReplies}
              ticketActions={ticketActions}
              selectedTicket={selectedTicket}
              selectedProject={selectedProject}
              updateSelectedNav={updateSelectedNav}
              chatboxActiveNav={chatboxActiveNav}
              selectedQueueType={selectedQueueType}
              conversationLoading={conversationLoading}
              botConversationLoading={botConversationLoading}
              ticketIsResolvedStatus={ticketIsResolvedStatus}
              conversationBlockDataForReply={conversationBlockDataForReply}
              handleConversationBlockDataForReply={
                setConversationBlockDataForReply
              }
              setLineCount={setLineCount}
              updateSateData={updateSateData}
              fetchGroupMembers={fetchGroupMembers}
              handleRightbarAppearence={handleRightbarAppearence}
              updateShowUpgradeModal={updateShowUpgradeModal}
              handleTicketAssignment={handleTicketAssignment}
              handleFeedChannelMessage={handleFeedChannelMessage}
              handleEcommerceIconClick={handleToggleEcommerceModal}
              handleMessengerChannelMessage={handleMessengerChannelMessage}
              handleCloseCustomerReply={() =>
                setConversationBlockDataForReply(null)
              }
              updateInboxDatalabStateData={updateInboxDatalabStateData}
            />
          </>
        )}
        {emailChannels.includes(selectedTicket?.customer_platform_type) && (
          <EmailChatBox
            userId={userId}
            lineCount={lineCount}
            datalabList={datalabList}
            savedReplies={savedReplies}
            setLineCount={setLineCount}
            ticketActions={ticketActions}
            updateSateData={updateSateData}
            selectedTicket={selectedTicket}
            selectedProject={selectedProject}
            conversationData={conversationData}
            chatboxActiveNav={chatboxActiveNav}
            updateSelectedNav={updateSelectedNav}
            selectedQueueType={selectedQueueType}
            customerInformation={customerInformation}
            conversationLoading={conversationLoading}
            ticketIsResolvedStatus={ticketIsResolvedStatus}
            updateShowUpgradeModal={updateShowUpgradeModal}
            handleTicketAssignment={handleTicketAssignment}
            handleRightbarAppearence={handleRightbarAppearence}
            handleEcommerceIconClick={handleToggleEcommerceModal}
            handleEmailChannelMessage={handleEmailChannelMessage}
            updateInboxDatalabStateData={updateInboxDatalabStateData}
            handleMessengerChannelMessage={handleMessengerChannelMessage}
          />
        )}
      </div>
    </>
  );
};

const mapState = (state: any) => ({
  userId: state.auth.id,
  adminAvatar: state.auth.avatar,
  agentList: state.inbox.agentList,
  agentGroup: state.inbox.agentGroup,
  savedReplies: state.inbox.savedReplies,
  datalabList: state.inboxDatalab.datalabList,
  ticketActions: state.inbox.ticketActions,
  isEcommerceModalOpen: state.ecommerce.openModal,
  conversationData: state.inbox.conversationData,
  chatboxActiveNav: state.inbox.chatboxActiveNav,
  ticketFilterData: state.inbox.ticketFilterData,
  selectedProject: state.dashboard.selectedProject,
  selectedQueueType: state.inbox.selectedQueueType,
  customerInformation: state.inbox.customerInformation,
  ticketIsResolvedStatus: state.inbox.ticketIsResolvedStatus,
  resolveTicketLoading: state.loading.effects.inbox.resolveTicket,
  conversationLoadingOnScroll:
    state.loading.effects.inbox.fetchTicketConversationOnScroll,
  botConversationLoadingOnScroll:
    state.loading.effects.inbox.fetchBotConversationOnScroll,
});

const mapDispatch = (dispatch: any) => ({
  toggleEcommerceModal: (value: boolean) =>
    dispatch.ecommerce.updateEcommerceStateData({ key: 'openModal', value }),
  updateSateData: (key: string, value: any) =>
    dispatch.inbox.updateSateData({ key, value }),
  resolveTicket: (ticketId: number, status: boolean) =>
    dispatch.inbox.resolveTicket({ ticketId: ticketId, status: status }),
  holdTicket: (
    ticketId: number,
    status: boolean,
    ticketData: TicketInterface,
    userId: Number
  ) =>
    dispatch.inbox.holdTicket({
      ticketId: ticketId,
      status: status,
      ticketData: ticketData,
      userId: userId,
    }),
  sendMessegengerChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => dispatch.inbox.sendMessegengerChannelMessage(requestBody),
  sendFeedChannelMessage: (requestBody: SendMessageRequestBodyInterface) =>
    dispatch.inbox.sendFeedChannelMessage(requestBody),
  sendEmailChannelMessage: (
    requestBody: SendEmailMessageRequestBodyInterface
  ) => dispatch.inbox.sendEmailChannelMessage(requestBody),
  updateConversationData: (newConversation: any) =>
    dispatch.inbox.updateConversationData(newConversation),
  addConversationData: (event: any, adminId: number) =>
    dispatch.inbox.addConversationData({ event: event, adminId: adminId }),
  addFeedReplyViaPusher: (event: any, adminId: number) =>
    dispatch.inbox.addFeedReplyViaPusher({ event: event, adminId: adminId }),
  updateFeedCommentStatus: (payload: CommentStatusPayloadInterface) =>
    dispatch.inbox.updateFeedCommentStatus(payload),
  updateSingleTicket: (payload: UpdateSingleTicketReducerInterface) =>
    dispatch.inbox.updateSingleTicket(payload),
  assignTicket: (requestBody: assignTicketRequestBody) =>
    dispatch.inbox.assignTicket(requestBody),
  assignBotTicket: (requestBody: assignTicketRequestBody) =>
    dispatch.inbox.assignBotTicket(requestBody),
  reopenClosedTicket: (requestBody: assignTicketRequestBody) =>
    dispatch.inbox.reopenClosedTicket(requestBody),
  fetchTicketConversationOnScroll: (query: ConversationQueryInterface) =>
    dispatch.inbox.fetchTicketConversationOnScroll(query),
  fetchBotConversationOnScroll: (query: BotConversationQueryInterface) =>
    dispatch.inbox.fetchBotConversationOnScroll(query),
  getWhatsAppMesssageTemplates: (platformId: number) =>
    dispatch.inbox.getWhatsAppMesssageTemplates(platformId),
  removeTicketFromListOnAgentAssignment: (
    payload: UpdateSingleTicketReducerInterface
  ) => dispatch.inbox.removeTicketFromListOnAgentAssignment(payload),
  updateShowUpgradeModal: (payload: boolean) =>
    dispatch.dashboard.updateShowUpgradeModal(payload),
  fetchGroupMembers: (customer_id: number) =>
    dispatch.inbox.fetchGroupMembers(customer_id),
  updateSelectedNav: (navId: number) =>
    dispatch.dashboard.updateSelectedNav(navId),
  updateInboxDatalabStateData: (key: string, value: any) =>
    dispatch.inboxDatalab.updateInboxDatalabStateData({ key, value }),
});
// @ts-ignore
export default connect(mapState, mapDispatch)(MiddleBar);
