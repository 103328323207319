import {
  React,
  CheckIcon,
  useActions,
  useSelector,
  ConditionHeader,
  XMarkIcon,
} from 'pages/automationWorkflow/export';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from 'libraryV2/ui/dropdown-menu';
import {
  IDropdownGroup,
  IChildrenUiScreen,
} from 'pages/automationWorkflow/interface';

const RemoveTag = () => {
  const [removeTagValue, setRemoveTagValue] = React.useState('');
  // Extract required action functions from the custom hook
  const {
    removeSelectedTag,
    updateRemoveTagData,
    updateRemoveTagVariablePayload,
    updateConditionallyRenderingActionsUI,
  } = useActions();

  const { triggerInfo, selectedSaveActionsData } = useSelector(
    (state: any) => state.workflowAutomation
  );
  const { currentScreen, component } =
    selectedSaveActionsData?.eCommerce?.removeTags;

  const renderConditionallyRemoveTagsTypeView = (
    removeTagData: IChildrenUiScreen
  ) => {
    switch (removeTagData?.type) {
      case 'Tag_textInput':
        return (
          <div className='p-4 bg-white rounded mb-6'>
            <p className='text-gray-600 font-medium text-sm mb-1'>
              {removeTagData?.label || ''}{' '}
              {removeTagData?.required && (
                <span className='text-red-400 text-sm'>*</span>
              )}
            </p>
            <input
              value={removeTagValue}
              type={removeTagData?.['input-type']}
              className='w-full py-2 px-3 shadow-sm border border-gray-300 rounded-md text-sm text-gray-700 focus:ring-primary focus:border-primary focus:outline-none'
              placeholder='Value'
              onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                setRemoveTagValue(value.target.value)
              }
              onKeyDown={(value: any) => {
                if (value.key === 'Enter' && value.target.value.length !== 0) {
                  updateRemoveTagData(removeTagData.name, removeTagValue);
                  setRemoveTagValue('');
                }
              }}
            />
            {Array.isArray(
              component?.definition?.stored_data?.remove_tags_payload?.[
                removeTagData.name
              ]
            ) && (
              <div className='grid grid-cols-4 gap-3 mt-2'>
                {component?.definition?.stored_data?.remove_tags_payload?.[
                  removeTagData.name
                ].map((tagValue: string, index: number) => (
                  <div
                    key={index}
                    className='text-gray-800 text-sm font-medium bg-gray-100 rounded-xl px-2 py-1'
                  >
                    <div className='flex justify-between items-center'>
                      <div className='pr-2'>{tagValue}</div>
                      <div
                        className='cursor-pointer'
                        onClick={() => removeSelectedTag(index, tagValue)}
                      >
                        <XMarkIcon className='text-gray-400 w-4 h-4' />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <p className='text-gray-500 text-sm'>
              {removeTagData?.description}
            </p>
          </div>
        );
      case 'Dropdown':
        return (
          <div className='p-4 bg-white rounded mb-6'>
            <p className='text-gray-600 font-medium text-sm mb-1'>
              {removeTagData?.label || ''}
              {removeTagData?.required && (
                <span className='text-red-400 text-sm'>*</span>
              )}
            </p>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className='py-2 text-gray-500 text-sm w-full text-left bg-white border  rounded-md cursor-pointer focus:outline-none'>
                  <span className='px-3 py-1 text-sm font-regular text-gray-500'>
                    {component?.definition?.stored_data?.remove_tags_payload?.[
                      removeTagData.name
                    ] ?? 'Select Value'}
                  </span>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                side='bottom'
                align='start'
                className='shadow-lg w-60 bg-white z-30 rounded-md p-0'
              >
                {removeTagData?.['data-source']?.length &&
                  removeTagData?.['data-source']?.map(
                    (item: IDropdownGroup) => (
                      <DropdownMenuItem
                        key={item?.id}
                        onClick={() =>
                          updateRemoveTagData(removeTagData.name, item?.id)
                        }
                        className='flex items-center justify-between cursor-pointer bg-white hover:bg-gray-100 px-3 py-2 mt-1 mb-1'
                      >
                        <div>{item?.title}</div>
                        {component?.definition?.stored_data?.add_tag_payload?.[
                          removeTagData.name
                        ] === item?.title && (
                          <div>
                            <CheckIcon className='w-5 h-5 text-green-400' />
                          </div>
                        )}
                      </DropdownMenuItem>
                    )
                  )}
              </DropdownMenuContent>
            </DropdownMenu>
            <p className='text-gray-500 text-sm'>
              {removeTagData?.description}
            </p>
          </div>
        );
      case 'variable_dropdown_from_trigger':
        return (
          <div className='p-4 bg-white rounded mb-6'>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className='py-2 px-4 text-gray-500 text-sm w-full text-left bg-white border rounded-md cursor-pointer focus:outline-none'>
                  <span className='px-2 py-1 text-sm font-regular text-gray-500'>
                    Add Variable
                  </span>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align='start'
                className='shadow-lg bg-white z-30 w-64 divide-y divide-gray-100 rounded-md mb-3'
              >
                <DropdownMenuGroup asChild>
                  <>
                    {Object.keys(triggerInfo).map(
                      (item: string, categoryIndex: number) => (
                        <DropdownMenuSub key={categoryIndex}>
                          <DropdownMenuSubTrigger className='w-full border-none pt-0'>
                            <div
                              className={`${
                                Object.keys(triggerInfo).length - 1 ===
                                categoryIndex
                                  ? 'border-none pb-0'
                                  : 'border-b pb-2'
                              } w-full`}
                            >
                              <div
                                className={` w-full flex justify-between items-center cursor-pointer hover:bg-gray-100 py-1 text-gray-700 rounded-md`}
                              >
                                <h3 className='ml-3 font-medium text-base'>
                                  {item}
                                </h3>
                              </div>
                            </div>
                          </DropdownMenuSubTrigger>
                          <DropdownMenuPortal>
                            <DropdownMenuSubContent className='bg-white z-40 shadow-md h-full max-h-96 overflow-y-auto'>
                              {triggerInfo[item].length !== 0 &&
                                triggerInfo[item].map(
                                  (triggerValue: any, childIndex: number) => (
                                    <DropdownMenuItem
                                      key={childIndex}
                                      className='pt-0'
                                    >
                                      <div
                                        className='border-b p-2 pt-1 w-full'
                                        onClick={() => {
                                          updateRemoveTagVariablePayload(
                                            item,
                                            triggerValue?.name,
                                            `{{${triggerValue?.name}}}`
                                          );
                                        }}
                                      >
                                        <div
                                          className={` flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md`}
                                        >
                                          <div className='text-gray-600'>{`{{${triggerValue?.name}}}`}</div>
                                        </div>
                                      </div>
                                    </DropdownMenuItem>
                                  )
                                )}
                              {triggerInfo[item].length === 0 && (
                                <div className='p-2'>
                                  <div
                                    className={` bg-white flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md`}
                                  >
                                    <div className='text-gray-600'>
                                      No Variable found
                                    </div>
                                  </div>
                                </div>
                              )}
                            </DropdownMenuSubContent>
                          </DropdownMenuPortal>
                        </DropdownMenuSub>
                      )
                    )}
                  </>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <ConditionHeader
        title={currentScreen?.title}
        previousUIAction={() =>
          updateConditionallyRenderingActionsUI('actionElement')
        }
      />
      <div
        className='bg-gray-50 p-4 overflow-auto'
        style={{ height: 'calc(100vh - 196px)' }}
      >
        {currentScreen.layout.children[0].children.map(
          (item: IChildrenUiScreen, index: number) => (
            <div key={index}>{renderConditionallyRemoveTagsTypeView(item)}</div>
          )
        )}
      </div>
    </div>
  );
};

export default RemoveTag;
