import React from 'react';

import SmartFeed from './SmartFeed';
import SmartBlock from '../../messageBlocks/SmartBlock';
import ConversationBarLoader from './ConversationBarLoader';
import {
  emailChannels,
  feedChannels,
  messengerChannels,
} from '../../../../../../utilities/utils';
import emptyConversationBar from '../../../../assets/images/emptyConversationBar.svg';
import EmailSmartBlock from '../../messageBlocks/EmailSmartBlock';
import {
  CommentStatusPayloadInterface,
  BotConversationQueryInterface,
  ConversationQueryInterface,
  CustomerInfoInterface,
  ITicketQueueData,
  TicketInterface,
  CustomerReplyBlockInterface,
} from 'pages/inbox/inboxInterface';
import useTranslation from 'components/customHooks/useTranslation';
import { CircleSpinner } from 'pages/inbox/assets/iconComponent/Spinner';

interface Props {
  userId: number;
  lineCount?: number; // tobe refactored
  ticketQueue: ITicketQueueData;
  adminAvatar: string | null;
  conversationLoading: boolean;
  botConversationLoading: boolean;
  conversationLoadingOnScroll: boolean;
  botConversationLoadingOnScroll: boolean;
  selectedTicket: TicketInterface;
  conversationData: any;
  customerInformation: CustomerInfoInterface;

  updateConversationDataOnScroll: (
    query: ConversationQueryInterface
  ) => Promise<boolean>;
  updateBotConversationDataOnScroll: (
    query: BotConversationQueryInterface
  ) => Promise<boolean>;
  updateFeedCommentStatus: (payload: CommentStatusPayloadInterface) => void;
  handleConversationDataForAdminReply: (
    blockData: CustomerReplyBlockInterface
  ) => void;
}

const ConversationBar: React.FC<Props> = ({
  userId,
  adminAvatar,
  ticketQueue,
  lineCount = 1,
  selectedTicket,
  conversationData,
  customerInformation,
  conversationLoading,
  botConversationLoading,
  conversationLoadingOnScroll,
  botConversationLoadingOnScroll,
  updateFeedCommentStatus,
  updateConversationDataOnScroll,
  updateBotConversationDataOnScroll,
  handleConversationDataForAdminReply,
}) => {
  const { t } = useTranslation();
  const hasConversationData = (): boolean => {
    return (
      !!conversationData &&
      conversationData.length !== 0 &&
      !!selectedTicket &&
      !conversationLoading &&
      !botConversationLoading
    );
  };

  const shouldShowEmptyState = (): boolean => {
    return (
      !!conversationData &&
      conversationData.length === 0 &&
      !conversationLoading &&
      !botConversationLoading
    );
  };

  const handleScroll = async (event: any) => {
    let scrollYPosition = event?.currentTarget?.scrollTop;
    if (
      scrollYPosition === 0 &&
      hasConversationData() &&
      messengerChannels.includes(selectedTicket?.customer_platform_type)
    ) {
      let cursor = conversationData[conversationData.length - 1]?.id;
      let topElement = document.getElementById(`conversation_${cursor}`);
      let container = document.getElementById('conversation-bar');
      let isScrollable = false;
      if (ticketQueue?.type === 'bot') {
        let query = {
          customerId: selectedTicket?.id,
          parameter: `cursor=${cursor}&limit=100`,
        };
        isScrollable = await updateBotConversationDataOnScroll(query);
      } else {
        let query = {
          ticketId: selectedTicket?.id,
          parameter: `cursor=${cursor}&limit=100`,
        };
        isScrollable = await updateConversationDataOnScroll(query);
      }
      if (!!isScrollable && !!topElement && !!container) {
        let scrollPosition = topElement.offsetTop - 200;
        container.scrollTop = scrollPosition;
      }
    }
  };

  return (
    <div
      // inline style is being used so that we can control the conversation bar height according to line height
      // TODO: check if we can do it with tailwind css
      style={{
        height: `calc( 100% - ${
          !emailChannels.includes(selectedTicket?.customer_platform_type)
            ? 270 + (lineCount - 1) * 16
            : 320 + (lineCount - 1) * 16
        }px )`, // TODO: to be refactored
      }}
      className='mb-3 overflow-y-auto'
      id='conversation-bar'
      onScroll={handleScroll}
    >
      {(!!conversationLoading || !!botConversationLoading) && (
        <div className='flex flex-col content-center w-auto bg-white'>
          <ConversationBarLoader />
        </div>
      )}
      {(!!conversationLoadingOnScroll || !!botConversationLoadingOnScroll) && (
        <div className='flex flex-col content-center w-auto bg-white'>
          <CircleSpinner className='animate-spin ml-1 h-5 w-5 text-primary inline ' />
        </div>
      )}
      {hasConversationData() && (
        <>
          {feedChannels.includes(selectedTicket?.customer_platform_type) &&
            !Array.isArray(conversationData) && (
              <div className='flex flex-col w-auto bg-white'>
                <SmartFeed
                  conversationData={conversationData}
                  userId={userId}
                  adminAvatar={adminAvatar}
                  conversationLoading={conversationLoading}
                  updateFeedCommentStatus={updateFeedCommentStatus}
                />
              </div>
            )}
          {messengerChannels.includes(selectedTicket?.customer_platform_type) &&
            !!Array.isArray(conversationData) && (
              <div className='flex flex-col-reverse w-auto bg-white'>
                <SmartBlock
                  selectedTicket={selectedTicket}
                  conversationData={conversationData}
                  userId={userId}
                  conversationLoading={conversationLoading}
                  botConversationLoading={botConversationLoading}
                  handleConversationDataForAdminReply={
                    handleConversationDataForAdminReply
                  }
                />
              </div>
            )}

          {emailChannels.includes(selectedTicket?.customer_platform_type) &&
            !!Array.isArray(conversationData) && (
              <div className='flex flex-col-reverse w-auto bg-white'>
                <EmailSmartBlock
                  selectedTicket={selectedTicket}
                  conversationData={conversationData}
                  userId={userId}
                  conversationLoading={conversationLoading}
                  customerInformation={customerInformation}
                />
              </div>
            )}
        </>
      )}

      {shouldShowEmptyState() && (
        <div className='flex flex-col content-center justify-center w-auto h-full bg-white'>
          <img
            className='w-40 h-16'
            src={emptyConversationBar}
            alt='emptyConversation'
          />
          <div className='font-normal text-sm text-gray-500 mt-[18.5px]'>
            {t('No active chats here.')}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConversationBar;
