import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';
import { toast } from '../../../../libraryV2/ui/use-toast';
import { LOCAL_UTILS } from '../export';

const initialState = {
  limit: 10,
  offset: 0,
  total: 0,
  slaData: {},
  slaList: [],
  localSLAData: {},
};

export const settingsSLAConfiguration = {
  state: {
    ...initialState,
  },
  reducers: {
    updateSLAList(state, payload) {
      return { ...state, slaList: payload };
    },
    updateSLAData(state, payload) {
      return { ...state, slaData: payload };
    },
    updateLocalSLAData(state, payload) {
      return { ...state, localSLAData: payload };
    },
    updateLimit(state, payload) {
      return { ...state, limit: payload };
    },
    updateOffset(state, payload) {
      return { ...state, offset: payload };
    },
    updateTotal(state, payload) {
      return { ...state, total: payload };
    },
    updateSingleSLAOnList(state, payload) {
      const updatedSLAList = state.slaList.map((sla) =>
        sla.id === payload.id ? { ...sla, ...payload } : sla
      );

      return { ...state, slaList: updatedSLAList };
    },
    updateAgentListOnLocalSLAData(state, payload) {
      let copiedData = { ...payload };
      let updatedLocalSLAData = {
        ...copiedData.escalations,
        persons: LOCAL_UTILS.extractAgentIdsFromList(
          copiedData?.escalations?.persons
        ),
      };
      return { ...state, localSLAData: updatedLocalSLAData };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchSLAPolicyList(payload) {
      try {
        const res = await axios.get(
          config.inbox.fetchSLAPolicy(
            payload.teamId,
            'edge',
            payload.offset,
            payload.limit
          )
        );
        if (res.status === 200) {
          dispatch.settingsSLAConfiguration.updateSLAList(res.data.dataSource);
          dispatch.settingsSLAConfiguration.updateTotal(res.data.total);
          dispatch.settingsSLAConfiguration.updateLimit(payload.limit);
          dispatch.settingsSLAConfiguration.updateOffset(payload.offset);
          return {
            status: true,
            data: res.data,
          };
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Unable to fetch SLA data</p>,
          description: `Something went wrong while fetching sla data. Please try again`,
        });
        return {
          status: false,
          res: null,
        };
      }
    },
    async searchSLAPolicyList(payload) {
      try {
        const res = await axios.get(
          config.inbox.searchSLAPolicy(
            payload.teamId,
            'edge',
            payload.offset,
            payload.limit,
            payload.name
          )
        );
        if (res.status === 200) {
          dispatch.settingsSLAConfiguration.updateTotal(res.data.total);
          dispatch.settingsSLAConfiguration.updateLimit(payload.limit);
          dispatch.settingsSLAConfiguration.updateOffset(payload.offset);
          return {
            status: true,
            data: res.data.dataSource,
          };
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Unable to fetch SLA data</p>,
          description: `Something went wrong while fetching sla data. Please try again`,
        });
        return {
          status: false,
          res: null,
        };
      }
    },
    async createSLAPolicy(payload) {
      try {
        const res = await axios.post(
          config.inbox.slaCreation(payload.teamId, 'edge'),
          payload.requestBody
        );
        if (res.status === 200) {
          dispatch.settingsSLAConfiguration.updateSLAData(res.data.dataSource);
          dispatch.settingsSLAConfiguration.updateLocalSLAData(
            res.data.dataSource
          );
          toast({
            title: <p className='text-[#04B25F]'>SLA Creation Successful!</p>,
            description: `Your SLA has been created. Configure it now.`,
          });
          return {
            status: true,
            data: res.data,
          };
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Unable to create SLA Policy</p>,
          description: `Something went wrong while creating sla policy. Please try again`,
        });
        return {
          status: false,
          res: null,
        };
      }
    },

    async updateSLAPolicy(payload) {
      try {
        const res = await axios.patch(
          config.inbox.slaUpdate(
            payload.teamId,
            payload.requestBody.id,
            'edge'
          ),
          payload.requestBody
        );
        if (res.status === 200) {
          toast({
            title: <p className='text-green-500'>SLA configured Successful!</p>,
            description: 'Your SLA has been updated.',
          });
          dispatch.settingsSLAConfiguration.updateSLAData({});
          dispatch.settingsSLAConfiguration.updateLocalSLAData({});
          return {
            status: true,
            data: res.data.dataSource,
          };
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Failed to update SLA Policy</p>,
          description: `Something went wrong while updating sla policy. Please try again`,
        });
        return {
          status: false,
          res: null,
        };
      }
    },

    async updateIsActiveStatus(payload) {
      try {
        const res = await axios.patch(
          config.inbox.slaUpdate(
            payload.teamId,
            payload.requestBody.id,
            'edge'
          ),
          payload.requestBody
        );
        if (res.status === 200) {
          toast({
            title: <p className='text-green-500'>SLA status updated</p>,
            description: 'Your SLA status has been updated.',
          });
          dispatch.settingsSLAConfiguration.updateSLAData({});
          dispatch.settingsSLAConfiguration.updateLocalSLAData({});
          return {
            status: true,
            data: res.data.dataSource,
          };
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Failed to update SLA Policy</p>,
          description: `Something went wrong while updating sla policy. Please try again`,
        });
        return {
          status: false,
          res: null,
        };
      }
    },
    async deleteSLAPolicy(payload) {
      try {
        const res = await axios.delete(
          config.inbox.slaDelete(payload.teamId, payload.slaId, 'edge'),
          payload.requestBody
        );
        if (res.status === 200) {
          toast({
            title: <p className='text-green-500'>SLA successfully deleted</p>,
            description: 'Your SLA has been deleted.',
          });
          dispatch.settingsSLAConfiguration.updateSLAData({});
          dispatch.settingsSLAConfiguration.updateLocalSLAData({});
          return {
            status: true,
            data: res.data,
          };
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Failed to update SLA Policy</p>,
          description: `Something went wrong while deleting sla policy. Please try again`,
        });
        return {
          status: false,
          res: null,
        };
      }
    },
  }),
};
