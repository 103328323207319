import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import InputTag from '../../../../../components/utilityComponent/InputTag';
import DropDownList from '../components/DropDownList';
import WebChatPreview from '../../../assets/component/WebChatPreview';
import ImageUploadField from '../../../../../components/utilityComponent/ImageUploadField';

import { ChromePicker } from 'react-color';
import { CheckIcon } from '@heroicons/react/20/solid';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { handleImageUpload } from '../../../../../utilities/utils';
import { CustomSwitch as Switch } from '../../common/components/CustomSwitch';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';
interface Props {
  success?: boolean;
  actionType?: string;
  errorMessage?: string;
  subscriptionType?: string;
  webChatSettingData: webChatSettingData;
  integratedNlpList: { id: string; name: string; provider: string }[];
  handleChange: (key: string, value: string | string[] | boolean) => void;
}

const WebChatSetting: React.FC<Props> = ({
  handleChange,
  errorMessage,
  success = true,
  integratedNlpList,
  webChatSettingData,
  actionType = 'create',
  subscriptionType = 'free',
}) => {
  const { t } = useTranslation();
  const { currentPartnerInfo } = usePartner();
  const partnerName = currentPartnerInfo?.shortName ?? 'MyAlice';
  const getSelectedNlp = () => {
    return integratedNlpList.filter(
      (item: any) => item.id === webChatSettingData.connected_nlp_integration_id
    )[0];
  };

  const [selectedNlpData, setSelectedNlpData] = React.useState<any>(
    !!getSelectedNlp() ? getSelectedNlp() : null
  );
  const [showColorPallete, setShowColorPallete] = React.useState(false);
  const [selectedColor, setSelectedColor] = React.useState(
    webChatSettingData.primary_color
  );
  let getSelectedColor = !!selectedColor ? selectedColor : '#04B25F';
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  return (
    <>
      {!success && (
        <div className='flex justify-center p-6 gap-2'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='text-sm text-red-600'>{errorMessage}</p>
        </div>
      )}
      <div className='p-6'>
        <div className='col-span-6 sm:col-span-6'>
          <label
            htmlFor='chat_avatar'
            className='block mb-1 text-sm font-medium text-gray-700'
          >
            {t('Chat Avatar')}
          </label>
          <ImageUploadField
            image={webChatSettingData.avatar || ''}
            handleDrop={(image) =>
              handleImageUpload(image, (imageUrl: string) =>
                handleChange('avatar', imageUrl)
              )
            }
            handleClear={() => handleChange('avatar', '')}
          />
        </div>
        <div className='col-span-6 mt-5 sm:col-span-6'>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Title')}*
          </label>
          <input
            type='text'
            placeholder={t('My website channel')}
            value={webChatSettingData.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='title'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 text-sm text-gray-500'>
            {t(
              `This title will be used in ${partnerName} to identify this channel. Give it a title that you can differentiate with later.`
            )}
          </p>
        </div>
      </div>
      <div className='p-6 border-t'>
        <div className='flex'>
          <label
            htmlFor='url_whitelist'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Whitelist Domain')}
          </label>
        </div>
        <InputTag
          handleChange={handleChange}
          tagList={webChatSettingData.whitelisted_domains}
        />
      </div>
      <div className='p-6 border-t'>
        <label
          htmlFor='nlp_selection'
          className='block mb-1 text-sm font-medium text-gray-700'
        >
          {t('Select NLP App')}
        </label>
        <DropDownList
          options={integratedNlpList}
          selectedValue={!!selectedNlpData ? selectedNlpData : {}}
          titleKeyName={'name'}
          isFullWidth={true}
          buttonClassName={'w-full'}
          placeHolder={t('Select NLP app')}
          handleSelectedOption={(value: any) => {
            if (value.id !== webChatSettingData.connected_nlp_integration_id) {
              setSelectedNlpData(value);
              handleChange('connected_nlp_integration_id', value.id);
            } else {
              setSelectedNlpData(null);
              handleChange('connected_nlp_integration_id', '');
            }
          }}
        />
      </div>
      <div className='p-6 boreder-t'>
        <div className='col-span-6 sm:col-span-6'>
          <label
            htmlFor='color'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Color')}
          </label>
          <button
            onClick={() => setShowColorPallete(!showColorPallete)}
            style={{ background: `${getSelectedColor}` }}
            className='inline-flex w-10 h-10 p-2 bg-gray-500 rounded-full shadow focus:outline-none focus:shadow-outline'
          >
            <CheckIcon className='text-white' />
          </button>
          {showColorPallete ? (
            <div className='absolute z-10'>
              <div
                className='fixed inset-0'
                onClick={() => setShowColorPallete(!showColorPallete)}
              />
              <ChromePicker
                className='relative -top-px'
                color={getSelectedColor}
                disableAlpha={true}
                onChange={(clr: any) => {
                  setSelectedColor(clr.hex);
                  handleChange('primary_color', clr.hex);
                }}
              />
            </div>
          ) : null}
        </div>
        <div className='col-span-6 mt-3 sm:col-span-6'>
          <label
            htmlFor='preview'
            className='block text-sm font-medium text-gray-500'
          >
            {t('Webchat Preview:')}
          </label>
          <WebChatPreview
            title={webChatSettingData.title}
            fillColor={getSelectedColor}
            image={!!webChatSettingData.avatar ? webChatSettingData.avatar : ''}
          />
        </div>
      </div>
      <div className='p-6 border-t'>
        <label
          htmlFor='nlp_selection'
          className='block mb-1 text-sm font-medium text-gray-700'
        >
          {t(
            'Do you want to show the Welcome message prompt in your Livechat?'
          )}
        </label>
        <div className='flex justify-between mt-3'>
          <label className='font-medium text-gray-500'>
            {t('Desktop View')}
          </label>
          <Switch
            className={'ml-3'}
            checked={webChatSettingData?.live_chat_should_show_desktop_prompt}
            onChange={() => {
              handleChange(
                'live_chat_should_show_desktop_prompt',
                !webChatSettingData.live_chat_should_show_desktop_prompt
              );
            }}
          />
        </div>
        <div className='flex justify-between mt-3'>
          <label className='font-medium text-gray-500'>
            {t('Mobile View')}
          </label>
          <Switch
            className={'ml-3'}
            checked={webChatSettingData?.live_chat_should_show_mobile_prompt}
            onChange={() => {
              handleChange(
                'live_chat_should_show_mobile_prompt',
                !webChatSettingData.live_chat_should_show_mobile_prompt
              );
            }}
          />
        </div>
      </div>
      <div className='p-6 border-t'>
        <div className='flex justify-between mt-3'>
          <label
            htmlFor='widget_position'
            className='block mb-1 text-sm font-medium text-gray-700'
          >
            {t('Show the floating Livechat Widget on')}
          </label>
          <div className='space-y-4 sm:flex sm:rtl:gap-4 sm:items-center sm:space-y-0 sm:space-x-10'>
            <div className='flex items-center'>
              <input
                className='w-4 h-4 border-gray-300 text-primary focus:ring-0 focus:ring-primary'
                name='live_chat_widget_position'
                type='radio'
                checked={
                  webChatSettingData?.live_chat_widget_position === 'left'
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.name, 'left')
                }
              />
              <label
                htmlFor='live_chat_widget_position'
                className='block ltr:ml-3 rtl:mr-3 text-sm font-medium text-gray-700'
              >
                {t('Left Side')}
              </label>
            </div>
            <div className='flex items-center'>
              <input
                className='w-4 h-4 border-gray-300 text-primary focus:ring-0 focus:ring-primary'
                name='live_chat_widget_position'
                type='radio'
                checked={
                  webChatSettingData?.live_chat_widget_position === 'right'
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.name, 'right')
                }
              />
              <label
                htmlFor='live_chat_widget_position'
                className='block ltr:ml-3 rtl:mr-3 text-sm font-medium text-gray-700'
              >
                {t('Right Side')}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='p-6 border-t'>
        <div className='flex justify-between mt-3'>
          <label
            data-tip='tooltip'
            data-for='live_chat_should_show_myalice_branding'
            className={`block text-sm font-medium ${
              subscriptionType === 'free' ? 'text-gray-500' : 'text-gray-700'
            }`}
          >
            {t(`Do you want to remove “Chat By ${partnerName}” branding`)}
          </label>
          <div
            data-tip='tooltip'
            data-for='live_chat_should_show_myalice_branding'
          >
            <Switch
              className={'ml-3'}
              disabled={subscriptionType === 'free'}
              checked={
                !webChatSettingData?.live_chat_should_show_myalice_branding
              }
              onChange={() => {
                handleChange(
                  'live_chat_should_show_myalice_branding',
                  !webChatSettingData.live_chat_should_show_myalice_branding
                );
              }}
            />
          </div>
          {/* @ts-ignore */}
          <ReactTooltip
            disable={subscriptionType !== 'free'}
            delayShow={1}
            id='live_chat_should_show_myalice_branding'
            aria-haspopup='true'
            effect='solid'
          >
            <span>{t('This is available for Paid Packages')}</span>
          </ReactTooltip>
        </div>
      </div>

      {/* this may need if there come any plan to use checkbox instead of switch */}
      {/* <div className='p-6 border-t'>
        <div className='flex mt-3'>
          <div
            data-tip
            data-for='live_chat_should_show_myalice_branding'
            className='flex items-center mr-5'
          >
            <input
              aria-describedby='live_chat_should_show_myalice_branding-description'
              name='live_chat_should_show_myalice_branding'
              type='checkbox'
              disabled={subscriptionType === 'free'}
              checked={
                !webChatSettingData?.live_chat_should_show_myalice_branding
              }
              onChange={() => {
                handleChange(
                  'live_chat_should_show_myalice_branding',
                  !webChatSettingData.live_chat_should_show_myalice_branding
                );
              }}
              className='w-5 h-5 border-gray-300 rounded text-primary focus:ring-primary'
            />
          </div>
          <ReactTooltip
            disable={subscriptionType !== 'free'}
            delayShow={1}
            id='live_chat_should_show_myalice_branding'
            aria-haspopup='true'
          >
            <span>This is available for Paid Packages</span>
          </ReactTooltip>
          <label
            htmlFor='nlp_selection'
            className={`block text-sm font-medium ${
              subscriptionType === 'free' ? 'text-gray-500' : 'text-gray-700'
            }`}
          >
            Remove MyAlice branding
          </label>
        </div>
      </div> */}
    </>
  );
};
export default WebChatSetting;
