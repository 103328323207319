import React from 'react';
import { Switch } from 'evergreen-ui';
import PackageCard from './PackageCard';
import { connect } from 'react-redux';
import { availablePlansProperty, subscriptionDetailsType } from '../interface';
import { XCircleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import dayjs from 'dayjs';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

interface availablePlansProps {
  availablePlans: availablePlansProperty[];
  isBillingAnnually: boolean;
  updateIsBillingAnnually: (payload: boolean) => void;
  selectedProject: SelectedTeamInterface;
  subscriptionDetails: subscriptionDetailsType;
  setShowCancelModal: (value: boolean) => void;
  setShowReactivateModal: (value: boolean) => void;
}

const AvailablePlans: React.FC<availablePlansProps> = ({
  availablePlans,
  isBillingAnnually,
  updateIsBillingAnnually,
  selectedProject,
  subscriptionDetails,
  setShowCancelModal,
  setShowReactivateModal,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='flex w-full mb-4'>
        <p className='text-lg text-gray-900 font-medium w-1/2'>
          {t('Available Plans')}
        </p>
        <div className='flex w-1/2 justify-end'>
          <p className='text-sm text-gray-700 font-medium ltr:mr-1 rtl:ml-1'>
            {t('Billed Annually')}
          </p>
          <p className='text-sm text-primary font-semibold ltr:mr-1 rtl:ml-1'>
            {t('15% OFF')}
          </p>
          <Switch
            className='switch-rd'
            onChange={() => updateIsBillingAnnually(!isBillingAnnually)}
            height={20}
            checked={isBillingAnnually}
            hasCheckIcon={false}
          />
        </div>
      </div>
      <div
        className={`flex w-full gap-3 ${
          subscriptionDetails?.project_billing_info?.subscription_status ===
          'cancellation_scheduled'
            ? 'mb-4'
            : 'mb-8'
        }`}
      >
        {!!availablePlans &&
          availablePlans.map((plan) => (
            <PackageCard
              key={plan.order}
              selectedProject={selectedProject}
              subscriptionDetails={subscriptionDetails}
              planData={plan}
              discount={isBillingAnnually}
              setShowCancelModal={setShowCancelModal}
              setShowReactivateModal={setShowReactivateModal}
            />
          ))}
      </div>
      {subscriptionDetails?.project_billing_info?.subscription_status ===
        'cancellation_scheduled' && (
        <div className='flex flex-col w-full bg-red-50 p-4 mb-8 rounded-md'>
          <div className='flex'>
            <XCircleIcon className='w-4 h-4 text-red-400 m-0.5' />
            <p className='text-sm text-red-800 font-medium ml-3'>
              Cancellation Notice
            </p>
          </div>
          <div className='flex ml-8'>
            <p className='text-sm text-red-700 font-normal'>
              {t('You may enjoy your current subscription until')}{' '}
              <>
                {dayjs(
                  parseInt(
                    subscriptionDetails?.project_billing_info?.subscription_plan
                      ?.billing_end_date
                  ) * 1000
                ).format('DD MMMM YYYY')}
              </>
              . {t('Click')}{' '}
              <span className='text-sm text-red-800 font-medium'>
                {t('Reactivate')}
              </span>{' '}
              to revoke the cancellation decision and continue with the{' '}
              <span className='text-sm text-red-700 font-normal capitalize'>
                {
                  subscriptionDetails?.project_billing_info?.subscription_plan
                    ?.name
                }{' '}
                Plan.
              </span>{' '}
              {t('For further queries, please')}{' '}
              <a
                target='_blank'
                href='https://www.myalice.ai/support'
                className='text-sm text-red-800 font-medium'
                rel='noreferrer'
              >
                {t('contact our support.')}
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  availablePlans: state.newBilling.availablePlans,
  isBillingAnnually: state.newBilling.isBillingAnnually,
});

const mapDispatch = (dispatch: any) => ({
  updateIsBillingAnnually: dispatch.newBilling.updateIsBillingAnnually,
});

export default connect(mapState, mapDispatch)(AvailablePlans);
