import {
  ZidIcon,
  SallaImg,
  ShopifyImg,
  WoocommerceImg,
  UtilityContents,
  ChevronRightIcon,
  useTriggerActions,
  useAutomationWorkflow,
} from 'pages/automationWorkflow/export';
import {
  TriggerTagTypes,
  TriggerTagTypeCode,
} from 'pages/automationWorkflow/interface';

const TriggersTabView = () => {
  const { selectedTriggerTab } = useAutomationWorkflow();
  const { selectedTriggerTabAction } = useTriggerActions();

  const renderTriggerImg = (triggerCode: TriggerTagTypeCode) => {
    switch (triggerCode) {
      case 'shopify':
        return <img src={ShopifyImg} alt='shopify-icon' />;
      case 'woocommerce':
        return <img src={WoocommerceImg} alt='woocommerce-icon' />;
      case 'salla':
        return <img src={SallaImg} alt='salla-icon' />;
      case 'zid':
        return <img src={ZidIcon} alt='zid-icon' />;
      default:
        return null;
    }
  };

  return (
    <>
      {UtilityContents.TriggerTabData.map(
        (trigger: TriggerTagTypes, index: number) => {
          const isSelected = selectedTriggerTab === trigger?.code;
          const cursorStyle = isSelected
            ? 'cursor-pointer'
            : 'cursor-not-allowed';
          const bgColorAndHover = isSelected
            ? 'bg-gray-100 hover:bg-gray-100'
            : '';

          const combinedClasses = `flex justify-between items-center p-4 rounded-md ${cursorStyle} ${bgColorAndHover}`;
          return (
            <div className='border-b p-2 ' key={index}>
              <div
                className={combinedClasses}
                onClick={() => {
                  if (selectedTriggerTab === trigger?.code) {
                    selectedTriggerTabAction(trigger?.code);
                  }
                }}
                // Disable onClick for triggers other than 'shopify'
                {...(selectedTriggerTab !== trigger?.code && {
                  disabled: true,
                })}
              >
                <div className='flex items-center'>
                  {renderTriggerImg(trigger.code)}
                  <h3 className='ml-3 text-gray-700 font-medium text-base'>
                    {trigger?.title}
                  </h3>
                </div>
                <div>
                  <ChevronRightIcon className='w-5 h-5 text-gray-500' />
                </div>
              </div>
            </div>
          );
        }
      )}
    </>
  );
};

export default TriggersTabView;
