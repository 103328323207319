import { FC } from 'react';
import {
  Button,
  TrashIcon,
  Select,
  SelectValue,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectContent,
  AgentSelection,
  TargetSelection,
  PrioritySelection,
  usePolicy,
  LOCAL_UTILS,
  useEscalationsUtils,
  CircleAlertIcon,
} from '../../export';
import { IEscalation } from '../../interface';

interface Props {
  index: number;
  lastIndex: number;
  escalation: IEscalation;
  onDelete: () => void;
  handleEscalationRuleChange: (
    key: keyof IEscalation,
    value: string | number | Array<string | number>
  ) => void;
}

const EscalationRule: FC<Props> = ({
  index,
  escalation,
  onDelete,
  handleEscalationRuleChange,
}) => {
  const { checkForDuplicateEscalation, timeMap, checkEscalationRuleCondition } =
    useEscalationsUtils();
  let duplicationResult = checkForDuplicateEscalation(index);
  let violatedConditions = checkEscalationRuleCondition(index);

  return (
    <div className='mb-4'>
      <div className='flex justify-between mb-2'>
        <div className='flex flex-wrap items-center space-y-3'>
          <span className='mr-2'>If tickets priority is/are</span>
          <PrioritySelection
            priorityList={escalation.priority}
            handlePriorityListUpdate={(updatedList) =>
              handleEscalationRuleChange('priority', updatedList)
            }
          />
          <span className='mr-2'>and</span>
          <TargetSelection
            target={escalation.target}
            handleTargetUpdate={(selectedTarget) =>
              handleEscalationRuleChange('target', selectedTarget)
            }
          />
          <span className='mr-2'>exceeds,</span>
          <span className='mr-2'>send an escalation email</span>
          <div className='mr-2 w-52'>
            <SendingTimeSelection
              timeMap={timeMap}
              time={escalation.time}
              handleEscalationRuleChange={handleEscalationRuleChange}
            />
          </div>
          <span className='mr-2'>to</span>
          <div className='w-96'>
            <AgentSelection
              selectedAgentsList={escalation.persons}
              handleAgentListUpdate={(updatedList) =>
                handleEscalationRuleChange('persons', updatedList)
              }
            />
          </div>
        </div>
        <Button
          variant='outline'
          onClick={onDelete}
          className='px-2.5 py-2 mt-3'
        >
          <TrashIcon className='w-4 h-4' />
        </Button>
      </div>
      {duplicationResult && (
        <div className='flex flex-wrap items-center bg-white rounded-lg p-2.5 mt-4 text-textSecondary text-xs font-medium'>
          <CircleAlertIcon className='w-4 h-4 mr-1 error ' />
          <span className='mr-1'>Escalation can’t be set for</span>
          {duplicationResult?.priority.map((result: number) => (
            <span className='mr-1'>
              <b>{LOCAL_UTILS.getPriorityLabel(result)}</b>
            </span>
          ))}
          {}
          <span>
            priority tickets. You have already set{' '}
            <b>
              {timeMap.hasOwnProperty(Number(escalation?.time))
                ? timeMap[Number(escalation?.time)]
                : 'Unknown'}
            </b>{' '}
            as the{' '}
            <b>
              {duplicationResult?.target === 'resolution_time'
                ? `Resolution time `
                : `First response time `}
            </b>
            for it.
          </span>
        </div>
      )}
      {violatedConditions && (
        <div className='flex flex-wrap items-center bg-white rounded-lg p-2.5 mt-4 text-textSecondary text-xs font-medium'>
          <CircleAlertIcon className='w-4 h-4 mr-1 error ' />
          <span className='mr-1'>Escalation can’t be set for</span>
          {violatedConditions?.priority.map((result: number) => (
            <span className='mr-1'>
              <b>{LOCAL_UTILS.getPriorityLabel(result)}</b>
            </span>
          ))}
          {}
          <span>
            priority tickets. You have set the approach time lower than the
            <b>
              {violatedConditions?.target === 'resolution_time'
                ? ` Resolution time. `
                : ` First response time. `}
            </b>
          </span>
        </div>
      )}
    </div>
  );
};

const SendingTimeSelection: FC<{
  time: number;
  timeMap: Record<number, string>;
  handleEscalationRuleChange: (key: keyof IEscalation, value: number) => void;
}> = ({ time, timeMap, handleEscalationRuleChange }) => {
  const { inputFieldClass } = usePolicy();

  return (
    <Select
      value={String(time)}
      onValueChange={(value) =>
        handleEscalationRuleChange('time', Number(value))
      }
    >
      <SelectTrigger className={`${inputFieldClass} h-8 w-52 mr-2`}>
        <SelectValue placeholder='Select sending time' />
      </SelectTrigger>
      <SelectContent className='mt-1 bg-white'>
        <SelectGroup className='text-sm text-textPrimary'>
          {Object.entries(timeMap).map(([value, label]) => (
            <SelectItem
              key={value}
              value={value}
              className='hover:bg-background-hover'
            >
              {label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default EscalationRule;
