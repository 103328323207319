import { axios, useSelector } from 'pages/datalab/export';
import { getAPIURL, UtilityFunctions } from 'pages/automationWorkflow/export';
const useDynamicSearchField = () => {
  const { validateResponseObject, mapResponseData } = UtilityFunctions;

  const selectedTeam = useSelector(
    (state: any) => state.dashboard?.selectedProject
  );

  const preparePayloadForAxiosCall = <T,>(
    url: string,
    payload: T,
    method: string,
    replaceValue: string
  ): any => {
    // Clone the original payload to avoid modifying the input object
    const updatedPayload: T = { ...payload };

    // Replace the value of properties with the provided value
    for (const key in updatedPayload) {
      if (
        Object.prototype.hasOwnProperty.call(updatedPayload, key) &&
        updatedPayload[key] === '$$'
      ) {
        (updatedPayload as any)[key] = replaceValue;
      }
    }

    // Move payload properties to URL parameters for GET method
    if (method.toLowerCase() === 'get') {
      const { ...rest } = updatedPayload;
      return { url, method, params: { ...rest } };
    }

    // For other methods, payload remains in the request body
    return { url, method, data: updatedPayload };
  };

  const getPlaceholderValue = (key: string) => {
    const {
      id = 0,
      name = '',
      api_key = '',
      country = '',
      slug = '',
    } = selectedTeam;

    const placeholderMap: any = {
      base_url: getAPIURL(),
      team_id: id,
      team_name: name,
      team_slug: slug,
      team_country: country,
      team_api_key: api_key,
    };
    return placeholderMap[key] || '';
  };

  const replaceKeysInUrl = (url: string) => {
    const regex = /{{(.*?)}}/g;
    let match;

    // Iterate over all matches of the pattern {{key}} in the URL
    while ((match = regex.exec(url)) !== null) {
      const key = match[1];
      const value = getPlaceholderValue(key);
      url = url.replace(match[0], value);
    }

    return url;
  };

  const conditionComponentDynamicApiCallFunc = async (
    apiRequest: any,
    apiResponse: any,
    callback: any,
    query: string
  ) => {
    const { url, method, payload = {} } = apiRequest;
    const apiConfiguration = preparePayloadForAxiosCall(
      !!url ? replaceKeysInUrl(url) : '',
      payload,
      method.toLowerCase(),
      query
    );

    axios
      .request(apiConfiguration)
      .then((response: any) => {
        const validateResponse = validateResponseObject(
          response.data,
          apiResponse
        );
        if (!validateResponse?.success)
          callback({ ...validateResponse, data: [] });
        else {
          const data = mapResponseData(response, apiResponse);
          callback({
            success: !!data && data?.length > 0,
            data,
            error: 'No result found. Try different keyword',
          });
        }
      })
      .catch((error: any) => {
        console.error('error:', error);
        callback({
          success: false,
          data: [],
          error: 'No Data Found',
        });
      });
  };

  return { conditionComponentDynamicApiCallFunc };
};

export default useDynamicSearchField;
