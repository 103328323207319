import moment from 'moment';
import {
  CustomerInfoInterface,
  EmailConversationInterface,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import React from 'react';
import GmailLogo from '../../../../../assets/icons/gmailLogo.svg';
import defaultAvatar from '../../../assets/images/anonymousAvatar.svg';
import botAvatar from '../../../assets/images/bot-message-square.svg';
import ActionBar from './ActionBar';
import EmailAttachmentBlock from './EmailAttachmentBlock';
import EmailBlock from './EmailBlock';
import NoteMessage from './NoteMessage';

interface Props {
  userId: number;
  conversationLoading: boolean;
  selectedTicket: TicketInterface;
  conversationData: EmailConversationInterface[];
  customerInformation: CustomerInfoInterface;
}

const EmailSmartBlock: React.FC<Props> = ({
  userId,
  conversationLoading,
  selectedTicket,
  conversationData,
  customerInformation,
}) => {
  const getAvatar = (block: EmailConversationInterface) => {
    switch (block.source) {
      case 'customer':
        return block?.customer_info?.avatar || defaultAvatar;
      case 'bot':
        return botAvatar;
      case 'admin':
        return block?.admin_info?.avatar || defaultAvatar;
      case 'gmail':
        return GmailLogo;
      default:
        return defaultAvatar;
    }
  };

  const getDateBarFlag = (index: number) => {
    let now = moment().endOf('day');
    let nextBlock = now.diff(
      moment(conversationData[index + 1]?.timestamp),
      'days'
    );
    let thisBlock = now.diff(
      moment(conversationData[index]?.timestamp),
      'days'
    );

    if (nextBlock > thisBlock) {
      return true;
    } else if (index + 1 === conversationData.length) {
      return true;
    } else {
      return false;
    }
  };

  const getReplyName = (block: EmailConversationInterface): string => {
    switch (block.source) {
      case 'bot':
        return 'Chatbot';
      case 'admin':
        return block?.admin_info?.id === userId
          ? 'You'
          : block?.admin_info?.full_name || 'Anonymous User';
      case 'customer':
        return (
          block?.customer_info?.full_name || block?.customer_info?.primary_id
        );
      case 'gmail':
        return 'Gmail';
      case 'custom_email':
      case 'office365':
        return 'Admin';
      default:
        return 'Anonymous User';
    }
  };

  const getDefaultMessageBlock = (
    block: EmailConversationInterface,
    index: number
  ) => {
    let dateBarFlag = getDateBarFlag(index);

    let {
      source,
      timestamp,
      report,
      customer_info,
      subject,
      conversation_id,
      conversation_status,
    } = block;
    let blockData = {
      type: 'text',
      success: false,
      error: 'Data Type not Supported',
      text: '',
      payload: '',
    };
    return (
      <EmailBlock
        blockData={blockData}
        avatar={getAvatar(block)}
        name={getReplyName(block)}
        time={timestamp}
        source={source}
        isSent={conversation_status}
        enableDateBar={dateBarFlag}
        report={report}
        customerInfo={customer_info}
        subject={subject}
        meta={customerInformation}
        conversationId={conversation_id}
      />
    );
  };

  const getMessageBlocks = (
    block: EmailConversationInterface,
    index: number
  ) => {
    let {
      dataV2,
      source,
      timestamp,
      report,
      customer_info,
      subject,
      conversation_id,
      conversation_status,
    } = block;
    let dateBarFlag = getDateBarFlag(index);

    switch (dataV2?.type) {
      case 'text':
        return (
          <EmailBlock
            blockData={dataV2}
            avatar={getAvatar(block)}
            name={getReplyName(block)}
            time={timestamp}
            source={source}
            isSent={conversation_status}
            enableDateBar={dateBarFlag}
            report={report}
            customerInfo={customer_info}
            subject={subject}
            meta={customerInformation}
            conversationId={conversation_id}
          />
        );
      case 'note':
        return (
          <NoteMessage
            blockData={dataV2}
            avatar={getAvatar(block)}
            name={getReplyName(block)}
            time={timestamp}
            source={source}
            enableDateBar={dateBarFlag}
            platformType={customerInformation.platform.platform_type}
          />
        );
      case 'attachment':
        return (
          <EmailAttachmentBlock
            blockData={dataV2}
            avatar={getAvatar(block)}
            name={getReplyName(block)}
            time={timestamp}
            source={source}
            isSent={conversation_status}
            enableDateBar={dateBarFlag}
            report={report}
            subject={subject}
            customerInfo={customer_info}
            meta={customerInformation}
            conversationId={conversation_id}
          />
        );
      case 'action':
        return (
          <ActionBar
            blockData={dataV2}
            time={timestamp}
            enableDateBar={dateBarFlag}
          />
        );
      default:
        return getDefaultMessageBlock(block, index);
    }
  };

  const scrollToBottom = () => {
    const hasScrollBehavior =
      'scrollBehavior' in document.documentElement.style;
    const container = document.getElementById('conversation-bar');
    if (!!container) {
      if (hasScrollBehavior) {
        container.scroll({
          top: container.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        container.scrollTop = container?.scrollHeight;
      }
    }
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [conversationLoading]);

  return (
    <>
      {!!selectedTicket &&
        !!conversationData &&
        conversationData.map(
          (block: EmailConversationInterface, index: number) => (
            <div
              className='flex flex-col-reverse'
              key={index}
              id={`conversation_${block.id}`}
            >
              {getMessageBlocks(block, index)}
            </div>
          )
        )}
    </>
  );
};

export default EmailSmartBlock;
