import {
  useDispatch,
  useSelector,
  UtilityContents,
} from 'pages/automationWorkflow/export';
import { IUserFlowTableDataTypes } from 'pages/automationWorkflow/interface';

const useAutomationHistories = () => {
  const dispatch = useDispatch();
  const { selectedProject } = useSelector((state: any) => state.dashboard);

  // Destructure required state loading from the workflowAutomation
  const {
    getSelectedUserActionFlowLogList: isGetSelectedUserActionFlowLogListLoading,
  } = useSelector((state: any) => state.loading.effects.workflowAutomation);

  const handleViewSelectedAutomationHistories = (
    flowData: IUserFlowTableDataTypes
  ) => {
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'automationFlowDisplayViewType',
      value: 'viewHistories',
    });
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'automationName',
      value: flowData?.name,
    });
  };

  const fetchSelectedUserActionFlowLogData = (
    flowId: number,
    offset: number
  ) => {
    dispatch.workflowAutomation.getSelectedUserActionFlowLogList({
      flowId,
      offset,
      limit: UtilityContents.UserActionFlowHistoryGetterApiDataLimit,
      projectId: selectedProject?.id,
    });
  };

  const updateHistoryTableOffset = (data: number) => {
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'historyTableOffset',
      value: data,
    });
  };

  return {
    updateHistoryTableOffset,
    fetchSelectedUserActionFlowLogData,
    handleViewSelectedAutomationHistories,
    isGetSelectedUserActionFlowLogListLoading,
  };
};

export default useAutomationHistories;
