import React from 'react';
import FacebookLogin from 'react-facebook-login';
import instagramLogo from '../../../assets/icons/instaChat.svg';
import config from '../../../../../utilities/config';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { getMetaPermissionScope } from 'utilities/utils';

interface Props {
  processInstagramResponse: (response: any) => any;
  loading: boolean;
  type: string;
}

const InstagramConnection: React.FC<Props> = ({
  processInstagramResponse,
  loading = false,
  type,
}) => {
  const appId = config.misc.facebookAppId();

  return (
    <>
      <div className='p-6'>
        {loading && (
          <div className='flex flex-col items-center justify-center '>
            <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
            <span>fetching page list...</span>
          </div>
        )}
        {!loading && (
          <>
            <img
              src={instagramLogo}
              className='m-auto text-center'
              alt={'instagram logo'}
            />
            <p className='mt-3 text-xl font-medium text-center'>
              Connect With Instagram!
            </p>
            <p className='mt-3 text-sm font-normal text-center text-gray-00'>
              Connect your Instagram account, you can edit the details later.
              Please provide us the required permission to get started!
            </p>
            <br />
            <div className='flex justify-center'>
              <FacebookLogin
                appId={!!appId ? appId : ''}
                isDisabled={false}
                autoLoad={false}
                fields='email'
                scope={getMetaPermissionScope(type)}
                callback={processInstagramResponse}
                cssClass='instagram-button-connect'
                textButton='Connect With Instagram'
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default InstagramConnection;
