import useTranslation from 'components/customHooks/useTranslation';
import useMessageBlocksAction from 'pages/inbox/hooks/useMessageBlocksAction';
import {
  ConversationBlockAttachmentDataInterface,
  ConversationInterface,
  CustomerReplyBlockInterface,
  conversationStatusType,
} from 'pages/inbox/inboxInterface';
import React, { useRef, useState } from 'react';
import pauseIcon from '../../../assets/images/pauseIcon_v2.svg';
import playIcon from '../../../assets/images/playIcon.svg';
import { convertHttpToHttps } from '../../../utils/functions';
import Avatar from '../components/conversation/Avatar';
import ConversationBlockWrapper from '../components/conversation/ConversationBlockWrapper';
import AdminReplyToButton from './AdminReplyToButton';
import CustomerReply from './CustomerReply';
import DateBar from './DateBar';

interface Props {
  rawBlockData: ConversationInterface;
  blockData: ConversationBlockAttachmentDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  isSent: conversationStatusType | string;
  isMergeable: boolean;
  enableDateBar: boolean;
  report?: any; // TODO: format report
  hasPermissionToShowAdminReply: boolean;
  handleConversationDataForAdminReply: (
    blockData: CustomerReplyBlockInterface
  ) => void;
}

const AudioAttachment: React.FC<Props> = ({
  blockData,
  avatar,
  name,
  time,
  source,
  isSent,
  isMergeable,
  enableDateBar,
  report,
  rawBlockData,
  hasPermissionToShowAdminReply,
  handleConversationDataForAdminReply,
}) => {
  const { t } = useTranslation();
  const audioPlayer = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playPauseIcon, setPlayPauseIcon] = useState(playIcon);
  const [audioDuration, setAudioDuration] = useState('00:00');
  const [remainingTime, setRemainingTime] = useState('00:00');
  const [showReplyToButton, setShowReplyToButton] = React.useState(false);

  const { rowDirection, elementDirection, isZigzagView } =
    useMessageBlocksAction({ source });

  const getSourceNameColor = () => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
      case 'echo':
        return 'text-gray-900';
      default:
        return 'text-green-500';
    }
  };

  const renderAudioPlayerView = () => {
    return (
      <div
        className={`flex ltr:justify-between rtl:justify-end w-[160px] h-8 py-1.5 px-2.5 rounded-xl ${
          (report?.result?.reply_to_message || blockData.reply_to_data) &&
          'bg-[#FAFAFA] border z-10'
        }`}
      >
        <audio
          data-testid='audio-block'
          src={convertHttpToHttps(blockData?.urls[0]) || ''}
          ref={audioPlayer}
          onLoadedData={(e) => {
            getAudioDuration(Math.floor(e?.currentTarget?.duration));
          }}
          onTimeUpdate={(e) => {
            getRemainingTime(
              Math.floor(e?.currentTarget?.currentTime),
              Math.floor(e.currentTarget?.duration)
            );
          }}
        />
        <div className='flex items-center justify-center gap-2'>
          <button onClick={playPause} className='rtl:order-1'>
            <img className='w-4 h-4' src={playPauseIcon} alt='playPauseIcon' />
          </button>
          <div className='font-semibold sm:text-xs'>
            {remainingTime === '00:00' ? audioDuration : remainingTime}
          </div>
        </div>
      </div>
    );
  };

  const playPause = () => {
    const audio = audioPlayer?.current;
    let playerPromise;
    if (!isPlaying) {
      setIsPlaying(true);
      setPlayPauseIcon(pauseIcon);
      playerPromise = audio?.play();
    }
    if (!!isPlaying) {
      setIsPlaying(false);
      setPlayPauseIcon(playIcon);
      playerPromise = audio?.pause();
    }
    if (playerPromise !== undefined) {
      playerPromise.catch((error) => {
        console.log('error', error);
      });
    }
  };

  const resetTrack = () => {
    setRemainingTime(audioDuration);
    setIsPlaying(false);
    setPlayPauseIcon(playIcon);
    return remainingTime;
  };

  const getAudioDuration = (currentTime: number) => {
    let time = currentTime === Infinity ? 0 : currentTime;
    let minutes =
      Math.floor(time / 60) === 0
        ? '0' + Math.floor(time / 60)
        : Math.floor(time / 60);
    let seconds =
      Math.floor(time % 60) < 10
        ? '0' + Math.floor(time % 60)
        : Math.floor(time % 60);
    setAudioDuration(`${minutes}:${seconds}`);
  };

  const getRemainingTime = (currentTime: number, duration: number) => {
    let time = duration - currentTime;
    let minutes =
      Math.floor(time / 60) === 0
        ? '0' + Math.floor(time / 60)
        : Math.floor(time / 60);
    let seconds =
      Math.floor(time % 60) < 10
        ? '0' + Math.floor(time % 60)
        : Math.floor(time % 60);
    setRemainingTime(`${minutes}:${seconds}`);
    if (currentTime === duration) {
      resetTrack();
    }
  };

  const renderCustomerReply = () => {
    return (
      blockData?.reply_to_data && (
        <CustomerReply
          blockData={blockData?.reply_to_data}
          isZigzagView={isZigzagView}
        />
      )
    );
  };

  const renderContent = () => {
    return (
      <ConversationBlockWrapper
        time={time}
        success={isSent}
        isMerged={false}
        report={report}
        source={source}
        isFirstElement={!isMergeable}
        conversationType={blockData?.sub_type}
        replay={blockData?.reply_to_data ? true : false}
      >
        {!!blockData.reply_to_data && renderCustomerReply()}
        {!!audioPlayer && renderAudioPlayerView()}
      </ConversationBlockWrapper>
    );
  };

  return (
    <>
      <div
        className='relative'
        onMouseEnter={() => setShowReplyToButton(true)}
        onMouseLeave={() => setShowReplyToButton(false)}
      >
        {!isMergeable && (
          <div
            className={`flex w-full h-auto pt-0.5 pb-1.5 hover:bg-gray-100 ${rowDirection}`}
          >
            <Avatar avatar={avatar} />
            <div>
              <div className={`flex gap-2 ${rowDirection}`}>
                <div
                  className={`my-1.5 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()}`}
                >
                  {t(name)}
                </div>
              </div>
              <div className={`flex ${rowDirection}`}> {renderContent()}</div>
            </div>
          </div>
        )}

        {!!isMergeable && (
          <div
            className={`relative flex w-full h-auto py-1 px-16 group hover:bg-gray-50 ${elementDirection}`}
          >
            {renderContent()}
          </div>
        )}

        {showReplyToButton && hasPermissionToShowAdminReply && (
          <AdminReplyToButton
            source={source}
            handleConversationDataForAdminReplyOnClick={() =>
              handleConversationDataForAdminReply({
                type: rawBlockData?.dataV2?.type,
                success: rawBlockData?.dataV2?.success,
                error: rawBlockData?.dataV2?.error,
                sub_type: rawBlockData?.dataV2?.sub_type,
                text: '',
                urls: rawBlockData?.dataV2?.urls || [],
                payload: rawBlockData?.conversation_id,
              })
            }
          />
        )}
      </div>
      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default AudioAttachment;
