import React, { useEffect, useState } from 'react';
import ChannelCard from './components/ChannelCard';
import PluginScript from './components/PluginScript';
import ChannelReconnect from '../integrated/components/ChannelReconnect';
import ConfirmationModal from '../../../../library/modal/ConfirmationModal';
import EditChannelSettingData from '../integrated/components/EditChannelSettingData';
import { connect } from 'react-redux';
import CloneChannelSettingData from '../integrated/components/CloneChannelSettingsData';
import { mixpanelTrack } from 'utilities/mixpanel';
import Modal from './components/Modal';
import TicketSettings from './components/TicketSettings';
import ManageWhatsAppTemplatesData from './components/ManageWhatsAppTemplatesData';
import LiveChatModal from './livechat/LiveChatModal';
import { SelectedTeamInterface } from 'index';
import WhatsappBspEditModal from './components/WhatsappBspEditModal';
import EditEmailIntegration from './components/EditEmailIntegration';
import {
  IWhatsappAccountInfo,
  channelListProps,
  sequenceProps,
} from 'pages/integration/interface';
import EditCustomEmailSettings from '../availableIntegrations/Email/customEmail/editEmail';
import CreateWhatsAppTemplateV2 from './components/CreateWhatsAppTemplateV2';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';
import WhatsappAccountInfo from './components/AccountInfo';
import useWhatsappTemplateData from 'pages/integration/hooks/useWhatsappTemplateData';

interface Props {
  integratedChannleData: channelListProps[];
  selectedProject: SelectedTeamInterface;
  attributes: string[];
  deleteWhatsappMessageTemplate: (payload: any) => any;
  handleChannelPublishStatus: (
    teamId: number,
    channelId: string,
    alertMessage: string,
    data: { is_published: boolean }
  ) => boolean;
  handleChannelDisconnect: (
    teamId: number,
    channelId: string,
    alertMessage: string
  ) => boolean;
  handleChannelRemove: (
    teamId: number,
    channelId: string,
    alertMessage: string
  ) => boolean;
  getSingleChannelData: (
    teamId: number,
    channelId: string
  ) => Promise<channelListProps>;
  fetchSequence: (channelId: string) => Promise<sequenceProps[]>;
  fetchAgentGroup: (projectId: number) => void;
  fetchAgent: (projectId: number) => void;
  fetchAttributes: (channelId: string) => void;
  handleLivechatMigration: (channelId: string, teamId: number) => boolean;
}

const ChannelIndex: React.FC<Props> = ({
  integratedChannleData,
  selectedProject,
  attributes,
  handleChannelPublishStatus,
  handleChannelDisconnect,
  handleChannelRemove,
  getSingleChannelData,
  fetchSequence,
  fetchAgentGroup,
  fetchAgent,
  fetchAttributes,
  handleLivechatMigration,
  deleteWhatsappMessageTemplate,
}) => {
  const { t } = useTranslation();
  const { currentPartnerInfo } = usePartner();
  const { handleUpdateWhatsappAccountInfo } = useWhatsappTemplateData();
  const partnerName = currentPartnerInfo?.shortName ?? 'MyAlice';

  // refactor needed
  const [actionType, setActionType] = React.useState('');
  const [openGmailConnectModal, setOpenGmailConnectModal] = useState(false);
  const [showModal, setShowModal] = React.useState<null | number>(null);
  const [reconnectChannel, setReconnectChannel] = React.useState(false);
  const [editChannel, setEditChannel] = React.useState(false);
  const [pluginScript, setPluginScript] = React.useState<any>(null);
  const [selectedChannel, setSelectedChannel] = React.useState<any>(null);
  const [sequence, setSequence] = React.useState<sequenceProps[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cloneChannel, setCloneChannel] = React.useState(false);
  const [hasConfirmatioModalError, setHasConfirmatioModalError] =
    React.useState<boolean>(false);
  const [manageTemplateStep, setManageTemplateStep] = React.useState<number>(0);
  const [openTicketModal, setOpenticketModal] = React.useState<string | null>(
    null
  );
  const [whatsappAccountInfoData, setWhatsappAccountInfoData] =
    React.useState<IWhatsappAccountInfo | null>(null);
  const [isOpenWhatsappAccountInfoModal, setIsOpenWhatsappAccountInfoModal] =
    useState<boolean>(false);
  const [selectedWhatsappId, setSelectedWhatsappId] = useState<string | null>(
    null
  );

  const setInitialState = () => {
    setLoading(false);
    setShowModal(null);
    setEditChannel(false);
    setPluginScript(false);
    setSelectedChannel(null);
    setReconnectChannel(false);
    setHasConfirmatioModalError(false);
    setActionType('');
    setCloneChannel(false);
    setOpenGmailConnectModal(false);
  };

  React.useEffect(() => {
    fetchAgentGroup(selectedProject.id);
    fetchAgent(selectedProject.id);
    //eslint-disable-next-line
  }, []);

  const handleIsPublishedOnChange = async (
    value: boolean,
    channelId: string
  ) => {
    let alertMessage = value
      ? t(`Channel successfully published`)
      : t(`Channel unpublished`);
    let res = await handleChannelPublishStatus(
      selectedProject.id,
      channelId,
      alertMessage,
      {
        is_published: value,
      }
    );
    return res;
  };

  const handleChannelEditOnClick = async (channelId: string) => {
    let channelData = await getSingleChannelData(selectedProject.id, channelId);
    let sequenceData = await fetchSequence(channelId);
    setSelectedChannel(channelData);
    setSequence(sequenceData);
    setEditChannel(true);
  };

  const handleChannelReconnectOnClick = async (channelId: string) => {
    let channelData = await getSingleChannelData(selectedProject.id, channelId);
    setOpenGmailConnectModal(true);
    setSelectedChannel(channelData);
    setReconnectChannel(true);
  };

  const handleChannelDisconnectOnClick = async (
    channelId: string,
    channelName: string
  ) => {
    let alertMessage = t(`Channel {{${channelName}}} successfuly disconnected`);
    let res = await handleChannelDisconnect(
      selectedProject.id,
      channelId,
      alertMessage
    );
    return res;
  };

  const handleChannelRemoveOnClick = (
    channelId: string,
    channelName: string
  ) => {
    let alertMessage = t(`Channel {{${channelName}}} successfuly removed`);
    let res = handleChannelRemove(selectedProject.id, channelId, alertMessage);
    return res;
  };

  const handleGmailModalOpen = () => {
    setOpenGmailConnectModal((v: boolean) => !v);
  };

  const handleModalActionButtonClick = async (
    id: string,
    title: string,
    inputValue: string | undefined
  ) => {
    setLoading(true);
    if (actionType === 'delete' && inputValue?.toUpperCase() !== 'DELETE') {
      setHasConfirmatioModalError(true);
      setLoading(false);
      return false;
    }
    let res = true;
    actionType === 'delete'
      ? (res = handleChannelRemoveOnClick(id, title))
      : (res = await handleChannelDisconnectOnClick(id, title));
    if (res) {
      setInitialState();
      mixpanelTrack('Deleted Channel', {
        channelId: id,
      });
    }
    return res;
  };

  const handleChannelCloneOnClick = async (channelId: string) => {
    let channelData = await getSingleChannelData(selectedProject.id, channelId);
    setSelectedChannel(channelData);
    setCloneChannel(true);
  };

  const getDeleteDisconnectModalData = () => {
    switch (actionType) {
      case 'delete':
        return {
          actionTitle: 'Delete',
          title: t('Delete the Channel?'),
          description: t(
            'Please type “DELETE” in the box below to confirm the action. You will lose all data related to this channel. Once deleted, you cannot undo this action.'
          ),
        };

      case 'disconnect':
        return {
          actionTitle: 'Disconnect',
          title: t('Disconnect the Channel?'),
          description: t(
            `You won't be able to manage the channel from ${partnerName} anymore. You can reconnect later.`
          ),
        };
      default:
        return {
          actionTitle: 'Disconnect',
          title: t('Disconnect the Channel?'),
          description: t(
            `Your channel is still connected to ${partnerName}. Please disconnect your channel first to remove it.`
          ),
        };
    }
  };

  const handleManageTemplate = async (channelId: string) => {
    let channelData = await getSingleChannelData(selectedProject.id, channelId);
    setSelectedChannel(channelData);
    let sequenceData = await fetchSequence(channelId);
    setSequence(sequenceData);
    await fetchAttributes(channelId);
    setManageTemplateStep(1);
  };

  const handleTicketSettings = (id: string | null) => {
    setOpenticketModal(id);
  };

  useEffect(() => {
    if (whatsappAccountInfoData !== null) {
      setWhatsappAccountInfoData(null);
    }
    if (selectedWhatsappId !== null) {
      handleWhatsappAccountInfo(selectedWhatsappId);
    }
    //eslint-disable-next-line
  }, [selectedWhatsappId]);

  const handleWhatsappAccountInfo = async (id: string) => {
    try {
      const res = await handleUpdateWhatsappAccountInfo(id);
      if (res?.success === true) {
        setWhatsappAccountInfoData(res?.dataSource);
        setIsOpenWhatsappAccountInfoModal(true);
      } else {
        setWhatsappAccountInfoData(null);
        setIsOpenWhatsappAccountInfoModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMigrationToLivechat = async (channelId: string) => {
    if (await handleLivechatMigration(channelId, selectedProject.id)) {
      handleChannelEditOnClick(channelId);
    }
  };
  return (
    <>
      {integratedChannleData.map((data: channelListProps, index: number) => (
        <div key={index}>
          <div className='w-full'>
            <ChannelCard
              elementData={data}
              handleIsPublishedOnChange={handleIsPublishedOnChange}
              handleChannelDisconnect={() => {
                setActionType('disconnect');
                setShowModal(index);
              }}
              handleChannelReconnect={() => {
                handleChannelReconnectOnClick(data.id);
              }}
              handleChannelRemove={() => {
                setShowModal(index);
                data.is_connected
                  ? setActionType('default')
                  : setActionType('delete');
              }}
              handleChannelEdit={() => {
                handleChannelEditOnClick(data.id);
              }}
              handleScriptCopy={() => {
                setPluginScript({
                  platformType: data.platform_type,
                  platformId: data.id,
                  primaryId: data.primary_id,
                  accessToken: data.access_token,
                });
              }}
              handleChannelClone={() => {
                handleChannelCloneOnClick(data.id);
              }}
              handleManageTemplate={() => {
                handleManageTemplate(data.id);
              }}
              handleAccountInfo={() => {
                setSelectedWhatsappId(data?.id);
              }}
              handleTicketSettings={handleTicketSettings}
              ecommerceType={selectedProject.ecommerce_type}
              handleLivechatMigration={handleMigrationToLivechat}
            />
            <ConfirmationModal
              open={index === showModal}
              intent={
                actionType === 'disconnect' || actionType === 'default'
                  ? 'warning'
                  : 'danger'
              }
              title={t(getDeleteDisconnectModalData().title)}
              description={t(getDeleteDisconnectModalData().description)}
              confirmText={'DELETE'}
              isConfirmButtonLoading={loading}
              hasConfirmation={actionType === 'delete'}
              hasError={hasConfirmatioModalError}
              actionText={t(getDeleteDisconnectModalData().actionTitle)}
              handleClose={() => setInitialState()}
              handleCancel={() => setInitialState()}
              handleAction={(inputValue: string | undefined) =>
                handleModalActionButtonClick(data.id, data.title, inputValue)
              }
            />
          </div>

          {openTicketModal === data.id && (
            <Modal key={index}>
              <TicketSettings
                setOpenticketModal={setOpenticketModal}
                data={data}
              />
            </Modal>
          )}
        </div>
      ))}

      {editChannel &&
        ['livechat_messenger'].includes(selectedChannel?.platform_type) && (
          <LiveChatModal
            key={selectedChannel?.id}
            open={!!selectedChannel?.id}
            handleClose={() => setEditChannel(false)}
            channelData={!!selectedChannel ? selectedChannel : {}}
          />
        )}

      {editChannel &&
        ['custom_email'].includes(selectedChannel?.platform_type) && (
          <EditCustomEmailSettings
            open={editChannel}
            key={selectedChannel?.id}
            handleClose={() => setEditChannel(false)}
            channelData={!!selectedChannel ? selectedChannel : {}}
          />
        )}

      {editChannel && ['gmail'].includes(selectedChannel?.platform_type) && (
        <EditEmailIntegration
          key={selectedChannel?.id}
          open={!!selectedChannel?.id}
          handleClose={() => setEditChannel(false)}
          channelData={!!selectedChannel ? selectedChannel : {}}
        />
      )}

      {editChannel &&
        ['whatsapp_bsp'].includes(selectedChannel.platform_type) && (
          <WhatsappBspEditModal
            sequences={sequence}
            isOpen={!!selectedChannel?.id}
            setInitialState={() => setInitialState()}
            handleClose={() => setEditChannel(false)}
            channelData={!!selectedChannel ? selectedChannel : {}}
          />
        )}

      {editChannel &&
        ![
          'custom_email',
          'gmail',
          'livechat_messenger',
          'whatsapp_bsp',
        ].includes(selectedChannel.platform_type) && (
          <EditChannelSettingData
            sequences={sequence}
            isOpen={!!selectedChannel?.id}
            setInitialState={() => setInitialState()}
            handleClose={() => setEditChannel(false)}
            channelData={!!selectedChannel ? selectedChannel : {}}
          />
        )}
      {reconnectChannel && (
        <ChannelReconnect
          isOpen={reconnectChannel}
          isGmailModalOpened={openGmailConnectModal}
          setIsGmailModalOpened={handleGmailModalOpen}
          setInitialState={() => setInitialState()}
          channelData={!!selectedChannel ? selectedChannel : {}}
        />
      )}
      {!!pluginScript && (
        <PluginScript
          isOpen={true}
          platfromType={pluginScript.platformType}
          channelData={{
            id: pluginScript.platformId,
            primary_id: pluginScript.primaryId,
            access_token: pluginScript.accessToken,
          }}
          handleClose={() => setPluginScript(null)}
        />
      )}
      {cloneChannel && (
        <CloneChannelSettingData
          sequences={sequence}
          isOpen={!!selectedChannel?.id}
          setInitialState={() => setInitialState()}
          handleClose={() => setCloneChannel(false)}
          channelData={!!selectedChannel ? selectedChannel : {}}
        />
      )}
      {manageTemplateStep === 1 && (
        <ManageWhatsAppTemplatesData
          isOpen={!!selectedChannel?.id}
          handleConfirm={() => setManageTemplateStep(2)}
          handleClose={() => setManageTemplateStep(0)}
          channelData={!!selectedChannel ? selectedChannel : {}}
          sequences={sequence}
          deleteWhatsappMessageTemplate={deleteWhatsappMessageTemplate}
        />
      )}
      {manageTemplateStep === 2 && (
        <>
          <CreateWhatsAppTemplateV2
            isOpen={!!selectedChannel?.id}
            handleClose={() => setManageTemplateStep(0)}
            sequence={sequence}
            channelData={!!selectedChannel ? selectedChannel : {}}
            actionType='CREATE'
          />
        </>
      )}
      {isOpenWhatsappAccountInfoModal && (
        <WhatsappAccountInfo
          setOpenAccountInfoModal={setIsOpenWhatsappAccountInfoModal}
          whatsappAccountInfoData={whatsappAccountInfoData ?? null}
          isOpenWhatsappAccountInfoModal={isOpenWhatsappAccountInfoModal}
        />
      )}
    </>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
  platformList: state.dashboard.platformList,
  attributes: state.builder.attributes,
});

const mapDispatch = (dispatch: any) => ({
  deleteNlpIntegration: (teamId: number, integrationId: string) =>
    dispatch.integration.deleteNlpIntegration({
      teamId,
      integrationId,
    }),
  handleChannelPublishStatus: (
    teamId: number,
    channelId: string,
    alertMessage: string,
    data: object
  ) =>
    dispatch.integration.handleChannelPublishStatus({
      teamId,
      channelId,
      alertMessage,
      data,
    }),
  handleChannelDisconnect: (
    teamId: number,
    channelId: string,
    alertMessage: string
  ) =>
    dispatch.integration.disconnectChannel({
      teamId,
      channelId,
      alertMessage,
    }),
  handleChannelRemove: (
    teamId: number,
    channelId: string,
    alertMessage: string
  ) =>
    dispatch.integration.removeChannel({
      teamId,
      channelId,
      alertMessage,
    }),
  getSingleChannelData: (teamId: number, channelId: string) =>
    dispatch.integration.getSingleChannelData({ teamId, channelId }),
  fetchSequence: (channelId: string) =>
    dispatch.integration.fetchSequence(channelId),
  fetchAgentGroup: (projectId: number) =>
    dispatch.crm.fetchAgentGroup(projectId),
  fetchAgent: (projectId: number) => dispatch.block.fetchAgent(projectId),
  fetchAttributes: (channelId: string) =>
    dispatch.builder.fetchAttributes(channelId),
  handleLivechatMigration: (channelId: string, teamId: number) =>
    dispatch.integration.handleLiveChatMigration({ channelId, teamId }),

  deleteWhatsappMessageTemplate: (payload: any) =>
    dispatch.integration.deleteWhatsappMessageTemplate(payload),
});

export default connect(mapState, mapDispatch)(ChannelIndex);
