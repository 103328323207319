import React from 'react';
import DropDownList from '../components/DropDownList';

import { XCircleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';
import { whatsappSettingDataProps } from 'pages/integration/interface';
import WhatsappFileUploader from 'components/utilityComponent/WhatsappFileUploader';
import { IntegrateWhatsappDescriptionLimit } from 'pages/integration/utils/content';

interface Props {
  success?: boolean;
  actionType?: string;
  errorMessage?: string;
  whatsappSettingData: whatsappSettingDataProps;
  integratedNlpList: { id: string; name: string; provider: string }[];
  handleChange: (key: string, value: string | string[]) => void;
}

const WhatsappSetting: React.FC<Props> = ({
  success = true,
  handleChange,
  errorMessage,
  whatsappSettingData,
  integratedNlpList,
  actionType = 'create',
}) => {
  const { replaceWithPartnerName } = usePartner();
  const { t } = useTranslation();
  const getSelectedNlp = () => {
    return integratedNlpList.filter(
      (item: any) =>
        item.id === whatsappSettingData.connected_nlp_integration_id
    )[0];
  };

  const [selectedNlpData, setSelectedNlpData] = React.useState<any>(
    !!getSelectedNlp() ? getSelectedNlp() : null
  );

  return (
    <>
      {!success && (
        <div className='flex justify-center gap-2 p-6'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='text-sm text-red-600'>{t(errorMessage ?? '')}</p>
        </div>
      )}
      <div className=''>
        <div className=''>
          <label
            htmlFor='title'
            className='flex text-sm font-medium text-gray-600'
          >
            {t('Title')}
            <span className='text-red-500'>*</span>
          </label>
          <input
            type='text'
            placeholder={t('My whatsapp channel')}
            value={whatsappSettingData.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='title'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm placeholder-gray-500'
          />
          <p className='mt-2 rtl:text-right text-sm text-gray-500'>
            {replaceWithPartnerName(
              t(
                'This title will be used in {{MyAlice}} to identify this channel. Give it a title that you can differentiate with later.'
              )
            )}
          </p>
        </div>
        <div className='mt-6'>
          <label
            htmlFor='avatar'
            className='flex text-sm font-medium text-gray-600'
          >
            {t('Upload Profile Photo')}
          </label>
          <WhatsappFileUploader
            file={whatsappSettingData.avatar}
            handleFileChange={(image) => handleChange('avatar', image)}
            handleClear={() => handleChange('avatar', '')}
            fileMimeTypes={['image/jpeg', 'image/png']}
            templateHeaderType='image'
          />
        </div>
        <div className='mt-6'>
          <label
            htmlFor='business_name'
            className='flex text-sm font-medium text-gray-600'
          >
            {t('WhatsApp business display name')}
          </label>
          <input
            type='text'
            placeholder={t('MyAlice')}
            value={whatsappSettingData.platform_meta?.business_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='business_name'
            disabled
            className='block w-full mt-1 text-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm bg-gray-100 placeholder-gray-500'
          />
          <p className='mt-2 rtl:text-right text-sm text-gray-500'>
            Business Name can only be changed from FB Business Manager. Once you
            change the name, it goes through an approval process by Meta.{' '}
            <a
              className='text-[#0078CF]'
              target='_blank'
              rel='noreferrer'
              href='https://business.facebook.com/wa/manage/phone-numbers/?business_id=799651940787680&waba_id=137563496106908&phone_number=971581469622&childRoute=PHONE_PROFILE%2FPROFILE'
            >
              Click here
            </a>{' '}
            to create a request for changing name.
          </p>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <label
              htmlFor='description'
              className='flex text-sm font-medium text-gray-600'
            >
              {t('Description')}
            </label>
            <div className='text-gray-500 text-sm font-medium'>
              {whatsappSettingData.description.length}/
              {IntegrateWhatsappDescriptionLimit}
            </div>
          </div>

          <textarea
            maxLength={512}
            placeholder={t('Enter description')}
            value={whatsappSettingData.description}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              handleChange('description', e.target.value)
            }
            name='description'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm p-3 placeholder-gray-500'
          />
          <p className='mt-2 rtl:text-right text-sm text-gray-500'>
            {replaceWithPartnerName(
              t('This description will be shown in your business profile.')
            )}
          </p>
        </div>
        <div className='mt-6'>
          <label
            htmlFor='email'
            className='flex text-sm font-medium text-gray-600'
          >
            {t('Email')}
          </label>
          <input
            type='email'
            placeholder={t('Enter email')}
            value={whatsappSettingData.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange('email', e.target.value)
            }
            name='email'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm placeholder-gray-500'
          />
          <p className='mt-2 rtl:text-right text-sm text-gray-500'>
            {replaceWithPartnerName(
              t('This email will be shown in your business profile.')
            )}
          </p>
        </div>
      </div>
      <div className='border-t pt-6 pb-2'>
        <label
          htmlFor='nlp_selection'
          className='flex mb-1 text-sm font-medium text-gray-600'
        >
          {t('Select NLP App')}
        </label>
        <DropDownList
          options={integratedNlpList}
          selectedValue={!!selectedNlpData ? selectedNlpData : {}}
          titleKeyName={'name'}
          isFullWidth={true}
          buttonClassName={'w-full'}
          placeHolder={t('Select NLP app')}
          handleSelectedOption={(value: any) => {
            if (value.id !== whatsappSettingData.connected_nlp_integration_id) {
              setSelectedNlpData(value);
              handleChange('connected_nlp_integration_id', value.id);
            } else {
              setSelectedNlpData(null);
              handleChange('connected_nlp_integration_id', '');
            }
          }}
        />
      </div>
    </>
  );
};
export default WhatsappSetting;
