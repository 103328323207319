import useTranslation from 'components/customHooks/useTranslation';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'libraryV2/ui/alert-dialog';
import { Button } from 'libraryV2/ui/button';
import { TrashIcon } from 'lucide-react';

interface IProps {
  type: string;
  name: string;
  onDelete: () => void;
}

const DeleteCofirmationDialog: React.FC<IProps> = ({
  type,
  onDelete,
  name,
}) => {
  const { t } = useTranslation();
  const handleOnDelete = () => {
    onDelete();
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          variant='outline'
          size='icon'
          className='ltr:mr-3 rtl:ml-3'
          style={{ background: '#FFF0F0', color: '#FF0000' }}
        >
          <TrashIcon className='h-4 w-4' />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className='bg-white'>
        <AlertDialogHeader>
          <AlertDialogTitle className='rtl:text-right'>
            {t('Delete')} {name} {t('?')}
          </AlertDialogTitle>
          <AlertDialogDescription className='rtl:text-right'>
            {t('This will permanently delete')} <strong>{name}</strong>{' '}
            {t('from your form.')} {t('Are you sure you want to delete?')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className='rtl:justify-start'>
          <AlertDialogCancel className='bg-[#F4F4F5] hover:bg-[#E4E4E7] rtl:ml-2'>
            {t('No')}
          </AlertDialogCancel>
          <AlertDialogAction
            className='ltr:ml-3 rtl:mr-3 text-white bg-[#FF0000] hover:bg-red-700'
            onClick={handleOnDelete}
          >
            {t('Yes! Delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteCofirmationDialog;
