import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  IFormFieldComponent,
  getHookFormRules,
  Textarea,
  FC,
  useFormContext,
} from '../../export';

export const TextAreaField: FC<IFormFieldComponent> = ({
  data,
  formActionType,
  valuePath,
}) => {
  const shouldDisableInput = formActionType === 'VIEW';
  const form = useFormContext();
  const currentFieldValuePath = valuePath.length
    ? valuePath + '.' + data.slug
    : data.slug;

  const { name: label, placeholder, is_required: required } = data;
  const isFormViewMode = formActionType === 'VIEW';

  const renderTextAreaFieldView = () => {
    return (
      <div className='border border-zinc-200 space-y-2 rounded-md p-3'>
        <p className='text-xs text-zinc-500'> {data?.label_agent || label} </p>
        <p className='text-zinc-900 whitespace-pre-line max-h-[320px] overflow-y-auto text-sm'>
          {form.getValues(currentFieldValuePath) || (
            <span className='text-zinc-500'> (Empty) </span>
          )}
        </p>
      </div>
    );
  };

  const renderTextAreaField = () => (
    <FormField
      control={form.control}
      name={currentFieldValuePath}
      disabled={data.is_readonly}
      rules={getHookFormRules(data)}
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            {data?.label_agent || label}
            {required && <span className='text-destructive'>*</span>}
          </FormLabel>
          <FormControl>
            <Textarea
              disabled={shouldDisableInput}
              placeholder={placeholder}
              {...field}
              className='focus:border-green-500 border-gray-300 focus-visible:ring-0'
            />
          </FormControl>
          <FormDescription
            className={
              data.help_text ? 'visible text-zinc-500 text-sm' : 'hidden'
            }
          >
            {data.help_text}
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
  return isFormViewMode ? renderTextAreaFieldView() : renderTextAreaField();
};

export default TextAreaField;
