'use client';
import { ReactElement } from 'react';
import {
  ReactTable as Table,
  useDatalab,
  Badge,
  Cross2Icon,
} from '../../../export';

import DatalabSideSheet from '../DatalabSideSheet';
import ConfirmationModal from './../../../components/common/ConfirmationModal';

interface DataTableViewOptionsProps<TData> {
  table: Table<TData>;

  isSheetOpen: boolean;
  toggleSheetOpen: () => void;
  tableDatalabExportResponse: ExportStateProps;
  setTableDatalabExportResponse: (state: ExportStateProps) => void;
}

interface ExportStateProps {
  show: boolean;
  success: boolean;
  message: {
    title: string;
    description: string | ReactElement;
  };
}

export function DataTableViewOptions<TData>({
  isSheetOpen = false,
  toggleSheetOpen,
  tableDatalabExportResponse,
  setTableDatalabExportResponse,
}: DataTableViewOptionsProps<TData>) {
  const { selectedDatalabInfo, appliedFilterView } = useDatalab();

  return (
    <>
      <div className='flex flex-wrap'>
        {appliedFilterView?.filter_options?.length > 0 &&
          appliedFilterView?.filter_options?.map((filter: any) => (
            <div
              key={filter.slug}
              className='flex items-center space-x-1 mt-1 mr-2'
            >
              <span className='text-xs text-gray-500'>{filter.slug}: </span>
              <span className='italic text-purple-500 text-xs'>
                {filter.operator}
              </span>
              <Badge
                variant='ghost'
                className='first-of-type:ml-0 ml-2 bg-gray-100 rounded-md'
                style={{
                  height: '22px',
                  paddingLeft: '8px',
                  paddingRight: '5px',
                }}
              >
                <span className='text-xs text-[#18181B] font-medium'>
                  {Array.isArray(filter.value)
                    ? filter.value.join(', ')
                    : filter.value}
                </span>
                <Cross2Icon className='w-3 h-3 ml-1 text-[#A1A1AA] font-semibold hover:cursor-pointer' />
              </Badge>
            </div>
          ))}
      </div>
      {isSheetOpen && (
        <DatalabSideSheet
          isOpen={isSheetOpen}
          datalabEntries={{}}
          datalabShape={selectedDatalabInfo}
          header='Enter new Data'
          mode='CREATE'
          onClose={toggleSheetOpen}
        />
        // <SiteSheet header='Enter new Data' onClose={handleSheetClose} />
      )}

      <ConfirmationModal
        shouldShowCancelButton={false}
        isShown={tableDatalabExportResponse?.show}
        title={tableDatalabExportResponse?.message?.title}
        description={tableDatalabExportResponse?.message?.description}
        intent={tableDatalabExportResponse?.success ? 'primary' : 'danger'}
        shouldRenderDescriptionAsNode={tableDatalabExportResponse?.success}
        confirmButtonTitle='Got it!'
        onConfirm={() => {
          setTableDatalabExportResponse({
            ...tableDatalabExportResponse,
            show: false,
          });
        }}
      />
    </>
  );
}
