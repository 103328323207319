import React, { Fragment, useEffect, useState } from 'react';
import {
  DragTypes,
  useActions,
  arrayMove,
  useTriggerActions,
  DropZone,
} from 'pages/datalab/export';
import BlockEdit from './blockEdit';
import { Accordion } from 'libraryV2/ui/accordion';

const BlockList: React.FC = () => {
  const { getAddedFormFields, getController } = useActions();
  const {
    createDataLabField,
    addTempFieldToLabFields,
    updateDataLabFieldOrder,
  } = useTriggerActions();
  const addedFormFields = getAddedFormFields();
  const { labId, selectedFieldId } = getController();

  const [accordionValue, setAaccordionValue] = useState('');

  useEffect(() => {
    setAaccordionValue(selectedFieldId);
  }, [selectedFieldId]);

  const handleOnSort = (drag_index: number, hover_index: number) => {
    if (drag_index !== hover_index && hover_index > -1) {
      // handle sorting of fields
      const updatedLabFields = arrayMove(
        addedFormFields,
        drag_index,
        hover_index
      );

      const data = updatedLabFields.map((field: any, index: number) => ({
        id: field.id,
        serial: index,
      }));

      updateDataLabFieldOrder(labId, data);
    }
  };

  const handleDrop = (dropZone: any, item: any) => {
    if (item.type === DragTypes.BLOCK_FIELD) {
      handleOnSort(item.index, Number(dropZone.index));
    } else if (item.type === DragTypes.FIELD) {
      const field = item.field;
      field.serial = Number(dropZone.index);

      if (field.type === 'group') {
        createDataLabField(labId, field);
      } else {
        addTempFieldToLabFields(field);
      }
    }
  };

  const handleOnAccordionChange = (index: any) => {
    setAaccordionValue(index);
  };

  return (
    <div className='w-full' style={{ width: '580px', minHeight: '6px' }}>
      {addedFormFields && (
        <Accordion
          type='single'
          collapsible
          value={accordionValue}
          onChange={handleOnAccordionChange}
        >
          {addedFormFields.map((field: any, index: number) => (
            <Fragment key={field.id}>
              <DropZone
                data={{
                  index: `${index}`,
                  childrenCount: addedFormFields.length,
                }}
                onDrop={handleDrop}
              />
              <BlockEdit index={index} key={field.id} field={field} />
            </Fragment>
          ))}
        </Accordion>
      )}

      <DropZone
        data={{
          index: `${addedFormFields?.length}`,
          childrenCount: addedFormFields?.length,
        }}
        onDrop={handleDrop}
        style={{ display: 'flex' }}
      />
    </div>
  );
};

export default BlockList;
