import {
  useActions,
  VariableDropdown,
  useAutomationWorkflow,
} from 'pages/automationWorkflow/export';
import { IAssignVariableTypes } from 'pages/automationWorkflow/interface';

const AssignVariables = () => {
  // Extract required action functions from the custom hook
  const {
    updateSelectedAssignVariableFallbackValue,
    updateSelectedAssignVariableCategoryAndValue,
  } = useActions();

  // Destructure required state variables from the automation workflow hook
  const { triggerInfo, selectedSaveActionsData } = useAutomationWorkflow();

  // Destructure required data from the selectedSaveActionsData
  const { whatsapp: { selectedTemplate: { variables = [] } = {} } = {} } =
    selectedSaveActionsData;

  return (
    <>
      {!!variables && variables.length > 0 && (
        <div className='p-4 bg-white rounded mt-6'>
          <p className='text-gray-600 text-sm font-medium'>Assign Variables</p>
          <p className='text-gray-500 text-sm'>
            Use Variable+Text to include a longer message.
          </p>
          <div className='mt-4 grid grid-flow-row gap-4'>
            {variables.map(
              (variable: IAssignVariableTypes, variableIndex: number) => (
                <div className='grid grid-cols-5 gap-4' key={variableIndex}>
                  <div className='col-span-3'>
                    <div className='flex items-center'>
                      <div className='text-indigo-400 font-medium text-sm mr-3 w-28'>
                        {`${variable?.item}`}
                      </div>
                      <div className='w-full'>
                        <VariableDropdown
                          variables={variables}
                          triggerInfo={triggerInfo}
                          updateSelectedAssignVariableCategoryAndValue={
                            updateSelectedAssignVariableCategoryAndValue
                          }
                          variableIndex={variableIndex}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='col-span-2'>
                    <input
                      type='string'
                      className='w-full py-2 px-3 shadow-sm border border-gray-300 rounded-md text-sm text-gray-700 focus:ring-primary focus:border-primary focus:outline-none'
                      placeholder='Fallback value'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateSelectedAssignVariableFallbackValue(
                          e.target.value,
                          variableIndex
                        )
                      }
                      value={variables[variableIndex]?.fallbackValue ?? ''}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AssignVariables;
