import { useDispatch, useSelector } from 'react-redux';
import {
  IAuthTemplateTranslationCategory,
  TAuthLanguages,
  getAuthTranslationData,
} from '../components/integrated/whatsapp/utils/authTemplateTranslation';
import { IWhatsappTemplateButton, channelListProps } from '../interface';
import WhatsappTemplateDefaultData from '../models/WhatsappTemplateDefaultData';
import { useEffect, useState } from 'react';
import { useToast } from 'libraryV2/ui/use-toast';

export type TCarouselCardHeaderType = 'image' | 'video';

export interface IWhatsappCarouselCard {
  id: number;
  header: {
    type?: TCarouselCardHeaderType;
    value: string;
  };
  body: string;
  buttons: IWhatsappTemplateButton[];
}

export interface IWhatsappStore {
  name: string;
  templateType: 'GENERIC' | 'CAROUSEL';
  category: 'AUTHENTICATION' | 'MARKETING' | 'UTILITY';
  language: TAuthLanguages;
  body?: string;
  bodyVariables: IWhatsappTemplateVarible[];
  authTemplateBody?: string;
  footer: string;
  headerType?: string;
  headerValue?: string;
  headerVariable?: string;
  buttonCount?: IWhatsappTemplateButtonCount;
  buttons: IWhatsappTemplateButton[];
  hasSecurityRecommendation?: boolean;
  hasAuthCodeExpirationTime?: boolean;
  authCodeExpirationTime?: string;
  activeCarouselCardIndex: number;
  cards: IWhatsappCarouselCard[];
}

export interface IWhatsappTemplateVarible {
  position: number;
  index: string;
  value: string;
}

export interface IWhatsappTemplateButtonCount {
  phone_number: number;
  sequence: number;
  url: number;
}

const useWhatsappTemplateData = () => {
  const { toast } = useToast();
  const [isValidTemplateData, setIsValidTemplateData] =
    useState<boolean>(false);
  const selectedProject = useSelector(
    (state: any) => state?.dashboard?.selectedProject ?? {}
  );
  const dispatch = useDispatch();

  const { createWhatsappMessageTemplate: createWhatsapptemplateLoading } =
    useSelector((state: any) => state?.loading?.effects?.integration);

  const whatsappTemplateState: IWhatsappStore = useSelector(
    (state: any) => state?.integration.whatsappTemplateData
  );

  const validateAuthTemplateData = () => {
    const mandatoryFiledsForAuthTemplate = ['authTemplateBody'];
    const hasMandatoryAuthFields = mandatoryFiledsForAuthTemplate.every(
      (field) => field.length
    );
    if (!hasMandatoryAuthFields) {
      setIsValidTemplateData(false);
      return;
    }
    if (
      whatsappTemplateState.hasAuthCodeExpirationTime &&
      !whatsappTemplateState.authCodeExpirationTime?.toString().length
    ) {
      setIsValidTemplateData(false);
      return;
    }
    if (
      Number(whatsappTemplateState.authCodeExpirationTime) > 90 ||
      Number(whatsappTemplateState.authCodeExpirationTime) < 0
    ) {
      setIsValidTemplateData(false);
      return;
    }

    setIsValidTemplateData(true);
  };

  const validateGenericTemplateData = () => {
    const mandatoryFiledsForGenericTemplate: (keyof IWhatsappStore)[] = [
      'body',
    ];
    const hasMandatoryFields = mandatoryFiledsForGenericTemplate.every(
      (field) => (whatsappTemplateState[field] as string)?.trim().length
    );
    if (!hasMandatoryFields) {
      setIsValidTemplateData(false);
      return;
    }
    if (
      whatsappTemplateState?.headerType &&
      !whatsappTemplateState?.headerValue?.length
    ) {
      setIsValidTemplateData(false);
      return;
    }
    if (whatsappTemplateState.buttons.length) {
      const result = whatsappTemplateState.buttons.every((button) => {
        if (!button?.type || !button?.title || !button?.value) {
          return false;
        }
        return true;
      });
      if (!result) {
        setIsValidTemplateData(false);
        return;
      }
    }
    setIsValidTemplateData(true);
  };
  const validateCarouselTemplateData = () => {
    const mandatoryFiledsForCarouselTemplate: (keyof IWhatsappStore)[] = [
      'name',
      'category',
      'templateType',
      'language',
      'body',
    ];
    const hasMandatoryFields = mandatoryFiledsForCarouselTemplate.every(
      (field) => (whatsappTemplateState[field] as string)?.trim().length
    );
    if (!hasMandatoryFields) {
      setIsValidTemplateData(false);
      return;
    }
    const cards = whatsappTemplateState.cards;
    if (!cards.length) {
      setIsValidTemplateData(false);
      return;
    }
    // check mandatory fields for each card & their header type
    const result = cards.every((card) => {
      if (
        !card.body?.trim() ||
        !card?.header.value?.trim() ||
        !card?.buttons.length
      ) {
        return false;
      }
      const hasAllbuttonsData = card.buttons.every(
        (b) => b.title?.length && b.value?.toString().length
      );

      return hasAllbuttonsData;
    });

    if (!result) {
      setIsValidTemplateData(false);
      return;
    }
    setIsValidTemplateData(true);
  };

  /**
   * @description check if neccessary template data is present for specific template category
   * returns boolean if all the required data is present otherwise false
   * @returns boolean
   */
  const validateTemplateData = () => {
    const mandatoryFileds: (keyof IWhatsappStore)[] = [
      'name',
      'category',
      'language',
      'templateType',
    ];
    const hasMandatoryFields = mandatoryFileds.every(
      (field) => (whatsappTemplateState[field] as string)?.trim().length
    );
    if (!hasMandatoryFields) {
      setIsValidTemplateData(false);
      return;
    }
    // specific fields for AUTHENTICATION template
    if (whatsappTemplateState.category === 'AUTHENTICATION') {
      validateAuthTemplateData();
    } else if (whatsappTemplateState.templateType === 'CAROUSEL') {
      validateCarouselTemplateData();
    } else {
      validateGenericTemplateData();
    }
  };

  useEffect(() => {
    validateTemplateData();
    //eslint-disable-next-line
  }, [whatsappTemplateState, whatsappTemplateState?.buttons]);

  const resetWhatsappTemplateState = () => {
    dispatch.integration.updateStateData({
      key: 'whatsappTemplateData',
      value: WhatsappTemplateDefaultData,
    });
  };

  const updateWhatsappTemplateData = (templateData: any) => {
    const defaultState = Object.assign(
      { ...WhatsappTemplateDefaultData },
      { ...templateData }
    );

    dispatch.integration.updateWhatsappTemplateData(defaultState);
  };

  const updateTemplateBody = (value: string) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'body',
      value: value,
    });
  };

  const updateTemplateBodyVariables = (uniqueVariables: any) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'bodyVariables',
      value: { ...uniqueVariables },
    });
  };

  const updateAuthTemplateBody = (
    categoryType: IAuthTemplateTranslationCategory
  ) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'authTemplateBody',
      value: getAuthTranslationData(
        categoryType,
        whatsappTemplateState?.language
      ),
    });
  };

  const updateTemplateButton = (buttonData: IWhatsappTemplateButton) => {
    dispatch.integration.updateWhatsappTemplateButtonData({
      ...buttonData,
    });
  };

  const addNewTemplateButton = () => {
    dispatch.integration.addWhatsappTemplateButton([
      {
        id: whatsappTemplateState.buttons.length + 1,
      },
    ]);
  };

  const deleteTemplateButton = (buttonId: number) => {
    const buttons = whatsappTemplateState.buttons;
    buttons.splice(buttonId - 1, 1);
    for (let i = 0; i < buttons.length; i++) {
      buttons[i]['id'] = i + 1;
    }
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'buttons',
      value: buttons,
    });
    return buttons;
  };

  const updateTemplateFooter = (footerText: string) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'footer',
      value: footerText,
    });
  };

  const updateTemplateName = (value: string) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'name',
      value: value,
    });
  };

  const updateTemplateCategory = (value: string) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'category',
      value: value,
    });
  };

  const updateTemplateType = (value: string) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'templateType',
      value: value,
    });
  };

  const updateTemplateLanguage = (value: string) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'language',
      value: value,
    });
  };

  const updateTemplateHeaderType = (value: string) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'headerType',
      value: value,
    });
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'headerValue',
      value: '',
    });
  };

  const updateTemplateHeaderValue = (value: string) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'headerValue',
      value: value,
    });
  };

  const updateTemplateHeaderVariable = (value: string) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'headerVariable',
      value: value,
    });
  };

  const updateAuthTemplateSecurityRecommendation = (isChecked: boolean) => {
    const currentLanguage = whatsappTemplateState?.language;

    const authBodyTextWithSecurity = getAuthTranslationData(
      'security_recommendation',
      currentLanguage
    );
    const defaultAuthBodyText = getAuthTranslationData(
      'code_text',
      currentLanguage
    );

    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'hasSecurityRecommendation',
      value: isChecked,
    });
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'authTemplateBody',
      value: isChecked ? authBodyTextWithSecurity : defaultAuthBodyText,
    });
  };

  const updateExpirationTimeCheck = (isChecked: boolean) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'hasAuthCodeExpirationTime',
      value: isChecked,
    });
    if (isChecked) {
      dispatch.integration.updateWhatsappTemplateDataField({
        key: 'footer',
        value: getAuthTranslationData(
          'expiration_text',
          whatsappTemplateState?.language,
          true,
          whatsappTemplateState?.authCodeExpirationTime
        ),
      });
    } else {
      dispatch.integration.updateWhatsappTemplateDataField({
        key: 'authCodeExpirationTime',
        value: '',
      });
      dispatch.integration.updateWhatsappTemplateDataField({
        key: 'footer',
        value: '',
      });
    }
  };

  const updateExpirationTime = (expirationTime: string) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'authCodeExpirationTime',
      value: expirationTime,
    });
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'footer',
      value: getAuthTranslationData(
        'expiration_text',
        whatsappTemplateState.language,
        true,
        expirationTime
      ),
    });
  };

  const getAuthTemplateRequestPayload = () => {
    const copyButton = getAuthTranslationData(
      'copy_text',
      whatsappTemplateState.language
    );
    const authRequestPayload = {
      name: whatsappTemplateState?.name,
      category: whatsappTemplateState?.category,
      template_type: whatsappTemplateState.templateType,
      language: whatsappTemplateState?.language,
      body: whatsappTemplateState?.authTemplateBody,
      footer: getAuthTranslationData(
        'expiration_text',
        whatsappTemplateState.language,
        true,
        whatsappTemplateState.authCodeExpirationTime ?? '0'
      ),
      buttons: [
        {
          id: 0,
          type: 'OTP',
          title: copyButton,
          value: copyButton,
        },
      ],
      has_security_recommendation:
        whatsappTemplateState?.hasSecurityRecommendation,
      code_expiration_minutes: whatsappTemplateState?.authCodeExpirationTime
        ? whatsappTemplateState.authCodeExpirationTime
        : null,
    };
    return authRequestPayload;
  };

  const getCarouselRequestPayload = () => {
    const header: Record<string, string> = {};
    if (whatsappTemplateState.headerType && whatsappTemplateState.headerValue) {
      header['type'] = whatsappTemplateState.headerType;
      header['value'] = whatsappTemplateState.headerValue;
    }
    const { cards, headerType } = whatsappTemplateState;

    const formatedCards = cards.map((c) => ({
      ...c,
      header: { type: headerType, value: c.header.value },
    }));
    const carouselRequestPayload = {
      name: whatsappTemplateState?.name,
      category: whatsappTemplateState?.category,
      language: whatsappTemplateState?.language,
      template_type: whatsappTemplateState.templateType,
      body: whatsappTemplateState?.body,
      header: header,
      footer: whatsappTemplateState?.footer,
      buttons: whatsappTemplateState?.buttons,
      cards: formatedCards,
    };
    return carouselRequestPayload;
  };

  //for now both Marketing & utility template request body is same except the category type
  const getMarketingRequestPayload = () => {
    const header: Record<string, string> = {};
    if (whatsappTemplateState.headerType && whatsappTemplateState.headerValue) {
      header['type'] = whatsappTemplateState.headerType;
      header['value'] = whatsappTemplateState.headerValue;
    }
    const marketingRequestPayload = {
      name: whatsappTemplateState?.name,
      category: whatsappTemplateState?.category,
      template_type: whatsappTemplateState.templateType,
      language: whatsappTemplateState?.language,
      body: whatsappTemplateState?.body,
      header: header,
      footer: whatsappTemplateState?.footer,
      buttons: whatsappTemplateState?.buttons,
    };
    return marketingRequestPayload;
  };

  const createWhatsappMessageTemplate = ({
    channelData,
    callback,
  }: {
    callback: (response: any) => void;
    channelData: channelListProps;
  }) => {
    let templateRequestData = null;
    if (whatsappTemplateState.category === 'AUTHENTICATION') {
      templateRequestData = getAuthTemplateRequestPayload();
    } else if (whatsappTemplateState.templateType === 'CAROUSEL') {
      templateRequestData = getCarouselRequestPayload();
    } else {
      templateRequestData = getMarketingRequestPayload();
    }
    const finalRequestData = {
      data: templateRequestData,
      channelId: channelData?.id,
      callback: callback,
    };
    dispatch.integration.createWhatsappMessageTemplate(finalRequestData);
  };

  const editWhatsappMessageTemplate = ({
    templateId,
    channelData,
    callback,
  }: {
    templateId: string;
    channelData: channelListProps;
    callback: (response: any) => void;
  }) => {
    let templateRequestData = null;
    if (whatsappTemplateState.category === 'AUTHENTICATION') {
      templateRequestData = getAuthTemplateRequestPayload();
    } else if (whatsappTemplateState.templateType === 'CAROUSEL') {
      templateRequestData = getCarouselRequestPayload();
    } else {
      templateRequestData = getMarketingRequestPayload();
    }
    const finalRequestData = {
      channelId: channelData.id,
      templateId,
      data: templateRequestData,
      channelData: channelData,
      callback: callback,
    };
    dispatch.integration.editWhatsappMessageTemplate(finalRequestData);
  };

  const handleUpdateWhatsappAccountInfo = async (id: string) => {
    try {
      const res = await dispatch.integration.updateWhatsappAccountInfo(id);
      if (res?.success) {
        return { success: res?.success, dataSource: res?.dataSource };
      } else {
        toast({
          title: `Something went wrong while fetching Account Information.`,
        });
        return { success: res?.success, dataSource: res?.dataSource };
      }
    } catch (error) {
      console.log(error);
      toast({
        title: `Something went wrong while fetching Account Information.`,
      });
      return { success: false };
    }
  };

  const updateCarouselTemplateCardsData = (cards: IWhatsappCarouselCard[]) => {
    dispatch.integration.updateWhatsappTemplateDataField({
      key: 'cards',
      value: cards,
    });
  };

  const updateSingleCarouselCardData = (newCard: IWhatsappCarouselCard) => {
    let cardList = whatsappTemplateState.cards;
    const targetedCardIndex = cardList.findIndex((c) => c.id === newCard.id);
    if (targetedCardIndex === -1) {
      return;
    }
    if (newCard.id === 1) {
      const targetCard = cardList[targetedCardIndex];
      //check if button type are changed
      const changedButtonIndex = targetCard.buttons.findIndex(
        (b, idx) => b.type !== newCard.buttons[idx].type
      );
      if (changedButtonIndex !== -1) {
        //change button type for other card buttons
        cardList = cardList.map((c) => {
          c.buttons[changedButtonIndex].type =
            newCard.buttons[changedButtonIndex].type;
          return c;
        });
      }
    }
    cardList[targetedCardIndex] = newCard;
    updateCarouselTemplateCardsData(cardList);
  };

  const updateActiveCarouselCard = (cardIndex: number = 0) => {
    dispatch.integration.updateStateData({
      key: 'whatsappTemplateData',
      value: {
        ...whatsappTemplateState,
        activeCarouselCardIndex: cardIndex,
      },
    });
  };

  const updateCarouselTemplateHeaderType = (
    headerType: TCarouselCardHeaderType
  ) => {
    //reset values of other cards header value
    const newCardList = whatsappTemplateState.cards.map((c) => {
      const cd = { ...c };
      cd.header.value = '';
      return { ...c, header: { value: '' } };
    });

    dispatch.integration.updateStateData({
      key: 'whatsappTemplateData',
      value: {
        ...whatsappTemplateState,
        headerType: headerType,
        cards: newCardList,
      },
    });
  };

  const addNewCarouselCard = () => {
    const cardList = structuredClone(whatsappTemplateState.cards);
    const defaultCardData: IWhatsappCarouselCard = {
      id: cardList.length + 1,
      body: '',
      header: {
        value: '',
      },
      buttons: [],
    };
    if (cardList.length >= 1) {
      const firstCardButtons = cardList[0].buttons.map((b) => {
        return {
          id: b.id,
          type: b.type,
        };
      });
      defaultCardData.buttons = firstCardButtons;
    }
    cardList.push(defaultCardData);
    dispatch.integration.updateStateData({
      key: 'whatsappTemplateData',
      value: {
        ...whatsappTemplateState,
        cards: cardList,
        activeCarouselCardIndex: defaultCardData.id,
      },
    });
  };

  const deleteCarouselCard = (cardId: number = 1) => {
    const cardList = structuredClone(whatsappTemplateState.cards);

    let newActiveCard = cardList[cardId];

    if (cardId === cardList.length) {
      newActiveCard = cardList[cardList.length - 2];
    } else if (cardId > 1) {
      newActiveCard = cardList[cardId - 1];
    }

    const filteredCard = cardList
      .filter((card) => card.id !== cardId)
      .map((card, index) => ({ ...card, id: index + 1 }));

    dispatch.integration.updateStateData({
      key: 'whatsappTemplateData',
      value: {
        ...whatsappTemplateState,
        cards: filteredCard,
        activeCarouselCardIndex: newActiveCard.id,
      },
    });
  };

  const deleteCarouselCardButton = (buttonId: number) => {
    const cards = whatsappTemplateState.cards;
    const newCards = cards.map((c) => {
      const buttons = c.buttons
        .filter((b) => b.id !== buttonId)
        .map((b, index) => {
          return { ...b, id: index + 1 };
        });
      return { ...c, buttons };
    });
    updateCarouselTemplateCardsData(newCards);
  };

  const addCarouselTemplateButton = (card: IWhatsappCarouselCard) => {
    const defaultButtonData: IWhatsappTemplateButton = {
      id: card.buttons.length + 1,
    };
    const newCards = whatsappTemplateState.cards.map((c) => {
      c.buttons.push({ ...defaultButtonData });
      return c;
    });
    updateCarouselTemplateCardsData(newCards);
  };

  return {
    selectedProject,
    addNewCarouselCard,
    updateTemplateType,
    updateTemplateBody,
    updateTemplateName,
    deleteCarouselCard,
    isValidTemplateData,
    addNewTemplateButton,
    deleteTemplateButton,
    updateTemplateButton,
    updateTemplateFooter,
    updateExpirationTime,
    whatsappTemplateState,
    updateTemplateLanguage,
    setIsValidTemplateData,
    updateAuthTemplateBody,
    updateTemplateCategory,
    updateTemplateHeaderType,
    deleteCarouselCardButton,
    updateActiveCarouselCard,
    updateExpirationTimeCheck,
    updateTemplateHeaderValue,
    addCarouselTemplateButton,
    resetWhatsappTemplateState,
    updateWhatsappTemplateData,
    updateTemplateBodyVariables,
    editWhatsappMessageTemplate,
    updateTemplateHeaderVariable,
    updateSingleCarouselCardData,
    createWhatsapptemplateLoading,
    createWhatsappMessageTemplate,
    updateCarouselTemplateCardsData,
    handleUpdateWhatsappAccountInfo,
    updateCarouselTemplateHeaderType,
    updateAuthTemplateSecurityRecommendation,
  };
};

export default useWhatsappTemplateData;
