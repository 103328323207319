export const capitalizeKey = (key: string) => {
  const words = key.split('_').map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return words.join(' ');
};

export const getColumnTitle = (lab_fields: any[], slug: string) => {
  for (const field of lab_fields) {
    if (field.slug === slug) {
      return field.label_agent || field.name;
    }
  }
  return capitalizeKey(slug);
};
