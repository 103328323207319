import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from 'libraryV2/ui/sheet';

import { Filter } from '../../../export';
import useTranslation from 'components/customHooks/useTranslation';

interface SiteSheetProps {
  onClose: () => void;
  onApplyFilter: (a: any) => void;
  isOpen: boolean;
}

const FilterSideSheet = ({
  onClose,
  onApplyFilter,
  isOpen = false,
}: SiteSheetProps) => {
  const { t } = useTranslation();
  
  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent
        side='right'
        className='bg-white sm:max-w-[470px] sm:w-[470px] overflow-auto scrollbar-hide'
      >
        <SheetHeader>
          <SheetTitle>{t('Add filter')}</SheetTitle>
        </SheetHeader>
        <div className='h-[82vh] mt-4'>
          <Filter onSideSheetClose={onClose} onApplyFilter={onApplyFilter} />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSideSheet;
