import {
  React,
  Listbox,
  CheckIcon,
  Transition,
  ChevronDownIcon,
} from '../../../../../export';
import { IConditionalDropdown } from 'pages/automationWorkflow/interface';

type Props = {
  activeValue: IConditionalDropdown;
  options: any;
  onChange: (selected: any) => void;
};

const ConditionDropDown: React.FC<Props> = ({
  activeValue,
  options,
  onChange,
}) => {
  return (
    <Listbox value={activeValue} onChange={onChange}>
      {({ open }) => (
        <>
          <div className='relative w-auto md:w-auto'>
            <Listbox.Button
              className={`${
                open ? 'border-primary' : 'border-gray-300'
              } relative py-2 px-4 text-gray-500 text-sm w-full text-left bg-white border  rounded-md cursor-pointer focus:outline-none`}
            >
              {activeValue?.label}
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none'>
                <ChevronDownIcon
                  className='w-4 h-4 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={React.Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className='absolute z-10 w-full mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              >
                {options?.map((channel: any, index: number) => (
                  <Listbox.Option
                    key={index}
                    className='cursor-pointer select-none relative py-2 px-3 my-1 text-gray-600 text-sm hover:bg-gray-100'
                    value={channel}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className='flex items-center w-full'>
                          <span className='ml-2 block truncate'>
                            {channel?.label}
                          </span>
                        </div>

                        {selected ? (
                          <span className='absolute inset-y-0 right-0 flex items-center pr-4 text-primary'>
                            <CheckIcon className='w-5 h-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default ConditionDropDown;
