import moment from 'moment';
import React from 'react';

import {
  ConversationInterface,
  CustomerReplyBlockInterface,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import defaultAvatar from '../../../assets/images/anonymousAvatar.svg';
import botAvatar from '../../../assets/images/bot-message-square.svg';
import metaAvatarIcon from '../../../assets/images/metaAvatarIcon.svg';
import ActionBar from './ActionBar';
import AudioAttachment from './AudioAttachment';
import ButtonBlock from './ButtonBlock';
import FileAttachment from './FileAttachment';
import GalleryBlock from './GalleryBlock';
import MediaAttachment from './MediaAttachment';
import NoteMessage from './NoteMessage';
import QuickReplyBlock from './QuickReplyBlock';
import ReferralBlock from './ReferralBlock';
import TemplateBlock from './TemplateBlock';
import TextMessage from './TextMessage';

interface Props {
  userId: number;
  conversationLoading: boolean;
  botConversationLoading: boolean;
  selectedTicket: TicketInterface;
  conversationData: ConversationInterface[];
  handleConversationDataForAdminReply: (
    blockData: CustomerReplyBlockInterface
  ) => void;
}

const SmartBlock: React.FC<Props> = ({
  userId,
  selectedTicket,
  conversationData,
  conversationLoading,
  botConversationLoading,
  handleConversationDataForAdminReply,
}) => {
  const getAvatar = (block: ConversationInterface) => {
    switch (block.source) {
      case 'customer':
        return selectedTicket?.customer_avatar || defaultAvatar;
      case 'bot':
        return botAvatar;
      case 'admin':
        return block?.admin_info?.avatar || defaultAvatar;
      case 'echo':
        return metaAvatarIcon || defaultAvatar;
      default:
        return defaultAvatar;
    }
  };

  const getReplyName = (block: ConversationInterface): string => {
    switch (block.source) {
      case 'bot':
        return 'Chatbot';
      case 'admin':
        return block?.admin_info?.id === userId
          ? 'You'
          : block?.admin_info?.full_name || 'Anonymous User';
      case 'customer':
        return selectedTicket?.customer_platform_type === 'telegram_group'
          ? block?.customer_info?.full_name
          : selectedTicket?.customer_full_name;
      case 'echo':
        return 'Facebook Business Manager';
      default:
        return 'Anonymous User';
    }
  };

  const getMergeableConversationFlag = (index: number): boolean => {
    let nextBlock = moment(conversationData[index + 1]?.timestamp);
    let thisBlock = moment(conversationData[index]?.timestamp);
    let timeDifference = moment.duration(thisBlock.diff(nextBlock)).asMinutes();

    // TODO: Handle Telegram mergable flag for multiple customers in a group
    if (selectedTicket?.customer_platform_type === 'telegram_group') {
      return false;
    }

    let isMergeable =
      conversationData[index + 1]?.source === conversationData[index]?.source &&
      conversationData[index + 1]?.dataV2?.type !== 'action'
        ? true
        : false;
    return timeDifference > 0 && timeDifference < 5 && isMergeable
      ? true
      : false;
  };

  const getDateBarFlag = (index: number) => {
    let now = moment().endOf('day');
    let nextBlock = now.diff(
      moment(conversationData[index + 1]?.timestamp),
      'days'
    );
    let thisBlock = now.diff(
      moment(conversationData[index]?.timestamp),
      'days'
    );

    if (nextBlock > thisBlock) {
      return true;
    } else if (index + 1 === conversationData.length) {
      return true;
    } else {
      return false;
    }
  };

  const getConversationStatus = (block: ConversationInterface) => {
    // TODO: to be removed after backend engineer run a script to update conversation_status for old conversations
    let { success, timestamp, conversation_status } = block;
    let successStatus = !!success ? 'delivered' : 'failed';
    return timestamp > 1709442661252 ? conversation_status : successStatus;
  };

  const getDefaultMessageBlock = (
    block: ConversationInterface,
    index: number
  ) => {
    let isMergeableFlag = getMergeableConversationFlag(index);
    let dateBarFlag = getDateBarFlag(index);
    let hasPermissionToShowAdminReply =
      userId === selectedTicket?.assigned_agent;
    let blockData = {
      type: 'text',
      success: false,
      error: 'Data Type not Supported',
      text: '',
      payload: '',
    };
    let conversationStatus = getConversationStatus(block);
    return (
      <TextMessage
        rawBlockData={block}
        blockData={blockData}
        avatar={getAvatar(block)}
        name={getReplyName(block)}
        time={block?.timestamp}
        source={block?.source}
        isSent={conversationStatus}
        isMergeable={isMergeableFlag}
        enableDateBar={dateBarFlag}
        platformType={block?.platform_type}
        hasPermissionToShowAdminReply={hasPermissionToShowAdminReply}
        handleConversationDataForAdminReply={
          handleConversationDataForAdminReply
        }
      />
    );
  };

  const getMessageBlocks = (block: ConversationInterface, index: number) => {
    let { dataV2, source, timestamp, report } = block;
    let isMergeableFlag = getMergeableConversationFlag(index);
    let dateBarFlag = getDateBarFlag(index);
    let hasPermissionToShowAdminReply =
      userId === selectedTicket?.assigned_agent;
    let conversationStatus = getConversationStatus(block);

    switch (dataV2?.type) {
      case 'text':
        return (
          <TextMessage
            rawBlockData={block}
            blockData={dataV2}
            avatar={getAvatar(block)}
            name={getReplyName(block)}
            time={timestamp}
            source={source}
            isSent={conversationStatus}
            isMergeable={isMergeableFlag}
            enableDateBar={dateBarFlag}
            report={report}
            platformType={block?.platform_type}
            handleConversationDataForAdminReply={
              handleConversationDataForAdminReply
            }
            hasPermissionToShowAdminReply={hasPermissionToShowAdminReply}
          />
        );
      case 'attachment':
      case 'story_mention':
        switch (dataV2?.sub_type) {
          case 'image':
          case 'video':
            return (
              <MediaAttachment
                rawBlockData={block}
                blockData={dataV2}
                avatar={getAvatar(block)}
                name={getReplyName(block)}
                time={timestamp}
                source={source}
                isSent={conversationStatus}
                isMergeable={isMergeableFlag}
                enableDateBar={dateBarFlag}
                report={report}
                platformType={block?.platform_type}
                handleConversationDataForAdminReply={
                  handleConversationDataForAdminReply
                }
                hasPermissionToShowAdminReply={hasPermissionToShowAdminReply}
              />
            );
          case 'audio':
            return (
              <AudioAttachment
                rawBlockData={block}
                blockData={dataV2}
                avatar={getAvatar(block)}
                name={getReplyName(block)}
                time={timestamp}
                source={source}
                isSent={conversationStatus}
                isMergeable={isMergeableFlag}
                enableDateBar={dateBarFlag}
                report={report}
                handleConversationDataForAdminReply={
                  handleConversationDataForAdminReply
                }
                hasPermissionToShowAdminReply={hasPermissionToShowAdminReply}
              />
            );
          case 'file':
          case 'document':
            return (
              <FileAttachment
                rawBlockData={block}
                blockData={dataV2}
                avatar={getAvatar(block)}
                name={getReplyName(block)}
                time={timestamp}
                source={source}
                isSent={conversationStatus}
                isMergeable={isMergeableFlag}
                enableDateBar={dateBarFlag}
                report={report}
                handleConversationDataForAdminReply={
                  handleConversationDataForAdminReply
                }
                hasPermissionToShowAdminReply={hasPermissionToShowAdminReply}
              />
            );
          default:
            return getDefaultMessageBlock(block, index);
        }
      case 'button':
        return (
          <ButtonBlock
            blockData={dataV2}
            avatar={getAvatar(block)}
            name={getReplyName(block)}
            time={timestamp}
            source={source}
            isSent={conversationStatus}
            isMergeable={isMergeableFlag}
            enableDateBar={dateBarFlag}
            report={report}
          />
        );
      case 'quick_reply':
        return (
          <QuickReplyBlock
            blockData={dataV2}
            avatar={getAvatar(block)}
            name={getReplyName(block)}
            time={timestamp}
            source={source}
            isSent={conversationStatus}
            isMergeable={isMergeableFlag}
            enableDateBar={dateBarFlag}
            report={report}
          />
        );
      case 'note':
        return (
          <NoteMessage
            blockData={dataV2}
            avatar={getAvatar(block)}
            name={getReplyName(block)}
            time={timestamp}
            source={source}
            enableDateBar={dateBarFlag}
          />
        );
      case 'gallery':
        return (
          <GalleryBlock
            blockData={dataV2}
            avatar={getAvatar(block)}
            name={getReplyName(block)}
            time={timestamp}
            source={source}
            isSent={conversationStatus}
            isMergeable={isMergeableFlag}
            conversationId={block.conversation_id}
            enableDateBar={dateBarFlag}
            report={report}
          />
        );
      case 'template':
        return (
          <TemplateBlock
            blockData={dataV2}
            avatar={getAvatar(block)}
            name={getReplyName(block)}
            time={timestamp}
            source={source}
            isSent={conversationStatus}
            isMergeable={isMergeableFlag}
            conversationId={block.conversation_id}
            enableDateBar={dateBarFlag}
            report={report}
          />
        );
      case 'action':
        return (
          <ActionBar
            blockData={dataV2}
            time={timestamp}
            enableDateBar={dateBarFlag}
          />
        );
      case 'ads':
        return (
          <ReferralBlock
            blockData={dataV2}
            avatar={getAvatar(block)}
            name={getReplyName(block)}
            time={timestamp}
            source={source}
            isSent={conversationStatus}
            enableDateBar={dateBarFlag}
            report={report}
          />
        );
      case 'private_reply':
        return (
          <ReferralBlock
            blockData={dataV2}
            avatar={getAvatar(block)}
            name={getReplyName(block)}
            time={timestamp}
            source={source}
            isSent={conversationStatus}
            enableDateBar={dateBarFlag}
            report={report}
          />
        );
      default:
        return getDefaultMessageBlock(block, index);
    }
  };

  const scrollToBottom = () => {
    const hasScrollBehavior =
      'scrollBehavior' in document.documentElement.style;
    const container = document.getElementById('conversation-bar');
    if (!!container) {
      if (hasScrollBehavior) {
        container.scroll({
          top: container.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        container.scrollTop = container?.scrollHeight;
      }
    }
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [conversationLoading, botConversationLoading]);

  return (
    <>
      {!!selectedTicket &&
        !!conversationData &&
        conversationData.map((block: ConversationInterface, index: number) => (
          <div
            className='flex flex-col-reverse'
            key={index}
            id={`conversation_${block.id}`}
            data-id={block?.conversation_id}
          >
            {getMessageBlocks(block, index)}
          </div>
        ))}
    </>
  );
};

export default SmartBlock;
