import useTranslation from 'components/customHooks/useTranslation';
import { cn } from 'libraryV2/utils';
import React, { ReactNode } from 'react';
import { feedChannels } from 'utilities/utils';
import useMessageBlocksAction from '../../../../hooks/useMessageBlocksAction';
import MessageStatus from './MessageStatus';
import Time from './Time';

interface BoxProps {
  children: ReactNode;
  time: number;
  success: string | null;
  isMerged: boolean;
  report: string;
  source: string;
  isFirstElement?: boolean;
  conversationType: string;
  replay?: boolean;
  platformType?: string;
}

const ConversationBlockWrapper: React.FC<BoxProps> = ({
  children,
  time,
  source,
  report,
  success,
  conversationType,
  isFirstElement = true,
  replay,
  platformType,
}) => {
  const { isZigzagView } = useMessageBlocksAction({ source });
  const { isRtlLanguage } = useTranslation();
  const isFeedChannels = feedChannels.includes(platformType ?? '');

  const isAdmin = ['admin', 'echo'].includes(source);
  const borderRadius = isFirstElement
    ? (isAdmin || source === 'bot') && isZigzagView && !isFeedChannels
      ? 'rounded-xl ltr:rounded-tr-[4px] rtl:rounded-tl-[4px]'
      : (isAdmin || source === 'bot') && isFeedChannels
      ? 'rounded-xl ltr:rounded-tl-[4px] rtl:rounded-tr-[4px]'
      : 'rounded-xl ltr:rounded-tl-[4px] rtl:rounded-tr-[4px]'
    : 'rounded-xl';

  const backgroundColor =
    (isAdmin || source === 'bot') && conversationType === 'text'
      ? 'bg-[#FAFAFA]'
      : isAdmin || source === 'bot'
      ? 'bg-white'
      : 'bg-white';

  return (
    <div
      onClick={() => console.log('report', report)}
      className={cn(
        `relative p-2 overflow-hidden ${
          isAdmin || source === 'bot' ? 'pr-[72px]' : 'pr-14'
        } flex flex-col gap-2 border border-gray-200 ${borderRadius} ${backgroundColor}`,
        conversationType === 'attachment'
          ? 'rounded-xl border-0 p-0 w-max bg-transparent'
          : conversationType === 'text'
          ? 'w-full'
          : conversationType === 'template' ||
            conversationType === 'gallery' ||
            (conversationType === 'audio' && source !== 'customer') ||
            (conversationType === 'file' && source !== 'customer') ||
            (conversationType === 'quick_reply' && source !== 'customer') ||
            (conversationType === 'button' && source !== 'customer')
          ? `rounded-xl w-max p-0 bg-[#FAFAFA] ${borderRadius}`
          : `w-max rounded-xl p-0 bg-transparent border ${borderRadius}`,
        conversationType === 'gallery' && 'gap-0',
        conversationType === 'file' &&
          (isAdmin || source === 'bot') &&
          'pr-[72px]',
        conversationType === 'file' &&
          (!isAdmin || source !== 'bot') &&
          !isRtlLanguage &&
          'pr-[70px]',
        source === 'customer' && conversationType === 'file' && 'pr-16',
        conversationType === 'file' &&
          (!isAdmin || source !== 'bot') &&
          isRtlLanguage &&
          'pr-[4.5rem]',
        source === 'customer' &&
          conversationType === 'file' &&
          isRtlLanguage &&
          'pr-16',
        conversationType === 'quick_reply' && 'p-2',
        conversationType === 'button' && 'p-2 pb-8',
        conversationType === 'quick_reply' &&
          (isAdmin || source === 'bot') &&
          'pr-[80px]',
        (report?.result?.reply_to_message || replay) &&
          conversationType !== 'text' &&
          'bg-transparent border-0 pr-0',
        conversationType === 'text' && 'w-full',
        (report?.result?.reply_to_message || replay) &&
          conversationType === 'text' &&
          'w-[fit-content]'
      )}
    >
      <div
        className={cn(
          'mb-0 flex flex-col',
          conversationType === 'text' ? 'mr-2' : 'mr-0'
        )}
      >
        {children}
      </div>
      <div
        className={cn(
          'flex justify-start items-center gap-1 ltr:self-end rtl:self-start',
          conversationType === 'text' &&
            'absolute z-[8] right-2 bottom-2 justify-end',
          conversationType === 'text' ||
            conversationType === 'template' ||
            conversationType === 'gallery'
            ? 'w-max h-[fit-content] z-[9]'
            : conversationType === 'audio'
            ? 'absolute w-[40%] h-7 bottom-0 z-[9] right-2'
            : 'absolute w-1/4 h-7 bottom-0 z-[9] right-2',
          conversationType === 'template' || conversationType === 'gallery'
            ? 'm-2'
            : 'bottom-1',
          conversationType === 'file' &&
            'bottom-1 ltr:justify-end rtl:justify-start',
          conversationType === 'attachment' && 'right-0',
          conversationType === 'audio' &&
            'bottom-0 ltr:justify-end rtl:justify-start',
          conversationType === 'quick_reply' &&
            'bottom-0 ltr:justify-end rtl:justify-start',
          conversationType === 'button' &&
            'bottom-0 ltr:justify-end rtl:justify-start'
        )}
      >
        <Time
          time={time}
          isMerged={false}
          source={source}
          conversationType={conversationType}
        />
        {(isAdmin || source === 'bot') && (
          <MessageStatus
            success={success}
            isMerged={false}
            report={report}
            source={conversationType === 'text' ? source : undefined}
            conversationType={conversationType}
          />
        )}
      </div>
    </div>
  );
};

export default ConversationBlockWrapper;
