import TranslationWrapper from 'components/customHooks/useTranslationWrapper';
import { parse, startOfToday, subDays } from 'date-fns';
import lodash from 'lodash';
import { TFileType } from '../components/formFields/FileUploader';
import { IFormField, format } from '../export';

export function convertTo12H(time24: string | null) {
  if (!time24) {
    return '';
  }
  // 00:25 -> 12:25
  const [hours, minutes] = time24.trim().split(':');
  let hoursNum = parseInt(hours);
  const ampm = hoursNum >= 12 ? 'PM' : 'AM';
  hoursNum = hoursNum % 12;
  hoursNum = hoursNum ? hoursNum : 12;
  const minutesStr = minutes.padStart(2, '0');
  const time12 = `${hoursNum}:${minutesStr} ${ampm}`;
  return time12;
}

export const getGroupFieldValue = (
  formState: any,
  parentGroupField: string,
  subGroupId: string,
  fieldSlug: string
) => {
  if (!parentGroupField && !subGroupId) {
    return '';
  }
  const parentGroupValue = formState.values[parentGroupField];
  if (!parentGroupValue) {
    return '';
  }
  const subGroupValue = parentGroupValue[subGroupId];
  if (!subGroupValue) {
    return '';
  }
  const fieldValue = subGroupValue[fieldSlug];
  if (!fieldValue) {
    return '';
  }
  return fieldValue;
};

export const setGroupFieldValue = (
  rootState: any,
  parentGroupField: string,
  subGroupId: string,
  fieldSlug: string,
  value: any
) => {
  const formState = structuredClone(rootState);
  if (!parentGroupField && !subGroupId) {
    return '';
  }
  const parentGroupValue = formState.values[parentGroupField];
  if (!parentGroupValue) {
    formState.values[parentGroupField] = {};
  }
  const subGroupValue = formState.values[parentGroupField][subGroupId];
  if (!subGroupValue) {
    formState.values[parentGroupField][subGroupId] = {};
  }
  formState.values[parentGroupField][subGroupId][fieldSlug] = value;
  return formState;
};

export const combineDateTime = (dateString: any, timeString: string) => {
  const dateObj = new Date(dateString);

  // Parse the time string
  const time = parse(timeString, 'HH:mm', new Date());

  // Combine the date and time
  const combinedDate = new Date(
    dateObj.getTime() + time.getTime() - time.setHours(0, 0, 0, 0)
  );

  // Format the combined date and time
  const formattedDateTime = format(
    combinedDate,
    'EEE MMM dd yyyy HH:mm:ss xxx'
  );
  return formattedDateTime;
};
interface DatalabDefaultFieldsValueOptions {
  shouldPrefill: boolean;
  customerAttributes: {
    ticket: any;
    customer: any;
  };
}
export const getSingleFieldDefaultValue = (
  field: IFormField,
  options: DatalabDefaultFieldsValueOptions
) => {
  switch (field.type) {
    case 'group': {
      return {
        slug: field.slug,
        value: [getDefaultFieldsValue(field?.children ?? [])],
      };
    }
    case 'multiselect': {
      return {
        slug: field.slug,
        value: [],
      };
    }
    default: {
      const shouldPrefill =
        options.shouldPrefill && field.prefilled_customer_attribute;

      const defaultValue = {
        slug: field.slug,
        value: '',
      };
      if (shouldPrefill) {
        const attr = field?.prefilled_customer_attribute || '';
        const [infoSource, info] = attr
          .replace('{{', '')
          .replace('}}', '')
          .split('.');
        if (Object.keys(options.customerAttributes).includes(infoSource)) {
          defaultValue.value =
            options.customerAttributes[infoSource][info] || '';
        }
      }
      return defaultValue;
    }
  }
};

export const getDefaultFieldsValue = (
  fieldList: IFormField[],
  options: DatalabDefaultFieldsValueOptions = {
    shouldPrefill: false,
    customerAttributes: { ticket: {}, customer: {} },
  }
) => {
  const result: { [key: string]: any } = {};
  fieldList?.forEach((v) => {
    const { slug, value } = getSingleFieldDefaultValue(v, options);
    result[slug] = value;
  });
  return result;
};

export const mapFormValues = (
  response: Record<string, any>,
  defaultValue: Record<string, any>
) => {
  const validKeys = Object.keys(defaultValue);
  const newResponse = {};
  Object.keys(response).forEach((v) => {
    if (validKeys.includes(v)) {
      newResponse[v] = response[v];
    }
  });
  return newResponse;
};

export const getHookFormRules = (fieldData: IFormField) => {
  const isRequired = !!fieldData.is_required;
  const minLength = Number.isNaN(Number(fieldData.min_length))
    ? null
    : Number(fieldData.min_length);
  const maxLength = Number.isNaN(Number(fieldData.max_length))
    ? null
    : Number(fieldData.max_length);

  const result: any = {};

  if (isRequired) {
    result['required'] = {
      value: true,
      message: `${fieldData.name} field is required.`,
    };
  }

  if (minLength) {
    const validationKey = fieldData.type === 'number' ? 'min' : 'minLength';
    result[validationKey] = {
      value: minLength,
      message: `Minimum length is ${minLength}.`,
    };
  }

  if (maxLength) {
    const validationKey = fieldData.type === 'number' ? 'max' : 'maxLength';
    result[validationKey] = {
      value: maxLength,
      message: `Maximum length is ${maxLength}.`,
    };
  }
  // console.log(fieldData.type, result);
  return result;
};

export function isValidDate(date: any): boolean {
  return (
    date &&
    Object.prototype.toString.call(date) === '[object Date]' &&
    !isNaN(date)
  );
}

export const formatDatalabNewEntryRequestPayload = (
  formState: Record<string, any>
) => {
  const payload: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(formState)) {
    switch (typeof value) {
      case 'string': {
        if (value.length) {
          payload[key] = value;
        }
        break;
      }
      case 'object': {
        const isDate = isValidDate(value);
        if (isDate) {
          const dateTime = format(
            new Date(value!.toString()),
            'EEE MMM dd yyyy HH:mm:ss xxx'
          );
          payload[key] = dateTime;
          break;
        }
        // at this moment it's a group/multi select
        if (value?.length) {
          const etype = typeof value[0];

          if (etype === 'string') {
            payload[key] = value;
          } else {
            const groupValue = value.map((v: Record<string, any>) =>
              formatDatalabNewEntryRequestPayload(v)
            );
            if (Object.keys(groupValue)) {
              payload[key] = groupValue;
            }
          }
        }
        break;
      }
      case 'number': {
        payload[key] = value;
        break;
      }
      default:
        break;
    }
  }
  return payload;
};

export const formatDatalabFormEntryUpdate = (
  previousEntry: Record<string, any>,
  updatedEntry: Record<string, any>
) => {
  const changes = {};

  for (const key in previousEntry) {
    if (!lodash.has(updatedEntry, key)) {
      continue;
    }

    if (Array.isArray(previousEntry[key]) && Array.isArray(updatedEntry[key])) {
      // Both values are arrays
      const oldArray = previousEntry[key];
      const updatedArray = updatedEntry[key];
      const diff = updatedArray.filter(
        (item: any) => !oldArray.some((old: any) => lodash.isEqual(old, item))
      );
      const removed = oldArray.filter(
        (item: any) =>
          !updatedArray.some((updated: any) => lodash.isEqual(item, updated))
      );

      if (diff.length > 0 || removed.length > 0) {
        changes[key] = {
          from: oldArray,
          to: updatedArray,
        };
      }
    } else if (!lodash.isEqual(previousEntry[key], updatedEntry[key])) {
      // Values are not arrays or are different
      changes[key] = {
        from: previousEntry[key],
        to: updatedEntry[key],
      };
    }
  }

  for (const key in updatedEntry) {
    if (!(key in previousEntry)) {
      changes[key] = {
        from: null,
        to: updatedEntry[key],
      };
    }
  }

  return { changes };
};

export const defaultFileSizeLimits = {
  // in binary bytes
  video: 50 * 1024 * 1024, // 16mb
  image: 7 * 1024 * 1024, // 5mb
  document: 7 * 1024 * 1024, //100mb
  file: 7 * 1024 * 1024, //100mb
  audio: 50 * 1024 * 1024, // 16mb
} as const;

export const getFileMimeTypeFormat = (
  fileType: TFileType
): Record<string, string[]> => {
  switch (fileType) {
    case 'video':
      return {
        'video/*': [],
      };
    case 'image':
      return {
        'image/*': [],
      };
    // case 'document':
    case 'file':
      return {
        'application/msword': ['.doc', '.docx', '.csv'],
        'application/pdf': ['.pdf'],
      };
    case 'audio':
      return {
        'audio/*': [],
      };
    default:
      return {
        '*': ['*'],
      };
  }
};

export const getFileTypeErrorMessages = (
  allowedMimeTypes: string[],
  fileType: TFileType,
  size: number
) => {
  const formattedMimeTypes = Object.values(getFileMimeTypeFormat(fileType))
    .flat()
    .map((v) => v.replace('.', ''));
  const allowedFileTypeMessage =
    fileType === 'file' ? ` & type should be ${formattedMimeTypes}` : '.';
  const sizeInMB = Math.floor(size / (1024 * 1024));
  return `${fileType} size should be less than ${sizeInMB}MB${allowedFileTypeMessage}`;
};

export const getAllowedMimeTypes = (fileType: TFileType) => {
  switch (fileType) {
    case 'video':
      return ['video/mp4'];
    case 'image':
      return ['image/jpeg', 'image/png'];
    // case 'document':
    case 'file':
      return [
        'application/pdf',
        'application/msword',
        `application/vnd.openxmlformats-officedocument.wordprocessingml.document`,
      ];
    case 'audio':
      return ['audio/mpeg', 'audio/wav'];
    default:
      return [];
  }
};

export const getUploadGuideMessage = (
  allowedMimeTypes: string[],
  fileType: TFileType,
  size: number
) => {
  const sizeInMB = Math.floor(size / (1024 * 1024));
  // const mimeTypes = allowedMimeTypes
  //   .map((v) => v.split('/')?.pop()?.toUpperCase())
  //   .join(' or ');

  return (
    <span className='text-sm leading-5 text-zinc-900'>
      <TranslationWrapper text='Upload or drag & drop your' />
      {'  '}
      <TranslationWrapper text={fileType} /> {'  '}
      <TranslationWrapper text='here' />
      <br />
      <span className='text-[#A1A1AA]'>
        <TranslationWrapper text='Maximum file size is' /> {sizeInMB}{' '}
        <TranslationWrapper text='MB' />
      </span>
    </span>
  );
};

export function convertTo12HourTime(time24: string = '') {
  let [hours, minutes] = time24.split(':').map(Number);

  hours = Math.min(Math.max(0, hours), 23);
  const hours12 = hours % 12 || 12;

  minutes = Math.min(Math.max(0, minutes), 59);
  const period = hours >= 12 ? 'pm' : 'am';

  return {
    hours: hours12,
    minutes: minutes,
    period: period,
  };
}

export const isEmtyObject = (obj: any) => {
  return Object.keys(obj).length === 0;
};

export const getThirtyDaysDateRange = () => {
  const today = startOfToday();
  const initialFromDate = subDays(today, 30);
  const initialToDate = today;
  return { today, initialFromDate, initialToDate };
};
