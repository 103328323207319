import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ISLAData, IEscalation, Priority, IPolicy } from '../interface';
import { LOCAL_UTILS } from '../export';

const useEscalationsUtils = () => {
  const dispatch = useDispatch();
  const slaStateData = useSelector(
    (state: any) => state.settingsSLAConfiguration
  );
  const { localSLAData } = slaStateData;

  const handleEscalationRuleAddition = () => {
    let copiedSLAData: ISLAData = { ...localSLAData };

    let updatedEscalations: IEscalation[] = [
      ...copiedSLAData.escalations,
      LOCAL_UTILS.defaultEscalationRule,
    ];

    let updatedLocalSLAData = {
      ...copiedSLAData,
      escalations: updatedEscalations,
    };
    dispatch.settingsSLAConfiguration.updateLocalSLAData(updatedLocalSLAData);
  };

  const timeMap: Record<number, string> = useMemo(
    () => ({
      0: 'Immediately',
      60: 'After 1 hour',
      120: 'After 2 hours',
      180: 'After 3 hours',
      240: 'After 4 hours',
      300: 'After 5 hours',
      360: 'After 6 hours',
      420: 'After 7 hours',
      480: 'After 8 hours',
      720: 'After 12 hours',
      1440: 'After 1 day',
      2880: 'After 2 days',
    }),
    []
  );

  const handleEscalationRuleDeletion = (index: number) => {
    let copiedSLAData: ISLAData = { ...localSLAData };

    // Ensure index is valid
    if (index < 0 || index >= copiedSLAData.escalations.length) {
      console.error('Invalid index for escalation rule deletion.');
      return;
    }

    let updatedEscalations: IEscalation[] = copiedSLAData.escalations.filter(
      (_, i) => i !== index
    );

    let updatedLocalSLAData = {
      ...copiedSLAData,
      escalations: updatedEscalations,
    };
    dispatch.settingsSLAConfiguration.updateLocalSLAData(updatedLocalSLAData);
  };

  const checkForDuplicateEscalation = (index: number): EscalationMatch => {
    let { escalations } = localSLAData;
    // Function implementation goes here
    const targetEscalation = escalations[index];

    if (!targetEscalation) return false;

    const { priority, target } = targetEscalation;

    for (let i = 0; i < escalations.length; i++) {
      if (i !== index) {
        const escalation = escalations[i];

        // Find common priorities between the two lists
        const matchedPriorities = priority.filter((p: any) =>
          escalation.priority.includes(p)
        );

        // If there are common priorities and the target and time also match
        if (matchedPriorities.length > 0 && escalation.target === target) {
          return {
            priority: matchedPriorities, // Return only the matched priorities
            target: escalation.target,
            time: escalation.time,
          };
        }
      }
    }

    return false;
  };

  type EscalationMatch =
    | {
        priority: number[];
        target: string;
        time: string;
      }
    | false;

  const checkEscalationRuleCondition = (index: number): EscalationMatch => {
    let { escalations, policies } = localSLAData;

    const targetEscalation = escalations[index];
    if (!targetEscalation) return false;

    const { priority, target, time } = targetEscalation;

    const doesPriorityExceedPolicy = (matchedPriority: Priority): boolean => {
      const policy = policies.find(
        (p: IPolicy) => p.priority === matchedPriority
      );
      if (!policy) return false;

      if (time !== 0) {
        const isFirstResponseTimeExceeded =
          target === 'first_response_time' && time < policy.first_response_time;
        const isResolutionTimeExceeded =
          target === 'resolution_time' && time < policy.resolution_time;

        if (isFirstResponseTimeExceeded || isResolutionTimeExceeded) {
          return true;
        }
      }

      return false;
    };

    for (let i = 0; i < escalations.length; i++) {
      const escalation = escalations[i];

      // Find common priorities between the two lists
      let matchedPriorities = priority.filter((p: any) =>
        escalation.priority.includes(p)
      );

      // Filter the matched priorities based on time exceed rule
      matchedPriorities = matchedPriorities.filter(doesPriorityExceedPolicy);

      // If there are common priorities that violate the time exceed rule and the target also matches
      if (matchedPriorities.length > 0 && escalation.target === target) {
        return {
          priority: matchedPriorities, // Return only the matched priorities that violate the rule
          target: escalation.target,
          time: escalation.time,
        };
      }
    }

    return false;
  };

  return {
    timeMap,
    checkForDuplicateEscalation,
    handleEscalationRuleAddition,
    handleEscalationRuleDeletion,
    checkEscalationRuleCondition,
  };
};

export default useEscalationsUtils;
