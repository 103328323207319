import { FC } from 'react';
import {
  Command,
  Checkbox,
  usePolicy,
  CommandItem,
  useSLA,
  LOCAL_UTILS,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  DropdownField,
  DefaultAvatarIcon,
  TextWithTooltip,
} from '../../export';

interface Props {
  selectedAgentsList: Array<string | number>;
  handleAgentListUpdate: (updatedList: Array<number | string>) => void;
}

const AgentSelection: FC<Props> = ({
  selectedAgentsList,
  handleAgentListUpdate,
}) => {
  const { agentList } = useSLA();
  const { inputFieldClass } = usePolicy();

  // Function to determine the badge color based on the agent's status
  const renderAgentStatusBadgeColor = (status: string) => {
    if (status === 'online') {
      return 'bg-green-400';
    } else if (status === 'away') {
      return 'bg-yellow-400';
    } else return 'bg-[#D1D5DB]';
  };

  const DefaultAgent = () => (
    <CommandItem asChild key={-1} value={'Assigned Agent'}>
      <div className='flex items-center space-x-2'>
        <Checkbox
          id={'assigned_agent'}
          checked={selectedAgentsList.includes(-1)}
          className={`flex-shrink-0 w-[4.5%] ${
            selectedAgentsList.includes(-1)
              ? 'bg-primary text-white border-transparent'
              : ''
          }`}
          onCheckedChange={() => {
            let copiedAgentList = [...selectedAgentsList];
            const index = copiedAgentList.indexOf(-1);
            if (index !== -1) {
              copiedAgentList.splice(index, 1);
            } else {
              copiedAgentList.push(-1);
            }
            handleAgentListUpdate(copiedAgentList);
          }}
        />
        <div className='relative'>
          <img
            alt='agents'
            loading='lazy'
            className='flex-shrink-0 w-5 h-5 rounded-full'
            src={DefaultAvatarIcon}
          />
          <span
            className={`absolute -bottom-1 -right-1 t-0 inline-block w-2.5 h-2.5 ${renderAgentStatusBadgeColor(
              ''
            )} border-2 border-white rounded-full`}
          />
        </div>
        <div className='flex items-center w-[80%] space-x-2'>
          <label
            htmlFor={'assigned_agent'}
            className={`flex-grow text-sm font-normal leading-none cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-textPrimary w-2/3`}
          >
            Assigned Agent
          </label>
        </div>
      </div>
    </CommandItem>
  );

  const AgentSelectionComponet = () => (
    <Command>
      <CommandInput placeholder='Search channels' />
      <CommandList asChild>
        <CommandEmpty>No framework found.</CommandEmpty>
        <CommandGroup>
          {/* Render DefaultAgent first */}
          <DefaultAgent />
          {agentList.map((agent: any, index: number) => (
            <CommandItem
              asChild
              key={index}
              value={agent?.admin?.full_name}
              onSelect={(currentValue) => {
                // Handle selection logic here
              }}
            >
              <div className='flex items-center space-x-2'>
                <Checkbox
                  id={agent?.admin?.id}
                  checked={selectedAgentsList.includes(agent?.admin?.id)}
                  className={`flex-shrink-0 w-[4.5%] ${
                    selectedAgentsList.includes(agent?.admin?.id)
                      ? 'bg-primary text-white border-transparent'
                      : ''
                  }`}
                  onCheckedChange={() => {
                    let copiedAgentList = [...selectedAgentsList];
                    const index = copiedAgentList.indexOf(agent?.admin?.id);
                    if (index !== -1) {
                      copiedAgentList.splice(index, 1);
                    } else {
                      copiedAgentList.push(agent?.admin?.id);
                    }
                    handleAgentListUpdate(copiedAgentList);
                  }}
                />
                <div className='relative'>
                  <img
                    alt='agents'
                    loading='lazy'
                    className='flex-shrink-0 w-5 h-5 rounded-full'
                    src={agent?.admin?.avatar || DefaultAvatarIcon}
                  />
                  <span
                    className={`absolute -bottom-1 -right-1 t-0 inline-block w-2.5 h-2.5 ${renderAgentStatusBadgeColor(
                      agent?.admin?.status || ''
                    )} border-2 border-white rounded-full`}
                  />
                </div>
                <div className='flex items-center w-[80%] space-x-2'>
                  <label
                    htmlFor={agent?.admin?.id}
                    className={`flex-grow text-sm font-normal leading-none cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-textPrimary w-2/3`}
                  >
                    <TextWithTooltip
                      text={agent?.admin?.full_name}
                      dataFor={agent?.admin?.full_name}
                      dataTip={agent?.admin?.full_name}
                      shouldShowTooltip={agent?.admin?.full_name.length > 37}
                      className='truncate'
                    />
                  </label>
                  <label className='detail-medium'>{agent?.role?.name}</label>
                </div>
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );

  return (
    <DropdownField
      childrenName='person'
      popOverContentClass='w-96'
      buttonClass={`${inputFieldClass}`}
      children={<AgentSelectionComponet />}
      value={LOCAL_UTILS.getAgentNameList(selectedAgentsList, agentList)}
    />
  );
};

export default AgentSelection;
