import React from 'react';
import ResponseListCsat from '../CSAT/ResponseListCsat';
import ResponseDisCsat from '../CSAT/ResponseDisCsat';
import { connect } from 'react-redux';
import DataStack from '../CommonComponent/DataStack';
import { analytics } from '../../../../utilities/content';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { navigate } from '@reach/router';
import { Button } from 'evergreen-ui';
import TranslationWrapper from 'components/customHooks/useTranslationWrapper';

interface Prop {
  csatTotalCount: CsatStackProps;
  csatRespondedCount: CsatStackProps;
  csatAvgRating: CsatStackProps;
  handleRespondedListNext: () => void;
  handleRespondedListPrev: () => void;
  handleRespondedSearch: (search: string) => void;
  csatStackLoader: boolean;
  total: number;
  csatResponseList: any[];
  offset: number;
  csatResponseListLoader: boolean;
  csatResponseDist: CsatResponseDistProps[];
  csatResponseDistLoader: boolean;
  exportCSAT: (projectId: number) => void;
  selectedProject: SelectedTeamInterface;
}

interface CsatResponseDistProps {
  rating: number;
  count: number;
  percentage: string;
}

interface CsatStackProps {
  current_value: number | string;
  growth_direction: '+' | '-' | null;
  growth_value: string;
  previous_value: number | string;
  span: number;
}

const overlay = (selectedProject: SelectedTeamInterface) => {
  return (
    <div
      className='z-10 absolute w-full h-full'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='flex flex-col items-center justify-center h-full bg-[#ffffffe6]'>
        <LockClosedIcon className='w-10 h-10 p-2.5 m-5 text-blue-900 bg-blue-100 rounded-[50%]' />
        <p className='text-sm font-medium text-gray-900'>
          <TranslationWrapper text='Upgrade to Premium' />
        </p>
        <p className='text-sm font-normal text-gray-500'>
          <TranslationWrapper text='Add Superpowers to your support arsenal!' />
        </p>
        <Button
          onClick={() => navigate('/settings/billing')}
          className='button-alice__gradient m-5'
        >
          <TranslationWrapper text='Upgrade Now' />
        </Button>
      </div>
    </div>
  );
};

const CSATReports: React.FC<Prop> = ({
  csatTotalCount,
  csatAvgRating,
  csatRespondedCount,
  handleRespondedListNext,
  handleRespondedListPrev,
  handleRespondedSearch,
  csatStackLoader,
  csatResponseList,
  total,
  offset,
  csatResponseListLoader,
  csatResponseDist,
  csatResponseDistLoader,
  exportCSAT,
  selectedProject,
}) => {
  return (
    <div className='relative inline-block'>
      {selectedProject?.subscription_plan?.plan_type === 'free' &&
        overlay(selectedProject)}
      <DataStack
        column={3}
        data={[
          {
            ...csatTotalCount,
            name: analytics.CSAT.dataCard[0].title,
            loader: csatStackLoader,
            toolTip: analytics.CSAT.dataCard[0].toolTip,
          },
          {
            ...csatRespondedCount,
            name: analytics.CSAT.dataCard[1].title,
            loader: csatStackLoader,
            toolTip: analytics.CSAT.dataCard[1].toolTip,
          },
          {
            ...csatAvgRating,
            name: analytics.CSAT.dataCard[2].title,
            loader: csatStackLoader,
            toolTip: analytics.CSAT.dataCard[2].toolTip,
          },
        ]}
      />
      <div className='grid grid-cols-3 gap-4 m-3 h-2/4 '>
        <div className='h-full col-span-3 md:col-span-2'>
          <ResponseListCsat
            handleRespondedListNext={handleRespondedListNext}
            handleExport={() => {
              exportCSAT(selectedProject.id);
            }}
            handleRespondedListPrev={handleRespondedListPrev}
            handleRespondedSearch={handleRespondedSearch}
            total={total}
            offset={offset}
            list={csatResponseList}
            loader={csatResponseListLoader}
            exportLoader={false}
          />
        </div>
        <div className='col-span-3 md:col-span-1'>
          <ResponseDisCsat
            loader={csatResponseDistLoader}
            data={csatResponseDist}
          />
        </div>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  csatTotalCount: state.reporting.csatTotalCount,
  csatRespondedCount: state.reporting.csatRespondedCount,
  csatAvgRating: state.reporting.csatAvgRating,
  csatStackLoader: state.loading.effects.reporting.fetchCsatStack,
  csatResponseList: state.reporting.csatResponseList,
  total: state.reporting.csatResponseListTotal,
  csatResponseListLoader: state.loading.effects.reporting.fetchCsatResponseList,
  csatResponseDist: state.reporting.csatResponseDist,
  csatResponseDistLoader: state.loading.effects.reporting.fetchCsatResponseDist,
  selectedProject: state.dashboard.selectedProject,
  exportCSATLoader: state.loading.effects.reporting.exportCSAT,
});

const mapDispatch = (dispatch: any) => ({
  exportCSAT: (projectId: number | null) =>
    dispatch.reporting.exportCSAT(projectId),
});

const CSATReportsContainer = connect(mapState, mapDispatch)(CSATReports);

export default CSATReportsContainer;
