import { PlayCircleIcon } from '@heroicons/react/24/solid';
import {
  ArrowTopRightOnSquareIcon,
  PdfPreviewIcon,
  UtilityFunctions,
  useActions,
  useAutomationWorkflow,
} from 'pages/automationWorkflow/export';
import {
  IWhatsappTemplate,
  IWhatsappTemplateButton,
} from 'pages/automationWorkflow/interface';
import { transformTextStyle } from 'pages/inbox/utils/functions';

const WhatsappTemplatePreview = () => {
  // Destructure required state variables from the automation workflow hook
  const { selectedWhatsappData } = useAutomationWorkflow();

  // Extract required action functions from the custom hook
  const { getButtonColSpan } = useActions();

  // Function to render the body of the WhatsApp template with proper styling
  const renderTemplateBody = (text: string) => {
    text = transformTextStyle(text ?? '');
    const bodyText = UtilityFunctions.highlightAttributeFromText(text);

    return (
      <p
        className={`text-gray-600 font-normal text-sm leading-7`}
        dangerouslySetInnerHTML={{ __html: bodyText }}
      />
    );
  };

  // Function to render the header of the WhatsApp template based on its type
  const renderTemplateHeader = (data: IWhatsappTemplate) => {
    switch (data?.header?.type) {
      case 'text':
        const headerText = UtilityFunctions.highlightAttributeFromText(
          data?.header?.value
        );
        return (
          <p
            className={`text-gray-800 font-semibold
                        text-sm leading-7 mb-2`}
            dangerouslySetInnerHTML={{ __html: headerText }}
          />
        );
      case 'image':
        return <img src={data?.header?.value} alt='' />;
      case 'document':
        const pdfUrl = data?.header?.value;
        const pdfName = UtilityFunctions.getPdfNameFromUrl(pdfUrl);
        return (
          <div className='flex items-center justify-center flex-col gap-4 w-full border border-dashed border-gray-500 py-6 shadow-sm rounded-md'>
            <div>
              <PdfPreviewIcon />
            </div>
            <span className='text-gray-800 font-semibold text-sm leading-7'>
              {pdfName}
            </span>
          </div>
        );
      case 'video':
        return (
          <div className='flex items-center justify-center flex-col gap-4 w-full border border-dashed border-gray-500 py-6 shadow-sm rounded-md'>
            <div
              key={1}
              className='relative cursor-pointer  w-1/2 max-w-[190px] mx-auto'
            >
              <div className='absolute bg-gray-500 bg-opacity-40 rounded-md h-[120px] w-full flex justify-center items-center'>
                <PlayCircleIcon className='text-white w-9 h-9' />
              </div>
              <video className='object-fill rounded-md h-[120px] w-full'>
                <source src={data?.header?.value} />
              </video>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  // Extract button class logic into a separate function
  const renderButtonsView = (buttonData: IWhatsappTemplateButton) => {
    switch (buttonData?.type) {
      case 'url':
        const urlText = UtilityFunctions.highlightAttributeFromText(
          buttonData?.value.toString()
        );
        return (
          <>
            <div className='flex items-center justify-center'>
              <ArrowTopRightOnSquareIcon className='w-5 h-5 text-gray-500 mr-2' />
              <h3 className='text-gray-700 font-medium text-sm'>
                {buttonData?.title}
              </h3>
            </div>

            <p
              className='text-xs text-gray-500'
              dangerouslySetInnerHTML={{ __html: urlText }}
            />
          </>
        );
      case 'sequence':
        return (
          <>
            <h3 className='text-gray-700 font-medium text-sm'>
              {buttonData?.title}
            </h3>
            <p className='text-xs text-gray-500'>{buttonData?.verbose}</p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className='p-4 bg-white rounded mt-6'>
      <div className='rounded-md border border-gray-200 p-4'>
        <div className='grid grid-flow-row gap-4'>
          <h3 className='text-gray-600 text-sm font-medium'>
            Template Preview
          </h3>
          <div className='flex items-center'>
            {renderTemplateHeader(selectedWhatsappData)}
          </div>
          {renderTemplateBody(selectedWhatsappData?.body)}

          <div className={`grid grid-cols-2 gap-2 mt-2`}>
            {!!selectedWhatsappData &&
              selectedWhatsappData?.buttons &&
              selectedWhatsappData?.buttons?.length > 0 &&
              selectedWhatsappData?.buttons?.map(
                (item: IWhatsappTemplateButton, index: number) => {
                  return (
                    <button
                      key={index}
                      className={`w-full py-1 text-xs font-medium text-gray-700 border border-gray-200 rounded-md ${getButtonColSpan(
                        selectedWhatsappData?.buttons?.length || 0,
                        index
                      )}`}
                    >
                      {renderButtonsView(item)}
                    </button>
                  );
                }
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsappTemplatePreview;
