import React from 'react';
import {
  Button,
  EmptyPage,
  MailPlusIcon,
  useSLAConfig,
  useEscalationsUtils,
} from '../../export';
import { IEscalation } from '../../interface';
import EscalationRule from './EsclationRule';

interface Props {
  escalations: IEscalation[];
}
const EscalationSetup: React.FC<Props> = ({ escalations = [] }) => {
  const { handleEscalationDataOnChange } = useSLAConfig();
  const { handleEscalationRuleAddition, handleEscalationRuleDeletion } =
    useEscalationsUtils();

  return (
    <>
      {escalations.length === 0 ? (
        <EmptyEscalationView
          handleEscalationRuleAddition={handleEscalationRuleAddition}
        />
      ) : (
        <>
          <h5 className='py-3'>
            Set up and schedule escalation emails for SLA violations:{' '}
          </h5>
          <div className='bg-white border border-dashed rounded-lg border-border-light'>
            {escalations.map((rule: IEscalation, index) => (
              <div
                className={`px-4 py-1 bg-background-container border-b border-dashed border-border-light ${
                  index === 0
                    ? 'rounded-t-lg'
                    : index === escalations.length - 1
                    ? 'rounded-b-lg'
                    : ''
                }`}
              >
                <EscalationRule
                  key={index}
                  index={index}
                  escalation={rule}
                  lastIndex={escalations.length - 1}
                  handleEscalationRuleChange={(
                    key: keyof IEscalation,
                    value: string | number | Array<string | number>
                  ) => handleEscalationDataOnChange(index, key, value)}
                  onDelete={() => handleEscalationRuleDeletion(index)}
                />
              </div>
            ))}
            <div className='flex p-4 flex-start'>
              <Button
                variant='outline'
                onClick={() => handleEscalationRuleAddition()}
              >
                <MailPlusIcon className='text-textPrimary h-4 w-4 mr-[6px]' />
                Add New Escalation
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const EmptyEscalationView = ({ handleEscalationRuleAddition }: any) => {
  return (
    <div className='flex items-center justify-center p-6 mt-4 bg-white border border-dashed rounded-lg border-border-light'>
      <EmptyPage
        subtitle='Set up and schedule escalation emails for SLA violations.'
        iconClass='h-[56px] w-[87.93px]'
      >
        <Button
          variant='outline'
          onClick={() => handleEscalationRuleAddition()}
        >
          <MailPlusIcon className='text-textPrimary h-4 w-4 mr-[6px]' />
          Add New Escalation
        </Button>
      </EmptyPage>
    </div>
  );
};

export default EscalationSetup;
