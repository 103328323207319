// Text Inputs
import Text from './text';
import Paragraph from './paragraph';
import Number from './number';
import Phone from './phone';
import Email from './email';
import URL from './url';
import DateTime from './dateTime';

// Media
import Image from './image';
import Video from './video';
import File from './file';

// Multiple Choice
import MultiSelect from './multiSelect';
import SingleSelect from './singleSelect';

// Others
import Group from './group';
import {
  FileTypeIcon,
  FileUpIcon,
  HashIcon,
  ImageIcon,
  LayoutListIcon,
  LinkIcon,
  MailIcon,
  PhoneIcon,
  Rows2Icon,
  TypeIcon,
  VideoIcon,
} from 'lucide-react';
import { RadioLayoutListIcon } from 'pages/datalab/export';

export const FormFields = {
  text: {
    name: '',
    type: 'text',
    icon: <TypeIcon />,
    category: 'Text Inputs',
    label_agent: '',
    help_text: '',
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: Text,
  },

  textarea: {
    name: '',
    type: 'textarea',
    icon: <FileTypeIcon />,
    category: 'Text Inputs',
    label_agent: '',
    help_text: '',
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: Paragraph,
  },

  number: {
    name: '',
    type: 'number',
    icon: <HashIcon />,
    category: 'Text Inputs',
    label_agent: '',
    help_text: '',
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: Number,
  },

  phone: {
    name: '',
    type: 'phone',
    icon: <PhoneIcon />,
    category: 'Text Inputs',
    label_agent: '',
    help_text: '',
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: Phone,
  },

  email: {
    name: '',
    type: 'email',
    icon: <MailIcon />,
    category: 'Text Inputs',
    label_agent: '',
    help_text: '',
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: Email,
  },

  url: {
    name: '',
    type: 'url',
    icon: <LinkIcon />,
    category: 'Text Inputs',
    label_agent: '',
    help_text: '',
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: URL,
  },

  datetime: {
    name: '',
    type: 'datetime',
    icon: <ImageIcon />,
    category: 'Text Inputs',
    label_agent: '',
    help_text: '',
    only_date: true,
    only_time: false,
    date_time: false,
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: DateTime,
  },

  image: {
    name: '',
    type: 'image',
    icon: <ImageIcon />,
    category: 'Media',
    label_agent: '',
    help_text: '',
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: Image,
  },

  video: {
    name: '',
    type: 'video',
    icon: <VideoIcon />,
    category: 'Media',
    label_agent: '',
    help_text: '',
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: Video,
  },

  file: {
    name: '',
    type: 'file',
    icon: <FileUpIcon />,
    category: 'Media',
    label_agent: '',
    help_text: '',
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: File,
  },

  multiselect: {
    name: '',
    type: 'multiselect',
    icon: <LayoutListIcon />,
    category: 'Multiple Choice',
    label_agent: '',
    help_text: '',
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: MultiSelect,
  },

  singleselect: {
    name: '',
    type: 'singleselect',
    icon: <RadioLayoutListIcon />,
    category: 'Multiple Choice',
    label_agent: '',
    help_text: '',
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: SingleSelect,
  },

  // TODO: Group field name update
  group: {
    name: 'Group',
    type: 'group',
    icon: <Rows2Icon />,
    category: 'Others Choice',
    label_agent: '',
    help_text: '',
    placeholder: '',
    max_length: 0,
    min_length: 0,
    is_required: false,
    is_hidden: false,
    choices: [],
    Component: Group,
  },
};

export default FormFields;
