import React, { Fragment, useEffect, useState } from 'react';
import { PlayCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import {
  DocumentIcon,
  PhotoIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/solid';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import {
  handleFileUpload,
  handleImageUpload,
  handleVideoUpload,
} from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';
type WaHeaderType = 'video' | 'image' | 'document';
interface Props {
  file: string;
  handleClear: () => void;
  handleFileChange: (url: string) => void;
  templateHeaderType: WaHeaderType;
  fileMimeTypes: string[];
}
const defaultHeaderFileSizeLimits = {
  // in binary bytes
  video: 16777216, // 16mb
  image: 5242880, // 5mb
  document: 104857600, //100mb
} as const;
const getFileTypeErrorMessages = (
  allowedMimeTypes: string[],
  headerType: WaHeaderType,
  size: number
) => {
  const sizeInMB = Math.floor(size / (1024 * 1024));
  return `${_.capitalize(
    headerType
  )} size should be less than ${sizeInMB}MB & type should be ${allowedMimeTypes
    .map((v) => v.split('/')?.pop()?.toUpperCase())
    .join(' or ')}`;
};
const getUploadGuideMessage = (
  allowedMimeTypes: string[],
  headerType: WaHeaderType,
  size: number
) => {
  const sizeInMB = Math.floor(size / (1024 * 1024));
  return `Upload or drag & drop your ${headerType} here. Maximum size ${sizeInMB} MB allowed & ${headerType} type must be ${allowedMimeTypes
    .map((v) => v.split('/')?.pop()?.toUpperCase())
    .join(' or ')}.`;
};

const WhatsappFileUploader: React.FC<Props> = ({
  file,
  handleClear,
  handleFileChange,
  templateHeaderType,
  fileMimeTypes = [],
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [sizeLimitExceeded, setSizetLimitExceeded] = useState<boolean>(false);
  const [uploadFailed, setUploadFailed] = useState<boolean>(false);
  const uploadFailedErrorMessage = t('Failed to upload file.Please try again!');
  const maxAllowedVideoFileSize =
    defaultHeaderFileSizeLimits[templateHeaderType];
  const fileSizeLimitError = getFileTypeErrorMessages(
    fileMimeTypes,
    templateHeaderType,
    maxAllowedVideoFileSize
  );
  const uploadCallback = (url: string) => {
    if (!url) {
      setUploadFailed(true);
    } else {
      handleFileChange(url);
      setUploadFailed(false);
    }
    setLoading(false);
  };
  const uploadWrapper = (file: File) => {
    switch (templateHeaderType) {
      case 'image':
        handleImageUpload(file, uploadCallback);
        break;
      case 'video':
        handleVideoUpload(file, uploadCallback);
        break;
      case 'document':
        handleFileUpload(file, uploadCallback);
        break;
      default:
        break;
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: fileMimeTypes.join(','),
    onDrop: (files: File[]) => {
      setLoading(true);
      uploadWrapper(files[0]);
      setSizetLimitExceeded(false);
    },
    onDropRejected: () => {
      setSizetLimitExceeded(true);
      setLoading(false);
    },
    multiple: false,
    maxSize: maxAllowedVideoFileSize,
  });

  const renderHeaderPreview = () => {
    switch (templateHeaderType) {
      case 'image':
        return (
          <div className='py-2 h-[140px]'>
            <img
              src={file}
              alt='Block'
              className='w-auto h-full rounded-md mx-auto object-cover object-top'
            />
          </div>
        );
      case 'video':
        return (
          <div className={`flex w-full`}>
            <div
              key={1}
              className='relative cursor-pointer  w-[50%] max-w-[190px] mx-auto'
            >
              <div className='absolute bg-gray-500 bg-opacity-40 rounded-md h-[120px] w-full flex justify-center items-center'>
                <PlayCircleIcon className='text-white w-9 h-9' />
              </div>
              <video className='object-fill rounded-md h-[120px] w-full'>
                <source src={file} />
              </video>
            </div>
          </div>
        );
      case 'document':
        return (
          <div className='relative flex flex-col items-center'>
            <DocumentIcon className='w-10 h-10 text-gray-400' />
            <div className='w-9/12 text-center mt-2 truncate text-gray-600'>
              {file?.split('/').pop()}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderHeaderUploadGuide = () => {
    switch (templateHeaderType) {
      case 'image':
        return (
          <>
            <PhotoIcon className='text-gray-400 w-10 h-10' />
            <p className='w-[80%] text-center'>
              {t(
                getUploadGuideMessage(
                  fileMimeTypes,
                  templateHeaderType,
                  maxAllowedVideoFileSize
                )
              )}
            </p>
          </>
        );
      case 'video':
        return (
          <>
            <VideoCameraIcon className='text-gray-400 w-10 h-10' />
            <p className='w-[80%] text-center'>
              {t(
                getUploadGuideMessage(
                  fileMimeTypes,
                  templateHeaderType,
                  maxAllowedVideoFileSize
                )
              )}
            </p>
          </>
        );
      case 'document':
        return (
          <>
            <DocumentIcon className='text-gray-400 w-10 h-10' />
            <p className='w-[80%] text-center mt-2'>
              {t(
                getUploadGuideMessage(
                  fileMimeTypes,
                  templateHeaderType,
                  maxAllowedVideoFileSize
                )
              )}
            </p>
          </>
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    setSizetLimitExceeded(false);
    setUploadFailed(false);
  }, [templateHeaderType]);
  return (
    <>
      <section className='image-upload border-gray-500'>
        <div
          {...getRootProps()}
          tabIndex={-1}
          className='flex flex-col items-center justify-center p-2 text-[#8d99ae] max-h-[138px]'
        >
          {!file && !loading && (
            <Fragment>
              <input {...getInputProps()} />
              {renderHeaderUploadGuide()}
            </Fragment>
          )}
          {loading && (
            <div
              className='w-8 h-8 rounded-full animate-spin border-2
          border-solid border-green-500 border-t-transparent'
            ></div>
          )}
          {!!file && !loading && (
            <div className='relative w-full'>
              <div className='absolute top-2 ltr:right-2 rtl:left-2 z-20'>
                <XMarkIcon
                  className='h-5 w-5 text-red-500'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClear();
                  }}
                />
              </div>
              <div>{renderHeaderPreview()}</div>
            </div>
          )}
        </div>
      </section>
      {sizeLimitExceeded || uploadFailed ? (
        <p className='flex items-center text-red-500 text-sm mt-1'>
          <span className='mr-1'>
            <InformationCircleIcon className='h-4 w-4' />
          </span>
          {uploadFailed ? uploadFailedErrorMessage : fileSizeLimitError}
        </p>
      ) : null}
    </>
  );
};

export default WhatsappFileUploader;
