import React from 'react';
import WhatsappSetting from '../../common/pages/WhatsappSetting';
import { mixpanelTrack } from 'utilities/mixpanel';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { ArrowPathIcon } from '@heroicons/react/20/solid';

import {
  channelListProps,
  sequenceProps,
  icebreakerDataProps,
  persistentMenuDataProps,
} from '../../../interface';
import { SelectedTeamInterface } from 'index';
import useTranslation from 'components/customHooks/useTranslation';
import BlankModal from 'library/modal/BlankModal';
import { WhatsappBspIcon } from 'pages/integration/utils/content';

interface Props {
  isOpen: boolean;
  channelData: channelListProps;
  selectedProject: SelectedTeamInterface;
  sequences: sequenceProps[];
  integratedNlpList: { id: string; name: string; provider: string }[];
  handleClose: () => void;
  getIntegratedNlpList: (teamId: number) => void;
  setInitialState: () => void;
  handleChannelEdit: (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) => boolean;
}

const EditWhatsappBspSettingData: React.FC<Props> = ({
  isOpen,
  channelData,
  selectedProject,
  sequences,
  handleClose,
  getIntegratedNlpList,
  integratedNlpList,
  handleChannelEdit,
  setInitialState,
}) => {
  //initial data
  const initialChannelSettingData = {
    title: channelData.title,
    name: channelData.name,
    url: channelData.url,
    platform_type: channelData.platform_type,
    is_published: channelData.is_published,
    is_connected: channelData.is_connected,
    is_archived: channelData.is_archived,
    primary_token: channelData.primary_token,
    secondary_receiver_id: channelData.secondary_receiver_id,
    avatar: channelData.avatar,
    primary_color: channelData.primary_color,
    whitelisted_domains: channelData.whitelisted_domains,
    connected_nlp_integration_id: channelData.connected_nlp_integration_id,
    live_chat_widget_position: channelData.live_chat_widget_position,
    live_chat_should_show_mobile_prompt:
      channelData.live_chat_should_show_mobile_prompt,
    live_chat_should_show_desktop_prompt:
      channelData.live_chat_should_show_desktop_prompt,
    live_chat_should_show_myalice_branding:
      channelData.live_chat_should_show_myalice_branding,
    persistent_menu:
      !!channelData.persistent_menu && channelData.persistent_menu.length
        ? channelData.persistent_menu
        : [{ id: 0, title: '', type: 'url', value: '' }],
    ice_breaker:
      !!channelData.ice_breaker && channelData.ice_breaker.length
        ? channelData.ice_breaker
        : [{ id: 0, title: '', type: 'sequence', value: '' }],
    platform_meta: {
      ...channelData?.platform_meta,
    },
    email: channelData?.email,
    description: channelData?.description,
  };

  const { t } = useTranslation();
  //state
  const [channelSettingData, setChannelSettingData] = React.useState(
    initialChannelSettingData
  );
  const [loading, setLoading] = React.useState(false);
  const [isEdited, setIsEdited] = React.useState<boolean>(false);

  // local functions
  const handleConfirm = async () => {
    setLoading(true);
    let iceBreaker = channelSettingData.ice_breaker.filter(
      (item: icebreakerDataProps) => !!item.title && !!item.value
    );
    let persistentMenu = channelSettingData.persistent_menu.filter(
      (item: persistentMenuDataProps) => !!item.title && !!item.value
    );
    let payloadData = {
      title: channelSettingData.title,
      name: channelSettingData.name,
      url: channelSettingData.url,
      secondary_receiver_id: channelSettingData.secondary_receiver_id,
      persistent_menu: persistentMenu,
      ice_breaker: iceBreaker,
      avatar: channelSettingData.avatar,
      primary_color: channelSettingData.primary_color,
      whitelisted_domains: channelSettingData.whitelisted_domains,
      connected_nlp_integration_id:
        channelSettingData.connected_nlp_integration_id,
      live_chat_widget_position: channelSettingData.live_chat_widget_position,
      live_chat_should_show_mobile_prompt:
        channelSettingData.live_chat_should_show_mobile_prompt,
      live_chat_should_show_desktop_prompt:
        channelSettingData.live_chat_should_show_desktop_prompt,
      live_chat_should_show_myalice_branding:
        channelSettingData.live_chat_should_show_myalice_branding,
      email: channelSettingData?.email ?? '',
      description: channelSettingData?.description ?? '',
    };
    if (channelSettingData.platform_type === 'telegram_messenger') {
      payloadData['primary_token'] = channelSettingData.primary_token;
    }
    let res = await handleChannelEdit(
      selectedProject.id,
      channelData.id,
      payloadData,
      `${channelData.title} successfully updated`
    );
    if (res) {
      setLoading(false);
      setIsEdited(false);
      setInitialState();
      navigate(`/integrations/integrated`);
      mixpanelTrack('Edited Channel', {
        channelId: channelData.id,
      });
    } else {
      setInitialState();
      setLoading(false);
    }
  };

  const getLoadingPage = () => {
    return (
      <div className='flex flex-col items-center justify-center'>
        <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
        <span>{t('updating channel...')}</span>
      </div>
    );
  };

  const getSettingPages = () => {
    switch (channelData.platform_type) {
      case 'whatsapp_bsp':
        return {
          title: 'Update Whatsapp Settings',
          mandatoryFields: ['title'],
          settingPage: loading ? (
            getLoadingPage()
          ) : (
            <WhatsappSetting
              whatsappSettingData={channelSettingData}
              integratedNlpList={integratedNlpList}
              handleChange={(
                key: string,
                value: string | string[] | object | number
              ) => {
                setIsEdited(true);
                setChannelSettingData({
                  ...channelSettingData,
                  [key]: value,
                });
              }}
            />
          ),
        };
      default:
        return {
          settingPage: <p>No Channel</p>,
        };
    }
  };
  const checkFinishButton = () => {
    if (loading) return true;

    const mandatoryKeys = getSettingPages().mandatoryFields;
    let flag = false;
    !!mandatoryKeys &&
      mandatoryKeys.forEach((items) => {
        if (!(channelSettingData && channelSettingData[items])) {
          flag = true;
        }
      });
    return flag;
  };

  React.useEffect(() => {
    getIntegratedNlpList(selectedProject.id);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setChannelSettingData(initialChannelSettingData);
    // eslint-disable-next-line
  }, [channelData]);

  return (
    <>
      <BlankModal
        open={isOpen}
        headerIcon={<img src={WhatsappBspIcon} alt='' />}
        handleClose={() => {
          setIsEdited(false);
          handleClose();
        }}
        linkText='Read Documentation'
        disableSubmitButton={checkFinishButton() || !isEdited}
        title={'Integrate WhatsApp'}
        submitButtonText={t('Save Changes')}
        hideCancelButton={true}
        suggestionText='Need help?'
        documentationLink='https://docs.myalice.ai/myalice-for-whatsapp/overview'
        onSubmitButtonClick={handleConfirm}
      >
        {getSettingPages().settingPage}
      </BlankModal>
    </>
  );
};

const mapState = (state: any) => ({
  integratedNlpList: state.integration.integratedNlpList,
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  fetchFacebookPages: (id: string, token: string, type: string) =>
    dispatch.integration.fetchFacebookPages({ id, token, type }),
  getIntegratedNlpList: (teamId: number) =>
    dispatch.integration.getIntegratedNlpList(teamId),
  handleChannelEdit: (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) =>
    dispatch.integration.editChannel({
      teamId,
      channelId,
      data,
      alertMessage,
    }),
});

export default connect(mapState, mapDispatch)(EditWhatsappBspSettingData);
