import React from 'react';
import dayJs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { getUpdatedPlatformIconOutlined } from '../../../../utilities/utils';
import TableHeader from '../CommonComponent/TableHeader';
import EmptyTableCellsIcon from '../../assets/images/EmptyTableIcon.svg';
import ReactTooltip from 'react-tooltip';
import useTranslation from 'components/customHooks/useTranslation';

dayJs.extend(LocalizedFormat);

interface props {
  loader: boolean;
  tableData: tableDataProps[];
  searchTicket: string;
}

interface customerProps {
  full_name: string;
  platform: { name: string };
}

interface tableDataProps {
  id: number;
  description: string;
  created_at: string | number;
  customer: customerProps;
  tags: [];
  resolved_at: string | number;
  resolved_by: { full_name: string };
  first_assignment_time: number | string | null;
  resolution_time: number | string | null;
  first_response_time: number | string | null;
}

const TableData: React.FC<props> = ({ tableData, loader, searchTicket }) => {
  const { t } = useTranslation();
  const headerTitle = [
    'ID',
    'Channel',
    'Tags',
    'Customer Name',
    'Assigned To',
    'Resolved by',
    'Created at',
    'First Assign Time',
    'First Response Time',
    'Average response Time',
    'Resolve Time',
    'CSAT Rating',
  ];
  return (
    <>
      {!loader && (
        <div className='flex flex-col overflow-x-auto overflow-y-hidden'>
          <div className='-my-2'>
            <div className='inline-block min-w-full py-2 align-middle'>
              {tableData?.length !== 0 && (
                <>
                  <table className='min-w-full min-h-full border-t border-b divide-y divide-gray-200'>
                    <TableHeader data={headerTitle} />
                    <tbody className='divide-y divide-gray-200'>
                      {!!tableData &&
                        tableData
                          .filter(
                            (elem: any) =>
                              !!elem.id &&
                              elem.id
                                .toString()
                                .toLowerCase()
                                .includes(searchTicket.toLowerCase())
                          )
                          .map((ticket: any, index: number) => (
                            <tr key={index}>
                              <td className='px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap rtl:text-right'>
                                #{ticket.id}
                              </td>
                              <td className='inline-flex px-6 py-4 whitespace-nowrap'>
                                <img
                                  className='inline-block w-5 h-5 rounded-full'
                                  src={getUpdatedPlatformIconOutlined(
                                    ticket?.customer?.platform?.type
                                  )}
                                  alt={'channel'}
                                />
                                <p
                                  data-tip
                                  data-for={`channelName${index}`}
                                  className='ltr:ml-2 rtl:mr-2 rtl:text-right text-sm text-gray-500'
                                >
                                  {(ticket?.customer.platform?.title.length > 10
                                    ? ticket?.customer.platform?.title.substring(
                                        0,
                                        10
                                      ) + '...'
                                    : ticket?.customer.platform?.title) ||
                                    'Not Available'}
                                </p>
                                {ticket?.customer.platform?.title.length !==
                                  0 && (
                                  <ReactTooltip
                                    id={`channelName${index}`}
                                    type='dark'
                                    place='top'
                                  >
                                    <span>
                                      {ticket?.customer.platform?.title}
                                    </span>
                                  </ReactTooltip>
                                )}
                              </td>
                              {ticket.tags.length > 0 ? (
                                <td className='px-6 py-4 rtl:text-right text-sm text-gray-500 whitespace-nowrap'>
                                  {ticket.tags.length > 0 && (
                                    <span className='inline-flex items-center px-2.5 py-0.5 rounded-md text-xs bg-green-100 text-green-800 ltr:mr-1 rtl:ml-1'>
                                      {ticket.tags[0].name}
                                    </span>
                                  )}
                                  {ticket.tags.length > 1 && (
                                    <span
                                      data-tip
                                      data-for={`tagList${index}`}
                                      className='inline-flex items-center px-2.5 py-0.5 rounded-md text-xs bg-gray-100 text-gray-800'
                                    >
                                      {ticket.tags.length - 1}+
                                    </span>
                                  )}
                                  <ReactTooltip
                                    id={`tagList${index}`}
                                    type='dark'
                                    place='top'
                                  >
                                    {ticket.tags.map((t: any, index: any) => {
                                      if (index === 0) {
                                        return '';
                                      }
                                      return index !==
                                        ticket.tags.length - 1 ? (
                                        <span>{t.name},&nbsp;</span>
                                      ) : (
                                        <span>{t.name}</span>
                                      );
                                    })}
                                  </ReactTooltip>
                                </td>
                              ) : (
                                <td className='px-6 py-4 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                                  <span>--</span>
                                </td>
                              )}
                              <td className='px-6 py-4 text-sm rtl:text-right text-gray-900 whitespace-nowrap'>
                                {ticket.customer?.full_name || 'Anonymous User'}
                              </td>
                              <td className='px-6 py-4 text-sm rtl:text-right text-gray-900 whitespace-nowrap'>
                                {!!ticket.assigned_agent?.full_name
                                  ? ticket.assigned_agent.full_name
                                  : !!ticket.assigned_group?.name
                                  ? ticket.assigned_group?.name
                                  : '--'}
                              </td>
                              <td className='px-6 py-4 text-center text-sm rtl:text-right text-gray-900 whitespace-nowrap'>
                                {!!ticket.resolved_by?.full_name
                                  ? ticket.resolved_by.full_name
                                  : '--'}
                              </td>
                              <td className='px-6 py-4 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                                {dayJs(ticket.created_at * 1000).format('lll')}
                              </td>
                              <td className='px-6 py-4 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                                {!!ticket?.first_assignment_time
                                  ? ticket?.first_assignment_time
                                  : '--'}
                              </td>
                              <td className='px-6 py-4 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                                {!!ticket?.first_response_time
                                  ? ticket?.first_response_time
                                  : '--'}
                              </td>
                              <td className='px-6 py-4 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                                {!!ticket?.average_response_time
                                  ? ticket?.average_response_time
                                  : '--'}
                              </td>
                              <td className='px-6 py-4 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                                {!!ticket?.resolution_time
                                  ? ticket?.resolution_time
                                  : '--'}
                              </td>
                              <td className='px-6 py-4 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                                {!!ticket?.csat_rating
                                  ? ticket?.csat_rating
                                  : '--'}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </>
              )}
              {!loader && tableData.length === 0 && (
                <div className='flex flex-col items-center justify-center min-w-full min-h-full p-8 bg-white shadow'>
                  <img
                    src={EmptyTableCellsIcon}
                    alt={'empty table'}
                    className={'w-32 mt-8'}
                  />
                  <p className='mt-8 mb-8 font-sans text-sm font-normal'>
                    {t('No data available for this timeline')}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TableData;
