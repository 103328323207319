import React from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import { classNames } from 'library/helper/utils/class-names';
import EmptyImage from '../../assets/emptyData.svg';
import ReactTooltip from 'react-tooltip';
import Spinner from './Spinner';
import { connect } from 'react-redux';
import Pagination from './Pagination';
import { UserPropertyType } from '../../../../../index';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  isSearching: boolean;
  isUserRoleAdmin: boolean;
  responseList: CannedResponseInterface[];
  loadingCannedResponseList: boolean;
  sliceData: { limit: number; offset: number };
  userInfo: UserPropertyType;
  setIsAgent: (payload: boolean) => void;
  setOpenDeleteModal: (payload: boolean) => void;
  setSelectedCannedResponse: (payload: CannedResponseInterface) => void;
  setIsEdit: (payload: boolean) => void;
  setEditData: (payload: CannedResponseInterface) => void;
  setOpenCreateModal: (payload: boolean) => void;
  setSliceData: (payload: { limit: number; offset: number }) => void;
}

const ResponseList: React.FC<Props> = ({
  isSearching,
  responseList,
  isUserRoleAdmin,
  loadingCannedResponseList,
  userInfo,
  sliceData,
  setIsAgent,
  setSelectedCannedResponse,
  setOpenDeleteModal,
  setIsEdit,
  setEditData,
  setOpenCreateModal,
  setSliceData,
}) => {
  const { t } = useTranslation();
  const perPageData = 9;

  const nextPage = () => {
    if (sliceData.limit >= responseList.length) return;

    setSliceData({
      ...sliceData,
      limit: sliceData.limit + perPageData,
      offset: sliceData.offset + perPageData,
    });
  };

  const prevPage = () => {
    setSliceData({
      ...sliceData,
      limit: sliceData.limit - perPageData,
      offset: sliceData.offset - perPageData,
    });
  };

  const showUserAction = (item: CannedResponseInterface | null = null) => {
    if (!!item) {
      if (item.admin === userInfo.id) {
        return true;
      }
    } else if (item == null) {
      for (let item = 0; item < responseList.length; item++) {
        if (responseList[item].admin === userInfo.id) {
          return true;
        }
      }
    }

    return false;
  };

  // console.log('condition: ', isUserRoleAdmin(agentAccess), showUserAction());

  return (
    <div className='mt-8 flex flex-col'>
      <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
          <div
            className='relative overflow-hidden ring-1 ring-black max-h-[520px] overflow-y-auto
          ring-opacity-5 md:rounded-lg'
          >
            <table className='min-w-full table-fixed divide-y divide-gray-300'>
              <thead className='bg-[#F9FAFB]'>
                <tr>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 hidden border-b border-gray-300
                    bg-gray-50 bg-opacity-100 px-3 py-3.5 ltr:text-left rtl:text-right text-sm
                      font-medium text-[#6B7280] backdrop-blur
                      backdrop-filter sm:table-cell uppercase w-[20%]'
                  >
                    {t('Keyword')}
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 hidden border-b border-gray-300
                  bg-gray-50 bg-opacity-100 px-3 py-3.5 ltr:text-left rtl:text-right text-sm
                    font-medium text-[#6B7280] backdrop-blur
                    backdrop-filter sm:table-cell uppercase w-[50%]'
                  >
                    {t('Saved Replies')}
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 hidden border-b border-gray-300
                  bg-gray-50 bg-opacity-100 px-3 py-3.5 text-sm
                    font-medium text-[#6B7280] backdrop-blur
                    backdrop-filter sm:table-cell uppercase w-[15%] text-center'
                  >
                    {t('Visible To')}
                  </th>
                  {(isUserRoleAdmin || showUserAction()) && (
                    <th
                      scope='col'
                      className='sticky top-0 z-10 hidden border-b border-gray-300
                    bg-gray-50 bg-opacity-100 px-3 py-3.5 text-sm
                    font-medium text-[#6B7280] backdrop-blur
                    backdrop-filter sm:table-cell uppercase w-[15%] text-center'
                    >
                      {t('Actions')}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white '>
                {responseList?.length > 0 &&
                  responseList
                    .slice(sliceData.offset, sliceData.limit)
                    .map((item, index) => (
                      <tr key={index} className=''>
                        <td
                          className={classNames(
                            'whitespace-nowrap py-4 text-sm ltr:text-left rtl:text-right  font-medium px-3 text-gray-900 w-[20%]'
                          )}
                        >
                          #{item.title}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500  max-w-[280px] truncate w-[50%] ltr:text-left rtl:text-right'>
                          {item.text}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[15% text-center'>
                          {t(item.for_team ? 'Everyone' : 'Only Me')}
                        </td>
                        {(isUserRoleAdmin || showUserAction(item)) && (
                          <td className='whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-[15%]'>
                            <div className='flex gap-4 justify-center'>
                              {/* @ts-ignore */}
                              <ReactTooltip
                                id='edit'
                                place='top'
                                type='dark'
                                effect='float'
                              />
                              <PencilIcon
                                data-for='edit'
                                data-tip={t('Edit')}
                                className='w-5 h-5 text-gray-400 cursor-pointer'
                                onClick={() => {
                                  setIsAgent(!isUserRoleAdmin);
                                  setIsEdit(true);
                                  setEditData({
                                    id: item.id,
                                    admin: item.admin,
                                    project: {
                                      id: item.project.id,
                                      name: item.project.name,
                                    },
                                    title: item.title,
                                    text: item.text,
                                    for_team: item.for_team,
                                    tags: item.tags,
                                  });
                                  setOpenCreateModal(true);
                                }}
                              />
                              {/* @ts-ignore */}
                              <ReactTooltip
                                id='delete'
                                place='top'
                                type='dark'
                                effect='float'
                              />
                              <TrashIcon
                                data-for='delete'
                                data-tip={t('Delete')}
                                className='w-5 h-5 text-gray-400 cursor-pointer hover:text-red-500'
                                onClick={() => {
                                  setSelectedCannedResponse(item);
                                  setOpenDeleteModal(true);
                                }}
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                {responseList.length === 0 && (
                  <tr>
                    <td colSpan={7}>
                      {loadingCannedResponseList && (
                        <div className='w-full flex flex-col items-center justify-center min-h-[500px]'>
                          <Spinner
                            color='border-r-transparent border-t-gray-300 border-b-gray-300 border-l-gray-300'
                            size='w-16 h-16'
                          />
                        </div>
                      )}
                      {!loadingCannedResponseList && (
                        <div className='w-full flex flex-col items-center justify-center min-h-[500px]'>
                          <img src={EmptyImage} className='w-40 mb-2' alt='' />
                          <p className='text-gray-400 text-sm'>
                            {!isSearching &&
                              t('You need to create a new saved reply first')}
                            {isSearching &&
                              t(
                                'No saved replies found with provided search query.'
                              )}
                          </p>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              limit={
                sliceData.limit >= responseList.length
                  ? responseList.length
                  : sliceData.limit
              }
              offset={sliceData.offset}
              total={responseList.length}
              disablePrev={
                !responseList ||
                responseList.length === 0 ||
                sliceData.offset === 0
              }
              disableNext={sliceData.limit >= responseList.length}
              next={() => nextPage()}
              prev={() => prevPage()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  loadingCannedResponseList:
    state.loading.effects.settings.loadingCannedResponseList,
});
const mapDispatch = () => ({});

export default connect(mapState, mapDispatch)(ResponseList);
