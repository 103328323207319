import React, { useRef, useState } from 'react';
import defaultMediaImage from '../../../assets/images/defaultMediaImage.svg';
import leftArrow from '../../../assets/images/leftArrow.svg';
import rightArrow from '../../../assets/images/rightArrow.svg';
import Avatar from '../components/conversation/Avatar';
import DateBar from './DateBar';

import useTranslation from 'components/customHooks/useTranslation';
import { cn } from 'libraryV2/utils';
import useMessageBlocksAction from 'pages/inbox/hooks/useMessageBlocksAction';
import {
  ButtonDataType,
  ConversationBlockGalleryDataInterface,
  conversationStatusType,
  ElementDataType,
} from 'pages/inbox/inboxInterface';
import ConversationBlockWrapper from '../components/conversation/ConversationBlockWrapper';

interface Props {
  blockData: ConversationBlockGalleryDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  isSent: conversationStatusType | string;
  isMergeable: boolean;
  conversationId: string;
  enableDateBar: boolean;
  report?: any; // TODO: format report
}

const GalleryBlock: React.FC<Props> = ({
  blockData,
  avatar,
  name,
  time,
  source,
  isSent,
  isMergeable,
  conversationId,
  enableDateBar,
  report,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  const { rowDirection, elementDirection } = useMessageBlocksAction({ source });
  const [enableLeftArrow, setEnableLeftArrow] = useState<boolean>(false);
  const [enableRightArrow, setEnableRightArrow] = useState<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const galleryRef = useRef<HTMLDivElement>(null);

  const elementsPerView = 2;
  const totalSlides = Math.ceil(blockData.elements.length / elementsPerView);

  const getSourceNameColor = () => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
        return 'text-gray-900';
      case 'echo':
        return 'text-gray-900';
      default:
        return 'text-green-500';
    }
  };

  const renderLeftArrowView = () => {
    return (
      <button
        className={cn(
          'content-center ltr:mr-2 rtl:ml-2 w-6 h-6',
          !enableLeftArrow && 'cursor-not-allowed'
        )}
        onClick={scrollThroughElementsLeft}
        disabled={!enableLeftArrow}
      >
        <img
          src={leftArrow}
          className={cn('rtl:-rotate-180', !enableLeftArrow && 'opacity-30')}
          alt='leftIcon'
        />
      </button>
    );
  };

  const renderRightArrowView = () => {
    return (
      <button
        className={cn(
          'content-center ltr:ml-2 rtl:mr-2 w-6 h-6',
          !enableRightArrow && 'cursor-not-allowed'
        )}
        onClick={scrollThroughElementsRight}
        disabled={!enableRightArrow}
      >
        <img
          src={rightArrow}
          className={cn('rtl:-rotate-180', !enableRightArrow && 'opacity-30')}
          alt='rightIcon'
        />
      </button>
    );
  };

  const scrollToSlide = (slideIndex: number) => {
    const galleryElement = galleryRef.current;
    if (!galleryElement) return;

    const { clientWidth, scrollWidth } = galleryElement;
    const targetScroll = slideIndex * clientWidth;

    if (isRtlLanguage) {
      const rtlScrollLeft = scrollWidth - targetScroll - clientWidth;
      galleryElement.scrollTo({
        left: rtlScrollLeft,
        behavior: 'smooth',
      });
    } else {
      galleryElement.scrollTo({
        left: targetScroll,
        behavior: 'smooth',
      });
    }

    setCurrentIndex(slideIndex);
    setEnableLeftArrow(slideIndex > 0);
    setEnableRightArrow(slideIndex < totalSlides - 1);
  };

  const scrollThroughElements = (direction: 'left' | 'right') => {
    const nextIndex =
      direction === 'left'
        ? Math.max(currentIndex - 1, 0)
        : Math.min(currentIndex + 1, totalSlides - 1);

    scrollToSlide(nextIndex);
  };

  const renderPagination = () => {
    return (
      <div className='flex items-center justify-center gap-1'>
        {Array.from({ length: totalSlides }).map((_, index) => (
          <div
            key={index}
            className={cn(
              'w-1 h-1 rounded-full cursor-pointer',
              index === currentIndex ? 'bg-primary' : 'bg-gray-300'
            )}
            onClick={() => scrollToSlide(index)}
          />
        ))}
      </div>
    );
  };

  const getButtons = (button: ButtonDataType) => {
    return (
      <button
        key={button?.id}
        className='w-full px-1 mb-1 text-xs font-medium text-gray-700 truncate bg-white border border-gray-300 rounded-md cursor-not-allowed h-7 hover:bg-gray-100'
      >
        {button?.title}
      </button>
    );
  };

  const getElements = (element: ElementDataType) => {
    return (
      <div
        className={cn(
          'w-[210px] pb-3 h-auto  rounded-xl',
          blockData.elements.length > 2
            ? 'first:m-0 mx-3 last:mx-0 '
            : 'first:ml-0 ml-3',
          blockData.elements.length > 1 &&
            'drop-shadow-sm bg-white border border-[#E4E4E7]'
        )}
        key={element?.id}
      >
        <img
          data-testid='gallery-image'
          className='object-cover w-full h-32'
          src={element?.image}
          alt='galleryImage'
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = defaultMediaImage;
          }}
        />
        <p className='mx-2 mt-2 font-medium text-gray-700 break-words sm:text-xs line-clamp-2'>
          {element?.title}
        </p>
        <p className='mx-2 mt-1.5 sm:text-xs font-normal text-gray-500 break-words line-clamp-2'>
          {element?.subtitle}
        </p>
        <div className='flex flex-col mx-2 mt-2 place-content-end'>
          {element?.buttons?.map((button: ButtonDataType) =>
            getButtons(button)
          )}
        </div>
      </div>
    );
  };

  const scrollThroughElementsLeft = () => {
    const galleryElement = document.getElementById(
      `galleryId${conversationId}`
    );
    if (!galleryElement) return;

    const scrollAmount = isRtlLanguage ? 464 : -464;
    galleryElement.scrollBy({
      top: 0,
      left: scrollAmount,
      behavior: 'smooth',
    });

    setCurrentIndex(Math.max(currentIndex - 2, 0));
  };

  const scrollThroughElementsRight = () => {
    const galleryElement = document.getElementById(
      `galleryId${conversationId}`
    );
    if (!galleryElement) return;

    const scrollAmount = isRtlLanguage ? -464 : 464;
    galleryElement.scrollBy({
      top: 0,
      left: scrollAmount,
      behavior: 'smooth',
    });

    setCurrentIndex(Math.min(currentIndex + 2, blockData.elements.length - 1));
  };

  const handleScroll = () => {
    const galleryElement = galleryRef.current;
    if (!galleryElement) return;

    const { scrollLeft, scrollWidth, clientWidth } = galleryElement;
    const maxScroll = scrollWidth - clientWidth;

    let normalizedScrollLeft = isRtlLanguage
      ? maxScroll - scrollLeft // Adjust for RTL
      : scrollLeft;
    normalizedScrollLeft = Math.max(
      0,
      Math.min(normalizedScrollLeft, maxScroll)
    );

    const slideWidth = clientWidth;
    const currentSlide = Math.round(normalizedScrollLeft / slideWidth);

    setCurrentIndex(currentSlide);
    setEnableLeftArrow(currentSlide > 0);
    setEnableRightArrow(currentSlide < totalSlides - 1);
  };

  const renderArrowButton = (direction: 'left' | 'right') => {
    const isLeft = direction === 'left';
    const enabled = isLeft ? enableLeftArrow : enableRightArrow;
    return (
      <button
        className={cn(
          'content-center w-6 h-6',
          isLeft ? 'ltr:mr-2 rtl:ml-2' : 'ltr:ml-2 rtl:mr-2',
          !enabled && 'cursor-not-allowed'
        )}
        onClick={() => scrollThroughElements(direction)}
        disabled={!enabled}
      >
        <img
          src={isLeft ? leftArrow : rightArrow}
          className={cn(
            isRtlLanguage && '-rotate-180',
            !enabled && 'opacity-30'
          )}
          alt={isLeft ? 'leftIcon' : 'rightIcon'}
        />
      </button>
    );
  };

  const showLeftArrow = (): boolean => {
    return blockData?.elements?.length > 2;
  };

  const showPagination = (): boolean => {
    return blockData.elements.length > 2;
  };

  const showRightArrow = (): boolean => {
    return blockData?.elements?.length > 2;
  };

  return (
    <>
      {!isMergeable && (
        <div
          className={`flex w-full h-auto py-1 hover:bg-gray-100 ${rowDirection}`}
        >
          <Avatar avatar={avatar} />
          <div>
            <div className={`flex gap-2 ${rowDirection}`}>
              <div
                className={`ltr:mr-2 rtl:ml-2 my-2 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()}`}
              >
                {t(name)}
              </div>
            </div>
            {/* Gallery Block */}
            <div className={`flex ${rowDirection}`}>
              <ConversationBlockWrapper
                time={time}
                success={isSent}
                isMerged={false}
                report={report}
                source={source}
                isFirstElement={!isMergeable}
                conversationType={blockData?.type}
              >
                <div className='flex content-center'>
                  {showLeftArrow() && renderLeftArrowView()}
                  <div
                    ref={galleryRef}
                    className='flex max-w-[460px] h-auto overflow-x-hidden'
                    id={`galleryId${conversationId}`}
                    onScroll={handleScroll}
                    dir='ltr'
                  >
                    <div data-testid='gallery-block' className='flex gap-2'>
                      {!!blockData.elements &&
                        blockData.elements.map((element) =>
                          getElements(element)
                        )}
                    </div>
                  </div>
                  {showRightArrow() && renderRightArrowView()}
                </div>
              </ConversationBlockWrapper>
            </div>
          </div>
        </div>
      )}

      {!!isMergeable && (
        <div
          className={`relative flex w-full h-auto py-1 px-16 group hover:bg-gray-50 ${elementDirection}`}
        >
          {/* Gallery Block */}
          <ConversationBlockWrapper
            time={time}
            success={isSent}
            isMerged={false}
            report={report}
            source={source}
            isFirstElement={!isMergeable}
            conversationType={blockData?.type}
          >
            <div className={cn('flex content-center flex-col')}>
              <div
                ref={galleryRef}
                className='flex max-w-[464px] p-3.5 h-auto overflow-x-hidden'
                id={`galleryId${conversationId}`}
                onScroll={handleScroll}
                dir={'ltr'}
              >
                <div className='flex snap-x'>
                  {!!blockData.elements &&
                    blockData.elements.map((element) => getElements(element))}
                </div>
              </div>

              <div className='flex items-center justify-center gap-2'>
                {showLeftArrow() && renderArrowButton('left')}
                {showPagination() && renderPagination()}
                {showRightArrow() && renderArrowButton('right')}
              </div>
            </div>
          </ConversationBlockWrapper>
        </div>
      )}
      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default GalleryBlock;
