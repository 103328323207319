import EmailSetup from './setup';
import DisplaySetup from './displaySetup';
import { useEffect, useState } from 'react';
import BlankModal from 'library/modal/BlankModal';
import Stepper from 'pages/onboardingV2/components/common/Stepper';
import emailIconSet from '../../../../../../assets/icons/channelIconSet/email.svg';
import {
  useEmailSettings,
  IDataObject,
} from '../../../../hooks/useCustomEmail';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { navigate } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const stepPropsData = [
  {
    id: 1,
    name: 'Setup IMAP',
  },
  {
    id: 2,
    name: 'Setup SMTP',
  },
  {
    id: 3,
    name: 'Others',
  },
];

const CustomEmailSetup: React.FC<Props> = ({ open, handleClose }) => {
  const {
    clearAllData,
    emailSettings,
    isAllFieldValid,
    updateSmtpSettings,
    updateImapSettings,
    hasSameCredentials,
    verifyCustomEmailData,
    updateUserDataSettings,
    createCustomEmailChannel,
  } = useEmailSettings();
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(1);
  const [hasError, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    return () => {
      clearAllData();
      setStep(1);
      setError(false);
      setLoading(false);
    };
    //eslint-disable-next-line
  }, []);

  const handleSettingsDataChange = (data: IDataObject) => {
    if (step === 1) {
      updateImapSettings(data);
    } else if (step === 2) {
      updateSmtpSettings(data);
    } else {
      updateUserDataSettings(data);
    }
  };

  const isSubmitEnable = () => {
    if (step === 1) return isAllFieldValid('imap');
    else if (step === 2) return isAllFieldValid('smtp');
    else return isAllFieldValid('displaySettings');
  };

  const handleSubmitButtonClick = async () => {
    setLoading(true);
    setError(false);
    setErrorMessage('');
    if (step !== 3) {
      const isValid = await verifyCustomEmailData(step === 1 ? 'imap' : 'smtp');
      setError(!isValid);
      if (isValid) setStep(step + 1);
    } else {
      const response = await createCustomEmailChannel();
      if (response.success) {
        navigate(`/integrations/integrated`);
        handleModalClose();
      } else {
        setError(true);
        setErrorMessage(response.message);
      }
    }
    setLoading(false);
  };
  const handleCancelButtonClick = () => {
    if (step === 1) {
      handleModalClose();
    } else {
      setStep(step - 1);
    }
  };

  const handleModalClose = () => {
    clearAllData();
    handleClose();
  };

  const updateSmtpToImapCredentials = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      handleSettingsDataChange({
        username: emailSettings.imap.username,
        password: emailSettings.imap.password,
      });
    } else {
      handleSettingsDataChange({
        username: '',
        password: '',
      });
    }
  };

  const renderContent = () => {
    return (
      <>
        {step === 3 ? (
          <DisplaySetup
            settings={emailSettings.userData}
            handleDataChange={handleSettingsDataChange}
          />
        ) : (
          <EmailSetup
            stepName={step === 1 ? 'imap' : 'smtp'}
            hasSameCredentials={hasSameCredentials}
            handleDataChange={handleSettingsDataChange}
            updateSmtpToImapCredentials={updateSmtpToImapCredentials}
            settings={step === 1 ? emailSettings.imap : emailSettings.smtp}
          />
        )}
      </>
    );
  };

  const renderErrorMsg = () => {
    const protocolName = step === 1 ? 'IMAP' : 'SMTP';
    let errorMsg = '';
    if (!!errorMessage) {
      errorMsg = errorMessage;
    } else {
      errorMsg =
        step === 3
          ? t(
              'The requested operation could not be completed. Please check your request parameters and try again.'
            )
          : t(
              `Invalid data provided for {{${protocolName}}} fields. Please ensure that the information you've entered is accurate and follows the required format for {{${protocolName}}} configuration.`
            );
    }
    return (
      <div className='w-full rounded-md px-3 py-2 bg-red-50 border border-red-200 flex gap-2 justify-center mt-1 mb-2'>
        <ExclamationCircleIcon className='w-10 h-10 text-red-500' />
        <span className='text-sm text-red-500 rtl:text-right'>
          {t(errorMsg)}
        </span>
      </div>
    );
  };

  const renderLoadingView = () => {
    return (
      <div
        className='absolute top-0 left-0 w-full h-full flex justify-center items-center p-0'
        style={{ margin: '0px' }}
      >
        <div className='absolute top-0 left-0 w-full h-full bg-gray-600 opacity-50' />
        <div
          className='inline-block w-8 h-8 text-white border-4 rounded-full spinner-border animate-spin opacity-100 z-50'
          role='status'
        >
          <span className='visually-hidden'>{t('Loading')}...</span>
        </div>
      </div>
    );
  };

  return (
    <BlankModal
      open={open}
      loading={loading}
      title={t('Setup an email')}
      hideCancelButton={loading}
      hideSubmitButton={loading}
      suggestionText={t('Need help?')}
      linkText={t('Read Documentation')}
      disableCancelButton={loading}
      handleClose={handleModalClose}
      onSubmitButtonClick={handleSubmitButtonClick}
      onCancelButtonClick={handleCancelButtonClick}
      cancelButtonText={step === 1 ? t('Cancel') : t('Back')}
      submitButtonText={step === 3 ? t('Create') : t('Next')}
      disableSubmitButton={!isSubmitEnable() || loading}
      documentationLink='https://docs.myalice.ai/connect-social-channels/connect-your-email/connect-custom-email'
      headerIcon={
        <img src={emailIconSet} width={18} height={18} alt='social_channel' />
      }
    >
      <Stepper steps={stepPropsData} currentStep={step} />

      {hasError && renderErrorMsg()}
      {renderContent()}

      {loading && renderLoadingView()}
    </BlankModal>
  );
};

export default CustomEmailSetup;
