import React from 'react';
import Linkify from 'react-linkify';

import Avatar from '../components/conversation/Avatar';
import DateBar from './DateBar';

import useTranslation from 'components/customHooks/useTranslation';
import useMessageBlocksAction from 'pages/inbox/hooks/useMessageBlocksAction';
import {
  ButtonDataType,
  ConversationBlockQuickReplyDataInterface,
  conversationStatusType,
} from 'pages/inbox/inboxInterface';
import ConversationBlockWrapper from '../components/conversation/ConversationBlockWrapper';

interface Props {
  blockData: ConversationBlockQuickReplyDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  isSent: conversationStatusType | string;
  isMergeable: boolean;
  enableDateBar: boolean;
  report?: any; // TODO: format report
}

const QuickReplyBlock: React.FC<Props> = ({
  blockData,
  avatar,
  name,
  time,
  source,
  isSent,
  isMergeable,
  enableDateBar,
  report,
}) => {
  const { t } = useTranslation();
  const { rowDirection, elementDirection } = useMessageBlocksAction({ source });
  const getSourceNameColor = () => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
      case 'echo':
        return 'text-gray-900';
      default:
        return 'text-green-500';
    }
  };

  const renderQuickReplyTextView = () => {
    return (
      <p
        className={`w-auto h-auto text-gray-900 break-words whitespace-pre-wrap sm:text-sm flex`}
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              target='blank'
              className='text-link'
              href={decoratedHref}
              key={key}
            >
              {decoratedText}
            </a>
          )}
        >
          {blockData.success ? blockData?.title : blockData.error}
        </Linkify>
      </p>
    );
  };

  const getButtons = (button: ButtonDataType) => {
    return (
      <button
        key={button?.id}
        className='border w-auto h-7 py-1.5 px-2.5 bg-white border-gray-300 drop-shadow-sm
      content-center cursor-not-allowed hover:bg-gray-100 rounded-full truncate
      text-xs font-medium	text-gray-700'
      >
        {button?.title}
      </button>
    );
  };

  return (
    <>
      {!isMergeable && (
        <div
          className={`flex w-full h-auto pt-0.5 pb-1.5 hover:bg-gray-100 ${rowDirection}`}
        >
          <Avatar avatar={avatar} />
          <div data-testid='quick-reply-block' className={`w-[80%]`}>
            <div className={`flex gap-2 ${rowDirection}`}>
              <div
                className={`my-1.5 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()}`}
              >
                {t(name)}
              </div>
            </div>
            {/* Quick Reply Block */}
            <div className={`flex ${elementDirection}`}>
              <ConversationBlockWrapper
                time={time}
                success={isSent}
                isMerged={false}
                report={report}
                source={source}
                isFirstElement={!isMergeable}
                conversationType={blockData?.type}
              >
                {!!blockData.title && renderQuickReplyTextView()}
                <div className={`flex w-auto ${rowDirection}`}>
                  <div className='grid content-center grid-cols-3 gap-1 pt-1'>
                    {!!blockData?.buttons &&
                      blockData.buttons
                        .filter((button) => !!button?.title)
                        .map((eachButton) => getButtons(eachButton))}
                  </div>
                </div>
              </ConversationBlockWrapper>
            </div>
          </div>
        </div>
      )}

      {!!isMergeable && (
        <div
          className={`relative flex w-full h-auto py-1 px-16 group hover:bg-gray-50 ${elementDirection}`}
        >
          {/* Quick Reply Block */}
          <div className={`w-[80%] flex ${elementDirection}`}>
            <ConversationBlockWrapper
              time={time}
              success={isSent}
              isMerged={false}
              report={report}
              source={source}
              isFirstElement={!isMergeable}
              conversationType={blockData?.type}
            >
              {!!blockData.title && renderQuickReplyTextView()}
              <div className={`flex w-auto ${rowDirection}`}>
                <div className='grid grid-cols-2 gap-1 pt-1'>
                  {!!blockData?.buttons &&
                    blockData.buttons
                      .filter((button) => !!button?.title)
                      .map((eachButton) => getButtons(eachButton))}
                </div>
              </div>
            </ConversationBlockWrapper>
          </div>
        </div>
      )}
      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default QuickReplyBlock;
