import BlankModal from 'library/modal/BlankModal';
import emailIconSet from '../../../../../assets/icons/channelIconSet/email.svg';
import { initialUserProperties } from 'pages/integration/utils/content';
import { useEffect, useState } from 'react';
import { channelListProps } from 'pages/integration/interface';
import { toaster } from 'evergreen-ui';
import { SelectedTeamInterface } from 'index';
import { navigate } from '@reach/router';
import { classNames } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedProject: SelectedTeamInterface;
  gmailChannelProperies: channelListProps;
  handleChannelEdit: (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) => Promise<boolean>;
}

const GmailIntegration: React.FC<Props> = ({
  open,
  handleClose,
  selectedProject,
  gmailChannelProperies,
  handleChannelEdit,
}) => {
  const { t } = useTranslation();
  const [emailChannelTitle, setEmailChannelTitle] = useState('');
  const [emailChannelProperties, setEmailChannelProperties] =
    useState<channelListProps>(initialUserProperties);
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] =
    useState<boolean>(false);

  useEffect(() => {
    setEmailChannelProperties(gmailChannelProperies);
  }, [gmailChannelProperies]);

  const handleGmailChannelEdit = async () => {
    let res = await handleChannelEdit(
      selectedProject?.id,
      emailChannelProperties?.id,
      {
        title: emailChannelTitle,
        name: emailChannelProperties?.name,
      },
      t(`{{${emailChannelTitle}}} successfully updated`)
    );
    if (res) {
      setIsConfirmButtonLoading(false);
      handleClose();
      navigate(`/integrations/integrated`);
      toaster.success(
        t(`{{${emailChannelTitle}}} has been successfully connected`)
      );
    } else {
      setIsConfirmButtonLoading(false);
      toaster.danger(t('Something went wrong while creating this channel'));
    }
  };

  const renderModalBody = () => {
    return (
      <>
        <div className='flex flex-col'>
          <label className='mb-1 capitalize text-gray-600 text-sm rtl:text-right font-medium'>
            {t('Title')}
            <span className='text-red-500'>*</span>
          </label>
          <input
            type='text'
            className='rounded-md border border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 text-sm leading-5'
            placeholder={t('My Email Channel')}
            name={'email_title'}
            value={emailChannelTitle}
            onChange={(e) => {
              setEmailChannelTitle(e.target.value);
            }}
          />
          <p className='text-gray-500 rtl:text-right text-xs leading-5 mb-3'>
            {t(
              'The title is private. We use this info to identify this channel on MyAlice.'
            )}
          </p>
        </div>
        <div
          className={classNames(
            'flex-col',
            !emailChannelProperties?.primary_id ? 'hidden' : 'flex'
          )}
        >
          <label className='mb-1 rtl:text-right capitalize text-gray-600 text-sm font-medium'>
            {t('Email Address')}
            <span className='text-red-500'>*</span>
          </label>
          <span
            className='rounded-md border rtl:text-right text-gray-600 bg-gray-200 border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-3'
          >
            {!!emailChannelProperties?.primary_id
              ? emailChannelProperties?.primary_id
              : ''}
          </span>
          <span />
        </div>
      </>
    );
  };
  return (
    <BlankModal
      title={t('Custom Email')}
      open={open}
      submitButtonText={t('Complete')}
      disableSubmitButton={emailChannelTitle === ''}
      handleClose={handleClose}
      onSubmitButtonClick={() => handleGmailChannelEdit()}
      isSubmitButtonLoading={isConfirmButtonLoading}
      hideCancelButton={true}
      headerIcon={
        <img src={emailIconSet} width={18} height={18} alt='social_channel' />
      }
    >
      {renderModalBody()}
    </BlankModal>
  );
};

export default GmailIntegration;
