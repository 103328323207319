import React from 'react';
import Carousel from 'nuka-carousel';
import { XMarkIcon } from '@heroicons/react/20/solid';
import nextIcon from '../../../../assets/images/nextIcon.svg';
import previousIcon from '../../../../assets/images/previousIcon.svg';

import { UppyAwsS3SuccessfulResponse } from '../../../../inboxInterface';

interface Props {
  attachmentLists: UppyAwsS3SuccessfulResponse[];
  handleRemoveAttachment: (index: number) => void;
}

const AttachmentPreview: React.FC<Props> = ({
  attachmentLists,
  handleRemoveAttachment,
}) => {
  const getAttachmentName = (attachments: UppyAwsS3SuccessfulResponse) => {
    return attachments?.name;
  };

  const renderNukaCarouselCenterLeftControlsView = (
    previousSlide: any,
    currentSlide: number,
    slideCount: number
  ) => {
    return (
      <button
        className={'nuka-carousel-slide-button'}
        onClick={previousSlide}
        disabled={currentSlide === 0}
        style={{
          cursor: currentSlide === 0 ? 'not-allowed' : 'pointer',
          background: currentSlide === 0 ? '#E5E7EB' : 'white',
        }}
      >
        <img src={previousIcon} alt={'<'} />
      </button>
    );
  };

  const renderNukaCarouselCenterRightControlsView = (
    nextSlide: any,
    currentSlide: number,
    slideCount: number
  ) => {
    let isDisabled = currentSlide + 1 === slideCount - 1 || slideCount <= 1;
    return (
      <button
        className={'nuka-carousel-slide-button'}
        onClick={nextSlide}
        disabled={isDisabled}
        style={{
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          background: isDisabled ? '#E5E7EB' : 'white',
        }}
      >
        <img src={nextIcon} alt={'>'} />
      </button>
    );
  };

  const handleAttachmentClick = (url: string) => {
    window.open(url, '_blank');
  };

  const renderNukaCarouselPreview = () => {
    return (
      // there is dependency issue with create react app. it does not have build mechanism to compile .mjs file that nuka's latest package contain.
      // that's why we are using older version of nuka carousel package rather than the latest one.
      //@ts-ignore
      <Carousel
        slidesToShow={2}
        slidesToScroll={2}
        style={{ paddingLeft: '40px', paddingRight: '30px' }}
        renderCenterLeftControls={({
          previousSlide,
          currentSlide,
          slideCount,
        }) =>
          renderNukaCarouselCenterLeftControlsView(
            previousSlide,
            currentSlide,
            slideCount
          )
        }
        renderCenterRightControls={({ nextSlide, slideCount, currentSlide }) =>
          renderNukaCarouselCenterRightControlsView(
            nextSlide,
            currentSlide,
            slideCount
          )
        }
        defaultControlsConfig={{
          pagingDotsStyle: { display: 'none' },
        }}
      >
        {attachmentLists.map(
          (attachment: UppyAwsS3SuccessfulResponse, index: number) => (
            <div
              key={index}
              className={`p-2 flex border bg-gray-50 border-gray-200 mr-2 rounded-md h-10 mb-2`}
            >
              <p
                onClick={() => handleAttachmentClick(attachment?.uploadURL)}
                className='w-[90%] text-ellipsis overflow-hidden ... text-blue-500 font-normal text-sm cursor-pointer'
              >
                {getAttachmentName(attachment)}
              </p>
              <div className='w-[10%] flex justify-end'>
                <XMarkIcon
                  data-testid='remove-button'
                  className='w-5 h-5 text-gray-500 cursor-pointer'
                  onClick={() => handleRemoveAttachment(index)}
                />
              </div>
            </div>
          )
        )}
      </Carousel>
    );
  };

  return (
    <>
      {attachmentLists.length > 0 && (
        <div className={`border-none px-4 mb-2.5`}>
          <div className='flex flex-wrap w-auto h-10'>
            {renderNukaCarouselPreview()}
          </div>
        </div>
      )}
    </>
  );
};

export default AttachmentPreview;
