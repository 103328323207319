import React from 'react';

interface Props {
  savedReply: string;
}

const SavedReplyPreview: React.FC<Props> = ({ savedReply }) => {
  const checkVariables = (value: string) => {
    if (value.includes('|')) {
      return value.slice(0, value.indexOf('|')).trim();
    }
    return value;
  };

  const renderTemplateBody = (text: string) => {
    const textSplite = text.split(/[,.\s]/);
    const bodyText = textSplite.map((item: any) => {
      if (item.startsWith('{{') && item.endsWith('}}')) {
        return ` <span id=${checkVariables(item.slice(2, -2))}
       class='px-1 py-1 bg-green-100 rounded-md text-green-800'>
            ${checkVariables(item.slice(2, -2))}</span>`;
      } else {
        return ` ${item}`;
      }
    });
    return (
      <p
        className={`text-gray-500 font-normal
         text-[14px] leading-7 mb-2`}
        dangerouslySetInnerHTML={{ __html: bodyText.join(' ') }}
      />
    );
  };

  return (
    <div className='absolute top-0 ltr:right-[-285px] rtl:left-[-285px] w-[270px] overflow-y-auto h-auto bg-white rounded-md border border-gray-200 p-2'>
      <div>{renderTemplateBody(savedReply)}</div>
    </div>
  );
};

export default SavedReplyPreview;
