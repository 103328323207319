import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { IEscalation, IReminder, Priority } from './interface';

dayjs.extend(duration);

export const priorityColor = (priority: string) => {
  switch (priority) {
    case 'Urgent':
      return { background: 'bg-[#FF2323]', textColor: 'text-[#FF2323]' };
    case 'High':
      return { background: 'bg-[#F59E0B]', textColor: 'text-[#F59E0B]' };
    case 'Medium':
      return { background: 'bg-[#6366F1]', textColor: 'text-[#6366F1]' };
    case 'Low':
      return { background: 'bg-[#04B25F]', textColor: 'text-[#04B25F]' };
    default:
      return { background: 'bg-gray-200', textColor: 'text-gray-500' }; // Default values
  }
};

export const defaultEscalationRule: IEscalation = {
  priority: [],
  target: '',
  time: 0,
  persons: [],
};

export const defaultReminderRule: IReminder = {
  priority: [],
  target: '',
  time: 0,
  persons: [],
};

export const convertMinutesToTimeString = (minutes: number) => {
  const durationObj = dayjs.duration(minutes, 'minutes');

  const days = durationObj.days();
  const hours = durationObj.hours();
  const mins = durationObj.minutes();

  return [
    days ? `${days}d` : '',
    hours ? `${hours}h` : '',
    mins || (!days && !hours) ? `${mins}m` : '',
  ]
    .filter(Boolean)
    .join(' ');
};

export const convertToTotalMinutes = ({
  days,
  hours,
  minutes,
}: {
  days: number;
  hours: number;
  minutes: number;
}) => {
  const totalMinutes = days * 1440 + hours * 60 + minutes;
  return totalMinutes;
};

export const extractAgentIdsFromList = (selectedAgentsList?: any) => {
  if (!selectedAgentsList || !Array.isArray(selectedAgentsList)) {
    return [];
  }

  return selectedAgentsList
    .filter(
      (agent) => agent && typeof agent.id === 'number' && !isNaN(agent.id)
    )
    .map((agent) => agent.id as number);
};

export const getPriorityLabel = (priority: Priority) => {
  switch (priority) {
    case Priority.Low:
      return 'Low';
    case Priority.Medium:
      return 'Medium';
    case Priority.High:
      return 'High';
    case Priority.Urgent:
      return 'Urgent';
    default:
      return 'Unknown';
  }
};

export const getPriorityLabelList = (selectedList: Array<number | string>) => {
  return selectedList.map((priority) => {
    switch (priority) {
      case Priority.Low:
        return 'Low';
      case Priority.Medium:
        return 'Medium';
      case Priority.High:
        return 'High';
      case Priority.Urgent:
        return 'Urgent';
      default:
        return 'Unknown';
    }
  });
};

export const getAgentNameList = (
  selectedAgentIdList: Array<number | string>,
  agentList: any
) => {
  return selectedAgentIdList.map((id: number | string) => {
    const matchedObject = agentList.find((obj: any) => obj?.admin?.id === id);
    return matchedObject ? matchedObject?.admin?.full_name : 'Assigned Agent';
  });
};

export const getPlatformNameList = (
  selectedPlatformIdList: Array<number | string>,
  platformList: any
) => {
  let data = selectedPlatformIdList.map((id: number | string) => {
    const matchedObject = platformList.find(
      (obj: any) => Number(obj?.id) === Number(id)
    );
    return matchedObject ? matchedObject?.title : null;
  });
  return data;
};
