import { useEffect, useState } from 'react';
import { channelInfo, classNames } from 'utilities/utils';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { IEcommerceProductData } from 'pages/inbox/inboxInterface';
import { EmptyState } from 'pages/inbox/assets/iconComponent/SearchComponentIcons';
import { CircleSpinner } from 'pages/inbox/assets/iconComponent/Spinner';
import useDebounce from 'components/customHooks/useDebounce';
import defaultProductIcon from '../../../assets/images/defaultProductIcon.svg';
import Button from 'library/button';
import useTranslation from 'components/customHooks/useTranslation';
import { toaster } from 'evergreen-ui';

interface Props {
  hasCart: boolean;
  storeType?: string;
  platformType: string;
  addToCartLoading: boolean;
  recommendationLimit: number;
  products: IEcommerceProductData[];
  selectedList?: IEcommerceProductData[];
  setClose: () => void;
  handleBackToVariant: () => void;
  setAddToCartLoading: (data: boolean) => void;
  handleFetchProductOnScroll: () => Promise<boolean>;
  searchProducts: (query: string) => Promise<boolean>;
  handleAddItemToCart: (products: IEcommerceProductData[]) => void;
  handleRecommendation: (products: IEcommerceProductData[]) => void;
  handleSelectionListChange: (products: IEcommerceProductData[]) => void;
}

const ProductList: React.FC<Props> = ({
  hasCart,
  products,
  setClose,
  platformType,
  storeType = '',
  addToCartLoading,
  selectedList = [],
  searchProducts,
  recommendationLimit,
  handleBackToVariant,
  handleAddItemToCart,
  setAddToCartLoading,
  handleRecommendation,
  handleSelectionListChange,
  handleFetchProductOnScroll,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const [selectedProduct, updateSelectedProduct] =
    useState<IEcommerceProductData[]>(selectedList);
  const [loading, setLoading] = useState<boolean>(true);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  const [isFetchingProducts, setFetchingProducts] = useState<boolean>(false);

  const searchDebounce = useDebounce(query, 700);

  const handleDebounce = async () => {
    if (searchDebounce) {
      await searchProducts(query);
    } else await searchProducts('');
    setLoading(false);
  };
  useEffect(() => {
    handleDebounce();

    //eslint-disable-next-line
  }, [searchDebounce]);

  const handleAddToCartClick = () => {
    handleSelectionListChange([...selectedProduct]);
  };

  const shouldShowAddProduct = () =>
    JSON.stringify(selectedList) !== JSON.stringify(selectedProduct) &&
    !addToCartLoading;

  const handleProductSelect = (
    isChecked: boolean,
    product: IEcommerceProductData
  ) => {
    let newSelectedList = [];
    if (isChecked) {
      const selectedIndex = selectedList.findIndex(
        (productData: IEcommerceProductData) =>
          productData.product_id === product.product_id
      );
      if (selectedIndex > -1) product = { ...selectedList[selectedIndex] };
      else {
        product.quantity = 1; //initially adding one unit for selected product
        product.total_cost = product.unit_price; //initial total cost one unit
      }

      newSelectedList = [...selectedProduct, product];
    } else
      newSelectedList = selectedProduct.filter(
        (item) => item.product_id !== product.product_id
      );

    updateSelectedProduct([...newSelectedList]);
  };

  const getEmptyView = () => {
    return (
      <div className='w-full h-full items-center flex justify-center'>
        <div>
          <EmptyState className='mx-auto text-gray-400' aria-hidden='true' />
          <p className='mt-4 text-sm text-gray-500'>
            {t('Nothing found related to')} {query}
          </p>
        </div>
      </div>
    );
  };

  const isProductSelected = (productId: string | number) => {
    return selectedProduct.some(
      (item: IEcommerceProductData) => item.product_id === productId
    );
  };

  const getLoadingView = () => {
    return (
      <CircleSpinner className='animate-spin ml-1 h-5 w-5 text-primary inline ' />
    );
  };

  const loadingAndNoDataView = () => {
    if (loading)
      return (
        <div className='w-full h-full flex justify-center items-center py-2'>
          {getLoadingView()}
        </div>
      );
    else {
      return getEmptyView();
    }
  };

  const getProductList = () => {
    return products.map((item: IEcommerceProductData, index) => {
      const {
        in_stock,
        stock_count,
        product_id,
        product_name,
        product_images,
      } = item;
      const isProductActive = !in_stock
        ? false
        : ['salla', 'zid'].includes(storeType)
        ? true
        : stock_count > 0;
      return (
        <div
          key={product_id}
          className='flex items-center my-3 gap-3'
          onClick={() => {
            if (isProductActive) {
              handleProductSelect(!isProductSelected(product_id), item);
            } else {
              toaster.danger(`${product_name} is out of stock`);
            }
          }}
        >
          <input
            type={'checkbox'}
            checked={isProductSelected(product_id)}
            id={`${product_id}_${index}`}
            name={product_name}
            autoComplete={'nope'}
            readOnly={true}
            disabled={!isProductActive}
            className='w-4 h-4 border-gray-300 rounded text-primary without-ring'
          />
          <img
            className=' mx-2 w-6 h-6 rounded-sm'
            src={
              product_images && product_images.length > 0
                ? product_images[0]
                : defaultProductIcon
            }
            alt={'product_img'}
          />
          <label
            htmlFor={product_name}
            className={`text-sm rtl:text-right font-medium w-full text-truncate ${
              isProductActive
                ? 'text-gray-700 cursor-pointer'
                : 'text-gray-500 cursor-not-allowed'
            }`}
          >
            {product_name}
          </label>

          <div
            className={`text-sm inline rtl:ml-1 ltr:mr-1 font-medium ${
              !isProductActive ? 'text-gray-300' : 'text-gray-700'
            }`}
          >
            {stock_count > 99 ? '99+' : !isProductActive ? '' : stock_count}
          </div>
        </div>
      );
    });
  };

  const productScrollHandle = async () => {
    let el = document.getElementById('product-list');
    if (!!el && query === '' && !isFetchingProducts) {
      if (el.scrollTop + el.offsetHeight >= el.scrollHeight - 1) {
        // check if ticketLength === total
        setProductLoading(true);
        setFetchingProducts(true);
        await handleFetchProductOnScroll();
        setProductLoading(false);
        setFetchingProducts(false);
      }
    }
  };

  const isLoadingOrNoData = () => {
    return (query !== '' && loading) || products.length === 0;
  };

  return (
    <div className='h-full w-[45vw] my-1 px-2'>
      <div className='w-full flex items-center mb-2'>
        <span className=' font-semibold text-base text-gray-900'>
          {t('Search products')}
        </span>
        <XMarkIcon
          className='ltr:ml-auto rtl:mr-auto cursor-pointer'
          color='#9CA3AF'
          height={18}
          onClick={() => setClose()}
        />
      </div>
      {selectedProduct?.length > recommendationLimit && (
        <p className=' text-sm text-red-600 leading-5 font-normal mb-2 '>
          You cannot send more than {recommendationLimit} products on{' '}
          {channelInfo(platformType).title} at a time.
        </p>
      )}
      <div
        className='h-[35vh] mt-3 overflow-auto'
        id='product-list'
        onScroll={() => productScrollHandle()}
      >
        {isLoadingOrNoData() && loadingAndNoDataView()}

        {!isLoadingOrNoData() && (
          <>
            {getProductList()}
            {productLoading && (
              <div className='w-full flex justify-center items-center py-2'>
                {getLoadingView()}
              </div>
            )}
          </>
        )}
      </div>
      <div className='relative rounded-md shadow-sm mt-4'>
        <div className='pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-2 flex items-center pl-3'>
          <MagnifyingGlassIcon
            className='h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
        </div>
        <input
          type='text'
          name='search'
          autoComplete='off'
          autoFocus={false}
          onChange={(e) => {
            setQuery(e.target.value);
            setLoading(true);
          }}
          id='product_search'
          className='block w-full rounded-md border-gray-300 ltr:pl-10 rtl:pr-8 focus:border-green-300 focus:ring-green-300 sm:text-sm'
          placeholder={t('Search products')}
        />
      </div>
      <div
        className={classNames(
          'mt-4  items-center',
          selectedProduct.length > 0 || hasCart ? 'flex' : 'hidden'
        )}
      >
        <span
          className={` ${
            selectedProduct.length > 0 ? 'inline' : 'hidden'
          } text-sm font-medium text-[#0078CF] cursor-pointer`}
          onClick={() => {
            updateSelectedProduct([]);
          }}
        >
          {t('Unselect All')}
        </span>
        <div
          className={classNames(
            'ltr:ml-auto rtl:mr-auto items-center',
            hasCart ? 'hidden' : 'flex gap-2'
          )}
        >
          {addToCartLoading && (
            <button
              type='button'
              className='bg-gray-500 text-white px-4 py-2 rounded-md text-sm font-medium'
              disabled
            >
              {t('Please wait..')}
              <CircleSpinner />
            </button>
          )}
          {!addToCartLoading && (
            <Button
              intent='default'
              size='md'
              onClick={() => {
                setAddToCartLoading(true);
                handleAddToCartClick();
              }}
            >
              {t('Add to cart')} ({selectedProduct?.length})
            </Button>
          )}

          <Button
            intent='default'
            size='md'
            className='ltr:ml-2 rtl:mr-2'
            shouldHaveFocusRing={false}
            isDisabled={selectedProduct?.length > recommendationLimit}
            onClick={() => {
              setClose();
              handleRecommendation(selectedProduct);
            }}
          >
            {t('Send to chat')} ({selectedProduct.length})
          </Button>
        </div>
        <div
          className={classNames(
            'ltr:ml-auto rtl:mr-auto items-center',
            hasCart ? 'flex gap-2' : 'hidden'
          )}
        >
          <Button
            size='md'
            intent='default'
            shouldHaveFocusRing={false}
            isDisabled={selectedList && selectedList.length < 1}
            onClick={() => {
              handleBackToVariant();
            }}
          >
            {t('View Cart')}
          </Button>
          {addToCartLoading && (
            <button
              type='button'
              className='bg-gray-500 text-white px-4 py-2 ltr:ml-2 rtl:mr-2 rounded-md text-sm font-medium'
              disabled
            >
              {t('Please wait..')}
              <CircleSpinner />
            </button>
          )}
          {shouldShowAddProduct() && (
            <Button
              className={` ${
                selectedProduct.length > 0 ? 'block ml-2' : 'hidden'
              }`}
              shouldHaveFocusRing={false}
              intent='primary'
              size='md'
              onClick={() => {
                setAddToCartLoading(true);
                handleAddItemToCart(selectedProduct);
              }}
            >
              {t('Update cart')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductList;
