import React from 'react';
import CustomerInfo from './CustomerInfo';
import FormHeader from './FormHeader';
import FormFooter from './FormFooter';
import CartDetails from './CartDetails';
import OrderSummary from './OrderSummary';
import { connect } from 'react-redux';
import { SelectedTeamInterface } from 'index';
import { toaster } from 'evergreen-ui';
import ShopifyFailureModal from './FailureModal';
import { validateEmail } from 'pages/inbox/utils/validEmail';
import { getCurrentOrderFormInformationValue } from 'pages/inbox/utils/functions';
import {
  CalculateDraftOrderProperty,
  CountryDetailsProps,
  CreateDraftOrderProperty,
  CreateOrderProperty,
  ICustomerCartInfo,
  OrderFormInformationType,
  TicketInterface,
} from 'pages/inbox/inboxInterface';

interface Props {
  fetchCountry: () => void;
  countryList: CountryDetailsProps[];
  calculateDraftOrder: (payload: {
    projectId: number;
    data: CreateDraftOrderProperty | null;
  }) => {
    success: boolean;
    data: CalculateDraftOrderProperty | { error: string; code: number };
  };
  selectedProject: SelectedTeamInterface;
  customerInfo: ICustomerCartInfo;
  calculateDraftOrderProprty: CalculateDraftOrderProperty;
  openShopifyModal: (payload: boolean) => void;
  selectedTicket: TicketInterface;
  updateCart: (payload: {
    customerId: number | null;
    cartProducts: [];
  }) => void;
  placeOrder: (payload: {
    teamId: number;
    data: CreateOrderProperty;
  }) => Promise<{
    success: boolean;
    dataSource: {
      checkout_link: string;
      shopify_order_name: string;
    };
  }>;
  isOrderPlaceLoader: boolean;
  setShopifyCheckoutLink: (payload: string) => void;
  setShopifyOrderName: (payload: string) => void;
  setOpenShopifySuccessModal: (payload: boolean) => void;
  setShowRightbar: (payload: boolean) => void;
  updateOrderInformationState: (payload: {
    key: number | string;
    data: any;
  }) => void;
  orderInformationData: OrderFormInformationType;
  updateEcommerceStateData: (key: string, value: any) => void;
  getCustomerOrder: (payload: { customerId: number | null }) => void;
}

const CreateShopifyOrder: React.FC<Props> = ({
  fetchCountry,
  countryList,
  calculateDraftOrder,
  selectedProject,
  customerInfo,
  calculateDraftOrderProprty,
  openShopifyModal,
  selectedTicket,
  updateCart,
  placeOrder,
  isOrderPlaceLoader,
  setShopifyCheckoutLink,
  setShopifyOrderName,
  setOpenShopifySuccessModal,
  setShowRightbar,
  updateOrderInformationState,
  orderInformationData,
  updateEcommerceStateData,
  getCustomerOrder,
}) => {
  let initialAddress = {
    address_one: '',
    address_two: '',
    city: '',
    company: '',
    country: '',
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    postcode: '',
    state: '',
  };

  let initialShopifyData = {
    billing_address: initialAddress,
    shipping_address: initialAddress,
    apply_tax: false,
    customer_id: null,
    discount_amount: '',
    discount_type: '',
    shipping_line: {
      method_id: '',
      method_title: '',
      shipping_cost: 0,
    },
    sameAsShippingAddress: false,
  };

  const [step, setStep] = React.useState(1);
  const [createDraftOrder, setCreateDraftOrder] =
    React.useState<CreateDraftOrderProperty>(initialShopifyData);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [orderType, setOrderType] = React.useState('pending');
  const [openShopifyFailModal, setOpenShopifyFailModal] = React.useState(false);
  const [isValidBillingEmail, setIsValidBillingEmail] = React.useState('');
  const [isValidShippingEmail, setIsValidShippingEmail] = React.useState('');

  const renderForms = () => {
    switch (step) {
      case 1:
        return (
          <CustomerInfo
            createDraftOrder={createDraftOrder}
            setCreateDraftOrder={setCreateDraftOrder}
            countryList={countryList}
            isValidBillingEmail={isValidBillingEmail}
            isValidShippingEmail={isValidShippingEmail}
            updateOrderInformationState={updateOrderInformationState}
          />
        );
      case 2:
        return (
          <CartDetails
            createDraftOrder={createDraftOrder}
            setCreateDraftOrder={setCreateDraftOrder}
            updateOrderInformationState={updateOrderInformationState}
          />
        );
      case 3:
        return <OrderSummary createDraftOrder={createDraftOrder} />;
      default:
        return null;
    }
  };

  const checkValidEmail = () => {
    if (createDraftOrder?.shipping_address?.email.length === 0) {
      setIsValidShippingEmail('');
    }

    if (createDraftOrder?.billing_address?.email.length === 0) {
      setIsValidBillingEmail('');
    }

    if (
      !validateEmail(createDraftOrder?.shipping_address?.email || '') &&
      createDraftOrder?.shipping_address.email.length !== 0
    ) {
      setIsValidShippingEmail('Please give a valid email');
    } else {
      setIsValidShippingEmail('');
    }

    if (
      !validateEmail(createDraftOrder?.billing_address.email || '') &&
      createDraftOrder?.billing_address.email.length !== 0
    ) {
      setIsValidBillingEmail('Please give a valid email');
    } else {
      setIsValidBillingEmail('');
    }
  };

  const isNextButtonDisable = () => {
    switch (step) {
      case 1:
        if (
          createDraftOrder?.shipping_address.first_name !== '' &&
          createDraftOrder?.shipping_address.last_name !== '' &&
          createDraftOrder?.billing_address.first_name !== '' &&
          createDraftOrder?.billing_address.last_name !== '' &&
          isValidBillingEmail === '' &&
          isValidShippingEmail === ''
        ) {
          return false;
        } else {
          return true;
        }
      case 2:
        if (createDraftOrder?.shipping_line) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  };

  const handleOrderSubmit = async () => {
    const payload = {
      teamId: selectedProject?.id,
      data: {
        billing_address: createDraftOrder?.billing_address,
        shipping_address: createDraftOrder?.shipping_address,
        apply_tax: createDraftOrder?.apply_tax,
        customer_id: createDraftOrder?.customer_id,
        discount_amount: createDraftOrder?.discount_amount || '',
        discount_type: createDraftOrder?.discount_type,
        ticket_id: selectedTicket?.id,
        note: '',
        create_as: orderType,
        shipping_line: createDraftOrder?.shipping_line,
      },
    };

    // @ts-ignore
    const res = await placeOrder(payload);

    if (res.success && createDraftOrder) {
      updateCart({
        customerId: createDraftOrder?.customer_id,
        cartProducts: [],
      });
      delete orderInformationData[createDraftOrder?.customer_id];
      getCustomerOrder({ customerId: createDraftOrder?.customer_id });
      openShopifyModal(false);
      setShowRightbar(false);
      setOpenShopifySuccessModal(true);
      setShopifyCheckoutLink(res.dataSource?.checkout_link);
      setShopifyOrderName(res.dataSource?.shopify_order_name);
    } else {
      setOpenShopifyFailModal(true);
    }
  };

  const onNextButtonClick = async () => {
    const res = await calculateDraftOrder({
      projectId: selectedProject?.id,
      data: createDraftOrder,
    });

    if (res.success) {
      setStep(step + 1);
    } else {
      // @ts-ignore
      toaster.danger(res?.data?.error);
    }
  };

  React.useEffect(() => {
    fetchCountry();
    if (!orderInformationData?.hasOwnProperty(customerInfo?.customer_id)) {
      updateOrderInformationState({
        key: customerInfo.customer_id,
        data: {
          ...customerInfo,
          apply_tax: false,
          discount_amount: '',
          discount_type: '',
          shipping_line: null,
          sameAsShippingAddress: false,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    checkValidEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDraftOrder]);

  React.useEffect(() => {
    let currentOrderValue = getCurrentOrderFormInformationValue(
      orderInformationData,
      customerInfo?.customer_id
    );
    setCreateDraftOrder(currentOrderValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInformationData]);

  return (
    <div className='absolute top-0 right-0 bg-white w-full'>
      <FormHeader
        step={step}
        updateEcommerceStateData={updateEcommerceStateData}
      />
      <div className='h-[86vh] create-wooCoomerce-order overflow-y-scroll'>
        {renderForms()}
      </div>
      <div className='w-full z-10 bg-white h-[7.5vh]'>
        <FormFooter
          step={step}
          setStep={setStep}
          isNextDisable={isNextButtonDisable()}
          onNext={() => onNextButtonClick()}
          openSuccessModal={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
          handleOrderSubmit={handleOrderSubmit}
          orderType={orderType}
          setOrderType={setOrderType}
          setShowRightbar={setShowRightbar}
        />
      </div>
      <ShopifyFailureModal
        width='w-[344px]'
        height='h-auto'
        isOpen={openShopifyFailModal}
        setIsOpen={setOpenShopifyFailModal}
        handleTryAgain={handleOrderSubmit}
        isOrderPlaceLoader={isOrderPlaceLoader}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  countryList: state.ecommerce.countryList,
  selectedProject: state.dashboard.selectedProject,
  customerInfo: state.ecommerce.cart,
  calculateDraftOrderProprty: state.ecommerce.calculateDraftOrder,
  selectedTicket: state.inbox.selectedTicket,
  isOrderPlaceLoader: state.loading.effects.ecommerce.createOrder,
  orderInformationData: state.ecommerce.orderFormInformation,
});

const mapDispatch = (dispatch: any) => ({
  fetchCountry: () => dispatch.ecommerce.fetchCountryList(),
  calculateDraftOrder: (payload: {
    projectId: number;
    data: CreateDraftOrderProperty | null;
  }) => dispatch.ecommerce.calculateDraftOrder(payload),
  openShopifyModal: (payload: boolean) =>
    dispatch.ecommerce.updateOpenShopifyModal(payload),
  updateCart: (payload: { customerId: number | null; cartProducts: [] }) =>
    dispatch.ecommerce.updateCart(payload),
  placeOrder: (payload: { teamId: number; data: CreateOrderProperty }) =>
    dispatch.ecommerce.createOrder(payload),
  updateOrderInformationState: (payload: { key: number | string; data: any }) =>
    dispatch.ecommerce.updateOrderFormInformation(payload),
  updateEcommerceStateData: (key: string, value: any) =>
    dispatch.ecommerce.updateEcommerceStateData({ key, value }),
  getCustomerOrder: (payload: { customerId: number | null }) =>
    dispatch.ecommerce.fetchCustomerOrder(payload),
});

export default connect(mapState, mapDispatch)(CreateShopifyOrder);
