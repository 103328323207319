import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import WebhookList from '../component/webhook/WebhookList';
import { connect } from 'react-redux';
import { SelectedTeamInterface } from 'index';
import CreateWebhook from '../component/webhook/CreateWebhook';
import DeleteModal from '../component/webhook/DeleteModal';
import { toaster } from 'evergreen-ui';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  getWebhookList: (projectId: number) => boolean;
  selectedProject: SelectedTeamInterface;
  webhookList: WebhookInterface[];
  deleteWebhook: (payload: { projectId: number; webhookId: string }) => boolean;
}

const WebhookManagement: React.FC<Props> = ({
  getWebhookList,
  selectedProject,
  webhookList,
  deleteWebhook,
}) => {
  const { t } = useTranslation();
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [filterWebhook, setFilterWebhook] = React.useState<
    WebhookInterface[] | null
  >(webhookList);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [singleWebhook, setSingleWebhook] = React.useState<string>('');
  const [isEdit, setIsEdit] = React.useState(false);
  const [editData, setEditData] = React.useState<{
    id: null | string;
    event: string;
    endpoint: string;
  }>({
    id: null,
    event: '',
    endpoint: '',
  });

  const deleteSelectedWebhook = async () => {
    let payload = {
      projectId: selectedProject.id,
      webhookId: singleWebhook,
    };

    const res = await deleteWebhook(payload);

    if (res) {
      toaster.success(t('Webhook deleted successfully'));
      setOpenDeleteModal(false);
    } else {
      toaster.danger(t('Could not delete Webhook. Please try again.'));
      setOpenDeleteModal(false);
    }
  };

  React.useEffect(() => {
    getWebhookList(selectedProject?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setFilterWebhook(webhookList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webhookList]);

  return (
    <div className='p-4 w-full'>
      <div className='flex mt-3 mb-6 items-center justify-between'>
        <p className='text-gray-900 text-lg font-semibold'>
          {t('Manage Webhook')}
        </p>

        <div className='w-1/2 flex justify-end gap-2'>
          <button
            className='py-2 px-3 bg-[#04B25F] text-white capitalize
            rounded-md flex gap-2 items-center'
            onClick={() => setOpenCreateModal(true)}
          >
            <PlusIcon className='w-4 h-4 text-white' />
            {t('Create New Webhook')}
          </button>
        </div>
      </div>

      <div>
        {filterWebhook && (
          <WebhookList
            webhookList={filterWebhook}
            setOpenDeleteModal={setOpenDeleteModal}
            openDeleteModal={openDeleteModal}
            setSingleWebhook={setSingleWebhook}
            setIsEdit={setIsEdit}
            setEditData={setEditData}
            setOpenCreateModal={setOpenCreateModal}
          />
        )}
      </div>
      <CreateWebhook
        open={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        editData={editData}
        setEditData={setEditData}
      />
      <DeleteModal
        isOpen={openDeleteModal}
        deleteSelectedWebhook={deleteSelectedWebhook}
        setOpenDeleteModal={setOpenDeleteModal}
        selectedWebhook={singleWebhook}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
  webhookList: state.settings.webhooks,
});

const mapDispatch = (dispatch: any) => ({
  getWebhookList: (projectId: number) =>
    dispatch.settings.fetchWebhook(projectId),
  deleteWebhook: (payload: { projectId: number; webhookId: string }) =>
    dispatch.settings.deleteWebhook(payload),
});

export default connect(mapState, mapDispatch)(WebhookManagement);
