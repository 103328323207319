import React, { useEffect, useMemo } from 'react';
import './assets/styles/reporting.scss';
import { connect } from 'react-redux';
import { checkPermission, getQueryParameters } from '../../utilities/utils';
import ReportingNav from './component/Nav/ReportingNav';
import CustomerMetrics from './component/Screen/CustomerMetrics';
import AutomationMetrics from './component/Screen/AutomationMetrics';
import AgentMetrics from './component/Screen/AgentMetrics';
import CSATReports from './component/Screen/CSATReports';
import { SelectedTeamInterface } from 'index';
import WhatsappMetrics from './component/WhatsappMetrics';
import { IChannelTypeProps } from './interface';
import useWhatsappMetrics from './component/WhatsappMetrics/hooks/useWhatsappMetrics';
import AnalyticsLogs from './component/WhatsappMetrics/components/TemplateAnalytics/logs';

interface permissionListProps {
  id: number;
  short_code: string | number;
  verbose: string;
}

interface dataRangeProps {
  startDate: string;
  endDate: string;
  key: string;
}

interface platformDataProps {
  id: number | null;
  type: string;
  title: string;
}

interface platformProps {
  id: number;
  projects: any;
}

interface props {
  projects: SelectedTeamInterface[];
  projectId: any;
  selectedProject: SelectedTeamInterface;
  setSelectedProject: (project: any) => void;
  selectedPlatform: platformProps;
  platformList: platformProps;
  setSelectedPlatform: (val: any) => void;
  permissionList: permissionListProps[];
  clearState: () => void;
  currentTab: { id: number; name: string };
  updateStateData: (key: string, value: any) => void;
  dateRange: dataRangeProps;
  platformData: platformDataProps;
  selectedReportingViewMode: string;
  //user matrics
  fetchUserReportData: (projectId: number, endpoint: string) => void;
  fetchUserReportDataOnTabClick: (projectId: number, endpoint: string) => void;
  fetchUserReportDataUserMetricsLineGraph: (
    projectId: number,
    endpoint: string
  ) => void;
  fetchUserReportDataDefaultAppend: (
    projectId: number,
    endpoint: string
  ) => void;
  fetchUserReportHeatmap: (projectId: number) => void;
  fetchAgentList: (teamId: number) => void;
  fetchAutomationTicketTable: (
    projectId: number,
    groupId: number | null,
    limit: number,
    offset: number
  ) => void;
  fetchIntentReporting: (projectId: number) => void;
  //csat
  fetchCsatStack: (teamId: number, url: string) => void;
  fetchCsatResponseList: (
    teamId: number,
    search: string,
    limit: number,
    offset: number
  ) => void;
  fetchCsatResponseDist: (teamId: number) => void;
  updateSelectedNav: (navId: number) => void;
  isBusinessHourEnabled: boolean;
}

const Reporting: React.FC<props> = ({
  projects,
  projectId,
  selectedProject,
  setSelectedProject,
  selectedPlatform,
  platformList,
  setSelectedPlatform,
  permissionList,
  currentTab,
  updateStateData,
  dateRange,
  platformData,
  clearState,
  fetchUserReportData,
  fetchUserReportHeatmap,
  fetchAgentList,
  fetchAutomationTicketTable,
  fetchUserReportDataOnTabClick,
  fetchUserReportDataUserMetricsLineGraph,
  fetchUserReportDataDefaultAppend,
  fetchIntentReporting,
  fetchCsatStack,
  fetchCsatResponseList,
  fetchCsatResponseDist,
  isBusinessHourEnabled,
  updateSelectedNav,
  selectedReportingViewMode,
}) => {
  const readAccess = 'read:analytics';
  const {
    isWhatsappBsbIntegration,
    updateSelectedPlatformData,
    updateIsWhatsappBsbIntegration,
  } = useWhatsappMetrics();
  const [localDateRange, setLocalDateRange] = React.useState([
    {
      startDate: new Date(dateRange[0].startDate),
      endDate: new Date(dateRange[0].endDate),
      key: 'selection',
    },
  ]);

  //Customer Matrices Local State
  const [currentKeywordsIndex, setCurrentKeywordsIndex] = React.useState({
    id: 0,
    name: 'Keywords',
  });
  const [currentButtonsIndex, setCurrentButtonsIndex] = React.useState({
    id: 0,
    name: 'Buttons',
  });

  const [defaultGroup, setDefaultGroup] = React.useState({
    id: null,
    email: '',
    avatar: null,
    full_name: 'All Agents',
  });

  const perPageTicketLimit = 6;
  const csatRespondedLimit = 10;

  const [ticketListOffset, setTicketListOffset] = React.useState(0);
  const [csatRespondedOffset, setCsatRespondedOffset] = React.useState(0);
  const [csatRespondedSearch, setCsatRespondedSearch] = React.useState('');

  const fetchUserMetrics = () => {
    fetchUserReportData(selectedProject?.id, 'get_new_customer_count');
    fetchUserReportData(selectedProject?.id, 'get_interactive_customer_count');
    fetchUserReportData(
      selectedProject?.id,
      'get_triggered_message_count/referral'
    );
    fetchUserReportData(
      selectedProject?.id,
      'get_triggered_message_count/intent'
    );
    fetchUserReportDataUserMetricsLineGraph(
      selectedProject?.id,
      'get_language_metrics'
    );
    fetchUserReportDataUserMetricsLineGraph(
      selectedProject?.id,
      'get_timezone_metrics'
    );
    fetchUserReportDataUserMetricsLineGraph(
      selectedProject?.id,
      'get_country_metrics'
    );

    fetchUserReportHeatmap(selectedProject?.id);
    fetchUserReportDataDefaultAppend(selectedProject?.id, 'get_gender_metrics');
    fetchUserReportDataDefaultAppend(
      selectedProject?.id,
      'get_postback_and_text_message_count'
    );
    if (currentKeywordsIndex?.id === 0) {
      fetchUserReportDataDefaultAppend(
        selectedProject?.id,
        'get_triggered_message_list/keyword'
      );
    } else if (currentKeywordsIndex?.id === 1) {
      fetchUserReportDataDefaultAppend(
        selectedProject?.id,
        'get_triggered_message_list/intent'
      );
    }
    //   1
    if (currentButtonsIndex.id === 0) {
      fetchUserReportDataDefaultAppend(
        selectedProject?.id,
        'get_triggered_message_list/postback'
      );
    } else if (currentButtonsIndex.id === 1) {
      fetchUserReportDataDefaultAppend(
        selectedProject?.id,
        'get_triggered_message_list/referral'
      );
    }
  };

  const fetchAutomationMetrics = () => {
    fetchUserReportData(selectedProject?.id, 'unique-ticket-count');
    fetchUserReportData(selectedProject?.id, 'get_ticket_created_count');
    fetchUserReportData(selectedProject?.id, 'get_ticket_resolved_count');
    if (selectedProject?.id === 943) {
      // Only Available to MIBS
      fetchUserReportData(
        selectedProject?.id,
        'get-avg-process-first-response-time'
      );
    }

    fetchUserReportDataDefaultAppend(
      selectedProject?.id,
      'get_textual_message_vs_automation'
    );
    fetchUserReportDataDefaultAppend(selectedProject?.id, 'get_tag_cloud');
    fetchAutomationTicketTable(
      selectedProject?.id,
      defaultGroup?.id,
      perPageTicketLimit,
      ticketListOffset
    );
  };

  const fetchAgentMetrics = () => {
    fetchUserReportData(
      selectedProject?.id,
      'get_agent_average_first_response_time'
    );
    fetchUserReportData(
      selectedProject?.id,
      'get_agent_average_resolution_time'
    );
    fetchUserReportData(selectedProject?.id, 'get_agent_average_response_time');
    fetchUserReportData(
      selectedProject?.id,
      'get_agent_sales_conversion_ratio'
    );
    // fetchUserReportData(selectedProject?.id, 'get_agent_average_online_time');
    fetchUserReportDataDefaultAppend(selectedProject?.id, 'get-agent-table');
  };

  const fetchCsatMetrics = () => {
    fetchCsatStack(selectedProject?.id, 'csat-total-count');
    fetchCsatStack(selectedProject?.id, 'csat-responded-count');
    fetchCsatStack(selectedProject?.id, 'csat-average-rating');
    fetchCsatResponseList(
      selectedProject?.id,
      csatRespondedSearch,
      csatRespondedLimit,
      csatRespondedOffset
    );
    fetchCsatResponseDist(selectedProject?.id);
  };

  const fetchAnalyticsData = async () => {
    switch (currentTab.id) {
      case 0:
        fetchUserMetrics();
        break;
      case 1:
        fetchAutomationMetrics();
        break;
      case 2:
        fetchAgentMetrics();
        break;
      case 3:
        fetchCsatMetrics();
        break;
    }
  };

  const handleCustomerMetricsOnTabChange = (tab: any, pointer: number) => {
    if (pointer === 0) {
      setCurrentKeywordsIndex(tab);
      switch (tab.id) {
        case 0:
          fetchUserReportDataOnTabClick(
            selectedProject?.id,
            'get_triggered_message_list/keyword'
          );
          break;
        case 1:
          fetchUserReportDataOnTabClick(
            selectedProject?.id,
            'get_triggered_message_list/intent'
          );
          break;
      }
    } else if (pointer === 1) {
      setCurrentButtonsIndex(tab);
      switch (tab.id) {
        case 0:
          fetchUserReportDataOnTabClick(
            selectedProject?.id,
            'get_triggered_message_list/postback'
          );
          break;
        case 1:
          fetchUserReportDataOnTabClick(
            selectedProject?.id,
            'get_triggered_message_list/referral'
          );
          break;
      }
    }
  };

  const handleTicketListNext = () => {
    fetchAutomationTicketTable(
      selectedProject?.id,
      defaultGroup.id,
      perPageTicketLimit,
      ticketListOffset + perPageTicketLimit
    );
    setTicketListOffset(ticketListOffset + perPageTicketLimit);
  };

  const handleTicketListPrevious = () => {
    fetchAutomationTicketTable(
      selectedProject?.id,
      defaultGroup.id,
      perPageTicketLimit,
      ticketListOffset - perPageTicketLimit
    );
    setTicketListOffset(ticketListOffset - perPageTicketLimit);
  };

  const handleAgentSelect = (agentId: number) => {
    fetchAutomationTicketTable(
      selectedProject?.id,
      agentId,
      perPageTicketLimit,
      ticketListOffset
    );
  };

  const handleCsatRespondedListNext = () => {
    fetchCsatResponseList(
      selectedProject?.id,
      csatRespondedSearch,
      csatRespondedLimit,
      csatRespondedOffset + csatRespondedLimit
    );
    setCsatRespondedOffset(csatRespondedOffset + csatRespondedLimit);
  };

  const handleCsatRespondedListPrev = () => {
    fetchCsatResponseList(
      selectedProject?.id,
      csatRespondedSearch,
      csatRespondedLimit,
      csatRespondedOffset - csatRespondedLimit
    );
    setCsatRespondedOffset(csatRespondedOffset - csatRespondedLimit);
  };

  const handleCsatRespondedSearch = (search: string) => {
    fetchCsatResponseList(selectedProject?.id, search, csatRespondedLimit, 0);
    setCsatRespondedOffset(0);
    setCsatRespondedSearch(search);
  };

  const updateInformation = async () => {
    const project = projects.filter((p) => parseInt(projectId) === p.id)[0];
    if (!!selectedProject && projectId !== selectedProject.id) {
      await setSelectedProject(project);
      if (
        project?.platforms?.length > 0 &&
        project?.platforms.filter(
          (platform: any) => platform.id === selectedPlatform.id
        ).length === 0
      ) {
        setSelectedPlatform(project.platforms[0]);
      }
    }
    const hasPermission = checkPermission(permissionList, readAccess);

    if (hasPermission) {
      fetchAgentList(selectedProject?.id);
      fetchIntentReporting(selectedProject?.id);
    }
  };

  React.useEffect(() => {
    const hasPermission = checkPermission(permissionList, readAccess);
    if (hasPermission) {
      fetchAnalyticsData();
    }
    // eslint-disable-next-line
  }, [currentTab]);

  React.useEffect(() => {
    updateInformation();
    const query = getQueryParameters('tab');
    updateSelectedNav(5);
    if (query === 'automation-metrics') {
      updateStateData('currentTab', { id: 1, name: 'Automation Metrics' });
    } else if (query === 'agent-metrics') {
      updateStateData('currentTab', { id: 2, name: 'Agent Metrics' });
    }
    return () => {
      clearState();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Array.isArray(platformList)) {
      const getWhatsappBsbData = platformList.filter(
        (platform: IChannelTypeProps) =>
          platform.platform_type === 'whatsapp_bsp'
      );
      if (getWhatsappBsbData.length > 0) {
        updateIsWhatsappBsbIntegration(false);
      } else {
        updateIsWhatsappBsbIntegration(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  // Filter the platform list based on the current tab
  const filterPlatformList = () => {
    if (
      currentTab?.name === 'WhatsApp Metrics' &&
      Array.isArray(platformList)
    ) {
      return platformList.filter(
        (platform: IChannelTypeProps) =>
          platform.platform_type === 'whatsapp_bsp'
      );
    } else {
      return platformList;
    }
  };

  // Determine the initial selected platform based on the current tab and platform list
  const getInitialSelectedPlatform = useMemo(() => {
    if (
      currentTab?.name !== 'WhatsApp Metrics' ||
      !Array.isArray(platformList) ||
      platformList.length === 0
    ) {
      return platformData;
    }

    const whatsappBspPlatforms = platformList.filter(
      (platform: IChannelTypeProps) => platform.platform_type === 'whatsapp_bsp'
    );

    const selectedPlatform =
      whatsappBspPlatforms.find(({ id }) => id === platformData?.id) ||
      whatsappBspPlatforms[0];

    if (selectedPlatform) {
      updateSelectedPlatformData(selectedPlatform);
    }

    return selectedPlatform;

    // eslint-disable-next-line
  }, [currentTab?.name, platformData?.id]);

  return (
    <div>
      {selectedReportingViewMode === 'reporting' && (
        <div>
          <ReportingNav
            platformList={filterPlatformList() ?? []}
            selectedPlatformLocal={getInitialSelectedPlatform}
            setSelectedPlatformLocal={(val: any) =>
              updateStateData('platformData', val)
            }
            dateRange={localDateRange}
            setDateRange={setLocalDateRange}
            finalDateRange={dateRange}
            currentTab={currentTab}
            updateStateData={updateStateData}
            fetchAnalyticsData={() => fetchAnalyticsData()}
            onDateFilter={() => {
              updateStateData('dateRange', localDateRange);
              setTimeout(() => {
                fetchAnalyticsData();
              }, 500);
            }}
          />
          {{
            0: (
              <CustomerMetrics
                currentKeywordsIndex={currentKeywordsIndex}
                currentButtonsIndex={currentButtonsIndex}
                onTabChange={(tab: any, pointer: number) =>
                  handleCustomerMetricsOnTabChange(tab, pointer)
                }
                selectedProject={selectedProject}
              />
            ),
            1: (
              <AutomationMetrics
                defaultGroup={defaultGroup}
                setDefaultGroup={setDefaultGroup}
                limit={perPageTicketLimit}
                offset={ticketListOffset}
                setOffset={setTicketListOffset}
                handleTicketListNext={handleTicketListNext}
                handleTicketListPrevious={handleTicketListPrevious}
                handleAgentSelect={(agentId: number) =>
                  handleAgentSelect(agentId)
                }
                selectedProject={selectedProject}
              />
            ),
            2: (
              <AgentMetrics
                handleBusinessHourToggle={(val: boolean) => {
                  updateStateData('isBusinessHourEnabled', val);
                  setTimeout(() => {
                    fetchAnalyticsData();
                  }, 500);
                }}
              />
            ),
            3: (
              <CSATReports
                offset={csatRespondedOffset}
                handleRespondedListNext={handleCsatRespondedListNext}
                handleRespondedListPrev={handleCsatRespondedListPrev}
                handleRespondedSearch={handleCsatRespondedSearch}
              />
            ),
            4: !isWhatsappBsbIntegration && <WhatsappMetrics />,
          }[currentTab.id] || <div>Not Found </div>}
        </div>
      )}
      {selectedReportingViewMode === 'analyticsLogs' && <AnalyticsLogs />}
    </div>
  );
};

const mapState = (state: any) => ({
  projects: state.dashboard.projects,
  selectedProject: state.dashboard.selectedProject,
  selectedPlatform: state.dashboard.selectedPlatform,
  platformList: state.dashboard.platformList,
  permissionList: state.dashboard.permission?.list || [],
  currentTab: state.reporting.currentTab,
  dateRange: state.reporting.dateRange,
  platformData: state.reporting.platformData,
  isBusinessHourEnabled: state.reporting.isBusinessHourEnabled,
  selectedReportingViewMode: state.reporting.selectedReportingViewMode,
});

const mapDispatch = (dispatch: any) => ({
  setSelectedProject: (project: any) =>
    dispatch.dashboard.setSelectedProject(project),
  setSelectedPlatform: (platform: any) =>
    dispatch.dashboard.setSelectedPlatform(platform),
  updateStateData: (key: string, value: any) =>
    dispatch.reporting.updateStateData({ key, value }),

  updateSelectedNav: (navId: number) =>
    dispatch.dashboard.updateSelectedNav(navId),

  //user metrics
  fetchUserReportData: (projectId: number, endpoint: string) =>
    dispatch.reporting.fetchUserReportData({ projectId, endpoint }),
  fetchUserReportDataUserMetricsLineGraph: (
    projectId: number,
    endpoint: string
  ) =>
    dispatch.reporting.fetchUserReportDataUserMetricsLineGraph({
      projectId,
      endpoint,
    }),
  fetchUserReportDataDefaultAppend: (projectId: number, endpoint: string) =>
    dispatch.reporting.fetchUserReportDataDefaultAppend({
      projectId,
      endpoint,
    }),
  fetchUserReportDataOnTabClick: (projectId: number, endpoint: string) =>
    dispatch.reporting.fetchUserReportDataOnTabClick({ projectId, endpoint }),
  fetchUserReportHeatmap: (projectId: number) =>
    dispatch.reporting.fetchUserReportHeatmap(projectId),
  fetchAgentList: (teamId: number) => dispatch.crm.fetchAgentList(teamId),
  fetchAutomationTicketTable: (
    projectId: number,
    groupId: number | null,
    limit: number,
    offset: number
  ) =>
    dispatch.reporting.fetchAutomationTicketTable({
      projectId,
      groupId,
      limit,
      offset,
    }),
  clearState: () => dispatch.reporting.clearState(),
  fetchIntentReporting: (projectId: number) =>
    dispatch.reporting.fetchIntentReporting(projectId),
  //csat
  fetchCsatStack: (teamId: number, url: string) =>
    dispatch.reporting.fetchCsatStack({ teamId, url }),
  fetchCsatResponseList: (
    teamId: number,
    search: string,
    limit: number,
    offset: number
  ) =>
    dispatch.reporting.fetchCsatResponseList({
      teamId,
      search,
      limit,
      offset,
    }),
  fetchCsatResponseDist: (teamId: number) =>
    dispatch.reporting.fetchCsatResponseDist(teamId),
});

const ReportingContainer = connect(mapState, mapDispatch)(Reporting);

export default ReportingContainer;
