/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useRef } from 'react';
import { WhatsappTemplateActionType } from '../../components/CreateWhatsAppTemplateV2';
import useWhatsappTemplateData, {
  IWhatsappCarouselCard,
} from 'pages/integration/hooks/useWhatsappTemplateData';
import WhatsappFileUploader from 'components/utilityComponent/WhatsappFileUploader';
import CarouselTemplateButtonBlock from './CarouselTemplateButtonBlock';
import { sequenceProps } from 'pages/integration/interface';

interface CarouselTemplateCardProps {
  actionType: WhatsappTemplateActionType;
  cardId: number;
  activeCard?: IWhatsappCarouselCard;
  sequence: sequenceProps[];
}
const MAX_CAROUSEL_CARD_TEXT_SIZE = 160;

const CarouselTemplateCard: FC<CarouselTemplateCardProps> = ({
  cardId,
  actionType,
  sequence,
}) => {
  const {
    whatsappTemplateState,
    updateCarouselTemplateCardsData,
    updateSingleCarouselCardData,
  } = useWhatsappTemplateData();
  const cardList = whatsappTemplateState.cards;
  const activeCard = cardList[cardId - 1];
  const isTemplateActionView = actionType === 'VIEW';
  const isValidTemplateBody = true;
  const textAreaRef = useRef(null);

  const handleCardHeaderUpload = (fileUrl: string) => {
    // find & update active header value
    const updatedCards = cardList?.map((c) => {
      if (c?.id !== activeCard?.id) {
        return c;
      }
      c.header.value = fileUrl;
      return c;
    });
    updateCarouselTemplateCardsData(updatedCards);
  };

  const handleCardHeaderDelete = () => {
    if (isTemplateActionView) {
      return;
    }
    handleCardHeaderUpload('');
  };

  const renderCardHeaderUploader = () => {
    const headerType = whatsappTemplateState?.headerType;
    return (
      <div key={activeCard.id} className='flex flex-col gap-1'>
        <p className='capitalize text-gray-700 font-medium'>
          <span>{headerType}</span>
          <span className='text-red-500'>*</span>{' '}
        </p>
        {headerType === 'image' ? (
          <WhatsappFileUploader
            file={activeCard?.header?.value || ''}
            handleFileChange={handleCardHeaderUpload}
            handleClear={handleCardHeaderDelete}
            templateHeaderType='image'
            fileMimeTypes={['image/png', 'image/jpeg']}
          />
        ) : (
          <WhatsappFileUploader
            file={activeCard?.header?.value || ''}
            handleFileChange={handleCardHeaderUpload}
            handleClear={handleCardHeaderDelete}
            templateHeaderType='video'
            fileMimeTypes={['video/mp4']}
          />
        )}
      </div>
    );
  };

  const handleTemplateBodyChange = (
    ev: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    updateSingleCarouselCardData({
      ...activeCard,
      body: ev.currentTarget.value ?? '',
    });
  };

  const renderCardBody = () => {
    return (
      <div className='flex flex-col gap-1'>
        <p>
          <span className='text-gray-700 font-medium text-sm'>
            Carousel Text
          </span>
          <span className='text-red-500'>*</span>
        </p>
        <div
          className={`
         relative border border-gray-300 shadow-sm rounded px-3 py-2
         ${
           isValidTemplateBody
             ? 'focus-within:border-green-500 focus-within:ring-1 focus-within:ring-green-500'
             : 'focus-within:border-red-500 focus-within:ring-1 focus-within:ring-red-500'
         }
        ${
          isTemplateActionView
            ? 'bg-gray-100 cursor-not-allowed'
            : ' cursor-default bg-white'
        }`}
        >
          <textarea
            ref={textAreaRef}
            maxLength={MAX_CAROUSEL_CARD_TEXT_SIZE}
            id={`wa-template-body`}
            name='text-input-name'
            placeholder={'Type your message here'}
            value={activeCard?.body || ''}
            rows={3}
            disabled={isTemplateActionView}
            className={`resize-y text-sm text-gray-700 font-normal border-0 border-none rounded w-full
           ${
             isTemplateActionView
               ? 'bg-gray-100 cursor-not-allowed'
               : 'focus:ring-0 focus:border-none bg-white'
           } text-gray-500 focus:outline-none focus:ring-1`}
            onChange={handleTemplateBodyChange}
          />
          <div className='h-6 flex gap-1 items-center w-full'>
            <div className='flex w-[50%] justify-start items-center'></div>
            <div className='flex w-[50%] justify-end'>
              <div className={`rounded-md p-1 bg-green-100 text-green-800`}>
                {activeCard.body?.length} /{MAX_CAROUSEL_CARD_TEXT_SIZE}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderCardHeaderUploader()}
      {renderCardBody()}
      <CarouselTemplateButtonBlock
        actionType={actionType}
        sequence={sequence}
        activeCard={activeCard}
      />
    </>
  );
};

export default CarouselTemplateCard;
