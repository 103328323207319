import React, { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { globalHistory, navigate } from '@reach/router';

import store from './utilities/stores';
import ErrorBoundary from './pages/errorBoundary';
import { getQueryToken } from 'utilities/utils';
import { versionNumber } from './utilities/config';
import Routes from './utilities/routes';

import 'react-circular-progressbar/dist/styles.css';
import { mixpanelAnalyticsRoute } from 'utilities/mixpanel';
import { Toaster } from 'libraryV2/ui/toaster';

const App = () => {
  const forceLogout = () => {
    const excludeList = ['/public/', '/register', '/invitation']; //add more later

    const checkNonAuthenticatedURLs = () => {
      let isPublic = true;
      excludeList.forEach((url) => {
        if (window.location.href.includes(url)) {
          isPublic = false;
        }
      });
      return isPublic;
    };

    let oldVersion = localStorage.getItem('versionNumber');

    if (checkNonAuthenticatedURLs()) {
      //if the url is not public
      if (!!oldVersion) {
        if (oldVersion !== versionNumber) {
          localStorage.setItem('versionNumber', versionNumber);
          store.dispatch.auth.logout();
          navigate('/');
        }
      } else {
        localStorage.setItem('versionNumber', versionNumber);
      }
    }
  };

  const handleShopifyValidation = async () => {
    const shopifyToken = getQueryToken('shopify_installation_request_id');
    // if old token was in localstorage and not removed that means its a valid token
    if (!!shopifyToken) {
      const isValid = await store.dispatch.marketplace.validateShopifyToken(
        shopifyToken
      );
      if (isValid) {
        localStorage.setItem('shopify_installation_request_id', shopifyToken);
        navigate('/shopify-connect');
      } else {
        localStorage.removeItem('shopify_installation_request_id');
      }
    }
  };

  useEffect(() => {
    //for reload
    mixpanelAnalyticsRoute(null);

    //for path change in globalHistory
    //context: analytics dont get triggered when path changes unless its reloaded
    // use functions from 'src/utils/segment.tsx'
    globalHistory.listen((props) => {
      if (props.action === 'PUSH') {
        mixpanelAnalyticsRoute(props);
      }
    });

    handleShopifyValidation();
    forceLogout();

    return () => {
      if (globalHistory.removeItem) {
        globalHistory.removeItem();
      }
    };
  }, []);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <ErrorBoundary
          error={error}
          logoutAPI={store.dispatch.auth.logoutAPI}
        />
      )}
    >
      <Routes />
      <Toaster />
    </Sentry.ErrorBoundary>
  );
};

export default App;
