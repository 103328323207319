import {
  React,
  useSelector,
  useTriggerActions,
  AutomationListTable,
  useAutomationDashboard,
  AutomationDashboardHeader,
} from '../../export';

const AutomationDashboard = () => {
  const { fetchUserActionFlowData } = useAutomationDashboard();
  const { selectedProject } = useSelector((state: any) => state.dashboard);
  const { workflowDashboardTableOffset } = useSelector(
    (state: any) => state.workflowAutomation
  );
  const { selectedTriggerTabAction } = useTriggerActions();

  React.useEffect(() => {
    fetchUserActionFlowData(workflowDashboardTableOffset);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (selectedProject?.has_ecommerce_connected) {
      selectedTriggerTabAction(selectedProject?.ecommerce_type);
    } else {
      selectedTriggerTabAction(null);
    }
    // eslint-disable-next-line
  }, [
    selectedProject?.has_ecommerce_connected,
    selectedProject?.ecommerce_type,
  ]);

  return (
    <div className='relative w-full h-full'>
      <AutomationDashboardHeader />
      <div className='px-5'>
        <AutomationListTable />
      </div>
    </div>
  );
};

export default AutomationDashboard;
