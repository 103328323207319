import React from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { whatsappBSPSettingDataProps } from 'pages/integration/interface';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  success?: boolean;
  errorMessage?: string;
  whatsappSettingData: whatsappBSPSettingDataProps;
  handleChange: (key: string, value: string | string[] | boolean) => void;
}

const Step2: React.FC<Props> = ({
  success = true,
  errorMessage,
  handleChange,
  whatsappSettingData,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {!success && (
        <div className='flex justify-center'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='ml-2 text-sm text-red-600'>{errorMessage}</p>
        </div>
      )}
      <div>
        <div className='col-span-6 sm:col-span-6'>
          <label
            htmlFor='title'
            className='block rtl:text-right text-sm font-medium text-gray-700'
          >
            {t('Title')}*
          </label>
          <input
            type='text'
            placeholder={t('My whatsapp channel')}
            value={whatsappSettingData.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='title'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 rtl:text-right text-sm text-gray-500'>
            {t('This will be your Whatsapp Account Name.')}
          </p>
        </div>
        <div className='col-span-6 mt-5 sm:col-span-6'>
          <div className='flex justify-between'>
            <label
              htmlFor='description'
              className='block text-sm font-medium text-gray-700'
            >
              {t('Description')}
            </label>
            <span className='text-sm text-gray-500'>{t('Optional')}</span>
          </div>
          <input
            type='text'
            placeholder={t('Details of your business account')}
            value={whatsappSettingData.description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='description'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 rtl:text-right text-sm text-gray-500'>
            {t('This will be shown in the details of your business account.')}
          </p>
        </div>
      </div>
    </>
  );
};
export default Step2;
