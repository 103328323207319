import React from 'react';
import Modal from './Modal';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  isOpen: boolean;
  setOpenDeleteModal: (payload: boolean) => void;
  deleteSelectedWebhook: () => void;
  selectedWebhook: string;
}

const DeleteModal: React.FC<Props> = ({
  isOpen,
  setOpenDeleteModal,
  deleteSelectedWebhook,
  selectedWebhook,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} width={'w-[384px]'}>
      <>
        <div className='p-[24px] flex flex-col items-center'>
          <div className='mb-[20px]'>
            <ExclamationTriangleIcon className='bg-red-100 text-red-500 w-10 h-10 p-2 rounded-full' />
          </div>
          <p className='mb-[8px] text-gray-900 font-medium capitalize text-[18px]'>
            {t('Delete Webhook?')}
          </p>
          <p className='text-gray-500 font-[400] mb-[24px] text-center text-[14px]'>
            {t(`You are about to delete a webhook. Make sure the webhook is
            disconnected from any external apps or sources.`)}
          </p>
          <button
            className='w-full bg-[#DC2626] border border-[#DC2626] rounded-[6px] text-[14px]
    text-white font-[500] mb-[12px] p-2 cursor-pointer'
            onClick={() => deleteSelectedWebhook()}
          >
            {t('Delete')}
          </button>
          <button
            className='w-full bg-white border border-[#D1D5DB] text-300
    rounded-[6px] text-[14px] text-gray-700 font-[500] mb-[12px] p-2
    cursor-pointer'
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          >
            {t('Cancel')}
          </button>
        </div>
      </>
    </Modal>
  );
};

export default DeleteModal;
