import useTranslation from 'components/customHooks/useTranslation';
import { toaster } from 'evergreen-ui';
import { SelectedTeamInterface } from 'index';
import { Modal } from 'library';
import React from 'react';
import { connect } from 'react-redux';

interface Props {
  open: boolean;
  setOpenCreateModal: (payload: boolean) => void;
  createWebhook: (projectId: WebhookPayload) => {
    success: boolean;
    data: {
      success: boolean;
      error: string;
    };
  };
  selectedProject: SelectedTeamInterface;
  isEdit: boolean;
  setIsEdit: (payload: boolean) => void;
  editWebhook: (payload: {
    projectId: number;
    webhookId: string | null;
    data: { event: string; endpoint: string };
  }) => {
    success: boolean;
    data: {
      success: boolean;
      error: string;
    };
  };
  editData: { id: string | null; event: string; endpoint: string };
  setEditData: (payload: {
    id: string | null;
    event: string;
    endpoint: string;
  }) => void;
}

interface WebhookPayload {
  teamId: number;
  data: {
    event: string;
    endpoint: string;
  };
}

const CreateWebhook: React.FC<Props> = ({
  open,
  setOpenCreateModal,
  createWebhook,
  selectedProject,
  isEdit,
  setIsEdit,
  editWebhook,
  editData,
  setEditData,
}) => {
  const { t } = useTranslation();
  const [webhookName, setWebhookName] = React.useState(
    isEdit ? editData.endpoint : ''
  );
  const [webhookType, setWebhookType] = React.useState(
    isEdit ? editData.event : 'ticket.created'
  );
  const [error, setError] = React.useState<string | null>(null);

  const handleWebhook = async () => {
    if (isEdit) {
      const editDataPaylaod = {
        projectId: selectedProject.id,
        webhookId: editData.id,
        data: {
          event: webhookType,
          endpoint: webhookName,
        },
      };

      const res = await editWebhook(editDataPaylaod);

      if (res.success) {
        toaster.success(t('Webhook edited successfully'));
        setOpenCreateModal(false);
        setEditData({ id: null, endpoint: '', event: 'ticket.created' });
        setIsEdit(false);
      } else {
        setError(res.data.error);
      }
    } else {
      const payload = {
        teamId: selectedProject.id,
        data: {
          event: webhookType,
          endpoint: webhookName,
        },
      };

      const res = await createWebhook(payload);

      if (res.success) {
        toaster.success(t('Webhook added successfully.'));
        setOpenCreateModal(false);
      } else {
        setError(res.data.error);
      }
    }
  };

  const disableHandle = () => {
    if (isEdit) {
      return editData.endpoint !== webhookName || editData.event !== webhookType
        ? false
        : true;
    } else {
      return webhookName.length < 1 ? true : false;
    }
  };

  const handleClose = () => {
    setOpenCreateModal(false);
    setIsEdit(false);
    setEditData({ id: null, endpoint: '', event: 'ticket.created' });
    setError(null);
  };

  React.useEffect(() => {
    if (isEdit) {
      setWebhookName(editData.endpoint);
      setWebhookType(editData.event);
    } else {
      setWebhookName('');
      setWebhookType('ticket.created');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      open={open}
      title={t(isEdit ? 'Edit Webhook' : 'Create Webhook')}
      description=' '
      height='200px'
      width='w-[450px]'
      confirmText={t('Save')}
      cancelText={t('Cancel')}
      disableConfirm={disableHandle()}
      isConfirmButtonLoading={false}
      handleClose={() => handleClose()}
      handleConfirm={() => handleWebhook()}
      handleCancel={() => handleClose()}
    >
      <div className='p-4'>
        <div className='col-span-6 sm:col-span-3'>
          <label
            htmlFor='type'
            className='block text-sm rtl:text-right font-medium text-gray-700'
          >
            {t('Webhook Type')}
          </label>
          <select
            name='type'
            className='mt-1 rtl:text-left block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm'
            value={webhookType}
            onChange={(e) => {
              setWebhookType(e.target.value);
              setError(null);
            }}
          >
            <option
              selected={webhookType === 'ticket.created'}
              value='ticket.created'
            >
              {t('Ticket Created')}
            </option>
            <option
              selected={webhookType === 'ticket.resolved'}
              value='ticket.resolved'
            >
              {t('Ticket Resolved')}
            </option>
            <option
              selected={webhookType === 'ticket.tagged'}
              value='ticket.tagged'
            >
              {t('Ticket Tagged')}
            </option>
            <option
              selected={webhookType === 'ticket.reopened'}
              value='ticket.reopened'
            >
              {t('Ticket Reopened')}
            </option>
            <option
              selected={webhookType === 'customer.created'}
              value='customer.created'
            >
              {t('Customer Created')}
            </option>
            <option
              selected={webhookType === 'message.failed'}
              value='message.failed'
            >
              {t('Message Failed')}
            </option>
            <option
              selected={webhookType === 'message.sent'}
              value='message.sent'
            >
              {t('Message Sent')}
            </option>
            <option
              selected={webhookType === 'message.delivered'}
              value='message.delivered'
            >
              {t('Message Delivered')}
            </option>
            <option
              selected={webhookType === 'message.read'}
              value='message.read'
            >
              {t('Message Read')}
            </option>
          </select>
        </div>
        <div className='mt-4 rounded-md shadow-sm mb-2'>
          <label
            htmlFor='url'
            className='block rtl:text-right text-sm font-medium text-gray-700'
          >
            {t('Webhook URL')}
          </label>
          <div className='mt-1 flex rounded-md shadow-sm'>
            <input
              type='url'
              name='url'
              className={`block w-full rounded-md
              text-gray-500 placeholder-gray-400
              focus:outline-none  sm:text-sm ${
                error
                  ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                  : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
              }`}
              placeholder={t('Enter the webhook url')}
              value={webhookName}
              onChange={(e) => {
                setWebhookName(e.target.value);
                setError(null);
              }}
            />
          </div>
        </div>
        <p className='text-red-500 text-[14px] font-[400]'>{error && error}</p>
      </div>
    </Modal>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  createWebhook: (payload: WebhookPayload) =>
    dispatch.settings.createWebhook(payload),
  editWebhook: (payload: {
    projectId: number;
    webhookId: string | null;
    data: { endpoint: string; event: string };
  }) => dispatch.settings.editWebhook(payload),
});

export default connect(mapState, mapDispatch)(CreateWebhook);
