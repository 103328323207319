import React from 'react';
import {
  useSLA,
  SlaTable,
  EmptyPage,
  SLACreation,
  Loader2Icon,
  useSLAConfig,
} from './export';

const SLASettings = () => {
  const { clearSLAData } = useSLAConfig();
  const {
    slaList,
    getSlaList,
    isSLAFetchingListLoading,
    handleSLAConfigurationClick,
  } = useSLA();

  React.useEffect(() => {
    const fetchSLAList = async () => await getSlaList();
    clearSLAData();
    fetchSLAList();
    // eslint-disable-next-line
  }, []);

  // This is a hacky solution to override old component CSS.
  // It directly modifies the padding of the '.layout__content' element.
  // When we move the settings to the new design, we will update it accordingly.
  // Please avoid making this a habit, as direct DOM manipulation can lead to maintenance issues.
  React.useEffect(() => {
    const layoutContent: any = document.querySelector('.layout__content');
    if (layoutContent) {
      layoutContent.style.padding = '0';
    }
  }, []);

  return (
    <div className='p-6'>
      {isSLAFetchingListLoading && <Loading />}
      {!isSLAFetchingListLoading && (
        <>
          <SLAHeader />
          <div className='h-[84vh]'>
            {slaList.length === 0 ? (
              <NoSLAComponent />
            ) : (
              <SlaList
                slaList={slaList}
                handleSLAConfigurationClick={handleSLAConfigurationClick}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

const SLAHeader = () => {
  return (
    <>
      <h2 className='text-textPrimary'>SLA Configurations</h2>
      <p className='w-[500px] subtitle-medium mt-[6px]'>
        Service Level Agreement (SLA) policies help you set and maintain targets
        for your team's response and resolution times. 
        {/* <a
          href={'https://docs.myalice.ai/'}
          target='_blank'
          className='text-blue-500 hover:text-blue-800'
          rel='noreferrer'
        >
          Learn more
        </a> */}
      </p>
    </>
  );
};

const NoSLAComponent = () => {
  return (
    <EmptyPage
      title='No SLA Policies Found'
      titleClass={'text-textPrimary'}
      subtitle={`You don't have any SLA policies at the moment. Add a new policy to begin tracking response and resolution times.`}
    >
      <SLACreation />
    </EmptyPage>
  );
};

const SlaList = ({ slaList, handleSLAConfigurationClick }: any) => {
  return (
    <>
      <SlaTable />
    </>
  );
};

const Loading = () => {
  return (
    <div className='w-full flex justify-center items-center h-[90vh]'>
      <Loader2Icon size={48} className='mb-8 text-green-500 animate-spin' />
    </div>
  );
};

export default SLASettings;
