import React, { useState } from 'react';
import Linkify from 'react-linkify';

import useMessageBlocksAction from 'pages/inbox/hooks/useMessageBlocksAction';
import {
  CommentStatusPayloadInterface,
  ConversationBlockAttachmentDataInterface,
  ConversationInterface,
  ConversationParentCommentInterface,
  ConversationRepliesInterface,
  conversationStatusType,
  CustomerReplyBlockInterface,
} from 'pages/inbox/inboxInterface';
import defaultMediaImage from '../../../assets/images/defaultMediaImage.svg';
import intialQuery from '../../../assets/images/intialQuery.svg';
import PlayCircle from '../../../assets/images/playCircle.svg';
import Avatar from '../components/conversation/Avatar';
import BlockActions from '../components/conversation/BlockActions';
import ConversationBlockWrapper from '../components/conversation/ConversationBlockWrapper';
import FeedCommentStatus from '../components/conversation/FeedCommentStatus';
import MediaViewer from '../components/conversation/MediaViewer';
import MessageStatus from '../components/conversation/MessageStatus';
import Time from '../components/conversation/Time';
import AdminReplyToButton from './AdminReplyToButton';
import CustomerReply from './CustomerReply';
import DateBar from './DateBar';

import { TooltipProviderCustomised } from '../../../../../libraryV2/ui/tooltip';

interface Props {
  blockData: ConversationBlockAttachmentDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  isSent: conversationStatusType | string;
  isMergeable: boolean;
  enableDateBar: boolean;
  platformType: string;
  report?: any; // TODO: format report
  // Reply To Props
  hasPermissionToShowAdminReply?: boolean;
  rawBlockData?:
    | ConversationInterface
    | ConversationRepliesInterface
    | ConversationParentCommentInterface;
  handleConversationDataForAdminReply?: (
    blockData: CustomerReplyBlockInterface
  ) => void;
  // Feed Props
  isReplyComment?: boolean;
  isParentComment?: boolean;
  isHighlighted?: boolean;
  commentType?: string;
  commentId?: string;
  status?: string;
  updateFeedCommentStatus?: (payload: CommentStatusPayloadInterface) => void;
}

const MediaAttachment: React.FC<Props> = ({
  blockData,
  avatar,
  name,
  time,
  source,
  isSent,
  isMergeable,
  enableDateBar,
  report,
  // Reply To Props
  hasPermissionToShowAdminReply = false,
  handleConversationDataForAdminReply = () => null,
  // Feed Props
  isReplyComment = false,
  isParentComment = false,
  isHighlighted = false,
  commentType = '',
  commentId = '',
  status = '',
  rawBlockData,
  updateFeedCommentStatus,
  platformType,
}) => {
  const [selectedMedia, setSelectedMedia] = useState<string | null>(null);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<number>(0);

  const [showReplyToButton, setShowReplyToButton] = React.useState(false);

  const { isAdminReply, elementDirection, rowDirection, isZigzagView } =
    useMessageBlocksAction({ source });
  const isFeedChannels = ['facebook_feed', 'instagram_feed'].includes(
    platformType
  );

  const getSourceNameColor = () => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
        return 'text-gray-900';
      case 'echo':
        return 'text-gray-900';
      default:
        return 'text-green-500';
    }
  };

  const getTextMessageColor = (): string => {
    return status === 'hide' || status === 'remove'
      ? 'text-gray-400'
      : !!blockData.success
      ? isAdminReply && source !== 'bot'
        ? 'text-white'
        : 'text-gray-900'
      : 'text-gray-500';
  };

  const renderMediaTextView = () => {
    return (
      <div
        className={`w-auto h-auto ${getTextMessageColor()}
        sm:text-sm break-words whitespace-pre-wrap`}
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              target='blank'
              className='underline'
              href={decoratedHref}
              key={key}
            >
              {decoratedText}
            </a>
          )}
        >
          {blockData?.success ? blockData?.text : blockData?.error}
        </Linkify>
      </div>
    );
  };

  const handleImageClick = (url: any, index: number) => {
    setSelectedMedia(url);
    setSelectedMediaIndex(index);
  };

  const getImageBlocks = () => {
    return (
      <div
        className={`grid gap-1 relative ${
          blockData?.urls.length === 1 ? 'grid-cols-1' : 'grid-cols-4'
        }`}
      >
        {blockData?.urls?.map((url, index) => (
          <>
            {/* Overlay */}
            {blockData.urls.length === 1 && (
              <div
                aria-hidden='true'
                aria-label='image overlay'
                className='bg-gradient-to-t overflow-hidden w-full h-full to-transparent rounded-xl pointer-events-none from-black/40 absolute inset-0 z-[7]'
              />
            )}

            <img
              key={index}
              data-testid='media-image-block'
              className={`cursor-pointer relative z-[6] object-cover rounded-xl ${
                blockData?.urls.length === 1
                  ? 'h-[120px] w-[120px]'
                  : 'h-[70px] w-[70px]'
              }`}
              src={url}
              alt='media'
              onClick={() => {
                handleImageClick(url, index);
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = defaultMediaImage;
              }}
            />
          </>
        ))}
      </div>
    );
  };

  const getVideoBlocks = () => {
    return (
      <div
        className={`grid gap-1 relative ${
          blockData?.urls.length === 1 ? 'grid-cols-1' : 'grid-cols-4'
        }`}
      >
        {blockData?.urls?.map((url, index) => (
          <>
            {/* Overlay */}
            {blockData.urls.length === 1 && (
              <div
                aria-hidden='true'
                aria-label='image overlay'
                className='bg-gradient-to-t overflow-hidden w-full h-full to-transparent rounded-xl pointer-events-none from-black/40 absolute inset-0 z-[7]'
              />
            )}
            <div
              key={index}
              className='relative cursor-pointer'
              onClick={() => handleImageClick(url, index)}
            >
              <div
                className={`absolute bg-gray-500 bg-opacity-40 rounded-xl ${
                  blockData.urls.length === 1
                    ? 'h-[120px] w-[120px]'
                    : 'h-[70px] w-[70px]'
                }`}
              />
              <video
                className={`object-fill rounded-xl ${
                  blockData.urls.length === 1
                    ? 'h-[120px] w-[120px]'
                    : 'h-[70px] w-[70px]'
                }`}
              >
                <source data-testid='media-video-block' src={url} />
              </video>
              <img
                className={`absolute top-1/2 z-[9] left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                  blockData.urls.length === 1
                    ? 'w-[25.6px] h-[25.6px]'
                    : 'w-[19.2px] h-[19.2px]'
                }`}
                src={PlayCircle}
                alt='videoIcon'
              />
            </div>
          </>
        ))}
      </div>
    );
  };

  const renderCustomerReply = () => {
    if (!!blockData.reply_to_data) {
      return (
        <CustomerReply
          blockData={blockData?.reply_to_data}
          isZigzagView={isZigzagView}
        />
      );
    } else return <></>;
  };

  const renderMediaContentsForMessangerChannels = () => {
    return (
      <div
        className={`relative group flex w-full h-auto hover:bg-gray-100 py-1 ${rowDirection}`}
      >
        <Avatar
          avatar={avatar}
          isReplyComment={isReplyComment}
          isParentComment={isParentComment}
        />
        <div className={`w-[80%]`}>
          <div className={`flex items-center gap-2 ${rowDirection}`}>
            <div
              className={`my-2 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()} ${rowDirection}`}
            >
              {!!blockData?.permalink_url ? (
                <a
                  className='hover:underline'
                  target='_blank'
                  href={blockData?.permalink_url}
                  rel='noreferrer noopener'
                >
                  {name}
                </a>
              ) : (
                name
              )}
            </div>
            {!!isHighlighted && (
              <div className='flex content-center w-auto h-auto mt-2 ml-2 group group-hover:visible'>
                <TooltipProviderCustomised content={'Initial Query'}>
                  <img
                    src={intialQuery}
                    data-for='query'
                    data-tip='Initial Query'
                    alt='query'
                  />
                </TooltipProviderCustomised>
              </div>
            )}
          </div>
          {/* Media Content */}
          <div className={`flex ${rowDirection}`}>
            <ConversationBlockWrapper
              time={time}
              success={isSent}
              isMerged={false}
              report={report}
              source={source}
              isFirstElement={!isMergeable}
              conversationType={blockData?.type}
            >
              {renderCustomerReply()}
              {renderMediaTextView()}
              <div className='flex gap-2'>
                {blockData?.sub_type === 'image' && getImageBlocks()}
                {blockData?.sub_type === 'video' && getVideoBlocks()}
              </div>
            </ConversationBlockWrapper>
          </div>
        </div>
      </div>
    );
  };

  const renderMediaContentsForFeedChannels = () => {
    return (
      <div
        className={`relative group flex w-full h-auto hover:bg-gray-100 ${
          !!isReplyComment
            ? 'ltr:pl-11 rtl:pr-11 pt-1 pb-2'
            : `${rowDirection} py-1`
        }`}
      >
        {(!!isParentComment || !!isReplyComment) && (
          <BlockActions
            source={source}
            commentId={commentId}
            isReplyComment={isReplyComment}
            isParentComment={isParentComment}
            commentType={commentType}
            commentStatus={status}
            updateFeedCommentStatus={updateFeedCommentStatus}
          />
        )}
        <Avatar
          avatar={avatar}
          isReplyComment={isReplyComment}
          isParentComment={isParentComment}
        />
        <div className={`w-[80%]`}>
          <div className={`flex items-center gap-2 ${rowDirection}`}>
            <div
              className={`my-2 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()} ${rowDirection}`}
            >
              {!!blockData?.permalink_url ? (
                <a
                  className='hover:underline'
                  target='_blank'
                  href={blockData?.permalink_url}
                  rel='noreferrer noopener'
                >
                  {name}
                </a>
              ) : (
                name
              )}
            </div>
            <Time
              time={time}
              isMerged={false}
              isFeedComment={
                !!isParentComment || !!isReplyComment ? true : false
              }
            />
            {(!!isParentComment || !!isReplyComment) &&
              commentType !== 'message' && (
                <FeedCommentStatus
                  commentType={commentType}
                  commentStatus={status}
                />
              )}
            {['admin', 'bot', 'echo'].includes(source) && (
              <MessageStatus
                success={isSent}
                isMerged={false}
                report={report}
              />
            )}
            {!!isHighlighted && (
              <div className='flex content-center w-auto h-auto mt-2 ml-2 group group-hover:visible'>
                <TooltipProviderCustomised content={'Initial Query'}>
                  <img
                    src={intialQuery}
                    data-for='query'
                    data-tip='Initial Query'
                    alt='query'
                  />
                </TooltipProviderCustomised>
              </div>
            )}
          </div>
          {/* Media Content */}
          <ConversationBlockWrapper
            time={time}
            success={isSent}
            isMerged={false}
            report={report}
            source={source}
            isFirstElement={!isMergeable}
            conversationType={blockData?.type}
          >
            {renderCustomerReply()}
            {renderMediaTextView()}
            <div className='flex gap-2'>
              {blockData?.sub_type === 'image' && getImageBlocks()}
              {blockData?.sub_type === 'video' && getVideoBlocks()}
            </div>
          </ConversationBlockWrapper>

          {(!!isParentComment || !!isReplyComment) &&
            commentType === 'message' && (
              <FeedCommentStatus
                commentType={commentType}
                commentStatus={status}
              />
            )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className='relative'
        onMouseEnter={() => setShowReplyToButton(true)}
        onMouseLeave={() => setShowReplyToButton(false)}
      >
        {!isMergeable && (
          <>
            {isFeedChannels
              ? renderMediaContentsForFeedChannels()
              : renderMediaContentsForMessangerChannels()}
          </>
        )}
        {!!isMergeable && (
          <div
            className={`relative flex w-full px-16 h-auto py-1 group hover:bg-gray-100 ${elementDirection}`}
          >
            {/* Media Content */}
            <div>
              <ConversationBlockWrapper
                time={time}
                success={isSent}
                isMerged={false}
                report={report}
                source={source}
                isFirstElement={!isMergeable}
                conversationType={blockData?.type}
              >
                <div className='w-full flex flex-col'>
                  {renderCustomerReply()}
                  {renderMediaTextView()}
                  <div className='flex gap-2'>
                    {blockData?.sub_type === 'image' && getImageBlocks()}
                    {blockData?.sub_type === 'video' && getVideoBlocks()}
                  </div>
                </div>
              </ConversationBlockWrapper>
            </div>
          </div>
        )}

        {showReplyToButton && hasPermissionToShowAdminReply && (
          <AdminReplyToButton
            source={source}
            handleConversationDataForAdminReplyOnClick={() =>
              handleConversationDataForAdminReply({
                type: rawBlockData?.dataV2?.type,
                success: rawBlockData?.dataV2?.success,
                error: rawBlockData?.dataV2?.error,
                sub_type: rawBlockData?.dataV2?.sub_type,
                text: '',
                urls: rawBlockData?.dataV2?.urls || [],
                payload: rawBlockData?.conversation_id,
              })
            }
          />
        )}
        {!!selectedMedia && (
          <MediaViewer
            mediaType={blockData.sub_type}
            urls={blockData.urls}
            selectedMedia={selectedMedia}
            selectedMediaIndex={selectedMediaIndex}
            setSelectedMedia={setSelectedMedia}
            setSelectedMediaIndex={setSelectedMediaIndex}
          />
        )}
      </div>
      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default MediaAttachment;
