import { SelectedTeamInterface } from 'index';
import { Modal } from 'library';
import TextVariableInput from 'pages/integration/components/common/components/TextVariableInput';
import React from 'react';
import { connect } from 'react-redux';
import DropDown from './Dropdown';
// import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { toaster } from 'evergreen-ui';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  open: boolean;
  selectedProject: SelectedTeamInterface;
  isEdit: boolean;
  isAgent: boolean;
  editData: CannedResponseInterface;
  setIsEdit: (payload: boolean) => void;
  setOpenCreateModal: (payload: boolean) => void;
  createCannedResponse: (payload: {
    projectId: number;
    data: CannedresponsePayloadType | null;
  }) => {
    success: boolean;
    data: {
      success: boolean;
      dataSource: CannedResponseInterface;
      error: string;
    };
  };
  editCannedResponse: (payload: {
    projectId: number;
    cannedResponseId: number;
    data: CannedresponsePayloadType | null;
  }) => {
    success: boolean;
    data: {
      success: boolean;
      dataSource: CannedResponseInterface;
      error: string;
    };
  };
  getTaglist: (projectId: number) => void;
  setEditData: (payload: CannedResponseInterface) => void;
}

const CreateResponse: React.FC<Props> = ({
  open,
  selectedProject,
  isEdit,
  isAgent,
  editData,
  setIsEdit,
  setEditData,
  setOpenCreateModal,
  createCannedResponse,
  editCannedResponse,
  getTaglist,
}) => {
  const { t } = useTranslation();
  const [responseData, setResponseData] =
    React.useState<CannedresponsePayloadType>(
      isEdit
        ? {
            title: editData.title,
            text: editData.text,
            for_team: editData.for_team,
            tag_ids: editData.tags.map((item) => {
              return item.id;
            }),
          }
        : {
            title: '',
            text: '',
            for_team: false,
            tag_ids: [],
          }
    );
  const [error, setError] = React.useState<string | null>(null);
  const [showAttribute, setShowAttribute] = React.useState(false);
  const [showHashBeforeInput, setShowHashBeforeInput] = React.useState<boolean>(
    !!responseData?.title
  );
  // const [filterTags, setFilterTags] = React.useState<TagInterface[] | null>(
  //   null
  // );

  const handleResponse = async () => {
    if (isEdit) {
      const editDataPaylaod = {
        projectId: selectedProject.id,
        cannedResponseId: editData.id,
        data: responseData,
      };

      const res = await editCannedResponse(editDataPaylaod);

      if (res.success) {
        toaster.success(t('Reply saved successfully'));
        setOpenCreateModal(false);
        setEditData({
          id: 0,
          admin: 0,
          project: { id: 0, name: '' },
          title: '',
          text: '',
          for_team: false,
          tags: [],
        });
        setIsEdit(false);
      } else {
        res.data.error === "Can't update with duplicate title."
          ? setError(t('A duplicate keyword already exists.'))
          : setError(res.data.error);
      }
    } else {
      const payload = {
        projectId: selectedProject.id,
        data: responseData,
      };
      const res = await createCannedResponse(payload);

      if (res.success) {
        toaster.success('Reply saved successfully');
        handleClose();
      } else {
        res.data.error === "Can't create duplicate."
          ? setError('A duplicate keyword already exists.')
          : setError(res.data.error);
      }
    }
  };

  const disableHandle = () => {
    if (isEdit) {
      return (
        (responseData?.title === editData.title &&
          responseData?.text === editData?.text &&
          responseData?.for_team === editData.for_team) ||
        responseData?.title === '' ||
        responseData?.text === ''
      );
    } else {
      return responseData?.title === '' || responseData?.text === '';
    }
  };

  const handleClose = () => {
    setOpenCreateModal(false);
    setIsEdit(false);
    setEditData({
      id: 0,
      admin: 0,
      project: { id: 0, name: '' },
      title: '',
      text: '',
      for_team: false,
      tags: [],
    });
    setError(null);
  };

  React.useEffect(() => {
    if (isEdit) {
      setResponseData({
        title: editData.title,
        text: editData.text,
        for_team: editData.for_team,
        tag_ids: editData.tags.map((item) => {
          return item.id;
        }),
      });
      setShowHashBeforeInput(true);
    } else {
      setResponseData({
        title: '',
        text: '',
        for_team: false,
        tag_ids: [],
      });
      setShowHashBeforeInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    getTaglist(selectedProject.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={open}
      title={t('Save a New Reply')}
      description=' '
      height='h-[700px]'
      width='w-[700px]'
      confirmText={t('Save')}
      disableConfirm={disableHandle()}
      isConfirmButtonLoading={false}
      cancelText={t('Cancel')}
      handleClose={() => handleClose()}
      handleConfirm={() => handleResponse()}
      handleCancel={() => handleClose()}
    >
      <div className='p-4 rtl:text-right'>
        <label
          htmlFor=''
          className='text-[#374151] rtl:text-right text-[14px] font-medium'
        >
          {t('Keyword')}*
        </label>
        <div className='relative mt-1 rounded-md mb-[16px]'>
          <input
            type='text'
            maxLength={180}
            name='tag'
            id='tag'
            autoComplete='off'
            className={`block w-full rounded-md pr-[4.5rem] ${
              showHashBeforeInput ? 'pl-[24px]' : ''
            }
             text-gray-900 placeholder-gray-400 text-sm
            focus:outline-none rtl:text-right  sm:text-sm ${
              error
                ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
            }`}
            placeholder={t('# Choose a keyword')}
            value={responseData?.title || ''}
            onChange={(e) => {
              // @ts-ignore
              setResponseData({ ...responseData, title: e.target.value });
              setError(null);

              e.target.value === ''
                ? setShowHashBeforeInput(false)
                : setShowHashBeforeInput(true);
            }}
          />
          <span
            className={`${
              showHashBeforeInput ? 'visible' : 'invisible'
            } pl-[13px] rtl:left-0 text-sm absolute top-[11px] text-gray-900`}
          >
            #{' '}
          </span>
          <div
            className='text-green-800 bg-green-100 pointer-events-none
          absolute top-2 right-2 flex items-center px-2 py-1 rounded-md'
          >
            {responseData?.title?.length || 0}/180
          </div>
          <p className='text-red-500 text-[14px] font-[400]'>
            {error && error}
          </p>
        </div>
        <label
          htmlFor=''
          className='text-[#374151] rtl:text-right text-[14px] font-medium'
        >
          {t('Your saved reply')}*
        </label>
        <TextVariableInput
          id='canned'
          disabled={false}
          value={responseData?.text ? responseData.text : ''}
          type={'text'}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          placeHolder={t('Write your content here...')}
          classForCannedResponse={'text-gray-900 text-sm'}
          handleOnChange={(value: string) => {
            // @ts-ignore
            setResponseData({ ...responseData, text: value });
          }}
          setValue={(value) => {
            // @ts-ignore
            setResponseData({ ...responseData, text: value });
          }}
          hasWriteAccess={true}
          enableAttribute={false}
          enableEmoji={true}
          textLimit={2000}
        />
        <div className={`mt-[16px] mb-[20px]`}>
          <div className='flex align-center'>
            <label
              htmlFor=''
              className='text-[#374151] rtl:text-right text-[14px] font-medium mr-1.5'
            >
              {t('Visible to')}
            </label>

            <div className='relative group'>
              <div className='invisible group-hover:visible '>
                <span className='absolute ltr:left-[-10px] rtl:right-[-10px] bottom-[23px] w-[255px] text-xs text-white bg-gray-600 px-2 py-1 rounded-[3px]'>
                  {t('Only Admins can save replies for everone.')}
                </span>
                <span className='absolute bottom-[17px] w-[0px] h-[0px] border-l-[7px] border-r-[7px] border-t-[10px] border-l-transparent border-r-transparent border-t-gray-600'></span>
              </div>

              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-3.5 h-3.5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
                />
              </svg>
            </div>
          </div>
          {!isAgent && (
            <DropDown
              setResponseData={setResponseData}
              responseData={responseData}
            />
          )}
          {isAgent && (
            <div className='flex align-center px-[14px] py-[9px] h-[38px] border border-solid border-[#D1D5DB] bg-[#E5E7EB] rounded-[6px]'>
              <p className='text-sm font-normal text-[#6B7180]'>
                {t('Only me')}
              </p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  createCannedResponse: (payload: {
    projectId: number;
    data: CannedresponsePayloadType | null;
  }) => dispatch.settings.createCannedResponse(payload),
  editCannedResponse: (payload: {
    projectId: number;
    data: CannedresponsePayloadType | null;
  }) => dispatch.settings.editCannedResponse(payload),
  getTaglist: (projectId: number) => dispatch.settings.fetchTags(projectId),
});

export default connect(mapState, mapDispatch)(CreateResponse);
