import {
  IEcomProductVariation,
  IEcommerceProductData,
  IEcommerceProductDataForUI,
  IVariation,
} from 'pages/inbox/inboxInterface';

import defaultProductIcon from '../../../assets/images/defaultProductIcon.svg';
import { TrashIcon } from '@heroicons/react/24/outline';
import VariationView from './VariationView';
import { useEffect, useState } from 'react';

interface Props {
  ecommerce_type: string;
  currencySymbol: string;
  product: IEcommerceProductDataForUI;
  onRemoveProduct: (productId: number | string) => Promise<void>;
  onUpdate: (product: IEcommerceProductDataForUI) => Promise<void>;
  getVariations: (producId: number | string) => Promise<IVariation[]>;
  getProduct: (product_id: string | number) => IEcommerceProductData | null;
}

const SingleItemView: React.FC<Props> = ({
  onRemoveProduct,
  currencySymbol,
  ecommerce_type,
  getVariations,
  getProduct,
  onUpdate,
  product,
}) => {
  const [variations, setVariations] = useState<IVariation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const getVarationsOnLoad = async () => {
    if (product?.has_variations) {
      setLoading(true);
      const variants = await getVariations(product?.product_id);
      setVariations([...variants]);
      setLoading(false);
    }
  };
  useEffect(() => {
    getVarationsOnLoad();
    //eslint-disable-next-line
  }, []);
  const getTotalPrice = () => {
    if (!product.has_variations)
      return (product?.quantity * product?.unit_price).toFixed(2);

    return product?.variations
      ?.reduce(
        (sum, currentValue) =>
          sum + currentValue.unit_price * currentValue.quantity,
        0
      )
      .toFixed(2);
  };

  const getProductStockCount = () => {
    const productData = getProduct(product?.product_id);
    return productData !== null ? productData?.stock_count : 1;
  };

  const getStockCount = (variationId: string) => {
    if (!product.has_variations) {
      return getProductStockCount();
    } else {
      if (!variations || variations.length === 0) return 1;
      const filteredVariants = variations.filter((data) =>
        typeof data.variant_id === 'number'
          ? data.variant_id === Number(variationId)
          : data.variant_id === variationId
      );
      return filteredVariants[0]?.stock_count || 1;
    }
  };

  const onVariantSelect = async (variant: IVariation, index: number) => {
    if (variant.stock_count < 1) return;
    if (!!product.variations)
      product.variations[index] = {
        quantity: 1,
        unit_price: variant.unit_price,
        variant_id: variant.variant_id,
        variant_attributes: variant.attribute_list,
        variant_attributes_string: variant.attribute_string,
      };

    await onUpdate(product);
  };

  const variantQuantityUpdate = async (
    variant: IEcomProductVariation,
    amount: number,
    index: number
  ) => {
    const updatedQuantity = variant.quantity + amount;
    const stock = await getStockCount(variant.variant_id);
    if (
      updatedQuantity > 0 &&
      updatedQuantity <= stock &&
      !!product.variations
    ) {
      product.variations[index].quantity = updatedQuantity;
      onUpdate(product);
    }
  };

  const quantityUpdate = (amount: number) => {
    const updatedQuantity = product.quantity + amount;
    if (updatedQuantity > 0 && updatedQuantity <= getProductStockCount()) {
      product.quantity = updatedQuantity;
      onUpdate(product);
    }
  };

  const updateQuantityByAmount = (amount: number) => {
    const updatedQuantity = amount;
    if (updatedQuantity > 0 && updatedQuantity <= getProductStockCount()) {
      product.quantity = updatedQuantity;
      onUpdate(product);
    }
  };

  const updateVariantQuantityByAmount = async (
    variant: IEcomProductVariation,
    amount: number,
    index: number
  ) => {
    const stock = await getStockCount(variant.variant_id);
    if (amount > 0 && amount <= stock && !!product.variations) {
      product.variations[index].quantity = amount;
      onUpdate(product);
    }
  };

  const variantsToSelect = (variantId: string) => {
    if (!product?.has_variations) return [];
    let selectedVariantIds: string[] = [];
    product.variations?.forEach((data) => {
      if (variantId !== data.variant_id)
        selectedVariantIds.push(data.variant_id);
    });
    let remainingVariations = variations.filter(
      (variant: IVariation) => !selectedVariantIds.includes(variant.variant_id)
    );
    return remainingVariations || [];
  };

  const addNewVariantField = () => {
    product?.variations?.push({
      quantity: 0,
      unit_price: 0,
      variant_id: '',
      variant_attributes: [],
      variant_attributes_string: '',
    });
    onUpdate(product);
  };

  const removeVariantField = (index: number) => {
    product?.variations?.splice(index, 1);
    onUpdate(product);
  };

  const renderVariationView = () => {
    if (!product?.has_variations) {
      return (
        <VariationView
          hasVariations={false}
          index={-1}
          totalItem={1}
          ecommerce_type={ecommerce_type}
          variations={variantsToSelect('-1')}
          stockCount={getStockCount}
          quantity={product?.quantity}
          updateQuantity={quantityUpdate}
          onVariationSelect={onVariantSelect}
          updateQuantityByAmount={updateQuantityByAmount}
        />
      );
    } else {
      return (
        !!product.variations &&
        product?.variations.map(
          (variant: IEcomProductVariation, index: number) => {
            return (
              <VariationView
                key={variant?.variant_id ?? index}
                hasVariations={true}
                index={index}
                ecommerce_type={ecommerce_type}
                totalItem={product.variations?.length}
                variations={variantsToSelect(variant.variant_id)}
                selectedVariant={variant.variant_id}
                stockCount={getStockCount}
                quantity={variant?.quantity}
                variantString={variant?.variant_attributes_string}
                onVariationSelect={onVariantSelect}
                updateQuantity={(amount: number) => {
                  variantQuantityUpdate(variant, amount, index);
                }}
                removeVariant={removeVariantField}
                addAnotherVariant={addNewVariantField}
                updateQuantityByAmount={(amount: number) => {
                  updateVariantQuantityByAmount(variant, amount, index);
                }}
              />
            );
          }
        )
      );
    }
  };

  const renderLoaingView = () => {
    return (
      <div className='animate-pulse flex space-x-4 w-96'>
        <div className='rounded-full bg-gray-200 h-10 w-10'></div>
        <div className='flex-1 space-y-6 py-1'>
          <div className='h-2 bg-gray-200 rounded'></div>
          <div className='space-y-3'>
            <div className='grid grid-cols-3 gap-4'>
              <div className='h-2 bg-gray-200 rounded col-span-2'></div>
              <div className='h-2 bg-gray-200 rounded col-span-1'></div>
            </div>
            <div className='h-2 bg-gray-200 rounded'></div>
          </div>
        </div>
      </div>
    );
  };

  const getLoadingView = () => {
    return (
      <span className='ml-1 w-5 h-5 flex items-center'>
        <div
          className='inline-block w-5 h-5 text-red-500 border-2 rounded-full spinner-border animate-spin'
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      </span>
    );
  };

  const renderProductView = () => {
    return (
      <>
        <div className='flex items-center w-full'>
          <img
            src={
              product?.product_images && product?.product_images.length > 0
                ? product?.product_images[0]
                : defaultProductIcon
            }
            className=' w-9 h-9 rounded-[3px] '
            alt='product_img'
          />
          <div className=' ltr:ml-2 rtl:mr-2'>
            <p className=' text-sm font-normal text-gray-700  '>
              {!!product.product_name && product?.product_name?.length > 50
                ? product?.product_name?.substring(0, 50) + '...'
                : product?.product_name}
            </p>
            <p className=' text-xs text-gray-500 font-normal '>
              {currencySymbol}
              {getTotalPrice()}
            </p>
          </div>
          {!deleteLoading && (
            <TrashIcon
              className='ltr:ml-auto rtl:mr-auto w-5 h-5 cursor-pointer text-[#9CA3AF] hover:text-red-500'
              onClick={async () => {
                setDeleteLoading(true);
                await onRemoveProduct(product.product_id);
                setDeleteLoading(false);
              }}
            />
          )}
          {deleteLoading && (
            <div className='ml-auto inline'>{getLoadingView()}</div>
          )}
        </div>
        {renderVariationView()}
      </>
    );
  };

  const renderMainView = () => {
    return loading ? renderLoaingView() : renderProductView();
  };

  return (
    <div className=' overflow-visible rounded-md p-2 border border-gray-100 bg-white  mb-2'>
      {renderMainView()}
    </div>
  );
};

export default SingleItemView;
