import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';
import {
  CheckIcon,
  useActions,
  useSelector,
  ConditionHeader,
} from 'pages/automationWorkflow/export';
import {
  IRadioGroup,
  IDropdownGroup,
  IChildrenUiScreen,
} from 'pages/automationWorkflow/interface';

const Discount = () => {
  // Extract required action functions from the custom hook
  const { updateStoredData, updateConditionallyRenderingActionsUI } =
    useActions();

  const { selectedSaveActionsData } = useSelector(
    (state: any) => state.workflowAutomation
  );

  // Destructure properties to improve readability
  // Specify the type for timeOfDelay or provide a default value
  const { currentScreen, component } =
    selectedSaveActionsData?.eCommerce?.discount;

  const renderConditionallyDiscountTypeView = (
    discountData: IChildrenUiScreen
  ) => {
    switch (discountData?.type) {
      case 'TextInput':
        return (
          <div className='p-4 bg-white rounded mb-6'>
            <p className='text-gray-600 font-medium text-sm mb-1'>
              {discountData?.label || ''}{' '}
              {discountData?.required && (
                <span className='text-red-400 text-sm'>*</span>
              )}
            </p>
            <input
              value={
                component?.definition?.stored_data?.discount_payload?.[
                  discountData.name
                ] ?? ''
              }
              type={discountData?.['input-type']}
              className='w-full py-2 px-3 shadow-sm border border-gray-300 rounded-md text-sm text-gray-700 focus:ring-primary focus:border-primary focus:outline-none'
              placeholder='Value'
              onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                updateStoredData(discountData.name, value.target.value)
              }
            />
            <p className='text-gray-500 text-sm'>{discountData?.description}</p>
          </div>
        );
      case 'Dropdown':
        return (
          <div className='p-4 bg-white rounded mb-6'>
            <p className='text-gray-600 font-medium text-sm mb-1'>
              {discountData?.label || ''}
              {discountData?.required && (
                <span className='text-red-400 text-sm'>*</span>
              )}
            </p>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className='py-2 text-gray-500 text-sm w-full text-left bg-white border  rounded-md cursor-pointer focus:outline-none'>
                  <span className='px-3 py-1 text-sm font-regular text-gray-500'>
                    {component?.definition?.stored_data?.discount_payload?.[
                      discountData.name
                    ] ?? 'Select Value'}
                  </span>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                side='bottom'
                align='start'
                className='shadow-lg w-60 bg-white z-30 rounded-md p-0'
              >
                {discountData?.data_source?.length &&
                  discountData?.data_source?.map((item: IDropdownGroup) => (
                    <DropdownMenuItem
                      key={item?.id}
                      onClick={() =>
                        updateStoredData(discountData.name, item?.id)
                      }
                      className='flex items-center justify-between cursor-pointer bg-white hover:bg-gray-100 px-3 py-2 mt-1 mb-1'
                    >
                      <div>{item?.title}</div>
                      {component?.definition?.stored_data?.discount_payload?.[
                        discountData.name
                      ] === item?.id && (
                        <div>
                          <CheckIcon className='w-5 h-5 text-green-400' />
                        </div>
                      )}
                    </DropdownMenuItem>
                  ))}
              </DropdownMenuContent>
            </DropdownMenu>
            <p className='text-gray-500 text-sm'>{discountData?.description}</p>
          </div>
        );
      case 'RadioButtonsGroup':
        return (
          <div className='p-4 bg-white rounded mb-6'>
            <p className='text-gray-600 font-medium text-sm mb-1'>
              {discountData?.label || ''}
            </p>
            {discountData?.['data-source']?.length &&
              discountData?.['data-source'].map((item: IRadioGroup) => (
                <div key={item?.id} className='flex my-4'>
                  <input
                    type='radio'
                    id={item?.id}
                    className='text-green-400 without-ring'
                    name={discountData.name}
                    value={item.id}
                    checked={
                      component?.definition?.stored_data?.discount_payload?.[
                        discountData.name
                      ] === item?.id
                    }
                    onChange={() =>
                      updateStoredData(discountData.name, item?.id)
                    }
                  />
                  <div className='ml-3'>
                    <label
                      htmlFor={item?.id}
                      className='block  text-sm font-medium text-gray-800 -mt-1'
                    >
                      {item?.title}
                    </label>

                    <p className='text-gray-500 text-sm'>{item?.description}</p>
                  </div>
                </div>
              ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <ConditionHeader
        title={currentScreen?.title}
        previousUIAction={() =>
          updateConditionallyRenderingActionsUI('actionElement')
        }
      />
      <div
        className='bg-gray-50 p-4 overflow-auto'
        style={{ height: 'calc(100vh - 196px)' }}
      >
        {currentScreen.layout.children[0].children.map(
          (item: IChildrenUiScreen, index: number) => (
            <div key={index}>{renderConditionallyDiscountTypeView(item)}</div>
          )
        )}
      </div>
    </div>
  );
};

export default Discount;
