import React from 'react';
import {
  Select,
  SelectValue,
  SelectGroup,
  SelectItem,
  usePolicy,
  useSLAConfig,
  SelectTrigger,
  SelectContent,
  LOCAL_UTILS,
} from '../../export';

import { IPolicy, Priority } from '../../interface';
import TimeInput from '../common/TimeInput';

const priorityClassMapforBackground: Record<Priority, string> = {
  [Priority.Low]: 'bg-[#04B25F]',
  [Priority.Medium]: 'bg-indigo-500',
  [Priority.High]: 'bg-amber-500',
  [Priority.Urgent]: 'bg-error',
};

const priorityClassMapforText: Record<Priority, string> = {
  [Priority.Low]: 'text-[#04B25F]',
  [Priority.Medium]: 'text-indigo-500',
  [Priority.High]: 'text-amber-500',
  [Priority.Urgent]: 'text-error',
};

const Policy: React.FC<{ policies: IPolicy[] }> = ({ policies = [] }) => {
  const { handlePolicyDataOnChange } = useSLAConfig();
  const {
    inputFieldClass,
    defaultPolicies,
    tableHeaderClass,
    getPriorityLabel,
  } = usePolicy();
  const policyList = policies.length === 0 ? defaultPolicies : policies;

  return (
    <div className='overflow-x-auto'>
      <div className='mb-3'>
        <h5 className='mb-1 text-textPrimary'>Configure SLA Policy</h5>
        <span className='subtitle-medium'>
          Set your response times, operational hours, and escalation
          preferences.
        </span>
      </div>
      <div className='overflow-hidden border rounded-lg border-border-light'>
        <table className='min-w-full bg-white'>
          <thead>
            <tr className='w-full border-b'>
              <th className={`${tableHeaderClass} w-[15%]`}>Priority</th>
              <th className={`${tableHeaderClass} w-[28.33%]`}>
                First response time
              </th>
              <th className={`${tableHeaderClass} w-[28.33%]`}>
                Resolution time
              </th>
              <th className={`${tableHeaderClass} w-[28.33%]`}>
                Operational hours
              </th>
            </tr>
          </thead>
          <tbody>
            {policyList.map((policy: IPolicy, index: number) => (
              <tr
                key={index}
                className={`h-15 ${
                  index !== policyList.length - 1 ? 'border-b' : ''
                }`}
              >
                <td className='px-3 py-3.5 w-[15%]'>
                  <div className='flex items-center px-2 py-[1.5px] space-x-2 border rounded-md border-border-light h-[22px] w-fit'>
                    <span
                      className={`w-3 h-3 rounded-full ${
                        priorityClassMapforBackground[policy.priority]
                      }`}
                    />
                    <span
                      className={`text-xs ${
                        priorityClassMapforText[policy.priority]
                      }`}
                    >
                      {getPriorityLabel(policy.priority)}
                    </span>
                  </div>
                </td>
                <td className='px-3 py-3.5 w-[28.33%]'>
                  <TimeInput
                    initialDisplayValue={policy.first_response_time}
                    onChange={(time: any) => {
                      let FRT = LOCAL_UTILS.convertToTotalMinutes(time);
                      handlePolicyDataOnChange(
                        policy.priority,
                        'first_response_time',
                        FRT
                      );
                    }}
                  />
                </td>
                <td className='px-3 py-3.5 w-[28.33%]'>
                  <TimeInput
                    initialDisplayValue={policy.resolution_time}
                    onChange={(time: any) => {
                      let RT = LOCAL_UTILS.convertToTotalMinutes(time);
                      handlePolicyDataOnChange(
                        policy.priority,
                        'resolution_time',
                        RT
                      );
                    }}
                  />
                </td>
                <td className='px-3 py-3.5 w-[28.33%]'>
                  <Select
                    value={
                      policy.should_consider_business_hour
                        ? 'Custom Business Hours'
                        : '24/7 Business Hours'
                    }
                    onValueChange={(value: string) => {
                      let isCustomeBusinessHours =
                        value === 'Custom Business Hours';
                      handlePolicyDataOnChange(
                        policy.priority,
                        'should_consider_business_hour',
                        isCustomeBusinessHours
                      );
                    }}
                  >
                    <SelectTrigger className={`${inputFieldClass} h-8`}>
                      <SelectValue
                        defaultValue={'Custom Business Hours'}
                        className='placeholder:subtitle-regular'
                        placeholder='Select operational hour'
                      />
                    </SelectTrigger>
                    <SelectContent className='mt-1 bg-white'>
                      <SelectGroup className='text-sm text-textPrimary'>
                        <SelectItem
                          value='Custom Business Hours'
                          defaultChecked={true}
                          className='hover:bg-background-hover'
                        >
                          Custom Business Hours
                        </SelectItem>
                        <SelectItem
                          value='24/7 Business Hours'
                          className='hover:bg-background-hover'
                        >
                          24/7 Business Hours
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Policy;
