import React from 'react';
import dayjs from 'dayjs';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  DataTablePagination,
  DataTableToolbar,
  NoDataFound,
  Button,
  useState,
  Trash,
} from '../../../export';
import { toast } from 'libraryV2/ui/use-toast';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import DeleteAlert from '../DeleteAlert';
import useDatalab from 'pages/datalab/hooks/useDatalab';
import { toInteger } from 'lodash';
import useTranslation from 'components/customHooks/useTranslation';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

export function DataTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const {
    labId,
    pageChange,
    totalRowCount,
    hasDeleteAccess,
    updatePageChange,
    deleteBulkDatalabList,
    updateDatalabPageChange,
  } = useDatalab();

  const { t } = useTranslation();
  const { limit, offset } = pageChange;

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const excludedColumnHeader = [
    'id',
    'lab_id',
    'creator_id',
    'mongo_timestamp',
  ];

  const filteredColumns = columns.filter(
    //@ts-ignore
    (column) => !excludedColumnHeader.includes(column.accessorKey)
  );

  const table = useReactTable({
    data,
    columns: filteredColumns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    manualPagination: true,
    pageCount: Math.ceil(totalRowCount / limit),
  });

  const selectedRows = React.useMemo(() => {
    //@ts-ignore
    return table.getRowModel().rows.filter((row) => rowSelection[row.id]);
  }, [rowSelection, table]);

  const openAlert = () => setIsAlertOpen(true);
  const closeAlert = () => setIsAlertOpen(false);

  const handleBulkDeleteDataLabEntries = async () => {
    try {
      const listOfRowIds = selectedRows.map((row: any) => row.original.id);
      await deleteBulkDatalabList(labId, listOfRowIds);
      resetRowSelection();
      toast({
        title: t('Delete Successful!'),
        description: `${selectedRowCount} ${t(
          'entries have been deleted successfully.'
        )}`,
      });
    } catch (error) {
      toast({
        title: t('Error'),
        description: t('There was an error deleting the entries.'),
      });
    }
  };

  const selectedRowCount = selectedRows.length;

  const resetRowSelection = () => {
    setRowSelection({});
  };

  const handleLimitChange = async (newLimit: number) => {
    updatePageChange(newLimit, offset);
    updateDatalabPageChange(newLimit, offset);
  };

  const handlePageChange = (newOffset: number) => {
    updatePageChange(limit, newOffset);
    updateDatalabPageChange(limit, newOffset);
  };

  const renderDeleteButton = () => {
    if (selectedRows?.length <= 0) {
      return null;
    }
    return (
      <div className='flex justify-end w-full m-1'>
        <Button
          className={`flex gap-1 m-1 text-white bg-red-500 ${
            !hasDeleteAccess
              ? 'cursor-not-allowed bg-gray-200 text-gray-500'
              : ''
          }`}
          variant='outline'
          disabled={!hasDeleteAccess}
          onClick={openAlert}
        >
          <Trash size={16} /> {t('Delete')}
        </Button>
        <DeleteAlert
          open={isAlertOpen}
          onOpenChange={setIsAlertOpen}
          title={`${t('Delete')} ${selectedRowCount} ${t('Entries')}`}
          description={`${t('This will delete')} ${selectedRowCount} ${t(
            'entries that you have selected. Are you sure you want to delete?'
          )}`}
          onCancel={closeAlert}
          onConfirm={handleBulkDeleteDataLabEntries}
        />
      </div>
    );
  };

  return (
    <div className='m-4 space-y-2'>
      <DataTableToolbar table={table} />
      <div className='border rounded-md'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          {renderDeleteButton()}
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <>
                      <TableCell key={cell.id}>
                        {cell.column.id === 'created_at' ||
                        cell.column.id === 'last_updated_at'
                          ? !!cell.getValue()
                            ? dayjs(toInteger(cell.getValue()) * 1000).format(
                                'lll'
                              )
                            : '-'
                          : flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='h-24 mt-5 mb-5 text-center'
                >
                  <NoDataFound
                    hasButton={false}
                    headingText={t('No Results Found')}
                    descriptionText={t(
                      'Please filter with a different keyword.'
                    )}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination
        table={table}
        limit={limit}
        offset={offset}
        totalRowCount={totalRowCount}
        onLimitChange={handleLimitChange}
        onPageChange={handlePageChange}
      />
    </div>
  );
}