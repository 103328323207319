import {
  Button,
  Calendar,
  CalendarDays as CalendarIcon,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContent,
  PopoverTrigger,
  React,
  cn,
  format,
  useFormContext,
  type IFormFieldComponent,
} from '../../export';

export function DateTimeField({
  data,
  formActionType,
  valuePath,
}: IFormFieldComponent) {
  const shouldDisableInput = formActionType === 'VIEW';
  const currentFieldPath = valuePath.length
    ? valuePath + '.' + data.slug
    : data.slug;
  const form = useFormContext();
  const currentFieldValue = form.getValues(currentFieldPath);

  const [time, setTime] = React.useState('');

  const handleTimeChange = (newTime: string) => {
    setTime(newTime);
    const currentDateValue = form.getValues(currentFieldPath);
    const date = currentDateValue ? new Date(currentDateValue) : new Date();
    const times = newTime.split(':').map((v) => parseInt(v));
    date.setHours(times.at(0)!, times.at(1));
    form.setValue(currentFieldPath, date);
    form.clearErrors(currentFieldPath);
  };

  const handleDateChange = (selectedDate: Date | undefined) => {
    const date = selectedDate ? new Date(selectedDate) : new Date();
    const times = time ? time.split(':').map((v) => parseInt(v)) : [0, 0];
    date.setHours(times.at(0)!, times.at(1));
    form.clearErrors(currentFieldPath);
    form.setValue(currentFieldPath, date);
  };

  const isFormActionView = formActionType === 'VIEW';

  const renderDateTimeField = () => (
    <FormField
      control={form.control}
      disabled={data.is_readonly}
      name={currentFieldPath}
      rules={{
        required: {
          value: !!data.is_required,
          message: `${data.name} is required.`,
        },
      }}
      render={({ field }) => (
        <FormItem className='flex flex-col'>
          <FormLabel>
            {data.name}
            {data.is_required ? (
              <span className='text-destructive'>*</span>
            ) : null}
          </FormLabel>
          <FormControl>
            <Popover>
              <PopoverTrigger asChild disabled={shouldDisableInput}>
                <Button
                  type='button'
                  variant={'outline'}
                  className={cn(
                    'justify-between text-left font-normal data-[state="open"]:border-ring',
                    !currentFieldValue && 'text-muted-foreground'
                  )}
                >
                  {field.value ? (
                    format(field.value, 'dd/MM/yyyy hh:mm a')
                  ) : (
                    <span className='text-muted-foreground'>
                      Pick a date & time
                    </span>
                  )}
                  <CalendarIcon className='h-4 w-4' />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                className='bottom-0 lg:ml-[312px]  w-[320px] bg-white'
                onOpenAutoFocus={(e) => e.preventDefault()}
                align='start'
              >
                <div className=''>
                  <Calendar
                    mode='single'
                    selected={field.value}
                    onSelect={(day) => {
                      handleDateChange(day);
                    }}
                    // captionLayout='dropdown-buttons'
                    fromYear={new Date().getFullYear() - 200}
                    toYear={new Date().getFullYear() + 100}
                    initialFocus
                    footer={
                      <div className='border-t mt-2'>
                        <input
                          className='w-full mt-3 py-1 px-2 border  rounded-md focus:outline-none focus:ring-0 focus:border-green-500'
                          type='time'
                          value={time}
                          onChange={(e) => {
                            handleTimeChange(e.target.value);
                          }}
                        />
                      </div>
                    }
                  />
                </div>
              </PopoverContent>
            </Popover>
          </FormControl>
          <FormDescription
            className={
              data.help_text ? 'visible text-zinc-500 text-sm' : 'hidden'
            }
          >
            {data.help_text}
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
  const renderDateTimeFieldView = () => {
    return (
      <div className='border border-zinc-200 space-y-2 rounded-md p-3'>
        <p className='text-xs text-zinc-500'>
          {data?.label_agent || data.name}
        </p>
        <p className='text-zinc-900 text-sm'>
          {currentFieldValue || (
            <span className='text-zinc-500'> (Empty) </span>
          )}
        </p>
      </div>
    );
  };
  return isFormActionView ? renderDateTimeFieldView() : renderDateTimeField();
}

export default DateTimeField;
