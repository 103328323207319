import defaultAvatar from '../../../assets/images/default-avatar.png';
import { classNames } from 'utilities/utils';
import { renderAgentStatusBadgeColor } from 'pages/inbox/utils/functions';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  selectedAgent: number;
  selectedGroup: number;
  agents: AgentInterface[];
  groups: GroupInterface[];
  handleAgentChange: (agentId: number, type: string) => void;
}

const AgentListView: React.FC<Props> = ({
  agents,
  groups,
  selectedAgent,
  selectedGroup,
  handleAgentChange,
}) => {
  const { t } = useTranslation();
  const renderAgentList = () => {
    return (
      <div
        className={classNames(
          'bg-white mt-2',
          agents?.length > 0 ? 'block w-full' : 'hidden'
        )}
      >
        <span className='py-0.5 flex w-full my-2 border-t border-b text-xs text-gray-400 font-medium leading-5 border-gray-100'>
          {t('Members')}
        </span>
        {agents?.map((data: AgentInterface, index: number) => {
          return (
            <div
              className='flex gap-3 items-center py-1.5 px-1 cursor-pointer'
              key={index}
              onClick={() => handleAgentChange(data?.admin?.id, 'agent')}
            >
              <input
                type='radio'
                readOnly={true}
                checked={selectedAgent === data?.admin?.id}
                className='without-ring border-gray-300 text-primary'
              />
              <div className='relative'>
                <img
                  src={
                    !!data?.admin?.avatar ? data?.admin?.avatar : defaultAvatar
                  }
                  alt={'icon_box'}
                  className={
                    'h-6 w-6 rounded-full object-cover border border-gray-100'
                  }
                />
                <span
                  className={`absolute -bottom-1 right-0 inline-block w-3 h-3 ${renderAgentStatusBadgeColor(
                    data?.admin?.status
                  )} border-2 border-white rounded-full`}
                />
              </div>
              <span className='text-sm flex font-normal text-gray-900 leading-5'>
                {data?.admin?.full_name}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderGroupList = () => {
    return (
      <div
        className={classNames(
          'bg-white mt-2',
          groups?.length > 0 ? 'block' : 'hidden'
        )}
      >
        <span className='py-0.5 flex w-full my-2 border-t border-b text-xs text-gray-400 font-medium leading-5 border-gray-100'>
          {t('Groups')}
        </span>
        {groups?.map((data: GroupInterface, index: number) => {
          return (
            <div
              className='flex items-center gap-2 py-1.5 px-2 cursor-pointer'
              key={index}
              onClick={() => handleAgentChange(data?.id, 'group')}
            >
              <input
                type='radio'
                readOnly={true}
                checked={selectedGroup === data.id}
                className='without-ring border-gray-300 ml-0 text-primary'
              />
              <img
                src={!!data?.image ? data?.image : defaultAvatar}
                alt={'icon_box'}
                className={
                  'h-6 w-6 rounded-full object-cover border border-gray-100'
                }
              />

              <span className='text-sm font-normal text-gray-900 leading-5 ml-2'>
                {data?.name}
              </span>
              <div
                className={classNames(
                  'flex items-center',
                  data?.is_smart ? 'inline-block' : 'hidden'
                )}
              >
                <label className='px-1 py-0.5 ml-3 text-xs text-gray-800 bg-green-100 rounded'>
                  {t('Smart')}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <>
      {renderAgentList()}
      {renderGroupList()}
    </>
  );
};

export default AgentListView;
