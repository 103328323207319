import React from 'react';

interface Prop {
  fillColor?: string;
  width?: number;
  height?: number;
}

const PinIcon: React.FC<Prop> = ({
  fillColor = '#9CA3AF',
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill={fillColor}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.4194 6.50234L11.5554 9.37269C11.1378 10.0156 10.9625 10.7506 11.0108 11.4676L7.56751 9.23146C8.26115 8.98 8.84973 8.51341 9.25981 7.88193L11.1238 5.01158L13.4194 6.50234ZM15.8867 6.47226L10.1478 2.74538C9.83214 2.5404 9.40613 2.63102 9.20108 2.94676C8.99604 3.2625 9.08653 3.68854 9.40217 3.89352L9.97606 4.26621L8.11203 7.13656C7.49318 8.08951 6.2246 8.35936 5.27195 7.7407L4.52633 8.88884L7.95246 11.1138L5.34282 15.1323L5.54391 16.079L6.4906 15.8777L9.10024 11.8592L12.5608 14.1065L13.3064 12.9583C12.3538 12.3397 12.0843 11.071 12.7032 10.1181L14.5672 7.24772L15.1411 7.6204C15.4567 7.82538 15.8827 7.73476 16.0878 7.41902C16.2928 7.10328 16.2023 6.67724 15.8867 6.47226Z'
        fill={fillColor}
      />
    </svg>
  );
};

export const PinIconFilled: React.FC<Prop> = ({
  fillColor = '#F59E0B',
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.41147 8.36951L10.2755 5.49903L10.8496 5.87184C11.1653 6.07688 11.5914 5.9863 11.7965 5.67055C12.0015 5.35479 11.9109 4.92869 11.5952 4.72365L5.85424 0.995608C5.53849 0.790566 5.11239 0.881147 4.90735 1.1969C4.7023 1.51265 4.79288 1.93876 5.10864 2.1438L5.68273 2.5166L3.81871 5.38708C3.19986 6.34007 1.93101 6.6098 0.978015 5.99095L0.232408 7.13914L3.65975 9.36478L1.05013 13.3834L1.25142 14.3303L2.19832 14.1291L4.80795 10.1104L8.26974 12.3584L9.01535 11.2102C8.06235 10.5914 7.79262 9.32251 8.41147 8.36951Z'
        fill={fillColor}
      />
    </svg>
  );
};

export const PinIconOnLoad: React.FC<Prop> = ({
  fillColor = '#D97706',
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.41147 8.36951L10.2755 5.49903L10.8496 5.87184C11.1653 6.07688 11.5914 5.9863 11.7965 5.67055C12.0015 5.35479 11.9109 4.92869 11.5952 4.72365L5.85424 0.995608C5.53849 0.790566 5.11239 0.881147 4.90735 1.1969C4.7023 1.51265 4.79288 1.93876 5.10864 2.1438L5.68273 2.5166L3.81871 5.38708C3.19986 6.34007 1.93101 6.6098 0.978015 5.99095L0.232407 7.13914L3.65975 9.36478L1.05013 13.3834L1.25142 14.3303L2.19832 14.1291L4.80795 10.1104L8.26974 12.3584L9.01535 11.2102C8.06235 10.5914 7.79262 9.32251 8.41147 8.36951Z'
        fill={fillColor}
      />
    </svg>
  );
};

export default PinIcon;
