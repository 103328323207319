import { XMarkIcon } from '@heroicons/react/20/solid';
import { GroupMemberInterface } from 'pages/inbox/inboxInterface';

interface Props {
  suggestions: GroupMemberInterface[];
  handleClose: () => void;
  onMentionSelect: (member: GroupMemberInterface) => void;
}

const MentionPopup: React.FC<Props> = ({
  suggestions,
  handleClose,
  onMentionSelect,
}) => {
  return (
    <div className='absolute -top-40 z-10 mt-2 h-40 max-h-[160px] w-96 max-w-sm overflow-y-scroll bg-white border border-gray-50  rounded-lg shadow-lg'>
      <div className='flex items-center py-2 px-3'>
        <span className=' text-sm font-semibold text-gray-800  '>
          Group members
        </span>
        <XMarkIcon
          className=' h-4 text-gray-500 ml-auto cursor-pointer'
          onClick={() => handleClose()}
        />
      </div>
      {suggestions.map((suggestion) => (
        <button
          key={suggestion?.id}
          onClick={() => onMentionSelect(suggestion)}
          className='block w-full px-3 py-2 text-left cursor-pointer hover:bg-gray-100 focus:outline-none'
        >
          <div className='flex items-center'>
            <span className=' text-sm font-normal text-gray-900 '>
              {suggestion?.name}
            </span>
            {suggestion?.username.length !== 0 && (
              <span className=' text-xs font-normal text-gray-500 ml-auto'>
                @{suggestion?.username}
              </span>
            )}
          </div>
        </button>
      ))}
    </div>
  );
};

export default MentionPopup;
