import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';

interface Props {
  limit: number;
  offset: number;
  total: number;
  disablePrev: boolean;
  disableNext: boolean;
  next: () => void;
  prev: () => void;
}

const Pagination: React.FC<Props> = ({
  limit,
  offset,
  total,
  disablePrev,
  disableNext,
  next,
  prev,
}) => {
  const { t } = useTranslation();
  return (
    <div className='flex items-center border-t border-gray-200 bg-white px-6 py-3 w-full rounded-b-lg'>
      <div className='flex items-center w-full'>
        <div className='w-1/2'>
          <p className='text-sm text-gray-700'>
            {t('Showing')} <span className='font-medium'>{offset + 1}</span>{' '}
            {t('to')} <span className='font-medium'> {limit} </span> {t('of')}{' '}
            <span className='font-medium'>{total}</span> {t('results')}
          </p>
        </div>
        <div className='flex w-1/2'>
          <div className='flex w-full justify-end'>
            {!disablePrev && (
              <button
                className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white
                px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${
                  disablePrev ? 'bg-gray-100 cursor-not-allowed' : ''
                }`}
                disabled={disablePrev}
                onClick={prev}
              >
                {t('Previous')}
              </button>
            )}
            {!disableNext && (
              <button
                className={`relative ml-3 inline-flex items-center rounded-md
                  border border-gray-300 bg-white px-4 py-2 text-sm font-medium
                  text-gray-700 hover:bg-gray-50 ${
                    disableNext ? 'bg-gray-100 cursor-not-allowed' : ''
                  }`}
                disabled={disableNext}
                onClick={next}
              >
                {t('Next')}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
