import axios from '../../../utilities/httpClient';
import config from '../../../utilities/config';
import { toaster } from 'evergreen-ui';

const initialState = {};

export const onboarding = {
  state: {
    ...initialState,
  },
  reducers: {
    updateInitialState(state, payload) {
      return { ...state, ...payload };
    },
    updateStateData(state, payload) {
      return { ...state, [payload.key]: payload.value };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async createChannelIntegrations(payload) {
      try {
        const res = await axios.post(
          config.onboarding.createChannelIntegrations(
            payload.channelType,
            payload.teamId
          ),
          payload.data
        );

        if (res.status === 200) {
          return res;
        }
      } catch (err) {
        return err.response;
      }
    },

    async fetchFacebookPages(payload) {
      /*
        payload = {
          id: num // fb userID
          token: string //temp accessToken
        }
       */
      try {
        const res = await axios.get(
          config.onboarding.facebookPages(
            payload.id,
            payload.token,
            payload.type
          )
        );
        return res.data.dataSource;
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while fetching facebook pages. Try again later.'
          );
        } else {
          toaster.danger(err.response.data.error);
        }
        return false;
      }
    },

    async fetchInstagramPages(payload) {
      /*
        payload = {
          id: num // fb userID
          token: string //temp accessToken
        }
       */
      try {
        const res = await axios.get(
          config.channel.instagramPage(payload.id, payload.token, payload.type)
        );
        return res.data.dataSource;
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger(
            'Something went wrong while fetching instagram pages. Try again later.'
          );
        } else {
          toaster.danger(err.response.data.error);
        }
      }
    },
    async instagramMessengerChannelConnectionValidation(payload) {
      try {
        const res = await axios.post(
          config.integration.instagramMessengerChannelConnectionValidation(
            payload.channelType,
            payload.teamId
          ),
          payload.data
        );

        if (res.status === 200) {
          return res;
        }
      } catch (err) {
        return err.response;
      }
    },
  }),
};
