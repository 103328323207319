import {
  ExclamationCircleIcon,
  MicrophoneIcon,
  PaperClipIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import { cn } from 'libraryV2/utils';
import { CornerDownLeft, CornerDownRight } from 'lucide-react';
import {
  ButtonDataType,
  CustomerReplyBlockInterface,
} from 'pages/inbox/inboxInterface';
import React from 'react';
import Linkify from 'react-linkify';
import videoIcon from '../../../assets/images/videoIcon.svg';

interface Props {
  blockData: CustomerReplyBlockInterface;
  isConversationBlock?: boolean;
  handleCustomerReplyOnClose?: () => void;
  customerName?: string;
  isRightAligned?: boolean;
  isZigzagView?: boolean;
}

const CustomerReply: React.FC<Props> = ({
  blockData,
  customerName = '',
  isRightAligned = false,
  isConversationBlock = true,
  handleCustomerReplyOnClose,
  isZigzagView,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  const getRepliedMessageType = () => {
    switch (blockData?.type) {
      case 'text':
        return 'text';
      case 'attachment':
        return blockData?.sub_type;
      case 'quick_reply':
        return 'quick reply';
      case 'ads':
        return 'Ads';
      default:
        return 'a message';
    }
  };

  const getImageBlocks = () => {
    return (
      <div className='flex max-w-sm p-1'>
        <img
          className={`cursor-pointer object-cover rounded-xl h-24 w-24`}
          src={blockData?.urls[0]}
          alt='media'
        />
        <div
          className={`w-auto h-auto line-clamp-3 ${
            !!blockData?.success ? 'text-gray-900' : 'text-gray-500'
          }
        sm:text-sm break-words whitespace-pre-wrap mt-0`}
        >
          {blockData?.text}
        </div>
      </div>
    );
  };

  const getVideoBlocks = () => {
    return (
      <div className={`flex max-w-[396px]`}>
        <div className='relative cursor-pointer'>
          <div
            className={`grid gap-1 ${
              !!blockData && !!blockData.urls && blockData.urls.length === 1
                ? 'grid-cols-1'
                : 'grid-cols-4'
            }`}
          >
            {blockData?.urls?.map((url, index) => (
              <div key={index} className='relative cursor-pointer'>
                <div
                  className={`absolute bg-gray-500 bg-opacity-40 rounded-md ${
                    !!blockData.urls && blockData.urls.length === 1
                      ? 'h-[64px] w-[64px]'
                      : 'h-[32px] w-[32px]'
                  }`}
                />
                <video
                  className={`object-fill rounded-md ${
                    !!blockData.urls && blockData.urls.length === 1
                      ? 'h-[64px] w-[64px]'
                      : 'h-[32px] w-[32px]'
                  }`}
                >
                  <source data-testid='media-video-block' src={url} />
                </video>
                <img
                  className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                    !!blockData.urls && blockData.urls.length === 1
                      ? 'w-[10.6px] h-[10.6px]'
                      : 'w-[6.2px] h-[6.2px]'
                  }`}
                  src={videoIcon}
                  alt='videoIcon'
                />
              </div>
            ))}
          </div>
        </div>
        <div
          className={`w-auto h-auto line-clamp-3 ${
            !!blockData?.success ? 'text-gray-900' : 'text-gray-500'
          }
        sm:text-sm break-words whitespace-pre-wrap mt-2`}
        >
          {blockData?.text}
        </div>
      </div>
    );
  };

  const getAttachmentBlock = () => {
    return (
      <>
        <div className='flex items-center justify-start gap-1'>
          <PaperClipIcon className='w-5 h-5 text-gray-500' />
          <span className='block text-sm font-normal text-gray-700'>
            {t('Attachment')}
          </span>
        </div>
      </>
    );
  };

  const getVoiceMessageBlock = () => {
    return (
      <>
        <div className='flex items-center justify-start gap-1'>
          <MicrophoneIcon className='w-5 h-5 text-gray-500' />
          <span className='block text-sm font-normal text-gray-700'>
            {t('Voice message')}
          </span>
        </div>
      </>
    );
  };

  const renderButtonTextView = () => {
    return (
      <p className='w-auto h-auto text-gray-900 break-words whitespace-pre-wrap sm:text-sm'>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              target='blank'
              className='text-link'
              href={decoratedHref}
              key={key}
            >
              {decoratedText}
            </a>
          )}
        >
          {!!blockData &&
            (!!blockData.success ? blockData.text : blockData.error)}
        </Linkify>
      </p>
    );
  };

  const getButtons = (button: ButtonDataType) => {
    return (
      <div className='pt-1' key={`${button?.id}`}>
        <button
          className='content-center px-2 text-xs font-medium text-gray-700 truncate bg-white border border-gray-300 rounded-md cursor-not-allowed w-60 h-7 drop-shadow-sm hover:bg-gray-100'
          disabled
        >
          {button?.title}
        </button>
      </div>
    );
  };

  const getButtonsBlock = () => {
    return (
      <div className='p-2 border border-gray-300 rounded-md'>
        {!!blockData && !!blockData.text && renderButtonTextView()}
        {!!blockData &&
          !!blockData.button &&
          blockData?.button?.map((eachButton) => getButtons(eachButton))}
      </div>
    );
  };

  const scrollToMessage = (id: string) => {
    const content: HTMLElement | null = document.querySelector(
      `[data-id="${id}"]`
    );
    const container = document.getElementById('conversation-bar');
    if (!!content && !!container) {
      container?.scrollTo({
        top: content?.offsetTop + 300 - container.offsetHeight,
        behavior: 'smooth',
      });

      content.style.backgroundColor = '#f3f4f6';
      content.style.transform = 'scale(1.005)';
      setTimeout(() => {
        content.style.backgroundColor = 'transparent';
        content.style.transform = 'none';
      }, 1500);
    }
  };

  const renderContent = () => {
    if (blockData?.success) {
      if (blockData?.type === 'text') {
        return (
          <span className='block text-sm font-normal text-gray-700'>
            {blockData?.text}
          </span>
        );
      } else if (blockData?.type === 'button') {
        return getButtonsBlock();
      } else if (blockData?.type === 'quick_reply') {
        return (
          <span className='block text-sm font-normal text-gray-700'>
            {blockData?.title}
          </span>
        );
      } else {
        switch (blockData?.sub_type) {
          case 'image':
            return !!blockData?.urls && getImageBlocks();
          case 'video':
            return !!blockData?.urls && getVideoBlocks();
          case 'audio':
            return !!blockData?.urls && getVoiceMessageBlock();

          default:
            return getAttachmentBlock();
        }
      }
    } else {
      return (
        <div className='flex items-center justify-start'>
          {blockData?.error && (
            <ExclamationCircleIcon className='w-6 h-6 text-red-600 ' />
          )}
          <span className='ml-2 text-sm font-normal text-gray-700'>
            {blockData?.error || `Loading reply message...`}
          </span>
        </div>
      );
    }
  };

  const renderReplyBlock = () => {
    return (
      <div
        className={` ${
          blockData?.type !== 'text' && isZigzagView && 'self-end'
        } ${blockData?.type === 'text' && !isZigzagView && 'self-start'} ${
          blockData?.type !== 'text' && !isZigzagView && 'self-start'
        } ${blockData?.type === 'text' && isZigzagView && 'self-end'} ${
          !isConversationBlock && 'mx-4 mb-1 drop-shadow-2xl bg-gray-50'
        }`}
        style={{
          width: 'fit-content',
        }}
      >
        <div
          className={`text-[#A1A1AA] flex items-center ${
            isZigzagView ? 'justify-end' : 'justify-start'
          } gap-1 mb-1`}
        >
          {isRtlLanguage ? (
            <CornerDownLeft className='w-4 h-4' />
          ) : (
            <CornerDownRight className='w-4 h-4' />
          )}

          <span className='block text-xs font-normal text-gray-500'>
            {t('Replied to the')} {customerName || t(getRepliedMessageType())}
          </span>
        </div>
        <div className='flex flex-col relative'>
          <div
            className={`flex bg-[#F4F4F5] pb-2 -mb-2 rounded-lg rounded-br-none ${
              isConversationBlock ? 'cursor-pointer' : 'cursor-default'
            }  ${
              blockData?.type === 'attachment'
                ? 'justify-center'
                : 'justify-between'
            }`}
            onClick={() =>
              scrollToMessage(!!blockData?.payload ? blockData?.payload : '')
            }
          >
            <div
              className={cn(
                'flex flex-col px-3 py-2',
                blockData?.type === 'attachment' && 'p-2'
              )}
            >
              {renderContent()}
            </div>
            {!isConversationBlock && !!handleCustomerReplyOnClose && (
              <XMarkIcon
                className='h-4 m-2 text-gray-600 cursor-pointer'
                onClick={() => handleCustomerReplyOnClose()}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return <>{renderReplyBlock()}</>;
};

export default CustomerReply;
