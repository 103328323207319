import { DocumentIcon, PlayCircleIcon } from '@heroicons/react/24/solid';
import useTranslation from 'components/customHooks/useTranslation';
import { cn } from 'libraryV2/utils';
import { Dot } from 'lucide-react';
import useMessageBlocksAction from 'pages/inbox/hooks/useMessageBlocksAction';
import {
  ButtonDataType,
  ConversationBlockTemplateDataInterface,
  conversationStatusType,
} from 'pages/inbox/inboxInterface';
import React from 'react';
import defaultMediaImage from '../../../assets/images/defaultMediaImage.svg';
import Avatar from '../components/conversation/Avatar';
import ConversationBlockWrapper from '../components/conversation/ConversationBlockWrapper';
import DateBar from './DateBar';

interface Props {
  blockData: ConversationBlockTemplateDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  isSent: conversationStatusType | string;
  isMergeable: boolean;
  conversationId: string;
  enableDateBar: boolean;
  report?: any; // TODO: format report
}

const TemplateBlock: React.FC<Props> = ({
  blockData,
  avatar,
  name,
  time,
  source,
  isSent,
  isMergeable,
  enableDateBar,
  report,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const { rowDirection, elementDirection, isZigzagView } =
    useMessageBlocksAction({ source });

  const getSourceNameColor = () => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
      case 'echo':
        return 'text-gray-900';
      default:
        return 'text-green-500';
    }
  };

  const renderTemplateBody = () => {
    return (
      <div className='mx-2 my-2 leading-4 text-gray-900 break-words whitespace-pre-wrap sm:text-xs'>
        {blockData.body?.length > 192 && !isOpen
          ? blockData.body?.substring(0, 192) + '.. '
          : blockData.body}
        {blockData.body.length > 192 && (
          <button
            className='text-[#0078CF] underline'
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? 'See less' : 'See more'}
          </button>
        )}
      </div>
    );
  };
  const renderTemplateHeader = () => {
    switch (blockData?.header_type) {
      case 'text':
        return (
          <p className='mx-2 my-2 font-semibold text-gray-900 break-words whitespace-pre-wrap sm:text-xs'>
            {blockData?.header}
          </p>
        );
      case 'image':
        return (
          <img
            data-testid='template-image'
            className='object-cover w-full h-[152px] mx-auto'
            src={blockData?.header}
            alt='templateImage'
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = defaultMediaImage;
            }}
          />
        );
      case 'video':
        return (
          <div className={`flex w-full`}>
            <div key={1} className='relative w-full cursor-pointer'>
              <div className='absolute bg-gray-500 bg-opacity-40 rounded-t-md h-[120px] w-full flex justify-center items-center'>
                <PlayCircleIcon className='text-white w-9 h-9' />
              </div>
              <video className='object-fill rounded-t-md h-[120px] w-full'>
                <source src={blockData?.header} />
              </video>
            </div>
          </div>
        );
      case 'document':
        return (
          <div className='relative flex flex-col items-center py-5 bg-gray-100 rounded-t-xl'>
            <DocumentIcon className='w-10 h-10 text-gray-400' />
            <div className='w-[80%] text-center mt-2 truncate text-[#131B20]'>
              {blockData?.header?.split('/').pop()}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderTemplateView = () => {
    return (
      <div data-testid='template-block' className='flex'>
        <div className='w-56 h-auto bg-[#FAFAFA] rounded-xl'>
          {renderTemplateHeader()}
          {!!blockData.body && renderTemplateBody()}
          {!!blockData.footer && (
            <p className='mx-2 my-2 font-normal text-gray-500 break-words whitespace-pre-wrap sm:text-xs'>
              {blockData.footer}
            </p>
          )}
          {!!blockData.buttons && blockData.buttons.length !== 0 && (
            <div className='flex flex-col pb-1 mx-2 mt-1 place-content-end'>
              {blockData.buttons.map((button) => getButtons(button))}
            </div>
          )}
        </div>
      </div>
    );
  };

  const getButtons = (button: ButtonDataType) => {
    return (
      <button
        className='w-full px-2 mb-1 font-medium text-gray-700 truncate bg-white border border-gray-300 rounded-md cursor-not-allowed h-7 hover:bg-gray-100 sm:text-xs'
        key={button?.id}
      >
        {button?.title}
      </button>
    );
  };

  return (
    <>
      {!isMergeable && (
        <div
          className={`flex ${rowDirection} w-full h-auto pt-0.5 pb-1.5 hover:bg-gray-100`}
        >
          <Avatar avatar={avatar} />
          <div>
            <div className={`flex ${rowDirection}`}>
              <div
                className={`my-1.5 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()}`}
              >
                {t(name)}
              </div>
              <Dot
                className={cn(
                  'self-end w-6 h-6 mt-1 flex items-center justify-center text-[#DFDFE2]',
                  !isZigzagView && 'order-1'
                )}
              />
              <div
                className={cn(
                  'mt-2 font-medium text-[#04B25F] sm:text-xs',
                  !isZigzagView && 'order-2'
                )}
              >
                {t('Message Template')}
              </div>
            </div>
            <div>
              <ConversationBlockWrapper
                time={time}
                success={isSent}
                isMerged={false}
                report={report}
                source={source}
                isFirstElement={!isMergeable}
                conversationType={blockData?.type}
              >
                {renderTemplateView()}
              </ConversationBlockWrapper>
            </div>
          </div>
        </div>
      )}

      {!!isMergeable && (
        <div
          className={`relative flex ${elementDirection} w-full h-auto py-1 px-16 group hover:bg-gray-50`}
        >
          {/* Template Block */}
          <ConversationBlockWrapper
            time={time}
            success={isSent}
            isMerged={false}
            report={report}
            source={source}
            isFirstElement={!isMergeable}
            conversationType={blockData?.type}
          >
            {renderTemplateView()}
          </ConversationBlockWrapper>
        </div>
      )}
      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default TemplateBlock;
