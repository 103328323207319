import React from 'react';
import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../../../../library';
import EmptyTableCellsIcon from './../../assets/images/EmptyTableIcon.svg';
import dayJs from 'dayjs';
import { useDebounce } from '../../../../components';
import { LinearLoaderComponent } from '../../../../utilities/utils';
import { analytics } from '../../../../utilities/content';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  handleRespondedListNext: () => void;
  handleRespondedListPrev: () => void;
  handleRespondedSearch: (search: string) => void;
  total: number;
  list: any[];
  offset: number;
  loader: boolean;
  handleExport: () => void;
  exportLoader?: boolean;
}

const TableHead = () => {
  const { t } = useTranslation();
  return (
    <thead className='bg-gray-50'>
      <tr>
        <th
          scope='col'
          className='px-5 py-3 text-xs font-normal tracking-wider text-left text-gray-500 uppercase'
        >
          {t('Customer Name')}
        </th>
        <th
          scope='col'
          className='px-5 py-3 text-xs font-normal tracking-wider text-left text-gray-500 uppercase'
        >
          {t('Assigned bot/agent name')}
        </th>
        <th
          scope='col'
          className='px-5 py-3 text-xs font-normal tracking-wider text-left text-gray-500 uppercase'
        >
          {t('Rating')}
        </th>
        <th
          scope='col'
          className='px-5 py-3 text-xs font-normal tracking-wider text-left text-gray-500 uppercase'
        >
          {t('Time of Rating')}
        </th>
        <th
          scope='col'
          className='px-5 py-3 text-xs font-normal tracking-wider text-left text-gray-500 uppercase'
        >
          {t('Ticket ID')}
        </th>
      </tr>
    </thead>
  );
};

const ResponseCsat: React.FC<Prop> = ({
  handleRespondedSearch,
  handleRespondedListPrev,
  handleRespondedListNext,
  total,
  offset,
  list,
  loader,
  handleExport,
  exportLoader,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = React.useState('');

  const debouncedValue = useDebounce(search, 1000);

  React.useEffect(() => {
    handleRespondedSearch(debouncedValue);
    // eslint-disable-next-line
  }, [debouncedValue]);

  return (
    <div id='response-csat-list-id'>
      <div className='px-5 py-3.5 block sm:flex sm:items-center border-b sm:justify-between bg-white shadow rounded-t-lg mr-px ml-px'>
        <div className='flex text-base font-normal text-gray-900'>
          {t(analytics.CSAT.responseList.title)}
          <span
            className='mt-1 ltr:ml-2 rtl:mr-2'
            title={t(analytics.CSAT.responseList.toolTip)}
          >
            <InformationCircleIcon className='w-4 h-4' />
          </span>
        </div>
        <div className='mt-3 sm:mt-0 sm:ltr:ml-4 sm:rtl:mr-4'>
          <label htmlFor='search_candidate' className='sr-only'>
            Search
          </label>
          <div className='flex rounded-md'>
            <div className='relative flex-grow shadow-sm focus-within:z-0'>
              <div className='absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3 pointer-events-none'>
                <MagnifyingGlassIcon
                  className='w-5 h-5 text-gray-400'
                  aria-hidden='true'
                />
              </div>
              <input
                type='text'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className='w-full ltr:pl-10 rtl:pr-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:hidden sm:text-sm'
                placeholder={t('Search By Customer Name')}
              />
              <input
                type='text'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className='hidden w-full ltr:pl-10 rtl:pr-10 ltr:mr-20 rtl:ml-20 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:block sm:text-sm'
                placeholder={t('Search By Customer Name')}
              />
            </div>
            <Button
              intent='default'
              isDisabled={list.length === 0}
              size={'sm'}
              onClick={() => handleExport()}
              className={'ltr:ml-4 rtl:mr-4'}
            >
              {!exportLoader && (
                <ArrowDownTrayIcon
                  className='ltr:-ml-0.5 rtl:-mr-0.5 ltr:mr-2 rtl:ml-2 h-4 w-4'
                  aria-hidden='true'
                />
              )}
              {t('Export')}
            </Button>
          </div>
        </div>
      </div>
      <div className='w-full'>
        <div className='flex flex-col'>
          <div className='-my-2 overflow-x-auto scroll-none'>
            <div className='inline-block min-w-full py-2 align-bottom'>
              <div className='overflow-hidden shadow sm:rounded-b-lg'>
                {loader && <LinearLoaderComponent />}
                {!loader && list.length !== 0 && (
                  <>
                    <table className='min-w-full min-h-full border-b border-l divide-y divide-gray-200 shadow'>
                      <TableHead />
                      <tbody className='bg-white divide-y divide-gray-200'>
                        {list.map((csatItem, index) => (
                          <tr key={index}>
                            <td className='px-5 py-3 text-sm rtl:text-right font-medium text-gray-900 whitespace-nowrap'>
                              {csatItem.customer?.full_name || 'Unknown User'}
                            </td>
                            <td className='px-5 py-3 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                              {!!csatItem.sender
                                ? csatItem.sender?.full_name || 'Unknown Agent'
                                : 'Bot'}
                            </td>
                            <td className='px-5 py-3 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                              {csatItem.rating_text || 'N/A'}
                            </td>
                            <td className='px-5 py-3 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                              {!!csatItem.created_at
                                ? dayJs(csatItem.created_at * 1000).fromNow()
                                : 'N/A'}
                            </td>
                            <td className='px-5 py-3 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                              {csatItem?.ticket_id || 'N/A'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className='mt-auto bg-white border-l border-r'>
                      <div className='flex items-center justify-between px-5 py-3 whitespace-nowrap'>
                        <p className='text-sm font-normal text-gray-900'>
                          {t('Showing')}{' '}
                          <span className='font-medium'>{offset + 1}</span>{' '}
                          {t('to')}{' '}
                          <span className='font-medium'>
                            {offset + list.length}
                          </span>{' '}
                          {t('of')} <span className='font-medium'>{total}</span>{' '}
                          {t('results')}
                        </p>
                        <div className='flex'>
                          {offset > 9 && (
                            <Button
                              intent='default'
                              size={'sm'}
                              onClick={() => handleRespondedListPrev()}
                              className={offset + 10 < total ? 'mr-4' : ''}
                            >
                              {t('Previous')}
                            </Button>
                          )}
                          {offset + 10 < total && (
                            <Button
                              intent='default'
                              onClick={() => handleRespondedListNext()}
                              size={'sm'}
                            >
                              {t('Next')}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {!loader && list.length === 0 && (
                  <div className='flex flex-col items-center justify-center min-w-full min-h-full p-8 bg-white border-l border-r shadow'>
                    <img
                      src={EmptyTableCellsIcon}
                      alt={'empty table'}
                      className={'w-32 mt-40'}
                    />
                    <p className='mt-8 mb-40 font-sans text-sm font-normal'>
                      {t('No data available for this timeline')}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponseCsat;
